import { useEffect } from 'react';

const useScrollToTop = (): void => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
};

export { useScrollToTop };
