import { Col, Form, Row, Steps } from 'antd';
import * as React from 'react';
import OperatorDetails from './operator-details-form';
import { domHelpers } from '../../helpers/dom.helpers';
import OperatorContactForm from './operator-contact-form';
import { useLoader } from '../../stores/use-loader';
import _ from '../../helpers/lodash';
import { operatorHelpers } from '../../helpers/operator.helpers';
import { operatorService } from '../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

interface IOperationCreationFormProps {
  fetchedOperatorInfo?: any;
}

const OperationCreationForm: React.FunctionComponent<IOperationCreationFormProps> = ({ fetchedOperatorInfo }) => {
  const [operatorDetailsForm] = Form.useForm();
  const [operatorsContactForm] = Form.useForm();

  const [currentStep, setCurrentStep] = React.useState(0);

  const [isUpdate, setIsUpdate] = React.useState(false);

  const [formData, setFormData] = React.useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const { operatorId } = useParams();
  const navigate = useNavigate();
  const handleSubmit = async (formValues: any) => {
    const request = operatorHelpers.generateOperatorsRequestBody(formValues);

    if (isUpdate) {
      await handleUpdate(request);
    } else {
      await handleCreate(request);
    }
  };

  const handleCreate = async (request: any) => {
    const { errors } = await operatorService.createOperator(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Operator Created Successfully' });
      setFormData({});
      operatorDetailsForm.resetFields();
      operatorsContactForm.resetFields();
      setCurrentStep(0);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const handleUpdate = async (request: any) => {
    const { errors } = await operatorService.updateOperator(request, operatorId as string);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Operator updated Successfully' });

      setCurrentStep(0);
    } else {
      displayErrorNotifications(errors);
    }
  };
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    setLoading(true);
    if (!_.isEmpty(fetchedOperatorInfo)) {
      setIsUpdate(true);
      setFormData(fetchedOperatorInfo);
      operatorDetailsForm.setFieldsValue(fetchedOperatorInfo);
      operatorsContactForm.setFieldsValue(fetchedOperatorInfo);
    }else{
      operatorDetailsForm.setFieldsValue({
        is_package_provided: false
      })
    }

    setLoading(false);
  }, [fetchedOperatorInfo]);

  const handleClickNext = async (currentFormValues: any) => {
    domHelpers.scrollMainContentToTop();
    setFormData(formData => ({ ...formData, ...currentFormValues }));
    const formValues = { ...formData, ...currentFormValues };
    if (currentStep < 1) {
      setCurrentStep(current => current + 1);
    }

    if (currentStep === 1) {
      await handleSubmit(formValues);
    }
  };
  const backUrl = searchParams.get('goBackTo');

  const handleClickBack = (currentFormValues: any) => {
    if (currentStep === 0) {
      return backUrl ? navigate(backUrl) : navigate('/operators');
    }

    domHelpers.scrollMainContentToTop();

    setFormData(formData => ({ ...formData, ...currentFormValues }));

    if (currentStep > 0) setCurrentStep(current => current - 1);
  };

  return (
    <>
      <div className="my-8">
        <Row justify={'start'}>
          <Col xs={24} md={12}>
            <Steps
              responsive
              current={currentStep}
              items={[
                {
                  title: 'Operator Details & Configuration'
                },

                {
                  title: 'Operator Contact Details'
                }
              ]}
            />
          </Col>
        </Row>
      </div>

      {currentStep === 0 && (
        <OperatorDetails
          operatorDetailsForm={operatorDetailsForm}
          operatorDetails={formData}
          handleClickBack={handleClickBack}
          handleClickNext={handleClickNext}
        />
      )}
      {currentStep === 1 && (
        <OperatorContactForm
          currentStep={currentStep}
          isUpdate={isUpdate}
          operatorContactForm={operatorsContactForm}
          operatorDetails={formData}
          handleClickBack={handleClickBack}
          handleClickNext={handleClickNext}
        />
      )}
    </>
  );
};

export default OperationCreationForm;
