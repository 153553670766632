import { PRODUCT, PROMISE } from "../config/envConfig";
import httpClient from "../http/http.client";

export const getPdpFulFilmentOption = async (bodyData: any) => {
    const url = `${PROMISE}/v1/promise/pdp`
    return httpClient.post({ url, body: bodyData })
};

export const getQuantityOfCategory = (articleId: string) => {
    const url = `${PRODUCT}/v1/products/${articleId}`
    return httpClient.get({ url })
  }

export const promise = {
    getPdpFulFilmentOption,
    getQuantityOfCategory
};