import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Statistic, Switch } from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import PrimaryLayout from '../../../layouts/primary-layout';

import { CheckCircleOutlined, DeleteOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { urlHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { cbtProductService } from '../../../services/cbt-product.service';
import { useLoader } from '../../../stores/use-loader';
import { useLocale } from '../../../stores/use-locale';
import { IDomainPlanMap, Plan } from '../../../types/cbt-product.types';

interface IProductsPageProps { }
interface IProductListItemProps {
  name: string
  isSelected: boolean
  handleClickProduct: (domain: string) => void;
}

export const currencyList = [
  { label: '🇮🇳 INR', value: 'INR', country: 'India' },
  { label: '🇨🇱 CLP', value: 'CLP', country: 'Chile' },
  { label: '🇺🇸 USD', value: 'USD', country: 'USA' }
];


const ProductItem = ({
  label,
  domain,
  description,
  handleClickProduct,
  key,
  isSelected
}: {
  label: string;
  domain: string;
  description: string;
  key: string;
  handleClickProduct: (domain: string) => void;
  isSelected: boolean;
}) => {
  return (
    <div
      onClick={() => handleClickProduct(domain)}
      key={key}
      role="button"
      className={`min-h-[60px] border-b-solid border-slate-300 border-b-2 p-4 cursor-pointer`}
    >
      <div className="flex justify-between">
        <div>
          <span className="font-bold text-xl">{label}</span>
        </div>
        <div>{isSelected ? <CheckCircleOutlined size={24} style={{ color: 'green' }} /> : <RightOutlined />}</div>
      </div>
    </div>
  );
};

const ProductListItem: React.FunctionComponent<IProductListItemProps> = ({ name, isSelected, handleClickProduct }) => {
  const Label = name.charAt(0).toUpperCase() + name.slice(1)
  return (
    <div
      key={name}
      role="button"
      onClick={() => handleClickProduct(name)}
      className={`min-h-[60px] border-b-solid border-slate-300 border-b-2 p-4 cursor-pointer`}
    >
      <div className="flex justify-between">
        <div>
          <span className="font-bold text-xl">{Label}</span>
        </div>
        <div>{isSelected ? <CheckCircleOutlined size={24} style={{ color: 'green' }} /> : <RightOutlined />}</div>
      </div>
    </div>
  )
}

const PlansList = ({ plans, handleClickAddPlan }: { plans: Plan[]; handleClickAddPlan: () => void }) => {
  if (!plans.length) {
    return (
      <div className="text-center flex items-center flex-col gap-4 mt-8">
        <div>
          <span className="text-lg font-semibold">There are no plans available for the selected product!</span>
        </div>
        <Button type="primary" onClick={handleClickAddPlan} size="large">
          <BoldButtonLabel labelText="Add Plan"></BoldButtonLabel>
        </Button>
      </div>
    );
  }

  return (
    <section className="p-2">
      <div className="mb-4">
        <span className="text-2xl">Plans</span>
      </div>

      <div style={{ height: 'calc(100vh - 430px)', overflow: 'auto' }}>
        {plans.map(plan => {
          return (
            <PlanItem plan={plan} />
          )
        })}
      </div>
    </section>
  );
};

const PlanItem = ({
  plan: { name, amount, currency, plan_feature, description, interval, trial_days, api_limit, tax_percent, trial_usage_limit, api_range, ...rest }
}: {
  plan: any;
}) => {
  const { userCurrency } = useLocale(({ userCurrency }) => ({ userCurrency }));
  return (
    <>
      <section className="border-2 border-solid border-slate-300 p-4 rounded-lg">

        <Row justify={'space-between'}>
          <Col>
            <span className="text-xl font-semibold">{name}</span>
          </Col>
          {/* <Col>
            <span className="text-xl font-semibold">
              {priceHelpers.formatPriceToLocaleCurrency({ userCurrency, amount, baseCurrency: currency })}
            </span>
          </Col> */}
          <Col xs={8} className="text-right">
            <span className="text-sm font-semibold text-[#898a8c]">INTERVAL - {interval}</span>
          </Col>
        </Row>
        <Row justify={'space-between'}>
          <Col xs={16}>
            <span className="text-sm font-semibold text-[#898a8c]">{description}</span>
          </Col>
          {/* <Col xs={8} className="text-right">
            <span className="text-sm font-semibold text-[#898a8c]">{interval}</span>
          </Col> */}
        </Row>

        <div className="mt-4">
          <span className="text-sm font-semibold">{trial_days} Day(s) Free trial</span>
          <span className="text-sm font-semibold"> | Free trial limit - {trial_usage_limit}</span>
          {/* <span className="text-sm font-semibold"> | Overage - {overage}</span> */}
          {/* <span className="text-sm font-semibold"> | API Limit - {api_limit}</span> */}
          <span className="text-sm font-semibold"> | Tax - {tax_percent} %</span>
          <div className="mt-2">
            <div>
              <span className="font-semibold text-lg">API Limit</span>
            </div>
            {api_range?.map((data: any, index: any) => {
              return (
                <li key={index} className="text-[#898a8c] font-semibold">
                  - Amount : {data?.amount}, Api Limit From : {data?.api_limit_from}, Api Limit To : {data?.api_limit_to} , Is per Api : {data?.is_per_api.toString()}
                </li>
              );
            })}
          </div>
        </div>

        <div className="mt-2">
          <div>
            <span className="font-semibold text-lg">Features</span>
          </div>
          {plan_feature?.map((data: any) => {
            return (
              <li key={data?.feature_name} className="text-[#898a8c] font-semibold">
                - {data?.feature_name}
              </li>
            );
          })}
        </div>

      </section>
      <Divider type="horizontal" />
    </>
  );
};

const ProductsPage: React.FunctionComponent<IProductsPageProps> = props => {

  const handleClickProduct = async (domain: string) => {
    setCurrentActiveDomain(domain);
    setShowPlan(planListResp[domain])
  };

  //const and others
  const [planForm] = Form.useForm();
  const { businessGroupId } =
    urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

  //State
  const [currentActiveDomain, setCurrentActiveDomain] = React.useState<string | null>('');
  const [planModalVisible, setPlanModalVisible] = React.useState(false);
  const [domainPlanMap, setDomainPlanMap] = React.useState({} as IDomainPlanMap);
  const [productList, setProductList] = React.useState([] as any)
  const [planListResp, setPlanListResp] = React.useState({} as any)
  const [showPlan, setShowPlan] = React.useState([] as any)
  const [tenentOptions, setTenentOptions] = React.useState([] as any)

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  React.useEffect(() => {
    planForm.setFieldsValue({ domain: currentActiveDomain });
  }, [planModalVisible, currentActiveDomain]);

  React.useEffect(() => {
    loadIntialData()
  }, []);

  const loadIntialData = () => {
    getProductList()

  }

  const getProductList = async (domain = '') => {
    setLoading(true)
    const { data, errors } = await cbtProductService.getProduct();
    if (_.isEmpty(errors)) {
      let selectFirstProduct = data?.length ? data[0]?.name : ''
      setCurrentActiveDomain(selectFirstProduct)
      setProductList(data)
      getAllPlanList(selectFirstProduct, domain)
    } else {
      displayErrorNotifications(errors);
    }
  }

  const getTenentOptionData = async () => {
    const { data, errors } = await cbtProductService.getAllTenentData()
    if (_.isEmpty(errors)) {
      let addTenentData = (data.tenants || []).map((tenent: any) => {
        return { label: tenent, value: tenent }
      })
      setTenentOptions(addTenentData)
    } else {
      displayErrorNotifications(errors)
    }
  }

  const getAllPlanList = async (product: any, domain = '') => {
    const params = {
      business_grp_id: businessGroupId
    }
    const { data, errors } = await cbtProductService.getAllPlan(params)
    if (_.isEmpty(errors)) {
      let dataList = domain ? data[domain] : product ? data[product] : ''
      setShowPlan(dataList)
      setPlanListResp(data)
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false)
  }

  const getDomainPlanMap = async () => {
    const { data, errors } = await cbtProductService.getAllPlansGroupedByDomain();
    if (_.isEmpty(errors)) setDomainPlanMap(data);
    else displayErrorNotifications(errors);
  };

  const handleClickAddPlan = () => {
    planForm.setFieldsValue({
      trial_usage_limit_applicable: true,
      taxes: [{}],
      api_range: [{ is_per_api: true }],
      plan_feature: [{}]
    })
    getTenentOptionData()
    setPlanModalVisible(true);
  };

  const planList: Plan[] = domainPlanMap[currentActiveDomain as string] || [];

  const getNewFormData = () => {
    const { name, description, tenant_id, interval, interval_count, currency, discount_code, trial_days, trial_usage_limit_applicable, trial_usage_limit, domain, hsn_code, taxes, api_range, plan_feature } = planForm.getFieldsValue()
    let data = {
      business_grp_id: businessGroupId,
      tenant_id,
      name,
      description,
      interval,
      interval_count,
      currency,
      discount_code,
      trial_days,
      trial_usage_limit_applicable,
      trial_usage_limit,
      domain,
      hsn_code,
      taxes,
      api_range,
      plan_feature
    }

    return data
  }

  const onAddNewPlan = async () => {
    setLoading(true)
    const bodyData = getNewFormData()
    const { errors } = await cbtProductService.createPlan(bodyData)
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Plan Created Success!' })
      getProductList(planForm.getFieldsValue().domain)
      setPlanModalVisible(false)
      planForm.resetFields()
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false)
  }

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row className="border-2 border-solid border-slate-300">
            <Col xs={24} md={8}>
              <div className="border-r-2 border-r-solid border-slate-300 h-full border-b-2">
                <div className="flex justify-between items-center border-b-solid border-slate-300 border-b-2">
                  <div className="px-4 py-4">
                    <span className="text-xl font-bold">All Products</span>
                  </div>
                </div>

                <div>
                  {(productList || []).map((item: any) => {
                    return (
                      <ProductListItem name={item?.name} isSelected={item?.name === currentActiveDomain} handleClickProduct={handleClickProduct} />
                    )
                  })}
                </div>
              </div>
            </Col>

            <Col xs={24} md={16}>
              <div className="h-full">
                <div className="flex justify-between items-center border-b-solid border-slate-300 border-b-2">
                  <div className="px-4 py-4">
                    <span className="text-xl font-bold">
                      {currentActiveDomain
                        ? `${currentActiveDomain.toLocaleUpperCase()}`
                        : `Select a product to view its details`}
                    </span>
                  </div>
                  <div className="mr-2">
                    <Button type="primary" onClick={handleClickAddPlan} size="large">
                      <BoldButtonLabel labelText="Add Plan +"></BoldButtonLabel>
                    </Button>
                  </div>
                </div>

                {showPlan ? (<>
                  {showPlan.length !== 0 && (
                    <Row gutter={16}>
                      <Col xs={12} md={4}>
                        <Card bordered>
                          <Statistic
                            title="Plans"
                            value={showPlan.length}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                          />
                        </Card>
                      </Col>
                    </Row>
                  )}
                  <PlansList handleClickAddPlan={handleClickAddPlan} plans={showPlan} />
                </>) : (<div className="text-center flex items-center flex-col gap-4 mt-8">
                  <div>
                    <span className="text-lg font-semibold">There are no plans available for the selected product!</span>
                  </div>
                  <Button type="primary" onClick={handleClickAddPlan} size="large">
                    <BoldButtonLabel labelText="Add Plan"></BoldButtonLabel>
                  </Button>
                </div>)}
              </div>
            </Col>
          </Row>
        </Card>
        <Modal
          title={<span className="text-2xl font-bold">Add Plan</span>}
          footer={false}
          open={planModalVisible}
          onCancel={() => {
            planForm.resetFields()
            setPlanModalVisible(false)
          }}
          width={900}
        >
          <Form form={planForm} layout="vertical" onFinish={onAddNewPlan}>
            <Row gutter={12}>
              <Col xs={12} md={8}>
                <Form.Item name="name" label="Plan name" rules={[{ required: true, message: 'Field is required' }]}>
                  <Input placeholder="Enter plan name" size="large" />
                </Form.Item>
              </Col>{' '}
              <Col xs={12} md={8}>
                <Form.Item name="interval" label="Interval" rules={[{ required: true, message: 'Field is required' }]}>
                  <Select
                    options={tenentOptions}
                    className="w-full"
                    placeholder="Select plan interval"
                    size="large"
                  />
                </Form.Item>
              </Col>{' '}
              <Col xs={12} md={8}>
                <Form.Item name="tenant_id" label="Tenent Id" rules={[{ required: true, message: 'Field is required' }]}>
                  <Select
                    options={tenentOptions}
                    className="w-full"
                    placeholder="Select Tenent"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} md={24}>
                <Form.Item name="description" label="Description" >
                  <Input.TextArea placeholder="Enter description" size="large" />
                </Form.Item>
              </Col>{' '}
            </Row>
            <Row gutter={12}>
              <Col xs={12} md={8}>
                <Form.Item name="interval_count" label="Interval Count" rules={[{ required: true, message: 'Field is required' }]}>
                  <InputNumber className="w-full" placeholder="Select plan interval" size="large" />
                </Form.Item>
              </Col>{' '}
              <Col xs={12} md={8}>
                <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Field is required' }]}>
                  <Select className="w-full" placeholder="Select plan currency" size="large" options={currencyList} />
                </Form.Item>{' '}
              </Col>{' '}
              <Col xs={12} md={8}>
                <Form.Item name="amount" label="Plan Amount" rules={[{ required: true, message: 'Field is required' }]}>
                  <InputNumber className="w-full" placeholder="Select plan interval" min={0} size="large" />
                </Form.Item>
              </Col>{' '}
            </Row>
            <Row gutter={12}>
              <Col xs={12} md={8}>
                <Form.Item name="discount_code" label="Discount Code" rules={[{ required: true, message: 'Field is required' }]}>
                  <Input placeholder="Enter discount Code" size="large" />
                </Form.Item>
              </Col>{' '}
              <Col xs={12} md={8}>
                <Form.Item name="trial_days" label="Trial Days" rules={[{ required: true, message: 'Field is required' }]}>
                  <InputNumber className="w-full" placeholder="Enter Trial Days" min={0} size="large" />
                </Form.Item>{' '}
              </Col>{' '}
              <Col xs={12} md={8}>
                <Form.Item name="trial_percent" label="Tax Percent" rules={[{ required: true, message: 'Field is required' }]}>
                  <InputNumber className="w-full" placeholder="Enter Tax Percent" min={0} size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12} md={8}>
                <Form.Item name="api_limit" label="API Limit" rules={[{ required: true, message: 'Field is required' }]}>
                  <InputNumber className="w-full" placeholder="Enter API Limit" min={0} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={8}>
                <Form.Item name="domain" label="Domain" rules={[{ required: true, message: 'Field is required' }]}>
                  <Input placeholder="Enter domain" disabled size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>

              <Col xs={12} md={8}>
                <Form.Item name="trial_usage_limit_applicable" valuePropName="checked" label="Trial Usage Limit Applicable" rules={[{ required: true, message: 'Field is required' }]}>
                  <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
                </Form.Item>
              </Col>
              <Col xs={12} md={8}>
                <Form.Item name="trial_usage_limit" label="Trial Usage Limit" rules={[{ required: true, message: 'Field is required' }]}>
                  <InputNumber className="w-full" placeholder="Enter Trial Usage Limit" min={0} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={8}>
                <Form.Item name="hsn_code" label="HSN Code" rules={[{ required: true, message: 'Field is required' }]}>
                  <Input placeholder="Enter HSN Code" size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24}>
                <Form.List name="taxes">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => {
                        return (
                          <Card key={key} className='mb-2 bg-beige'>
                            <Row gutter={12}>
                              <Col xs={12} md={8}>
                                <Form.Item
                                  {...restField}
                                  rules={[{ required: true, message: 'Please enter Tax Code' }]}
                                  name={[name, 'tax_code']}
                                  label={`Tax Code`}
                                >
                                  <Input className="w-full" size="large" />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={8}>
                                <Form.Item
                                  {...restField}
                                  rules={[{ required: true, message: 'Please enter Tax Rate' }]}
                                  name={[name, 'tax_rate']}
                                  label={`Tac Rate`}
                                >
                                  <InputNumber className="w-full" size="large" min={0} placeholder="Tax Rate" />
                                </Form.Item>
                              </Col>
                              <div className="ml-2 my-2 flex justify-center items-center">
                                <DeleteOutlined
                                  className="text-red-500 text-xl"
                                  title="Delete Contact"
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </Row>
                          </Card>
                        );
                      })}
                      <Form.Item>
                        <Row justify={'start'}>
                          <Col xs={6}>
                            <Button block onClick={() => add()} icon={<PlusOutlined />}>
                              <BoldButtonLabel labelText="Add Taxes" />
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24}>
                <Form.List name="api_range">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => {
                        return (
                          <React.Fragment key={key}>
                            <div className="mb-2">
                              <span className="text-lg">API Range - {index + 1} </span>
                            </div>
                            <Card className="mb-4 bg-beige">
                              <Row gutter={12} className='flex justify-between'>
                                <Col xs={12} md={8}>
                                  <Form.Item
                                    {...restField}
                                    rules={[{ required: true, message: 'Please enter plan feature' }]}
                                    name={[name, 'is_per_api']}
                                    valuePropName="checked"
                                    label={`Per API`}
                                  >
                                    <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
                                  </Form.Item>
                                </Col>
                                <div className="ml-2 my-2 flex justify-center items-center">
                                  <DeleteOutlined
                                    className="text-red-500 text-xl"
                                    title="Delete Contact"
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              </Row>
                              <Row gutter={12}>
                                <Col xs={12} md={8}>
                                  <Form.Item
                                    {...restField}
                                    rules={[{ required: true, message: 'Please enter plan feature' }]}
                                    name={[name, 'amount']}
                                    label={`Amount`}
                                  >
                                    <InputNumber className="w-full" placeholder="Select Amount" size="large" />
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={8}>
                                  <Form.Item
                                    {...restField}
                                    rules={[{ required: true, message: 'Please enter plan feature' }]}
                                    name={[name, 'api_limit_from']}
                                    label={`API Limit From`}
                                  >
                                    <InputNumber className="w-full" placeholder="API Limit From" size="large" />
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={8}>
                                  <Form.Item
                                    {...restField}
                                    // rules={[{ required: true, message: 'Please enter plan feature' }]}
                                    name={[name, 'api_limit_to']}
                                    label={`API Limit To`}
                                  >
                                    <InputNumber className="w-full" placeholder="API Limit From" size="large" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </React.Fragment>
                        );
                      })}
                      <Form.Item>
                        <Row justify={'start'}>
                          <Col xs={6}>
                            <Button block onClick={() => {
                              const newProduct = {
                                is_per_api: true,
                              };
                              add(newProduct)
                            }} icon={<PlusOutlined />}>
                              <BoldButtonLabel labelText="Add API Range" />
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24}>
                <Form.List name="plan_feature">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => {
                        return (
                          <React.Fragment key={key}>
                            <div className="mb-4">
                              <span className="text-lg">Feature - {index + 1} </span>
                            </div>
                            <Row gutter={12}>
                              <Col xs={12}>
                                <Form.Item
                                  {...restField}
                                  rules={[{ required: true, message: 'Please enter plan feature' }]}
                                  name={[name, 'feature_name']}
                                  label={`Feature ${index + 1}`}
                                >
                                  <Input className="w-full" size="large" />
                                </Form.Item>
                              </Col>
                              <div className="ml-2 my-2 flex justify-center items-center">
                                <DeleteOutlined
                                  className="text-red-500 text-xl"
                                  title="Delete Contact"
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </Row>
                          </React.Fragment>
                        );
                      })}
                      <Form.Item>
                        <Row justify={'start'}>
                          <Col xs={6}>
                            <Button block onClick={() => add()} icon={<PlusOutlined />}>
                              <BoldButtonLabel labelText="Add Feature" />
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row justify={'start'} gutter={12}>
              <Col xs={24} md={8}>
                <Button block type="primary" size="large" htmlType="submit">
                  <BoldButtonLabel labelText="Save" />
                </Button>
              </Col>
              <Col xs={24} md={8}>
                <Button block size="large" onClick={() => {
                  planForm.resetFields()
                  setPlanModalVisible(false)
                }}>
                  <BoldButtonLabel labelText={'Go Back'} />
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default ProductsPage;
