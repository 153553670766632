import * as React from 'react';
import { IconCaretLeft, IconCaretRight } from '@tabler/icons';
import { Button, Form, InputNumber } from 'antd';

interface ICustomPaginationProps {
  currentPage: number;
  totalPages: number;
  handleNext: (currentPage: number) => any;
  handlePrevious: (currentPage: number) => any;
  handlePageChange: (currentPage: number) => any;
}

const CustomPagination: React.FunctionComponent<ICustomPaginationProps> = ({
  currentPage,
  totalPages,
  handleNext,
  handlePageChange,
  handlePrevious
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({ currentPage });
  }, [currentPage]);

  const handleClickNextPage = () => {
    const currentInputPage: number = form.getFieldsValue().currentPage;
    handleNext(currentInputPage + 1);
  };
  const handleClickPreviousPage = () => {
    const currentInputPage: number = form.getFieldsValue().currentPage;
    handlePrevious(currentInputPage - 1);
  };
  const handleInputBlur = () => {
    const currentInputPage: number = form.getFieldsValue().currentPage;
    if (currentInputPage === currentPage) return;
    if (currentInputPage) handlePageChange(currentInputPage);
    else form.setFieldsValue({ currentPage });
  };

  return (
    <div className="flex items-center">
      <Button
        disabled={currentPage === 1}
        className={'disabled:cursor-not-allowed '}
        onClick={handleClickPreviousPage}
        type="ghost"
        icon={<IconCaretLeft fill={'black'} />}
      ></Button>
      <div className="flex items-center gap-2">
        <Form form={form}>
          <Form.Item name="currentPage" noStyle>
            <InputNumber
              rootClassName="pagination__input text-lg"
              onBlur={() => {
                handleInputBlur();
              }}
              className="w-[50px]"
              max={totalPages}
            />
          </Form.Item>
        </Form>
        <span className="text-lg">/ {totalPages}</span>
      </div>
      <Button
        disabled={currentPage === totalPages}
        className={'disabled:cursor-not-allowed '}
        onClick={handleClickNextPage}
        type="ghost"
        icon={<IconCaretRight fill={'black'} />}
      ></Button>
    </div>
  );
};

export default CustomPagination;
