import { Card, Tabs, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import AddUser from './add-user';
import ManageUserListing from './manage-user-listing';

interface IManageUserProps { }

const ManageUser: React.FunctionComponent<IManageUserProps> = props => {
  const { TabPane } = Tabs;
  return (
    <PrimaryLayout>
      <div className="">
        <>
          <ManageUserListing />
        </>
      </div>
    </PrimaryLayout>
  );
};
export default ManageUser;
