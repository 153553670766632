import { LOGISTICS, OPERATOR } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';
import { IDNNPostPutRequest } from '../types/dnn-types';

const OPERATOR_BASE_URI = '';
const JAVA_MAX_INT = 2147483647;

const mockOperatorSearchResponse = {
  operators: [
    {
      operator_id: 'op123',
      operator_name: 'Operator One'
    },
    {
      operator_id: 'op456',
      operator_name: 'Operator Two'
    },
    {
      operator_id: 'op789',
      operator_name: 'Operator Three'
    }
  ],
  current_page: 0,
  total_pages: 0,
  page_size: 0
};

export const searchOperators = async (operator_name: string, offset = 0, limit = JAVA_MAX_INT) => {
  const url = `${OPERATOR}/v1/operators`;

  // return { data: mockOperatorSearchResponse, errors: [] };

  return httpClient.get({
    url,
    params: {
      'operator-name': operator_name,
    }
  });
};
