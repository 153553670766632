import { NODE_URI } from '../config/envConfig';
import httpClient from '../http/http.client';

const nodeUrls = {
  getNodeListUrl: () => `${NODE_URI}/v1/nodes`,
  createNodeUri: () => `${NODE_URI}/v1/nodes`,
  getNodeUri: (nodeId: string) => `${NODE_URI}/v1/nodes/${nodeId}`,
  putNodeUri: (nodeId: string) => `${NODE_URI}/v1/nodes/${nodeId}`,

  //!TODO -
  getStatusChangeUri: (nodeId: string) => `${NODE_URI}/v1/nodes/${nodeId}/status`,
  getActiveStatusChangeUri: (nodeId: string) => `${NODE_URI}/v1/nodes/${nodeId}/state`
};

const mockNodes = [
  { id: '1001', name: '1001' },
  { id: '1002', name: '1002' }
];

export const getNodeListByTenantId = async () => {
  // const url = nodeUrls.getNodeListUrl();
  //   const { data, errors } = await httpClient.get({ url });
  return { data: mockNodes, errors: [] };
};

export const getNodeListByTenantIdV2 = async (currentPage: number, pageSize:number, nodeName?: string) => {
  const url = nodeUrls.getNodeListUrl();

  const params: any = {
    limit: pageSize,
    offset: currentPage - 1,
    projection: 'BASIC'
  };

  if (nodeName) {
    params['node-name'] = nodeName;
  }

  const headers = {
    'Content-Type': 'application/json'
  };

  const { data, errors } = await httpClient.get({ url, params, headers });

  return { data, errors };
};

export const createNode = async (request: any) => {
  const url = nodeUrls.createNodeUri();

  const body = request;

  const { data, errors } = await httpClient.post({ url, body });

  return { data, errors };
};

export const getNodeByNodeId = async (nodeId: string) => {
  const url = nodeUrls.getNodeUri(nodeId);

  const { data, errors } = await httpClient.get({ url });

  return { data, errors };
};
export const putNodeByNodeId = async (nodeId: string, request: any) => {
  const url = nodeUrls.putNodeUri(nodeId);

  const { data, errors } = await httpClient.put({ url, body: request });

  return { data, errors };
};

export const changeNodeStatus = async (nodeId: string, action: { publish: boolean; unpublish: boolean }) => {
  const url = nodeUrls.getStatusChangeUri(nodeId);

  const params = {
    ...action
  };
  return httpClient.patch({ url, params, body: {} });
};
export const changeNodeActiveStatus = async (isActive: boolean, nodeId: string) => {
  const url = nodeUrls.getActiveStatusChangeUri(nodeId);

  const params = {
    is_active: isActive
  };

  return httpClient.patch({ url, params, body: {} });
};
