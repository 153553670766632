import { create } from 'zustand';
// import { persist } from "zustand/middleware";

interface IUseOrder {
  productData: null;
  setProductData: (productData: any) => void;
}
interface IUseFulfillmentType {
  fulfillmentType: null;
  setFulfillmentType: (fulfillmentType: any) => void;
}

export const useOrder = create<IUseOrder>(set => ({
  productData: {} as any,
  setProductData: productData => set({ productData })
}));

export const useFulfillmentType = create<IUseFulfillmentType>(set => ({
  fulfillmentType: null,
  setFulfillmentType: fulfillmentType => set({ fulfillmentType })
}));
