import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CustomPagination from '../../components/custom-pagination';
import { convertQueryStringToObj, deleteUndefinedValuesFromObject, objectHelpers, urlHelpers } from '../../helpers';
import {
  formatDateTime,
  resetISOTimeStampTimeToZeroIST,
  resetISOTimeStampTimeToZeroISTCurrent
} from '../../helpers/date.helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import TenantInfo from '../../components/TenantIdInfo';
import { fetchReqData } from '../../helpers/file.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { orderService } from '../../services';
import { useLoader } from '../../stores/use-loader';
import { IOrderListQueryType, IOrderListResponse } from '../../types/order.types';
import AuditModal from './audit-modal';

const statusUpdatedList = [
  { label: 'Draft Order', value: 'DRAFT_ORDER' },
  { label: 'Created', value: 'CREATED' },
  { label: 'In Negotiation', value: 'IN_NEGOTIATION' },
  { label: 'Backorder From IMS', value: 'BACKORDER_FROM_IMS' },
  { label: 'Order Line Hold', value: 'ORDER_LINE_HOLD' },
  { label: 'Tag Hold', value: 'TAG_HOLD' },
  { label: 'Awaiting Release', value: 'AWAITING_RELEASE' },
  { label: 'Released', value: 'RELEASED' },
  { label: 'Pickup Processing', value: 'PICKUP_PROCESSING' },
  { label: 'Picklist Generated', value: 'PICKLIST_GENERATED' },
  { label: 'Backorder From Node', value: 'BACKORDER_FROM_NODE' },
  { label: 'Partially Pick', value: 'PARTIALLY_PICK' },
  { label: 'Pick', value: 'PICK' },
  { label: 'Partially Pack', value: 'PARTIALLY_PACK' },
  { label: 'Pack', value: 'PACK' },
  { label: 'Ready For Pickup', value: 'READY_FOR_PICKUP' },
  { label: 'Picked Up', value: 'PICKED_UP' },
  { label: 'Shipment Processing', value: 'SHIPMENT_PROCESSING' },
  { label: 'Partially Shipped', value: 'PARTIALLY_SHIPPED' },
  { label: 'Shipped', value: 'SHIPPED' },
  { label: 'Line Reshipped', value: 'LINE_RESHIPPED' },
  { label: 'Stock Delivery Requested', value: 'STOCK_DELIVERY_REQUESTED' },
  { label: 'Handed Over To Courier', value: 'HANDED_OVER_TO_COURIER' },
  { label: 'In Transit', value: 'IN_TRANSIT' },
  { label: 'Out For Delivery', value: 'OUT_FOR_DELIVERY' },
  { label: 'Delivery Attempted', value: 'DELIVERY_ATTEMPTED' },
  { label: 'Delivered', value: 'DELIVERED' },
  { label: 'Return Open', value: 'RETURN_OPEN' },
  { label: 'Return Committed', value: 'RETURN_COMMITTED' },
  { label: 'Return Checked Out', value: 'RETURN_CHECKED_OUT' },
  { label: 'Return Ready For Pickup', value: 'RETURN_READY_FOR_PICKUP' },
  { label: 'Return Picked Up', value: 'RETURN_PICKED_UP' },
  { label: 'Cancelled', value: 'CANCELLED' }
];

interface IOrderListingPageProps { }

const OrderListingPage: React.FunctionComponent<IOrderListingPageProps> = props => {
  const [orderListFilterForm] = Form.useForm();
  const [orderData, setOrderData] = React.useState({} as IOrderListResponse);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [auditModal, setAuditModal] = React.useState(false as boolean);
  const [auditDetails, setAuditDetails] = React.useState([] as any);

  const [searchParams, setSearchParams] = useSearchParams();
  const backUrl = urlHelpers.getBackUrlParamString(searchParams);

  const columns: TableColumnsType<any> = [
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Order Number
          <CaretUpOutlined
            style={{ marginLeft: 8, cursor: 'pointer' }}
            onClick={() => fetchInitialData('order_number', 'sort')}
          />
          <CaretDownOutlined
            style={{ marginLeft: 8, cursor: 'pointer' }}
            onClick={() => fetchInitialData('order_number', 'desc')}
          />
        </div>
      ),
      align: 'center',
      render: (_, record) => <>{record?.order_number}</>
    },
    {
      title: 'Customer ID',
      align: 'center',
      width: '200px',
      render: (_, record: any) => {
        let reqData = fetchReqData(record?.address, 'address_tag', 'buyer-details');
        return (
          <section>
            <div>{reqData?.profile_id}</div>
          </section>
        );
      }
    },
    {
      title: 'Order Type',
      align: 'center',
      render: (_, record) => <>{record?.order_type}</>
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Date Ordered
          <CaretUpOutlined
            style={{ marginLeft: 8, cursor: 'pointer' }}
            onClick={() => fetchInitialData('order_start_trans_datetime', 'sort')}
          />
          <CaretDownOutlined
            style={{ marginLeft: 8, cursor: 'pointer' }}
            onClick={() => fetchInitialData('order_start_trans_datetime', 'desc')}
          />
        </div>
      ),
      align: 'center',
      render: (_, record) => <>{formatDateTime(record?.order_start_trans_datetime)}</>
    },
    { title: 'Order Status', align: 'center', render: (_, record) => <>{record?.status_description}</> },
    {
      title: 'Ordered Quantity',
      align: 'center',
      render: (_, record) => (
        <>
          {record.item_list.reduce((accumulator: any, curr: any) => {
            return accumulator + curr.original_quantity?.number_of_units;
          }, 0)}
        </>
      )
    },
    {
      title: 'Payment',
      align: 'center',
      render: (_, record) => {
        return <>{record?.payment_details?.payment_status}</>;
      }
    },
    {
      title: 'Cancelled Quantity',
      align: 'center',
      render: (_, record) => (
        <>
          {record.item_list.reduce((accumulator: any, curr: any) => {
            return accumulator + (curr.original_quantity?.number_of_units - curr.quantity?.number_of_units);
          }, 0)}
        </>
      )
    },
    {
      title: 'Total Items',
      align: 'center',
      render: (_, record) => <>{record.item_list?.length}</>
    },
    {
      title: 'Total Amount',
      align: 'center',
      render: (_, record) => {
        return <>${record?.value_details?.total_order_value.toFixed(2)}</>;
      }
    },
    {
      title: 'Total Refund',
      align: 'center',
      render: (_, record: any) => {
        let data = record?.payment_details?.total_refund_amount;
        return <>${data?.toFixed(2) || 0}</>;
      }
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record) => {
        return (
          <section className="flex gap-2 justify-center items-center">
            <Link to={`/order/${record?.order_number}?${backUrl}`}>View CO</Link>

            <Link to={`/release-order?order_number=${record?.order_number}&order_type=${record?.order_type}&${backUrl}`}>View ROs</Link>
          </section>
        );
      }
    }
  ];

  const handleNext = async (currentPage: number) => {
    handleApplyFilter(currentPage);
  };
  const handlePrevious = async (currentPage: number) => {
    handleApplyFilter(currentPage);
  };
  const handlePageChange = async (currentPage: number) => {
    handleApplyFilter(currentPage);
  };

  const handleApplyFilter = (page_number = 1, page_size: number = 10) => {
    let formValues = orderListFilterForm.getFieldsValue();

    if (formValues.date_range) {
      const created_after = resetISOTimeStampTimeToZeroIST(formValues.date_range[0]);
      const created_before = resetISOTimeStampTimeToZeroISTCurrent(formValues.date_range[1]);
      formValues = { ...formValues, created_after, created_before, date_range: undefined };
      delete formValues['date_range'];
    }

    const transformedFormValues = {
      ...formValues,
      page_number: parseInt(searchParams.get('page_number') as string) || 1,
      page_size: parseInt(searchParams.get('page_size') as string) || 10,
      status: formValues?.status?.join(',')
    };

    const filteredFormValues = objectHelpers.deleteUndefinedValuesFromObject(transformedFormValues);

    setSearchParams(filteredFormValues);
  };

  React.useEffect(() => {
    fetchInitialData();
    orderListFilterForm.setFieldsValue({
      sortBy: 'desc',
      sort: 'order_start_trans_datetime'
    });
  }, [searchParams]);

  const fetchInitialData = async (sortKey = '', sortWay = '') => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    const page_number = parseInt(searchParams.get('page_number') as string) || 1;
    const page_size = parseInt(searchParams.get('page_size') as string) || 10;

    const { created_after, created_before, status, order_type, state, order_id, order_number, customer_id } =
      queryStringObj;
    orderListFilterForm.setFieldsValue({
      status: status?.split(',') || [],
      order_type,
      state,
      date_range: created_after ? [dayjs(created_after), dayjs(created_before)] : undefined,
      order_id,
      order_number,
      customer_id
    });

    let sort_params = {
      [sortWay]: sortKey
    };

    let params = deleteUndefinedValuesFromObject({
      status,
      order_type,
      state,
      created_after,
      created_before,
      order_id,
      order_number,
      customer_id,
      ...sort_params
    });

    const queryParams = { ...params, offset: page_number - 1, limit: page_size };

    await handleFetchOrderList(queryParams);
  };

  const handleClear = () => {
    orderListFilterForm.resetFields();
    setSearchParams({});
    setOrderData({} as IOrderListResponse);
  };

  const handleFetchOrderList = async (queryParam: IOrderListQueryType) => {
    setTableLoading(true);
    const { data, errors } = await orderService.fetchOrderList(queryParam);

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      const updatedOrderList = data.order_list.map((order: any) => {
        return { ...order, key: order.order_number };
      });

      setOrderData({ ...data, order_list: updatedOrderList });
    }
    setTableLoading(false);
  };

  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, any>);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const navigate = useNavigate();

  const expandedRowRender = () => {
    const columns: TableColumnsType<any> = [
      {
        title: 'Fulfillment Details',
        align: 'left',
        width: '200px',
        render: (_, record: any) => {
          return (
            <section>
              <div>{record?.fo_number}</div>
              <div className="flex">
                <Typography className="font-bold">Ship Node : </Typography> {record?.node_name}
              </div>
              <div
                className="text-[#1e90ffde] underline cursor-pointer"
                onClick={() => {
                  setAuditDetails(record);
                  setAuditModal(true);
                }}
              >
                {'Show Audit'}
              </div>
            </section>
          );
        }
      },
      {
        title: 'Delivery Method',
        align: 'center',
        render: (_, record: any) => {
          return <>{record?.fulfilment_info?.delivery_method}</>;
        }
      },

      {
        title: 'Status',
        align: 'center',
        render: (_, record: any) => {
          return <>{record?.status_description}</>;
        }
      },
      {
        title: 'Fulfilment Dates',
        align: 'left',
        width: '700px',
        render: (_, record: any) => {
          let promise_date = fetchReqData(
            record?.fulfilment_info?.fulfillment_dates,
            'date_type',
            'promise-delivery-date'
          );

          if (record?.fulfilment_info?.delivery_method === 'PICK_UP') {
            let estimated_pick_up_date = fetchReqData(
              record?.fulfilment_info?.fulfillment_dates,
              'date_type',
              'estimated-pick-up-date'
            )?.date_time_stamp ?? ''
            return (
              <>
                <div className="flex">
                  <Typography className="font-bold">Estimated Pick Up Date - </Typography> &nbsp;
                  {formatDateTime(estimated_pick_up_date ?? '')}{' '}
                </div>
                {
                  record.status_description === 'PICKED_UP' &&
                  <>
                    <div className="flex">
                      <Typography className="font-bold">Pick Up - </Typography> &nbsp;
                      {formatDateTime(record?.order_start_trans_datetime)}{' '}
                    </div>
                  </>
                }
              </>

            );
          } else {
            return (
              <>
                <div className="flex">
                  <Typography className="font-bold">Created - </Typography> &nbsp;
                  {formatDateTime(record?.order_start_trans_datetime)}{' '}
                </div>
                <div className="flex">
                  <Typography className="font-bold">Expected Delivery - </Typography> &nbsp;
                  {formatDateTime(promise_date?.date_time_stamp)}{' '}
                </div>
                {/* <div>Cancelled - to be added </div> <div>Complete - to be added</div> */}
              </>
            );
          }
        }
      },
      {
        title: 'Total Quantity',
        align: 'center',
        render: (_, record) => (
          <>
            {record.item_list.reduce((accumulator: any, curr: any) => {
              return accumulator + curr.original_quantity?.number_of_units;
            }, 0)}
          </>
        )
      },
      {
        title: 'Total Lines',
        align: 'center',
        render: (_, record) => <>{record.item_list.length}</>
      },
      {
        title: 'Total Amount',
        align: 'center',
        render: (_, record: any) => {
          return <>${record.value_details.total_order_value.toFixed(2)}</>;
        }
      },
      {
        title: 'Fulfillment Type',
        align: 'center',
        render: (_, record: any) => {
          return <>{record?.fulfillment_type}</>;
        }
      },
      {
        title: 'Actions',
        align: 'center',
        render: (_, record: any) => {
          const willCancelButtonDisabled = (status: number, tenant: string) => {
            if (tenant === 'ejje') {
              if (status > 2900) return true;
              else return false;
            }
            if (status > 3300) return true;
          }
          return (
            <>
              <Button
                disabled={willCancelButtonDisabled(record?.status, record?.tenant_id)}
                type="link"
                onClick={() => {
                  const tagLevelLines: any[] = [];
                  record?.item_list?.forEach((item: any) => {
                    const tagLevelQuantities = item.tags.map((tag: any) => ({ ...tag, editable_cancel_quantity: 0 }));
                    tagLevelLines.push({
                      sku_id: item.sku_id,
                      product_desc: item.product_desc,
                      product_name: item.product_name,
                      status: item.status,
                      quantity: item.quantity,
                      editable_cancel_quantity: 0,
                      tagLevelQuantities,
                      co_line_id: item.co_line_id,
                      key: item.co_line_id
                    });
                  });
                  setFoCancelInfo({
                    modalOpen: true,
                    tagLevelLines,
                    record
                  });
                }}
              >
                Cancel
              </Button>

              {record.status_description !== 'BACKORDER_FROM_NODE' && (
                <>
                  {!(record.fulfillment_type === 'TRANSFER' && record.status_description === 'HOLD') && (
                    <Button
                      type="link"
                      onClick={() => {
                        navigate(`/release-order?fo_number=${record.fo_number}&order_type=${record?.order_type}&${backUrl}`);
                      }}
                    >
                      View ROs
                    </Button>
                  )}

                  {record.fulfillment_type === 'TRANSFER' && (
                    <Button
                      type="link"
                      onClick={() => {
                        navigate(`/transfer-order?parent_fo_number=${record.fo_number}&order_type=TRANSFER`);
                      }}
                    >
                      View TOs
                    </Button>
                  )}
                </>
              )}



            </>
          );
        }
      }
    ];
    return (
      <section className="">
        {/* <Typography className='text-[14px] font-semibold leading-6'>Fulfillment orders for Order {expandedRowKeys[0]} </Typography> */}
        <Table
          className=""
          scroll={{}}
          bordered
          columns={columns}
          dataSource={expandedRowData[expandedRowKeys[0]]}
          pagination={false}
        />
      </section>
    );
  };

  const [foCancelInfo, setFoCancelInfo] = React.useState<{
    modalOpen?: boolean;
    record?: any;
    tagLevelLines: any[];
  }>({ tagLevelLines: [], modalOpen: false, record: null });

  const fetchfulfillmentOrderList = async (order_number: string) => {
    setTableLoading(true);

    const { data, errors } = await orderService.getFulfillmentOrderList({ order_number, offset: 0, limit: 100 });
    if (_.isEmpty(errors)) {
      setExpandedRowData(orderData => ({ ...orderData, [order_number]: data?.order_list || [] }));
    }
    setTableLoading(false);
  };

  const foCancelColumns: any = [
    {
      title: 'SKU',
      dataIndex: 'sku_id',
      align: 'center'
    },
    {
      title: 'Order Quantity',
      dataIndex: 'sku_id',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.quantity?.number_of_units}</>;
      }
    },
    {
      title: 'Cancel Quantity',
      dataIndex: 'editable_cancel_quantity',
      align: 'center',
      editable: true,
      render: (_: any, record: any) => {
        return (
          <Button
            type="ghost"
            onClick={() => {
              setCurrentEditingFoLine(record);
              setCancelFoQuantityFormVisible(true);
              cancelFoQuantityEditForm.setFieldsValue({
                cancel_reason: record?.cancel_reason,
                tags_cancel_info: record.tagLevelQuantities.map((tag: any) => {
                  return {
                    sku_id: record?.sku_id,
                    product_name: record?.product_name,

                    ...tag,
                    quantity_number: tag?.quantity?.number_of_units
                  };
                })
              });
            }}
          >
            {record.editable_cancel_quantity} <EditOutlined />
          </Button>
        );
      }
    }
  ];

  const handleSubmitCancelFo = async () => {
    const { tagLevelLines, modalOpen, record } = foCancelInfo;
    let checkQtyisZero = tagLevelLines.some(data => data.editable_cancel_quantity === 0);
    if (checkQtyisZero) {
      displayErrorNotifications([{ message: 'Cancel Quantity cannot be zero!' }]);
      return;
    }
    const item_list = tagLevelLines.map(line => {
      return {
        co_line_id: line.co_line_id,
        reason: line.cancel_reason,
        sku_id: line.sku_id,
        tags: line.tagLevelQuantities
          // .filter((tag: any) => tag.editable_cancel_quantity > 0)
          .map((tag: any) => {
            return {
              tag_id: tag.tag_id,
              tag_type: tag.tag_type,
              quantity: {
                ...line.quantity,
                number_of_units: tag.editable_cancel_quantity
              }
            };
          })
      };
    });

    const request = {
      source: 'NEBULA',
      doc_type: 'FO',
      orders: [
        {
          cancelled_by: 'ui',
          cancelled_at: new Date().toISOString(),
          lines: [
            {
              order_number: record.order_number,
              fo_number: record.fo_number,
              item_list
            }
          ]
        }
      ]
    };

    setLoading(true);
    const { errors } = await orderService.cancelROFO(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'FO Cancelled successfully!' });
      setFoCancelInfo({ tagLevelLines: [], modalOpen: false, record: null });
      refetchPageDetails();
    }
    setLoading(false);
  };

  const { setLoading } = useLoader();

  const [cancelFoQuantityEditForm] = Form.useForm();
  const [cancelFoQuantityFormVisible, setCancelFoQuantityFormVisible] = React.useState(false);
  const [currentEditingFoLine, setCurrentEditingFoLine] = React.useState({} as any);

  const onEditCancelFoQuantityLineSave = () => {
    const { record } = foCancelInfo;

    const tagsFormInfo = cancelFoQuantityEditForm.getFieldsValue().tags_cancel_info || [];
    const cancellationReason = cancelFoQuantityEditForm.getFieldsValue().cancel_reason;
    const currentEditingSku = currentEditingFoLine?.sku_id;
    setFoCancelInfo(info => {
      const updatedTagLevelLines = info.tagLevelLines.map(line => {
        if (line.sku_id === currentEditingSku) {
          const tagLevelQuantities = line.tagLevelQuantities.map((tag: any) => {
            const updatedCancelQuantity =
              tagsFormInfo.find((tagForm: any) => tag.tag_id === tagForm.tag_id)?.quantity_cancelled || 0;
            //this was earlier editable_cancel_quantity now changed to quantity_cancelled
            return {
              ...tag,
              editable_cancel_quantity: updatedCancelQuantity
            };
          });

          return {
            ...line,
            tagLevelQuantities,
            cancel_reason: cancellationReason,
            editable_cancel_quantity: tagLevelQuantities.reduce((acc: any, curr: any) => {
              return acc + curr.editable_cancel_quantity;
            }, 0)
          };
        }
        return line;
      });

      return { ...info, tagLevelLines: updatedTagLevelLines };
    });
    setCurrentEditingFoLine(record);
    setCancelFoQuantityFormVisible(false);
    cancelFoQuantityEditForm.resetFields();
  };

  const refetchPageDetails = async () => {
    await fetchInitialData();
    await fetchfulfillmentOrderList(expandedRowKeys[0]);
  };

  const handleOnCloseAuditModal = () => {
    setAuditModal(false);
  };

  return (
    <PrimaryLayout>
      <div className="">
        <>
          <section className=" bg-white  mb-4 p-6">
            <Row justify="space-between" className="mb-2">
              <Col>
                <p className="text-[20px]">Orders</p>
              </Col>
            </Row>
            <TenantInfo />
          </section>

          <section className=" bg-white mt-4 mb-4 p-6">
            <Form form={orderListFilterForm} layout="vertical" onFinish={handleApplyFilter}>
              <Row gutter={12}>
                <Col lg={10} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="date_range"
                    label="Date Range"
                    rules={[{ required: false, message: 'Please select order date' }]}
                    required={false}
                  >
                    <DatePicker.RangePicker placeholder={['Start Date', 'End Date']} className="w-full" />
                  </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24} xs={24}>
                  <Form.Item name="order_type" label="Order Type">
                    <Select
                      allowClear
                      options={[
                        { label: 'Forward', value: 'FORWARD' },
                        { label: 'Return', value: 'RETURN' }
                      ]}
                      placeholder="Select Order Type"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col lg={5} md={12} sm={24} xs={24}>
                  <Form.Item name="status" label="Order Status">
                    <Select placeholder="Select Status" mode="tags" options={statusUpdatedList} allowClear showSearch />
                  </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24} xs={24}>
                  <Form.Item name="order_number" label="Order Number">
                    <Input placeholder="Enter Order Number" />
                  </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24} xs={24}>
                  <Form.Item name="customer_id" label="Customer ID">
                    <Input placeholder="Enter Customer ID" />
                  </Form.Item>
                </Col>

                <Col lg={3} md={12} sm={24} xs={24} className="flex items-center pt-1">
                  <Button block type="primary" htmlType="submit">
                    <BoldButtonLabel labelText="Apply" />
                  </Button>
                </Col>
                <Col lg={3} md={12} sm={24} xs={24} className="flex items-center pt-1">
                  <Button block onClick={handleClear} className="text-red-500 border-red-500">
                    <BoldButtonLabel labelText="Clear All" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
          <section className="m-6 bg-white p-6">
            <p className="text-[16px] mb-6">Table</p>
            <Table
              loading={tableLoading}
              expandable={{
                expandedRowRender,
                expandedRowKeys,
                async onExpand(expanded, record) {
                  if (expandedRowKeys.includes(record.order_number)) {
                    return setExpandedRowKeys([]);
                  }
                  if (expanded) {
                    if (_.isEmpty(expandedRowData[record.order_number])) {
                      await fetchfulfillmentOrderList(record.order_number);
                    }
                    setExpandedRowKeys([record.order_number]);
                  }
                }
              }}
              expandedRowClassName={() => 'bg-gray-50'}
              pagination={{
                total: orderData?.page_info?.total_pages
                  ? orderData.page_info?.total_pages * parseInt(searchParams.get('page_size') as string) || 10
                  : 0,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                pageSize: parseInt(searchParams.get('page_size') as string) || 10,
                current: parseInt(searchParams.get('page_number') as string) || 1,
                onChange: (currentPage, pageSize) => {
                  setSearchParams(prev => {
                    prev.set('page_number', currentPage + '');
                    prev.set('page_size', pageSize + '');
                    return prev;
                  });
                }
              }}
              bordered
              className="mt-4"
              dataSource={orderData.order_list}
              columns={columns}
              scroll={{ x: 1000 }}
            />
          </section>
        </>

        <Modal
          title="Cancel Order"
          footer={null}
          maskClosable={false}
          centered
          width={1200}
          open={foCancelInfo.modalOpen}
          onCancel={() => {
            setFoCancelInfo({ tagLevelLines: [], modalOpen: false, record: null });
          }}
        >
          <section className="mt-8">
            <Table pagination={false} bordered columns={foCancelColumns} dataSource={foCancelInfo.tagLevelLines} />
            <section className="flex gap-4 flex-wrap mt-4">
              <Button type="primary" size="large" onClick={handleSubmitCancelFo}>
                <BoldButtonLabel labelText="Submit" />
              </Button>
              <Button
                size="large"
                className="text-red-500 border-red-500"
                onClick={() => setFoCancelInfo({ tagLevelLines: [], modalOpen: false, record: null })}
              >
                <BoldButtonLabel labelText="Cancel" />
              </Button>
            </section>
          </section>
          <Modal
            onCancel={() => {
              setCurrentEditingFoLine(null);
              setCancelFoQuantityFormVisible(false);
              cancelFoQuantityEditForm.resetFields();
            }}
            width={1200}
            centered
            title="Enter tag level details"
            footer={null}
            maskClosable={false}
            open={cancelFoQuantityFormVisible}
          >
            <section>
              <Form
                form={cancelFoQuantityEditForm}
                layout="vertical"
                onFinish={() => {
                  console.log('Form Finish');

                  onEditCancelFoQuantityLineSave();
                }}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Cancel reason is required' }]}
                  label="Cancellation Reason"
                  name="cancel_reason"
                >
                  <Input.TextArea placeholder="Cancellation Reason" />
                </Form.Item>
                <Form.List name="tags_cancel_info">
                  {fields => (
                    <>
                      <div className="flex flex-col gap-4">
                        {fields.map((field, index) => (
                          <Card size="small" key={field.key}>
                            <Row gutter={12}>
                              <Col xs={8}>
                                <Form.Item label="SKU" name={[field.name, 'sku_id']}>
                                  <Input className="w-full" readOnly size="large" />
                                </Form.Item>
                              </Col>
                              <Col xs={8}>
                                <Form.Item label="Tag ID" name={[field.name, 'tag_id']}>
                                  <Input className="w-full" readOnly size="large" />
                                </Form.Item>
                              </Col>
                              <Col xs={8}>
                                <Form.Item label="Tag Type" name={[field.name, 'tag_type']}>
                                  <Input className="w-full" readOnly size="large" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={12}>
                              <Col xs={12}>
                                <Form.Item label="Ordered Quantity" name={[field.name, 'quantity_number']}>
                                  <InputNumber className="w-full" readOnly size="large" />
                                </Form.Item>
                              </Col>
                              <Col xs={12}>
                                <Form.Item
                                  rules={[
                                    { required: true, message: 'Cancel quantity is required' },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const orderedTagQuantity =
                                          getFieldValue('tags_cancel_info')[index]?.quantity_number;
                                        if (value > orderedTagQuantity) {
                                          return Promise.reject(
                                            new Error('Cancel quantity cannot be greater than ordered quantity')
                                          );
                                        }
                                        return Promise.resolve();
                                      }
                                    })
                                  ]}
                                  label="Cancel Quantity"
                                  name={[field.name, 'quantity_cancelled']}
                                >
                                  <InputNumber
                                    min={0}
                                    className="w-full"
                                    size="large"
                                    placeholder="Enter Cancel Quantity"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </div>
                      <div className="flex justify-end mt-4">
                        <Button type="primary" htmlType="submit" size="large">
                          <BoldButtonLabel labelText="Save" />
                        </Button>
                      </div>
                    </>
                  )}
                </Form.List>
              </Form>
            </section>
          </Modal>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default OrderListingPage;
