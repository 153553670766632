import PromotionListingPage from './promotion-list';

interface ILoyaltyReward { }

const LoyaltyReward: React.FunctionComponent<ILoyaltyReward> = props => {
    return <PromotionListingPage promo={'LOYT_REWARD'} promoName="Loyalty Reward"/>
    // LOYT_REWARD
};

export default LoyaltyReward;
