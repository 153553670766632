import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import { Button, Card } from 'antd';
import BoldButtonLabel from '../BoldButtonLabel';
import { useNavigate } from 'react-router-dom';

interface IGenericErrorComponentProps {
  error?: any;
}

const GenericErrorComponent: React.FunctionComponent<IGenericErrorComponentProps> = props => {
  const navigate = useNavigate();
  return (
    <PrimaryLayout>
      <div className="container mx-auto">
        <Card>
          <section className="bg-white">
            <div className="container flex items-center min-h-[700px] px-6 py-12 mx-auto">
              <div className="flex flex-col items-center max-w-sm mx-auto text-center">
                <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                    />
                  </svg>
                </p>
                <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">Something went wrong!</h1>
                <p className="mt-4 text-gray-500 "> Here are some helpful links:</p>

                <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
                  <Button type="primary" size="large" onClick={() => navigate('/')}>
                    <BoldButtonLabel labelText="Take me home"></BoldButtonLabel>
                  </Button>
                </div>
              </div>
            </div>
          </section>{' '}
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default GenericErrorComponent;
