import { OPERATOR_URI } from '../config/envConfig';
import httpClient from '../http/http.client';

const operatorUrls = {
  getOperatorListUri: () => `${OPERATOR_URI}/v1/operators`,
  getOperatorByOperatorId: (operatorId: string) => `${OPERATOR_URI}/v1/operators/${operatorId}`,
  createOperatorUri: () => `${OPERATOR_URI}/v1/operators`,
  putOperatorUri: (operatorId: string) => `${OPERATOR_URI}/v1/operators/${operatorId}`,
  statusChangeUri: (operatorId: string) => `${OPERATOR_URI}/v1/operators/${operatorId}/status`,
  activationChangeUri: (operatorId: string) => `${OPERATOR_URI}/v1/operators/${operatorId}/state`
};

export const getOperatorList = async (currentPage: number, operatorName?: string, limit = 10) => {
  const url = operatorUrls.getOperatorListUri();
  const params: any = {
    limit,
    offset: (currentPage - 1) * 10,
    projection: 'BASIC'
  };

  if (operatorName) {
    params['operator-name'] = operatorName;
  }
  return httpClient.get({ url, params });
};

export const getOperatorByOperatorId = async (operatorId: string) => {
  const url = operatorUrls.getOperatorByOperatorId(operatorId);
  const { data, errors } = await httpClient.get({ url });

  return { data, errors };
};

export const createOperator = async (operatorRequest: any) => {
  const url = operatorUrls.createOperatorUri();
  const body = operatorRequest;

  return httpClient.post({ url, body });
};

export const updateOperator = async (operatorRequest: any, operatorId: string) => {
  const url = operatorUrls.putOperatorUri(operatorId);
  const body = operatorRequest;

  return httpClient.put({ url, body });
};

export const changeOperatorStatus = async (operatorId: string, publish: boolean, unpublish: boolean) => {
  const params = {
    publish,
    unpublish
  };

  const url = operatorUrls.statusChangeUri(operatorId);

  return httpClient.patch({ url, params, body: {} });
};

export const changeActivationStatus = (operatorId: string, isActive: boolean) => {
  const url = operatorUrls.activationChangeUri(operatorId);
  const params = {
    is_active: isActive
  };

  return httpClient.patch({ url, params, body: {} });
};
