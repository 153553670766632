import { Card, Col, Row, Typography } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { orderService } from '../../services';

interface IRoDetailsProps { }

const RoDetails: React.FunctionComponent<IRoDetailsProps> = props => {
  const { releaseOrderNumber } = useParams();
  const { setLoading } = useLoader();
  const [releaseOrderDetails, setReleaseOrderDetails] = React.useState({} as any);

  const fetchRoDetails = async () => {

    setLoading(true);

    const { data, errors } = await orderService.getRoDetailsByRoNumber(releaseOrderNumber as string);
    if (_.isEmpty(errors)) {
      setReleaseOrderDetails(data);
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);

  };

  React.useEffect(() => {
    fetchRoDetails();
  }, []);

  return (
    <PrimaryLayout>
      <div className="container mx-auto">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Release Order - {releaseOrderNumber}
              </Typography.Title>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default RoDetails;
