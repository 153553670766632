import * as React from 'react';
import {
  HomeOutlined,
  HomeFilled,
  UsergroupAddOutlined,
  InboxOutlined,
  SettingOutlined,
  WalletOutlined,
  TagsOutlined,
  IdcardFilled,
  CarryOutFilled,
  AccountBookFilled,
  WalletFilled,
  ProfileFilled,

} from '@ant-design/icons';
interface ISideMenuIconProps {
  iconKey: string;
}
const commonStyle: React.CSSProperties = { fontSize: 14, color: 'white' };

const SideMenuIcon: React.FunctionComponent<ISideMenuIconProps> = ({ iconKey }) => {
  const iconKeyMap: Record<string, any> = {
    HOME: <HomeFilled style={commonStyle} />,
    TENANT_MANAGEMENT: <IdcardFilled style={commonStyle} />,
    PRODUCT: <ProfileFilled style={commonStyle} />,
    NEBULA_APP: <AccountBookFilled style={commonStyle} />,
    LOYALTY_ENGINE: <WalletFilled style={commonStyle} />,
    PROMOTION_APP: <CarryOutFilled style={commonStyle} />
  };

  return iconKeyMap[iconKey] ? iconKeyMap[iconKey] : null;
};

export default SideMenuIcon;





