import { Button, Card, Col, Form, Row, Switch, Table, TableColumnsType, Typography } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../components/ActionButton';
import TenantInfo from '../../components/TenantIdInfo';
import { convertQueryStringToObj, objectHelpers } from '../../helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { productService } from '../../modules/nebula/services';
import { useLoader } from '../../stores/use-loader';

interface IOrderProductsProps {}

const OrderProducts: React.FunctionComponent<IOrderProductsProps> = props => {
  const [form] = Form.useForm<any>();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [productResponse, setProductResponse] = React.useState({} as any);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: TableColumnsType<any> = [
    {
      title: 'Article Id',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record.article_id}</>;
      }
    },
    {
      title: 'SKU',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record.sku_id}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record.article_basic_dtls.name}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record.article_basic_dtls.category}</>;
      }
    },
    {
      title: 'List Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.price_info?.list_price
          ? record?.price_info?.list_price?.amount / record?.price_info?.list_price?.fraction
          : record?.price_info?.unit_cost?.amount / record?.price_info?.unit_cost?.fraction;
        return <>{data}</>;
      }
    },
    {
      title: 'Unit Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.price_info?.unit_cost?.amount / record?.price_info?.unit_cost?.fraction;
        return <>{data}</>;
      }
    },
    {
      title: 'UOM',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record.article_basic_dtls.unit_of_measure}</>;
      }
    },
    {
      title: 'Is Active?',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <section className="flex justify-center items-center">
            <Switch
              checkedChildren={'Yes'}
              unCheckedChildren={'No'}
              onChange={(value: boolean) => {
                handleParentStatusChange(value, record);
              }}
              checked={record?.is_active ? true : false}
            />
          </section>
        );
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.status;
        if (data === 'CREATED') {
          return (
            <Button
              type="link"
              onClick={() => {
                changeProductStatus(record, 'APPROVED');
              }}
            >
              Approve
            </Button>
          );
        }
        if (data === 'APPROVED') {
          return (
            <Button
              type="link"
              onClick={() => {
                changeProductStatus(record, 'PUBLISHED');
              }}
            >
              Publish
            </Button>
          );
        }
        if (data === 'PUBLISHED') {
          return (
            <Button
              type="link"
              onClick={() => {
                changeProductStatus(record, 'UNPUBLISHED');
              }}
            >
              Un-Publish
            </Button>
          );
        }
        if (data === 'UNPUBLISHED') {
          return (
            <Button
              type="link"
              onClick={() => {
                changeProductStatus(record, 'PUBLISHED');
              }}
            >
              Publish
            </Button>
          );
        }
      }
    },
    {
      title: '',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="VIEW"
              onClick={() => {
                navigate(`/order-products/view/${record?.article_id}`);
              }}
              title="View"
            />
          </section>
        );
      }
    }
  ];

  const ExpandRowColumn: TableColumnsType<any> = [
    {
      title: 'SKU',
      align: 'center',
      render: (_, record) => {
        return <>{record.sku_id}</>;
      }
    },
    {
      title: 'Variant Name',
      align: 'center',
      render: (_, record) => <>{record?.variant_name}</>
    },
    {
      title: 'Variant Attributes',
      align: 'center',
      render: (_, record) => (
        <>
          {record?.variant_attributes?.map((item: any) => {
            return (
              <div className="flex flex-row justify-center">
                <div className="flex gap-2">
                  <div className="min-w-[80px]">
                    <Typography.Text className="text-[#898c98]">Name: </Typography.Text>
                  </div>
                  <div className="font-semibold">{item?.attribute_name}</div>
                  <div className="min-w-[80px]">
                    <Typography.Text className="text-[#898c98]">Value: </Typography.Text>
                  </div>
                  <div className="font-semibold">{item?.attribute_value}</div>
                </div>
              </div>
            );
          })}
        </>
      )
    },
    {
      title: 'Status',
      align: 'center',
      render: (_, record) => {
        return (
          <section className="flex justify-center items-center">
            <Switch
              checkedChildren={'Yes'}
              unCheckedChildren={'No'}
              onChange={(value: boolean) => {
                handleChildStatusChange(value, record);
              }}
              checked={record?.is_active ? true : false}
            />
          </section>
        );
      }
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  const loadInitialData = async () => {
    const offset = pageControl.currentPage - 1;
    await handleSearch(offset);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    let params = { ...formValues, offset, limit: pageControl.pageSize };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await productService.getProductsDetails(filteredParams);
    if (_.isEmpty(errors)) {
      const dataWithParentIds = data?.products?.map((article: any) => {
        if (article.has_variants) {
          const variantsWithParentIds = article.variants.map((variant: any) => {
            const newVariant = { ...variant };
            newVariant.parentArticleId = article.article_id;
            return newVariant;
          });
          return { ...article, variants: variantsWithParentIds };
        } else {
          return article;
        }
      });
      const respData = { ...data, products: dataWithParentIds };
      setProductResponse(respData);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  //   const handlePageChange = (current_page: number) => {
  //     setCurrentPage(current_page);
  //   };

  //   const handlePageChangeNext = (current_page: number) => {
  //     setCurrentPage(current_page);
  //     handleSearch(current_page - 1);
  //   };

  //   const handlePageChangePrevious = (current_page: number) => {
  //     setCurrentPage(current_page);
  //     handleSearch(current_page - 1);
  //   };

  const expandedRowRender = (data: any) => {
    return (
      <section className="pl-8">
        <Table className="mt-2" bordered columns={ExpandRowColumn} dataSource={data?.variants} pagination={false} />
      </section>
    );
  };

  const changeProductStatus = async (record: any, change_status: any) => {
    setLoading(true);
    const body = {
      status: change_status
    };
    const { errors } = await productService.updateStatus(record.article_id, body);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'status updated !!' });
      handleSearch(pageControl.currentPage - 1);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleParentStatusChange = async (value: any, record: any) => {
    setLoading(true);
    const body = {
      is_active: value
    };
    const { errors } = await productService.updateParentStatus(record.article_id, body);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'status updated !!' });
      handleSearch(pageControl.currentPage - 1);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleChildStatusChange = async (value: any, record: any) => {
    setLoading(true);
    const body = {
      is_active: value
    };
    const { errors } = await productService.updateChildStatus(record?.parentArticleId, record?.sku_id, body);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'status updated !!' });
      handleSearch(pageControl.currentPage - 1);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mt-4">
            <Col xs={24} md={6}>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Products
              </Typography.Title>
            </Col>
          </Row>
          <Row className="mt-3">
            <TenantInfo />
          </Row>
          <Form form={form} layout="vertical" onFinish={() => handleSearch(0)}>
            {/* <Row gutter={24} className="mt-4">
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name="product_name"
                                    label="Product"
                                >
                                    <Input allowClear size="large" placeholder="Enter Product" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name="sku"
                                    label="SKU"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Enter Sku"
                                        mode="tags"
                                        size="large"
                                        options={[]}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col xs={12} md={2}>
                                <Button type="primary" size="large" className="mb-4" onClick={() => handleSearch(0)}>
                                    <BoldButtonLabel labelText="Search" />
                                </Button>
                            </Col>
                        </Row> */}
            <Row>
              {/* {!_.isEmpty(productResponse?.products) && (
                                <Row className="my-2 w-full" justify={'end'} gutter={[12, 12]} align={'middle'}>
                                    <Col>
                                        <CustomPagination
                                            currentPage={currentPage}
                                            totalPages={Math.ceil(productResponse?.total_pages)}
                                            handleNext={handlePageChangeNext}
                                            handlePageChange={handlePageChange}
                                            handlePrevious={handlePageChangePrevious}
                                        />
                                    </Col>
                                </Row>
                            )} */}
              <Table
                expandable={{
                  expandedRowRender,
                  rowExpandable: record => record.has_variants
                }}
                loading={false}
                pagination={{
                  current: pageControl?.currentPage,
                  total: productResponse.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],

                  onChange: (currentPage, pageSize) =>
                    setPageControl({
                      currentPage,
                      pageSize
                    })
                }}
                bordered
                dataSource={productResponse.products}
                columns={columns}
                rowKey={(record: any) => record}
                scroll={{ x: 1450 }}
              ></Table>
            </Row>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default OrderProducts;
