import { PROMOTION_REPORT_URI } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';

export const getReport = async (params: any) => {
  if (params?.customer_id) {
    const headers = {
      'x-customer-id': params?.customer_id
    };
    delete params.customer_id;
    const url = `${PROMOTION_REPORT_URI}/customers/v1/report`;
    return httpClient.get({ url, params, headers });
  }
  const url = `${PROMOTION_REPORT_URI}/customers/v1/report`;
  return httpClient.get({ url, params });
};

export const getReportDetails = async (params: any, customer_id: any) => {
  const headers = {
    'x-customer-id': customer_id
  };
  const url = `${PROMOTION_REPORT_URI}/customers/v1/report/redeemption`;
  return httpClient.get({ url, params, headers });
};

export const DownloadReport = () => {
  const url = `${PROMOTION_REPORT_URI}/customers/v1/report/download`;
  return httpClient.get({ url, responseType: 'arraybuffer' });
};

export const promotionReportService = {
  getReport,
  getReportDetails,
  DownloadReport
};
