import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import OperationCreationForm from '../../components/OperatorCreationForm';
import { Card, Col, Row, Typography } from 'antd';

interface IOperatorCreationPageProps {}

const OperatorCreationPage: React.FunctionComponent<IOperatorCreationPageProps> = props => {
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="pt-2 min-h-[82vh]">
          <Row justify={'space-between'} className="mb-2">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Create Operator
              </Typography.Title>
            </Col>
          </Row>
          <OperationCreationForm />
        </Card>
      </div>
    </PrimaryLayout>
  );
};
export default OperatorCreationPage;
