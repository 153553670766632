import { Button, Card, Col, Row, Table, TableColumnsType, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import FieldDisplay from '../../../components/FieldDisplay';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj } from '../../../helpers';
import _ from '../../../helpers/lodash';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';

interface IRedemptionProps {}

const RedemptionListing: React.FunctionComponent<IRedemptionProps> = props => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { reward_type, title, promotion_type, id, code } = queryStringObj;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [redemptionListingResponse, setRedemptionListingResponse] = React.useState({} as any);

  const columns: TableColumnsType<any> = [
    {
      title: 'Customer Id',
      render(value, record, index) {
        return <>{record.user_id}</>;
      },
      align: 'center'
    },
    {
      title: 'Redeemed Date',
      render(value, record, index) {
        const date = dayjs(record.created_at).format('YYYY-MM-DD');
        return <>{date}</>;
      },
      align: 'center'
    }
    // {
    //   title: 'Redeemed Time',
    //   render(value, record, index) {
    //     return <>{'time'}</>;
    //   },
    //   align: 'center'
    // }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    await handleSearch(offset);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    setLoading(false);
  };

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
  };

  const handleOnGoBackToCoupons = () => {
    const params = {
      reward_type,
      promotion_type,
      title,
      offset: '0',
      id: id
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/coupons?${paramString}`);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          {' '}
          <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Redemption
              </Typography.Title>
            </Col>
          </Row>
          <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
            <Col xs={24} md={12}>
              <section className="flex gap-4 flex-wrap">
                <FieldDisplay label="Reward Type" value={reward_type} />
                <FieldDisplay label="Promotion Type" value={promotion_type} />
                <FieldDisplay label="Coupon code" value={code} />
              </section>
            </Col>
          </Row>
          {!_.isEmpty(redemptionListingResponse?.code_usage) && (
            <Row className="my-2" justify={'end'} gutter={[12, 12]} align={'middle'}>
              <Col>
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(redemptionListingResponse.code_usage.length / 10)}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </Col>
            </Row>
          )}
          <Table
            loading={false}
            bordered
            pagination={false}
            className="mt-4"
            dataSource={redemptionListingResponse.code_usage || []}
            columns={columns}
            scroll={{ x: 1000 }}
          ></Table>
          <div className="mt-4">
            <Button size="large" type="primary" onClick={handleOnGoBackToCoupons}>
              <BoldButtonLabel labelText="Go back to Coupons" />
            </Button>
          </div>
        </Card>
      </div>
    </PrimaryLayout>
  );
};
export default RedemptionListing;
