import { Button, Card, Col, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useNavigate, useParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import * as React from 'react';
import { profileService } from '../../../services';
import { cbtProductService } from '../../../services/cbt-product.service';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import { useAuth } from '../../../stores/use-auth';

interface IManageUserForm {
  formInstance: FormInstance;
  formType: 'create' | 'edit';
  handleOnSave: () => void;
}



const roleList: { label: string; value: string }[] = [
  { label: 'Root', value: 'ROOT' },
  { label: 'Read Only', value: 'Read Only' },
  { label: 'Admin', value: 'Admin' }
  // { label: 'Ops Admin', value: 'Ops Admin' },
  // { label: 'Ops User', value: 'Ops User' },
];

const ManageUserForm: React.FunctionComponent<IManageUserForm> = ({ formInstance, formType, handleOnSave }) => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const [productOptions, setProductOptions] = React.useState<{ label: string; value: string }[]>([]);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
    tenentName,
    bussinessGroupName
  }));
  console.log(tenentName)
  let productList: { label: string; value: string }[] = []
  if (tenentName === 'sparta') {
    productList = [
      // { label: 'Opus', value: 'opus' },
      { label: 'Elysian', value: 'elysian' }
      // { label: 'Nebula', value: 'nebula' },
      // { label: 'Identity', value: 'identity' }
    ];
  } else {
    productList = [
      { label: 'Opus', value: 'opus' },
      { label: 'Elysian', value: 'elysian' }
      // { label: 'Nebula', value: 'nebula' },
      // { label: 'Identity', value: 'identity' }
    ];
  }

  React.useEffect(() => {
    if (formType === 'create') {
      formInstance.setFieldsValue({
        user_enabled: true,
        email_verified: true
      });
    }
    // getProductOptions();
  }, [formType]);

  const getProductOptions = async () => {
    try {
      setLoading(true);
      const { data } = await cbtProductService.getSubscriptions({});

      if (!Array.isArray(data?.data)) {
        throw new Error('Invalid response data format');
      }

      const options = data?.data
        .map((item: any) => item.domain)
        .filter((domain: string) => domain !== 'eopus')
        .map((domain: string) => ({ label: domain, value: domain }));

      setProductOptions(options);
    } catch (error: any) {
      displayErrorNotifications(error);
      setProductOptions([]);
    }
    setLoading(false);
  };

  return (
    <Form
      form={formInstance}
      layout={'vertical'}
      onFinish={handleOnSave}
      initialValues={{
        subscriptions: formType === 'edit' ? formInstance.getFieldValue('subscriptions') : []
      }}
    >
      <Row gutter={12} className="mt-4">
        <Col xs={12} md={6}>
          <Form.Item
            name={'first_name'}
            label={'First Name'}
            rules={[
              { required: true, message: "This field can't be empty" },
              {
                pattern: /^[A-Za-z.]+$/,
                message: 'Field Is Not Valid'
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={'First Name'} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name={'middle_name'}
            label={'Middle Name'}
            rules={[
              {
                pattern: /^[A-Za-z]+$/,
                message: 'Field Is Not Valid'
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={'Middle Name'} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name={'last_name'}
            label={'Last Name'}
            rules={[
              { required: true, message: "This field can't be empty" },
              {
                pattern: /^[A-Za-z]+$/,
                message: 'Field Is Not Valid'
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={'Last Name'} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name={'email'}
            label={'Email'}
            rules={[
              { required: true, message: "This field can't be empty" },
              {
                type: 'email',
                message: 'Field Is Not Valid'
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={'Email'} disabled={formType === 'edit'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name={'user_name'}
            label={'User Name'}
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <Input className="w-full" allowClear placeholder={'User Name'} disabled={formType === 'edit'} />
          </Form.Item>
        </Col>
        {formType === 'create' && (
          <>
            <Col xs={12} md={6}>
              <Form.Item
                name={'password'}
                label={'Password'}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  },
                  {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.'
                  }
                ]}
              >
                <Input.Password className="w-full" allowClear placeholder={'Password'} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={'confirm_password'}
                label={'Confirm Password'}
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords do not match!'));
                    }
                  })
                ]}
              >
                <Input.Password className="w-full" allowClear placeholder={'Confirm Password'} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      {formType === 'create' && (
        <>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item name="email_verified" valuePropName="checked" label="Email Verified?">
                <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item name="user_enabled" valuePropName="checked" label="Enable User?">
                <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Form.List
        name="subscriptions"
        rules={[
          {
            validator: async (_, subscriptions) => {
              if (!subscriptions || subscriptions.length < 1) {
                return Promise.reject(new Error('At least one subscription must be added.'));
              }
            }
          }
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <React.Fragment>
              {fields.map(({ key, name, ...restField }, index) => {
                return (
                  <Row gutter={12} key={key}>
                    <Col xs={12} md={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'product']}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                        label="Product"
                      >
                        <Select allowClear placeholder="Select Product" options={productList}></Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'role']}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                        label="Role"
                      >
                        <Select allowClear placeholder="Select Role" options={roleList}></Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="mx-4 h-full flex justify-center items-center">
                        <DeleteOutlined
                          className="text-red-500 text-xl cursor-pointer"
                          role="button"
                          title="Remove Subscription"
                          onClick={() => remove(name)}
                        ></DeleteOutlined>
                      </div>
                    </Col>
                  </Row>
                );
              })}
              <Form.Item>
                <Row justify={'start'}>
                  <Col xs={24} md={4}>
                    <Button onClick={() => add({ product: '', role: '' })} icon={<PlusOutlined />}>
                      <BoldButtonLabel labelText="Add Subscription" />
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Col>
                </Row>
              </Form.Item>
            </React.Fragment>
          );
        }}
      </Form.List>
      <Row gutter={12}>
        <Col xs={12} md={6} className="mt-7">
          <Button type="primary" htmlType="submit" block>
            <BoldButtonLabel labelText="Save"></BoldButtonLabel>
          </Button>
        </Col>
        <Col xs={12} md={6} className="mt-7">
          <Button type="primary" onClick={() => navigate(-1)} block>
            <BoldButtonLabel labelText="Back"></BoldButtonLabel>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ManageUserForm;
