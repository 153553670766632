import { PROMOTIONS_URI } from './../../../config/envConfig';
import httpClient from '../../../http/http.client';
import { useAuth } from '../../../stores/use-auth';

export const getCreatePromotion = async (bodyData: any) => {
  const url = `${PROMOTIONS_URI}/promo-config/v2`;
  const { bussinessGroupName, tenentName } = useAuth.getState();
  const requestObj = { ...bodyData, ...{ tenant_id: tenentName, business_group_id: bussinessGroupName } };

  const { data, errors } = await httpClient.post({ url: url, body: requestObj });
  return { data: data, errors: errors };
};

export const updatePromotion = async (bodyData: any, id: any) => {
  const url = `${PROMOTIONS_URI}/promo-config/v2/${id}`;
  const { bussinessGroupName, tenentName } = useAuth.getState();
  const requestObj = { ...bodyData, ...{ tenant_id: tenentName, business_group_id: bussinessGroupName } };

  const { data, errors } = await httpClient.put({ url: url, body: requestObj });
  return { data: data, errors: errors };
};

export const scheduleDiscount = async (id: any) => {
  const url = `${PROMOTIONS_URI}/promo-config/v2/${id}/status/SCHEDULED`;

  const { data, errors } = await httpClient.patch({ url: url });
  return { data: data, errors: errors };
};

const getPromotionByPromotionId = (id: string) => {
  const url = `${PROMOTIONS_URI}/promo-config/v2/${id}`;

  return httpClient.get({ url });
};

const getPromotions = async () => {
  const url = `${PROMOTIONS_URI}/promo-config/v2/all`;
  return httpClient.get({ url });
};

export const promotionServiceCreate = {
  getCreatePromotion,
  scheduleDiscount,
  getPromotionByPromotionId,
  getPromotions,
  updatePromotion
};
