import * as React from 'react';
import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Divider,
  FormInstance,
  TimePicker,
  Switch,
  Spin,
  Card
} from 'antd';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDnn } from '../../hooks/drop-down-hooks/use-Dnn';
import { currencyList } from '../../../../data/intl-data';
import { useFulfillmentType } from '../../../../stores/use-order';

interface IServiceNetworkFormProps {
  handleSubmit?: () => void;
  formInstance: FormInstance;
  isUpdate?: boolean;
  readOnly?: boolean;
}

const serviceCategories = [
  { label: 'Global', value: 'GLOBAL' },
  { label: 'National', value: 'NATIONAL' },
  { label: 'Regional', value: 'REGIONAL' },
  { label: 'Local', value: 'LOCAL' }
];

const shippingModeOptions = [
  { label: 'Air', value: 'AIR' },
  { label: 'Water', value: 'WATER' },
  { label: 'Land', value: 'LAND' }
];

const deliveryOptions = [
  { label: 'Stadard', value: 'STANDARD' },
  { label: 'Express', value: 'EXPRESS' },
  { label: 'Same Day', value: 'SAME_DAY' },
  { label: 'Next Day', value: 'NEXT_DAY' },
  { label: 'Two Day', value: 'TWO_DAY' }
];

const shippingChargeTypes: { label: string; value: string }[] = [
  { label: 'Free', value: 'FREE' },
  { label: 'Flat', value: 'FLAT' },
  { label: 'Base Weight', value: 'BASE_WEIGHT' },
  { label: 'Slab Weight', value: 'SLAB_WEIGHT' },
  { label: 'Order Price', value: 'ORDER_PRICE' }
];

const ServiceNetworkForm: React.FunctionComponent<IServiceNetworkFormProps> = ({
  formInstance,
  handleSubmit,
  isUpdate,
  readOnly
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { fulfillmentType } = useFulfillmentType();

  const { debouncedFetchDnnDropdownOptions, dnnDropDownFetching, dnnDropDownOptions } = useDnn();
  return (
    <Form layout="vertical" onFinish={handleSubmit} form={formInstance} disabled={readOnly}>
      <Row gutter={12}>
        <Col xs={24} md={8} lg={6}>
          <Form.Item
            name={'service_category'}
            label="Service Category"
            rules={[{ required: true, message: 'Service Category is required' }]}
          >
            <Select
              options={serviceCategories}
              allowClear
              showSearch
              size="large"
              placeholder="Service Category"
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Form.Item name={'dnn_id'} label="DNN" rules={[{ required: true, message: 'DNN is required' }]}>
            <Select
              allowClear
              showSearch
              size="large"
              filterOption={false}
              placeholder="Search operators"
              notFoundContent={dnnDropDownFetching ? <Spin size="small" /> : null}
              onSearch={async searchTerm => {
                if (searchTerm) await debouncedFetchDnnDropdownOptions(searchTerm, fulfillmentType || undefined);
              }}
              options={dnnDropDownOptions}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Form.Item name={'shipping_profile_id'} label="Shipping Profile Id">
            <Select
              options={[]}
              allowClear
              showSearch
              size="large"
              placeholder="Shipping Profile"
              disabled={true}
            ></Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={16} lg={16}>
          <Form.Item name={'description'} label="Description">
            <Input.TextArea size="large" rows={2} placeholder="Enter description"></Input.TextArea>
          </Form.Item>
        </Col>
      </Row>

      <section>
        <Typography.Title level={5} className="text-[#2e2a5b]">
          Policies
        </Typography.Title>

        <Form.List name="policies">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                // const clearFields = () => {
                //   // const fieldName = ['policies', name, 'shipping_charge'];
                //   const fieldName = `policies.${name}.shipping_charge`;
                //   formInstance.setFieldsValue({
                //     [fieldName]: {
                //       name: undefined,
                //       shipping_charge_type: null,
                //     },
                //   });
                // };
                return (
                  <Card key={key} className="mb-4">
                    <Row>
                      <Col xs={12}>
                        <Divider orientation="left" plain>
                          <span className="text-lg">Policy - {index + 1} </span>
                        </Divider>
                      </Col>
                    </Row>
                    <Row gutter={12} justify={'space-between'}>
                      <Col xs={12} md={5}>
                        <Form.Item label="Active ?" valuePropName="checked" name={[name, 'is_active']}>
                          <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <div className="ml-4 my-2 flex justify-center items-center">
                          <DeleteOutlined
                            className="text-red-500 text-xl"
                            title={`Delete policy - ${index + 1}`}
                            onClick={() => remove(name)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'cut_off_time']}
                          rules={[{ required: true, message: 'Cut Off Time is required' }]}
                          label="Cut Off Time"
                        >
                          <TimePicker format={'HH:mm'} className="w-full" size="large"></TimePicker>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'delivery_option']}
                          rules={[{ required: true, message: 'Delivery option is required' }]}
                          label="Delivery Option"
                        >
                          <Select
                            options={deliveryOptions}
                            allowClear
                            showSearch
                            size="large"
                            placeholder="Enter delivery option"
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'min_volume_in_cc']}
                          rules={[{ required: true, message: 'Min volume is required' }]}
                          label="Min volume in CC"
                        >
                          <InputNumber className="w-full" size="large" placeholder="Enter min volumne"></InputNumber>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'max_volume_in_cc']}
                          rules={[{ required: true, message: 'Max volume is required' }]}
                          label="Max volumne in CC"
                        >
                          <InputNumber className="w-full" size="large" placeholder="Enter max volumne"></InputNumber>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'min_weight_in_kg']}
                          rules={[{ required: true, message: 'Min weight is required' }]}
                          label="Min weight in KG"
                        >
                          <InputNumber className="w-full" size="large" placeholder="Enter min weight"></InputNumber>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'max_weight_in_kg']}
                          rules={[{ required: true, message: 'Max weight is required' }]}
                          label="Max weight in KG"
                        >
                          <InputNumber className="w-full" size="large" placeholder="Enter max weight"></InputNumber>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'shipping_mode']}
                          rules={[{ required: true, message: 'Shipping Mode is required' }]}
                          label="Shipping Mode"
                        >
                          <Select
                            options={shippingModeOptions}
                            allowClear
                            showSearch
                            size="large"
                            placeholder="Shipping Mode"
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'tat_time_in_hours']}
                          rules={[{ required: true, message: 'Time in Hours is required' }]}
                          label="Time in Hours"
                        >
                          <InputNumber className="w-full" size="large" placeholder="Total time in hours"></InputNumber>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col xs={12} md={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'service_execution_lead_time_in_hours']}
                          rules={[{ required: true, message: 'Time in Hours is required' }]}
                          label="Service Execution Lead Time (Hrs)"
                        >
                          <InputNumber className="w-full" size="large" placeholder="Total time in hours"></InputNumber>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Shipping Charges
                    </Typography.Title>
                    {/* <div className='flex flex-row-reverse' onClick={clearFields}>
                      <Typography.Title level={5} className="text-[#2e2a5b]">
                        Clear
                      </Typography.Title>
                    </div> */}
                    <Row gutter={12}>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={[name, 'shipping_charge', 'name']} label="Charge Name">
                          <Input size="large" placeholder="SNN Name"></Input>
                        </Form.Item>
                      </Col>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => {
                          const prevPolicies = prevValues?.policies || [];
                          const currPolicies = currentValues?.policies || [];
                          return (
                            prevPolicies[key]?.shipping_charge?.shipping_charge_type !==
                            currPolicies[key]?.shipping_charge?.shipping_charge_type
                          );
                        }}
                      >
                        {({ getFieldValue }) => {
                          const policies = getFieldValue('policies') || [];
                          const chargeType = policies[key]?.shipping_charge?.shipping_charge_type;
                          return (
                            <>
                              {chargeType === 'FLAT' && (
                                <Col xs={24} md={8} lg={6}>
                                  <Form.Item
                                    {...restField}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                    name={[name, 'shipping_charge', 'flat_charge_config', 'currency']}
                                    label="Currency"
                                  >
                                    <Select
                                      allowClear
                                      size="large"
                                      placeholder="Select currency"
                                      options={currencyList}
                                    ></Select>
                                  </Form.Item>
                                </Col>
                              )}
                              {chargeType === 'BASE_WEIGHT' && (
                                <Col xs={24} md={8} lg={6}>
                                  <Form.Item
                                    {...restField}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                    name={[
                                      name,
                                      'shipping_charge',
                                      'base_weight_charge_config',
                                      'base_weight',
                                      'cost',
                                      'currency'
                                    ]}
                                    label="Currency"
                                  >
                                    <Select
                                      allowClear
                                      size="large"
                                      placeholder="Select currency"
                                      options={currencyList}
                                    ></Select>
                                  </Form.Item>
                                </Col>
                              )}
                              {chargeType === 'SLAB_WEIGHT' && (
                                <Col xs={24} md={8} lg={6}>
                                  <Form.Item
                                    {...restField}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                    name={[
                                      name,
                                      'shipping_charge',
                                      'slab_weight_slab_charge_config',
                                      0,
                                      'cost',
                                      'currency'
                                    ]}
                                    label="Currency"
                                  >
                                    <Select
                                      allowClear
                                      size="large"
                                      placeholder="Select currency"
                                      options={currencyList}
                                    ></Select>
                                  </Form.Item>
                                </Col>
                              )}
                              {chargeType === 'ORDER_PRICE' && (
                                <Col xs={24} md={8} lg={6}>
                                  <Form.Item
                                    {...restField}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                    name={[
                                      name,
                                      'shipping_charge',
                                      'order_price_slab_charge_config',
                                      0,
                                      'cost',
                                      'currency'
                                    ]}
                                    label="Currency"
                                  >
                                    <Select
                                      allowClear
                                      size="large"
                                      placeholder="Select currency"
                                      options={currencyList}
                                    ></Select>
                                  </Form.Item>
                                </Col>
                              )}
                            </>
                          );
                        }}
                      </Form.Item>
                    </Row>
                    <Row gutter={12}>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item
                          name={[name, 'shipping_charge', 'shipping_charge_type']}
                          label="Shipping Charge Type"
                        >
                          <Select
                            allowClear
                            size="large"
                            placeholder="Select type"
                            options={shippingChargeTypes}
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => {
                        const prevPolicies = prevValues?.policies || [];
                        const currPolicies = currentValues?.policies || [];

                        return (
                          prevPolicies[key]?.shipping_charge?.shipping_charge_type !==
                          currPolicies[key]?.shipping_charge?.shipping_charge_type
                        );
                      }}
                    >
                      {({ getFieldValue }) => {
                        const policies = getFieldValue('policies') || [];
                        const chargeType = policies[key]?.shipping_charge?.shipping_charge_type;

                        return (
                          <>
                            {/* {chargeType === 'FREE' && <>FREE</>} */}
                            {chargeType === 'FLAT' && (
                              <>
                                <Typography.Title level={5} className="text-[#2e2a5b]">
                                  Flat charge configuration
                                </Typography.Title>
                                <Row gutter={12}>
                                  <Col xs={24} md={8} lg={6}>
                                    <Form.Item
                                      name={[name, 'shipping_charge', 'flat_charge_config', 'amount']}
                                      label="Flat charge amount"
                                      rules={[{ required: true, message: 'Field is required' }]}
                                    >
                                      <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={8} lg={6}>
                                    <Form.Item
                                      name={[name, 'shipping_charge', 'flat_charge_config', 'fraction']}
                                      label="Flat charge Fraction"
                                      rules={[{ required: true, message: 'Field is required' }]}
                                    >
                                      <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}
                            {chargeType === 'BASE_WEIGHT' && (
                              <>
                                <Typography.Title level={5} className="text-[#2e2a5b]">
                                  Base weight charge configuration
                                </Typography.Title>
                                <>
                                  <Row gutter={12}>
                                    <Col xs={24} md={8} lg={6}>
                                      <Form.Item
                                        name={[
                                          name,
                                          'shipping_charge',
                                          'base_weight_charge_config',
                                          'base_weight',
                                          'base_weight_in_kg'
                                        ]}
                                        label="Base weight in KG"
                                        rules={[{ required: true, message: 'Field is required' }]}
                                      >
                                        <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                      <Form.Item
                                        name={[
                                          name,
                                          'shipping_charge',
                                          'base_weight_charge_config',
                                          'base_weight',
                                          'cost',
                                          'amount'
                                        ]}
                                        label="Amount"
                                        rules={[{ required: true, message: 'Field is required' }]}
                                      >
                                        <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                      <Form.Item
                                        name={[
                                          name,
                                          'shipping_charge',
                                          'base_weight_charge_config',
                                          'base_weight',
                                          'cost',
                                          'fraction'
                                        ]}
                                        label="Fraction"
                                        rules={[{ required: true, message: 'Field is required' }]}
                                      >
                                        <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={12}>
                                    <Col xs={24} md={8} lg={6}>
                                      <Form.Item
                                        name={[
                                          name,
                                          'shipping_charge',
                                          'base_weight_charge_config',
                                          'additional_weight',
                                          'additional_weight_in_kg'
                                        ]}
                                        label="Additional weight in KG"
                                        rules={[{ required: true, message: 'Field is required' }]}
                                      >
                                        <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                      <Form.Item
                                        name={[
                                          name,
                                          'shipping_charge',
                                          'base_weight_charge_config',
                                          'additional_weight',
                                          'cost',
                                          'amount'
                                        ]}
                                        label="Amount"
                                        rules={[{ required: true, message: 'Field is required' }]}
                                      >
                                        <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                      <Form.Item
                                        name={[
                                          name,
                                          'shipping_charge',
                                          'base_weight_charge_config',
                                          'additional_weight',
                                          'cost',
                                          'fraction'
                                        ]}
                                        label="Fraction"
                                        rules={[{ required: true, message: 'Field is required' }]}
                                      >
                                        <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </>
                              </>
                            )}
                            {chargeType === 'SLAB_WEIGHT' && (
                              <>
                                <Typography.Title level={5} className="text-[#2e2a5b]">
                                  Slab weight charge configuration
                                </Typography.Title>
                                <Form.List name={[name, 'shipping_charge', 'slab_weight_slab_charge_config']}>
                                  {(slabFields, { add: addSlab, remove: removeSlab }) => (
                                    <>
                                      {slabFields.map(({ key: slabKey, name: slabName, ...restSlabField }) => (
                                        <Card key={slabKey} className="mb-2">
                                          <div className="flex flex-row-reverse">
                                            <Button
                                              type="link"
                                              className="text-red-600"
                                              onClick={() => removeSlab(slabName)}
                                            >
                                              <DeleteOutlined className="text-red-500 text-xl" title="Delete Package" />
                                            </Button>
                                          </div>
                                          <Row gutter={12}>
                                            <Col xs={24} md={8} lg={6}>
                                              <Form.Item
                                                {...restSlabField}
                                                name={[slabName, 'min_weight_in_kg']}
                                                rules={[{ required: true, message: 'Field is required' }]}
                                                label="Min Slab Weight (KGs)"
                                              >
                                                <InputNumber
                                                  size="large"
                                                  className="w-full"
                                                  placeholder="Enter slab weight"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8} lg={6}>
                                              <Form.Item
                                                {...restSlabField}
                                                name={[slabName, 'max_weight_in_kg']}
                                                rules={[{ required: true, message: 'Field is required' }]}
                                                label="Max Slab Weight (KGs)"
                                              >
                                                <InputNumber
                                                  size="large"
                                                  className="w-full"
                                                  placeholder="Enter slab weight"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8} lg={6}>
                                              <Form.Item
                                                {...restSlabField}
                                                name={[slabName, 'cost', 'amount']}
                                                rules={[{ required: true, message: 'Amount is required' }]}
                                                label="Amount"
                                              >
                                                <InputNumber
                                                  size="large"
                                                  className="w-full"
                                                  placeholder="Enter amount"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8} lg={6}>
                                              <Form.Item
                                                {...restSlabField}
                                                name={[slabName, 'cost', 'fraction']}
                                                rules={[{ required: true, message: 'Fraction is required' }]}
                                                label="Fraction"
                                              >
                                                <InputNumber
                                                  size="large"
                                                  className="w-full"
                                                  placeholder="Enter fraction"
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Card>
                                      ))}
                                      <Button type="dashed" onClick={() => addSlab()} icon={<PlusOutlined />}>
                                        Add Slab
                                      </Button>
                                    </>
                                  )}
                                </Form.List>
                              </>
                            )}
                            {chargeType === 'ORDER_PRICE' && (
                              <>
                                <Typography.Title level={5} className="text-[#2e2a5b]">
                                  Order charge configuration
                                </Typography.Title>
                                <Form.List name={[name, 'shipping_charge', 'order_price_slab_charge_config']}>
                                  {(orderFields, { add: addOrder, remove: removeOrder }) => {
                                    return (
                                      <>
                                        {orderFields.map(({ key: orderKey, name: orderName, ...restOrderField }) => {
                                          return (
                                            <Card key={orderKey} className="mb-2">
                                              <div className="flex flex-row-reverse">
                                                <Button
                                                  type="link"
                                                  className="text-red-600"
                                                  onClick={() => removeOrder(orderName)}
                                                >
                                                  <DeleteOutlined
                                                    className="text-red-500 text-xl"
                                                    title="Delete Package"
                                                  />
                                                </Button>
                                              </div>
                                              <Row gutter={12}>
                                                <Col xs={24} md={8} lg={6}>
                                                  <Form.Item
                                                    {...restOrderField}
                                                    name={[orderName, 'min_price']}
                                                    rules={[{ required: true, message: 'Field is required' }]}
                                                    label="Minimum Order Price"
                                                  >
                                                    <InputNumber
                                                      size="large"
                                                      className="w-full"
                                                      placeholder="Enter slab weight"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                  <Form.Item
                                                    {...restOrderField}
                                                    name={[orderName, 'max_price']}
                                                    rules={[{ required: true, message: 'Field is required' }]}
                                                    label="Maximum Order Price"
                                                  >
                                                    <InputNumber
                                                      size="large"
                                                      className="w-full"
                                                      placeholder="Enter slab weight"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                  <Form.Item
                                                    {...restOrderField}
                                                    name={[orderName, 'cost', 'amount']}
                                                    rules={[{ required: true, message: 'Field is required' }]}
                                                    label="Cost"
                                                  >
                                                    <InputNumber
                                                      size="large"
                                                      className="w-full"
                                                      placeholder="Enter slab weight"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                  <Form.Item
                                                    {...restOrderField}
                                                    name={[orderName, 'cost', 'fraction']}
                                                    rules={[{ required: true, message: 'Field is required' }]}
                                                    label="Fraction"
                                                  >
                                                    <InputNumber
                                                      size="large"
                                                      className="w-full"
                                                      placeholder="Enter slab weight"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              </Row>
                                            </Card>
                                          );
                                        })}
                                        <Button type="dashed" onClick={() => addOrder()} icon={<PlusOutlined />}>
                                          Add Condition
                                        </Button>
                                      </>
                                    );
                                  }}
                                </Form.List>
                              </>
                            )}
                          </>
                        );
                      }}
                    </Form.Item>
                  </Card>
                );
              })}

              <Form.Item>
                <Row justify={'start'}>
                  <Col xs={24} md={4}>
                    <Button
                      onClick={() => {
                        const newProduct = {
                          is_active: true
                        };
                        add(newProduct);
                      }}
                      icon={<PlusOutlined />}
                    >
                      <BoldButtonLabel labelText="Add policy" />
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
      </section>
      <Row gutter={[12, 12]}>
        {!readOnly && (
          <Col xs={24} md={8} lg={6}>
            <Button size="large" htmlType="submit" block type="primary">
              <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'} />
            </Button>
          </Col>
        )}
        <Col xs={24} md={8} lg={6}>
          <Button
            size="large"
            disabled={false}
            block
            onClick={() => {
              if (searchParams.get('backUrl')) {
                navigate(searchParams.get('backUrl') as string);
              } else {
                navigate('/logistics/service-network');
              }
            }}
          >
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ServiceNetworkForm;
