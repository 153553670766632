import dayjs from 'dayjs';

export const useTimezone = () => {
  const fetchTimezones = () => {};

  return {
    timezoneSelectOptions: [
      {
        label: dayjs.tz.guess(),
        value: dayjs.tz.guess()
      },
      {
        label: 'America/Santiago',
        value: 'America/Santiago'
      },
      {
        label: 'America/Punta_Arenas',
        value: 'America/Punta_Arenas'
      },
      {
        label: 'Pacific/Easter',
        value: 'Pacific/Easter'
      }
    ]
  };
};
