import { CloudUploadOutlined, EditOutlined, FormOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, FormInstance, Input, Modal, Row, Select, Switch, Table, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { priceListService } from '../services/pricelist.service';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
import * as XLSX from 'xlsx';

interface IMasterCatalogProps {}

const searchByStatusOptions = [
  { label: 'Active', value: true },
  { label: 'In-Active', value: false }
];

interface EditableRowProps {
  index: number;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<any> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<any>(null);
  const form = React.useContext(EditableContext)!;

  React.useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (dataIndex === 'is_active') form.setFieldsValue({ is_active: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      if (dataIndex === 'is_active')
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`
              }
            ]}
          >
            <Select
              ref={inputRef}
              placeholder="Status"
              size="large"
              allowClear
              onBlur={save}
              options={[
                { label: 'Active', value: true },
                { label: 'In-Active', value: false }
              ]}
            ></Select>
          </Form.Item>
        );
    } else {
      childNode = (
        <div className="cursor-pointer" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const Catalog: React.FunctionComponent<IMasterCatalogProps> = props => {
  const navigate = useNavigate();
  const [masterCatalogForm] = Form.useForm();
  const [createCatalogForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [catalogResponse, setCatalogResponse] = React.useState({} as any);
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  const columns: any = [
    {
      title: 'Name',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <Typography.Text>{record?.name}</Typography.Text>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            {' '}
            <section className="flex flex-wrap justify-center">
              <ActionButton
                action="VIEW"
                title="View"
                onClick={() => {
                  navigate(`/promotion-engine/promotions/catalog/${record?.id}/view`);
                }}
              ></ActionButton>
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  navigate(`/promotion-engine/promotions/catalog/${record?.id}/edit`);
                }}
              ></ActionButton>
            </section>
          </>
        );
      }
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'is_active',
      render(value: any, record: any, index: any) {
        return (
          <>
            <Switch
              checked={record?.is_active ? true : false}
              onChange={(value: boolean) => handleOnChaneStatus(value, record?.id)}
            />
          </>
        );
      }
    }
  ];

  const handleOnChaneStatus = async (value: boolean, itemId: any) => {
    setLoading(true);
    const { errors } = await priceListService.patchPriceMasterStatus(itemId, value);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Product update success' });
      if (pageControl?.currentPage == 1) handleSearch(0);
      else setPageControl(prev => ({ ...prev, currentPage: 1 }));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columnsEdited = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  const handleSave = async (row: any) => {
    const transformedRow = { ...row };
    const newData = [...catalogResponse.data];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    const PriceListId = row.id;
    const editedKey = getEditedKey(item, transformedRow);

    if (editedKey === '') return;

    setLoading(true);
    const { errors } = await editingActions[editedKey](PriceListId, transformedRow[editedKey as keyof any]);
    setLoading(false);

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
      return;
    }
    handleSearch(pageControl.currentPage - 1);
    displaySuccessNotification({ message: 'PriceList update success' });
  };

  const getEditedKey = (previousRowValues: any, currentRowValues: any): string => {
    for (const key in previousRowValues) {
      if (previousRowValues[key as keyof any] !== currentRowValues[key as keyof any]) {
        return key;
      }
    }
    return '';
  };

  const editingActions: Record<string, any> = {
    is_active: (id: string, value: string) => priceListService.patchPriceMasterStatus(id, value)
  };

  React.useEffect(() => {
    if (pageControl?.currentPage && pageControl?.pageSize) setIntialData();
  }, [pageControl]);

  const setIntialData = async () => {
    await handleSearch(pageControl.currentPage - 1);
  };

  const handlePageChange = (currrentPage: number) => {
    handleSearch(currrentPage - 1);
  };

  const handleOnCreateCatalog = async () => {
    setLoading(true);
    const { name, description } = createCatalogForm.getFieldsValue();
    const bodyData = {
      name: name,
      description: description
    };
    const { errors } = await priceListService.createMasterData(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Successfully created catalog!' });
      createCatalogForm.setFieldsValue({
        name: '',
        description: ''
      });
      pageControl.currentPage == 1 ? handleSearch(0) : setPageControl(prev => ({ ...prev, currentPage: 1 }));
      setUploadModalVisible(false);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = masterCatalogForm.getFieldsValue();
    delete formValues.is_active;
    let params = {
      offset,
      limit: pageControl.pageSize,
      active: masterCatalogForm.getFieldsValue().is_active,
      ...formValues
    };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await priceListService.getMasterData(filteredParams);
    if (_.isEmpty(errors)) {
      setCatalogResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnStatusChange = (value: boolean) => {
    masterCatalogForm.setFieldsValue({ is_active: value });
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form
          layout="vertical"
          form={masterCatalogForm}
          onFinish={() =>
            pageControl.currentPage == 1 ? handleSearch(0) : setPageControl(prev => ({ ...prev, currentPage: 1 }))
          }
        >
          <Card>
            {' '}
            <Row justify={'space-between'} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Price Master
                </Typography.Title>
              </Col>
              <Col className="flex gap-2 flex-col md:flex-row">
                <div>
                  <Button
                    size="large"
                    block
                    type="primary"
                    onClick={() => {
                      setUploadModalVisible(true);
                    }}
                  >
                    <BoldButtonLabel labelText="Create New Catalog" />
                    <FormOutlined />
                  </Button>
                </div>
              </Col>
            </Row>
            <TenantInfo />{' '}
            <section className="mt-4">
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Search by Status"
                    name="is_active"
                    rules={[{ required: false, message: 'This field cannot be empty' }]}
                  >
                    {' '}
                    <Select
                      allowClear
                      placeholder="Select an option"
                      size="large"
                      className="w-[100%]"
                      options={searchByStatusOptions}
                      onChange={handleOnStatusChange}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary" htmlType="submit">
                <BoldButtonLabel labelText="search" />
                <SearchOutlined />
              </Button>
            </section>
            <section className="mt-4">
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Sort By"
                    name="sort"
                    rules={[{ required: false, message: 'This field cannot be empty' }]}
                  >
                    <Select
                      allowClear
                      size="large"
                      placeholder="Sort By"
                      className="w-[100%]"
                      onChange={() =>
                        pageControl.currentPage == 1
                          ? handleSearch(0)
                          : setPageControl(prev => ({ ...prev, currentPage: 1 }))
                      }
                      options={[{ label: 'Name', value: 'name' }]}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Order"
                    name="sortBy"
                    rules={[{ required: false, message: 'This field cannot be empty' }]}
                  >
                    <Select
                      allowClear
                      size="large"
                      placeholder="Order"
                      className="w-[100%]"
                      onChange={() =>
                        pageControl.currentPage == 1
                          ? handleSearch(0)
                          : setPageControl(prev => ({ ...prev, currentPage: 1 }))
                      }
                      options={[
                        { label: 'Ascending order', value: 'ASC' },
                        { label: 'Descending order', value: 'DESC' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </section>
            <section>
              {/* {!_.isEmpty(catalogResponse.data) && (
                <div className="flex justify-end my-2">
                  <CustomPagination
                    currentPage={catalogResponse.page_info.current_page}
                    totalPages={catalogResponse.page_info.total_pages}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </div>
              )}{' '} */}
              <Table
                loading={false}
                pagination={{
                  current: pageControl?.currentPage,
                  total: catalogResponse?.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  onChange: (currentPage, pageSize) => {
                    setPageControl(prev => ({ ...prev, currentPage, pageSize }));
                  }
                }}
                components={components}
                bordered
                dataSource={catalogResponse.data || []}
                columns={columnsEdited}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
          </Card>
        </Form>
        <Modal
          centered
          open={uploadModalVisible}
          onCancel={() => {
            setUploadModalVisible(false);
          }}
          width={1000}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Create Catalog:</Typography.Title>
            </div>
          }
        >
          <Form form={createCatalogForm} layout="vertical" onFinish={handleOnCreateCatalog}>
            <section className="flex flex-col justify-center">
              <div style={{ maxHeight: '300px' }}>
                <Row gutter={24}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        { required: true, message: 'This Field Cannot be Empty' },
                        { max: 200, message: 'Max limit is 200 characters' }
                      ]}
                    >
                      <Input size="large" placeholder="Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        { max: 200, message: 'Max limit is 200 characters' }
                      ]}
                    >
                      <TextArea rows={4} placeholder="Description" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Button block size="large" type="primary" className="mt-4" htmlType="submit">
                <BoldButtonLabel labelText="Create" />
              </Button>
            </section>
          </Form>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default Catalog;
