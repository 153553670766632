import { create } from 'zustand';
import { IPriceListListing } from '../modules/promotion-engine/types/price-list-types.s';

interface ProductDetails {
    productData: null
    setProductData: (productData: any) => void,
    priceData: null,
    setPriceData: (priceData: any) => void
}

interface IBasicDetails {
    productData: IPriceListListing
    setProductData: (productData: any) => void,
    priceData: null,
    setPriceData: (priceData: any) => void,
    masterOptions: string[],
    setMasterOptions: (options: string[]) => void,
    parentOptions: string[],
    setParentOptions: (options: string[]) => void
}

export const useProduct = create<ProductDetails>(set => ({
    productData: null,
    setProductData: productData => set({ productData }),
    priceData: null,
    setPriceData: priceData => set({ priceData })
}))

export const useBasicDetails = create<IBasicDetails>(set => ({
    productData: {} as IPriceListListing,
    setProductData: productData => set({ productData }),
    priceData: null,
    setPriceData: priceData => set({ priceData }),
    masterOptions: [],
    setMasterOptions: data => set({ masterOptions: data }),
    parentOptions: [],
    setParentOptions: data => set({ parentOptions: data })
}))