import { LOGISTICS, PRODUCT } from '../../../config/envConfig';
// import { JAVA_MAX_INT } from '../../../constants/common-constants';
import httpClient from '../../../http/http.client';


export const getAllCategories = (dataParams: any) => {
  const url = `${PRODUCT}/v1/products/categories`;
  const params = {
    offset: dataParams.page - 1,
    limit: 10,
    name: dataParams.search_key
  };
  return httpClient.get({ url, params });
};

export const getAllProductsFromCategories = (body: any, page: number) => {
  // const url = `${PRODUCT}/v1/products/list` updated to new api as getAllProductsFromCategoriesPromise function
  const params = {
    offset: page - 1,
    limit: 10
  };
  const url = `${PRODUCT}/v1/products/skus`
  return httpClient.post({ url, body, params });
}
export const getAllProductsFromCategoriesPromise = (body: any, params: any) => {
  const url = `${PRODUCT}/v1/products/skus`
  return httpClient.post({ url, body, params });
}

export const patchCategoryAndProduct = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/${id}/products`
  return httpClient.patch({ url, body });
}

export const getCategories = (searchQuery: string) => {
  const url = `${PRODUCT}/v1/products/categories`;
  const params = {
    name: searchQuery
  };
  return httpClient.get({ url, params });
};

export const getProductsDetails = (params: any) => {
  const url = `${PRODUCT}/v1/products`;
  return httpClient.get({ url, params });
}

export const updateStatus = (articalId: any, body: any) => {
  const url = `${PRODUCT}/v1/products/${articalId}/status`;
  return httpClient.patch({ url, body });
}

export const updateParentStatus = (articalId: any, body: any) => {
  const url = `${PRODUCT}/v1/products/${articalId}/state`;
  return httpClient.patch({ url, body })
}

export const updateChildStatus = (parentId: any, childId: any, body: any) => {
  const url = `${PRODUCT}/v1/products/${parentId}/variants/${childId}/state`
  return httpClient.patch({ url, body })
}

export const viewProductDetails = (articleId: any) => {
  const url = `${PRODUCT}/v1/products/${articleId}`
  return httpClient.get({ url })
}
