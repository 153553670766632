import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useAuth } from '../../../stores/use-auth';
import { ORDERS_BASE_URI, PROMOTION_NEW_DASHBOARD } from '../../../config/envConfig';

interface ILoyalityDashboard {}

const PromotionDashboard: React.FunctionComponent<ILoyalityDashboard> = props => {
  const { bussinessGroupName, tenentName } = useAuth(({ bussinessGroupName, tenentName }) => ({
    bussinessGroupName,
    tenentName
  }));
  const labelUrl = `${PROMOTION_NEW_DASHBOARD}/opus-analytics-dashboard/${tenentName}/${bussinessGroupName}`;
  // https://api.dev.cloverbaylabs.com/superset/dashboard/3/opus-analytics-dashboard/{tenant_id}/{business_group_id}
  return (
    <PrimaryLayout>
      {/* <Card className='w-100%'> */}
        <iframe title="Igad" style={{ height: '100%', width:'100%' ,border: 0 }} src={labelUrl}></iframe>
      {/* </Card> */}
    </PrimaryLayout>
  );
};

export default PromotionDashboard;
