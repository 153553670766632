import { Button, Card, Col, Form, Input, Row, Switch, Typography } from "antd"
import PrimaryLayout from "../../layouts/primary-layout"
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useLoader } from "../../stores/use-loader";
import { productService } from "../../modules/nebula/services";
import _ from "../../helpers/lodash";
import { displayErrorNotifications } from "../../helpers/toast.helpers";
import BoldButtonLabel from "../../components/BoldButtonLabel";
import { useForm } from "antd/es/form/Form";
import { Product } from "./productview.type";
import TextArea from "antd/es/input/TextArea";

interface IViewOrderProductsProps { }

const ViewOrderProduct: React.FunctionComponent<IViewOrderProductsProps> = props => {
    const { id } = useParams();
    const [viewProductForm] = useForm();
    const navigate = useNavigate()

    // const [viewOrderResponse, setViewOrderResponse] = React.useState({} as any)

    const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

    React.useEffect(() => {
        loadInitialData();
    }, []);

    const loadInitialData = async () => {
        setLoading(true)
        const { data, errors } = await productService.viewProductDetails(id)
        if (_.isEmpty(errors)) {
            // setViewOrderResponse(data)
            setDataToForm(data)
        } else {
            displayErrorNotifications(errors);
        }
        setLoading(false)
    }

    const getVariantsInDetails = (data: any) => {
        let returnData = data?.map((item: any) => {
            return {
                ...item,
                listPrice: item?.price_info?.list_price?.amount ? item?.price_info?.list_price?.amount / item?.price_info?.list_price?.fraction : null,
                unitPrice: item?.price_info?.list_price?.amount ? item?.price_info?.unit_cost?.amount / item?.price_info?.unit_cost?.fraction : null,
            }
        })
        return returnData
    }

    const setDataToForm = (respData: Product) => {
        const { article_id, sku_id, product_type, status, is_active, has_variants, variants, article_basic_dtls, other_product_identifiers, price_info, fulfillment_settings_config, inventory_settings_config, product_dimensions, packaging_dimensions } = respData
        const { length, width, height, weight } = product_dimensions
        const { is_one_man_fulfillable, is_returnable, return_time_window_days, shipping_profile_category, fixed_capacity_qty_per_line, capacity_per_ordered_qty, min_order_quantity, max_order_quantity, open_box_delivery_eligible, shipping_allowed, customer_delivery_allowed, pickup_allowed, freezer_required, ship_by_air_allowed, product_lead_time_in_mins, release_consolidation_in_mins, release_cut_off_in_mins, ship_notification_in_mins } = fulfillment_settings_config
        const { tag_controlled, is_hazmat, is_perishable, special_handling, item_substitution_allowed, procurement_allowed, forwarding_allowed, on_hand_safety_factor_percentage, future_safety_factor_percentage, is_serialised_tracking, fifo_tracked } = inventory_settings_config
        // const {pk_length:length, pk_width:width, pk_height:height, pk_weight:weight} = packaging_dimensions
        let formValues: any = {
            product_name: article_basic_dtls?.name,
            article_id,
            sku_id: sku_id ? sku_id : article_id,
            product_type,
            category: article_basic_dtls?.category,
            manufacture_name: article_basic_dtls?.manufacturer_name,
            status,
            has_variants,
            description: article_basic_dtls?.description,
            product_length: `${length?.dimension_value} ${length?.unit_of_measure}`,
            product_width: `${width?.dimension_value} ${width?.unit_of_measure}`,
            product_height: `${height?.dimension_value} ${height?.unit_of_measure}`,
            product_weight: `${weight?.dimension_value} ${weight?.unit_of_measure}`,

            package_length: `${packaging_dimensions?.length?.dimension_value} ${packaging_dimensions?.length?.unit_of_measure}`,
            package_width: `${packaging_dimensions?.width?.dimension_value} ${packaging_dimensions?.width?.unit_of_measure}`,
            package_height: `${packaging_dimensions?.height?.dimension_value} ${packaging_dimensions?.height?.unit_of_measure}`,
            package_weight: `${packaging_dimensions?.weight?.dimension_value} ${packaging_dimensions?.weight?.unit_of_measure}`,
            variants: getVariantsInDetails(variants),
            is_one_man_fulfillable,
            is_returnable,
            return_time_window_days,
            shipping_profile_category,
            fixed_capacity_qty_per_line,
            capacity_per_ordered_qty,
            min_order_quantity,
            max_order_quantity,
            open_box_delivery_eligible,
            shipping_allowed,
            customer_delivery_allowed,
            pickup_allowed,
            freezer_required,
            ship_by_air_allowed,
            product_lead_time_in_mins,
            release_consolidation_in_mins,
            release_cut_off_in_mins,
            ship_notification_in_mins,
            special_handling,
            tag_controlled,
            is_hazmat,
            is_perishable,
            item_substitution_allowed,
            procurement_allowed,
            forwarding_allowed,
            on_hand_safety_factor_percentage, future_safety_factor_percentage,
            is_serialised_tracking,
            fifo_tracked
        }
        viewProductForm.setFieldsValue(formValues)
    }

    return (
        <PrimaryLayout>
            <div className="container mx-auto px-4">
                <Card>
                    <Form form={viewProductForm} layout="vertical" disabled>
                        <Card>
                            <Typography.Title level={4} className="text-[#898c98]">Products Details: </Typography.Title>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Product Name" name={'product_name'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Artical Id" name={'article_id'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="SKU Id" name={'sku_id'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Product Type" name={'product_type'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Category" name={'category'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Manufacture Name" name={'manufacture_name'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Current Status" name={'status'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Has Variants ?" valuePropName="checked" name="has_variants">
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                    >
                                        <TextArea rows={3} placeholder="Description" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="mt-2">
                            <Typography.Title level={4} className="text-[#898c98]">Product Dimenssion: </Typography.Title>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Product Length" name={'product_length'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Product Width" name={'product_width'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Product Height" name={'product_height'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Product Weight" name={'product_weight'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="mt-2">
                            <Typography.Title level={4} className="text-[#898c98]">Packing Dimenssion: </Typography.Title>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Length" name={'package_length'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Width" name={'package_width'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Height" name={'package_height'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Weight" name={'package_weight'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="mt-2">
                            <Typography.Title level={4} className="text-[#898c98]">Fulfillment Settings Config </Typography.Title>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="one Man Fulfillable ?" valuePropName="checked" name={'is_one_man_fulfillable'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Returnable ?" valuePropName="checked" name={'is_returnable'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Return time window days" name={'return_time_window_days'} >
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Shipping Profile Category" name={'shipping_profile_category'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Minimum Order Quantity" name={'min_order_quantity'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Maximum Order Quantity" name={'max_order_quantity'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Open Box Delivery" valuePropName="checked" name={'open_box_delivery_eligible'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Shipping Allowed" valuePropName="checked" name={'shipping_allowed'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Customer Delivery Allowed" valuePropName="checked" name={'customer_delivery_allowed'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Pick Up Allowed" valuePropName="checked" name={'pickup_allowed'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Freezer Allowed" valuePropName="checked" name={'freezer_required'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Ship By Air Allowed" valuePropName="checked" name={'ship_by_air_allowed'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="product_lead_time_in_mins" name={'product_lead_time_in_mins'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="release_consolidation_in_mins" name={'release_consolidation_in_mins'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="release_cut_off_in_mins" name={'release_cut_off_in_mins'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="release_cut_off_in_mins" name={'ship_notification_in_mins'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="mt-2">
                            <Typography.Title level={4} className="text-[#898c98]">Inventory Settings Config </Typography.Title>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Special handling" name={'special_handling'}>
                                        <Input size="large" className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Tag Controlled" valuePropName="checked" name={'tag_controlled'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="is_hazmat" valuePropName="checked" name={'is_hazmat'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Perishable" valuePropName="checked" name={'is_perishable'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Item Change Available" valuePropName="checked" name={'item_substitution_allowed'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="procurement_allowed" valuePropName="checked" name={'procurement_allowed'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Forwarding Allowed" valuePropName="checked" name={'forwarding_allowed'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Serialized Tracking" valuePropName="checked" name={'is_serialised_tracking'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item label="Fifo Tracking" valuePropName="checked" name={'fifo_tracked'}>
                                        <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        {viewProductForm.getFieldsValue().has_variants && <Card className="mt-2">
                            <Typography.Title level={4} className="text-[#898c98]">Variants :</Typography.Title>
                            <Form.Item noStyle shouldUpdate>
                                {form => {
                                    return (
                                        <Form.List name="variants">
                                            {(fields, { add, remove }) => {
                                                return (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }, index) => {
                                                            return (
                                                                <div style={{
                                                                    height: 'fit-content',
                                                                    width: '100%',
                                                                    background: '#9b9b9b12',
                                                                    padding: '20px',
                                                                    borderRadius: '20px',
                                                                    marginBottom: '15px'
                                                                }}>
                                                                    <Row gutter={12}>
                                                                        <Col xs={12} lg={6}>
                                                                            <Form.Item
                                                                                label="SKU Id"
                                                                                name={[name, 'sku_id']}
                                                                            >
                                                                                <Input size="large" className="w-full" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col xs={12} lg={6}>
                                                                            <Form.Item
                                                                                label="Name"
                                                                                name={[name, 'variant_name']}
                                                                            >
                                                                                <Input size="large" className="w-full" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col xs={12} lg={6}>
                                                                            <Form.Item
                                                                                label="Variant Description"
                                                                                name={[name, 'variant_name']}
                                                                            >
                                                                                <Input size="large" className="w-full" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col xs={12} lg={6}>
                                                                            <Form.Item
                                                                                label="List Price"
                                                                                name={[name, 'listPrice']}
                                                                            >
                                                                                <Input size="large" className="w-full" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={12}>
                                                                        <Col xs={12} lg={6}>
                                                                            <Form.Item
                                                                                label="Unit Price"
                                                                                name={[name, 'unitPrice']}
                                                                            >
                                                                                <Input size="large" className="w-full" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Typography.Title level={5}>Attributes :</Typography.Title>
                                                                    <Form.List name={[name, 'variant_attributes']}>
                                                                        {(variant_attributes, { add: addProduct, remove: removeProduct }) => {
                                                                            return (
                                                                                <div style={{
                                                                                    height: 'fit-content',
                                                                                    width: '100%',
                                                                                    background: '#9b9b9b12',
                                                                                    padding: '20px',
                                                                                    borderRadius: '20px',
                                                                                    marginBottom: '15px'
                                                                                }}>
                                                                                    {variant_attributes.map((item: any) => {
                                                                                        return (
                                                                                            <Row gutter={12}>
                                                                                                <Col xs={12} lg={6}>
                                                                                                    <Form.Item
                                                                                                        label="Name"
                                                                                                        name={[name, 'attribute_name']}
                                                                                                    >
                                                                                                        <Input size="large" className="w-full" />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col xs={12} lg={6}>
                                                                                                    <Form.Item
                                                                                                        label="Value"
                                                                                                        name={[name, 'attribute_value']}
                                                                                                    >
                                                                                                        <Input size="large" className="w-full" />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            )
                                                                        }}
                                                                    </Form.List>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            }}
                                        </Form.List>
                                    )
                                }}
                            </Form.Item>
                        </Card>}
                    </Form>
                    <Row gutter={12} className="mt-4">
                        <Col xs={24} md={8} lg={6}>
                            <Button block size="large" onClick={() => navigate(-1)}>
                                <BoldButtonLabel labelText={'Back'} />{' '}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        </PrimaryLayout>
    )
}

export default ViewOrderProduct