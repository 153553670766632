import React from 'react';
import { promotionService } from '../../services/promotion.service';
import _ from '../../../../helpers/lodash';

const cache: Record<string, string[]> = {};

export const useOnlineChannels = () => {
  const [onlineDropdownFetching, setOnlineDropdownFetching] = React.useState(false);
  const [onlineDropdownOptions, setOnlineDropdownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchOnlineDropdownOptions = async (searchQuery: string) => {
    setOnlineDropdownFetching(true);

    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setOnlineDropdownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await promotionService.getLocations(searchQuery, 'DOTCOM');
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setOnlineDropdownOptions(() => {
          return [...transformedResponse];
        });
      }
    }
    setOnlineDropdownFetching(false);
  };

  const transformResponse = (data: any) => {
    return (data || []).map((value: string) => {
      return { label: value, value };
    });
  };

  const debouncedFetchOnlineDropdownOptions = _.debounce(fetchOnlineDropdownOptions, 1000);

  return {
    debouncedFetchOnlineDropdownOptions,
    onlineDropdownOptions,
    onlineDropdownFetching
  };
};
