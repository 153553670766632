import { Button, Card, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';

interface IShippingBasicDetailsUpdate {
  basicformInstance: FormInstance;
  readOnly?: boolean;
  mandtCreation?: boolean;
  handleOnUpdate?: (e: any) => void;
}

const ShippingBasicDetailsUpdate: React.FunctionComponent<IShippingBasicDetailsUpdate> = ({
  basicformInstance,
  handleOnUpdate,
  readOnly,
  mandtCreation
}) => {
  return (
    <Card className="my-2">
      <Form form={basicformInstance} layout="vertical" onFinish={handleOnUpdate} disabled={readOnly}>
        <Row gutter={12}>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'shipping_profile_name'} label="Shipping Profile Name">
              <Input size="large" placeholder="Shipping Profile Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'shipping_profile_type'} label="Shipping Profile Type">
              <Select
                disabled={mandtCreation}
                allowClear
                size="large"
                placeholder="Select shipping profile type"
                options={[
                  { label: 'Default', value: 'DEFAULT' },
                  { label: 'Custom', value: 'CUSTOM' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'shipping_profile_id'} label="Shipping Profile ID">
            <Input size="large" placeholder="Shipping Profile Id" disabled={true}></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={16} lg={12}>
            <Form.Item name={'description'} label="Description">
              <Input.TextArea size="large" rows={2} placeholder="Enter description"></Input.TextArea>
            </Form.Item>
          </Col>
        </Row>{' '}
        <Row gutter={[12, 12]} justify={'end'}>
          {!readOnly && (
            <Col xs={24} md={8} lg={6}>
              <Button size="large" htmlType="submit" block type="primary">
                <BoldButtonLabel labelText={'Apply Changes'} />
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
};

export default ShippingBasicDetailsUpdate;
