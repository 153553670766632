import { FormInstance } from 'antd';
import * as React from 'react';
import SnnBasicDetailsUpdateForm from './snn-basicdetails-update-form';
import SnnChargesUpdateForm from './snn-charges-update-form';
interface ISnnUpdateFormProps {
  handleSubmitBasic?: () => void
  handleOnNodeChange?: (data:any) => void
  handleSubmitCharges?: () => void
  handleOnStatusUpdate?: (e:any) => void;
  formInstance: FormInstance;
  basicformInstance: FormInstance;
  isUpdate?: boolean;
  readOnly?: boolean;
}

const SnnUpdateForm: React.FunctionComponent<ISnnUpdateFormProps> = ({
  handleSubmitBasic,
  handleOnNodeChange,
  handleSubmitCharges,
  handleOnStatusUpdate,
  formInstance,
  basicformInstance,
  isUpdate,
  readOnly
}) => {
  return (
    <>
      <SnnBasicDetailsUpdateForm
        handleSubmit={handleSubmitBasic}
        handleOnNodeChange={handleOnNodeChange}
        handleOnStatusUpdate={handleOnStatusUpdate}
        basicformInstance={basicformInstance}
        isUpdate={isUpdate}
        readOnly={readOnly}
      />
      <SnnChargesUpdateForm
        handleSubmit={handleSubmitCharges}
        formInstance={formInstance}
        isUpdate={isUpdate}
        readOnly={readOnly}
      />
    </>
  );
};

export default SnnUpdateForm;
