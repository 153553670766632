import { Form, FormInstance, Radio } from 'antd';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import PriceListBasicDetails from './price-list-basic-details';
import PriceListPriceEnteries from './price-list-price-enteries';

interface IPriceListDiscountFormProps {
  istabVisible: boolean;
  isformVisible: boolean;
  referenceBlockVisiblity: boolean;
  formInstance: FormInstance<any>;
  basicDetailsForm: FormInstance<any>;
  handleOnSave: () => void;
  handleGoBack: () => void;
  onClickSuggestedLines: () => void;
  onClickCopyLines: () => void
  paretListDropDown: any;
  masterCatalogOptions: any;
  getProductName: () => { product_Name: string; prefered_button: string };
  handleOnClickDownload: () => void;
  loadIntialData: (name: string) => void;
}

const PriceListDiscountForm: React.FunctionComponent<IPriceListDiscountFormProps> = ({
  istabVisible,
  isformVisible,
  referenceBlockVisiblity,
  basicDetailsForm,
  formInstance,
  handleOnSave,
  handleGoBack,
  onClickSuggestedLines,
  onClickCopyLines,
  paretListDropDown,
  masterCatalogOptions,
  getProductName,
  handleOnClickDownload,
  loadIntialData
}) => {
  const navigate = useNavigate();
  const location = useLocation()
  const { parentId } = useParams();

  const handleOnTabChange = (e: any) => {
    let tabName = e.target.value;
    const params = {
      offset: '0',
      backTabTo: tabName
    };
    const paramString = new URLSearchParams(params).toString();
    if(location.pathname.includes('price-list-edit-form')){
      navigate(`/promotion-engine/promotions/price-list/${parentId}/price-list-edit-form?${paramString}`);
    }else{
      navigate(`/promotion-engine/promotions/price-list/${parentId}/price-list-view-form?${paramString}`);
    }
    
    loadIntialData(tabName);
  };

  return (
    <Form form={formInstance} layout="vertical" onFinish={handleOnSave}>
      <div className="container mt-4">
        <Form.Item name={'price_list_form_tab'}>
          <Radio.Group
            defaultValue="BASIC-DETAILS"
            buttonStyle="solid"
            onChange={e => {
              handleOnTabChange(e);
            }}
          >
            <Radio.Button value="BASIC-DETAILS">
              <BoldButtonLabel labelText="Basic Details" />
            </Radio.Button>
            <Radio.Button value="PRICE-ENTERIES" disabled={!istabVisible}>
              <BoldButtonLabel labelText="Price Enteries" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {form => {
            const priceListForm = formInstance.getFieldsValue();
            return (
              priceListForm.price_list_form_tab === 'BASIC-DETAILS' && (
                <PriceListBasicDetails
                  referenceBlockVisiblity={referenceBlockVisiblity}
                  basicDetailsForm={basicDetailsForm}
                  formInstance={formInstance}
                  handleOnSave={handleOnSave}
                  handleGoBack={handleGoBack}
                  isformVisible={isformVisible}
                  paretListDropDown={paretListDropDown}
                  masterCatalogOptions={masterCatalogOptions}
                />
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {form => {
            const priceListForm = formInstance.getFieldsValue();
            return (
              priceListForm.price_list_form_tab === 'PRICE-ENTERIES' && (
                <PriceListPriceEnteries
                  isformVisible={isformVisible}
                  onClickSuggestedLines={onClickSuggestedLines}
                  onClickCopyLines={onClickCopyLines}
                  getProductName={getProductName}
                  handleGoBack={handleGoBack}
                  handleOnClickDownload={handleOnClickDownload}
                />
              )
            );
          }}
        </Form.Item>
      </div>
    </Form>
  );
};

export default PriceListDiscountForm;
