import { Card, Table } from 'antd';
import * as React from 'react';
import FieldDisplay from '../../../../components/FieldDisplay';
import { priceHelpers } from '../../../../data/intl-data';
import { useLocale } from '../../../../stores/use-locale';

interface IItemLevelViewProps {
  itemInfo: any;
}

const ItemLevelView: React.FunctionComponent<IItemLevelViewProps> = ({ itemInfo }) => {
  const { baseCurrency, userCurrency } = useLocale();
  return (
    <>
      {' '}
      <section>
        <Card className='mb-5'>
            <div className="my-auto font-bold text-base">SKU Information </div>
            <div className="flex gap-4 flex-wrap mt-2">
              <div>
                <section className="px-2 flex flex-col">
                  <FieldDisplay label="Article ID" value={itemInfo?.article_id} />
                  <FieldDisplay label="SKU ID" value={itemInfo?.sku_id} />
                  <FieldDisplay label="Product Name" value={itemInfo?.orderDetailsItems?.product_name} />
                </section>
              </div>
              <div>
                <section className="px-2 flex flex-col">
                  {/* <FieldDisplay label="SKU Class" value={itemInfo?.product_class} /> */}
                  <FieldDisplay label="Product Type" value={itemInfo?.orderDetailsItems?.product_type} />
                  {/* <FieldDisplay label="Country of Origin" value={itemInfo?.orderDetailsItems?.country_origin} /> */}
                  {/* <FieldDisplay label="SKU Description" value={itemInfo?.product_desc} /> */}
                  <FieldDisplay label="Co line Id" value={itemInfo?.co_line_id} />
                </section>
              </div>
              {/* <div>
              <section className="px-2 flex flex-col">
                <FieldDisplay label="Co line Id" value={itemInfo?.co_line_id} />
              </section>
            </div> */}
            </div>
        </Card>
        <Card className='mb-5'>
          <div className="font-bold text-base"> Price Info </div>
          <div className="flex gap-4 flex-wrap mt-2">
            {/* <section className="px-2 flex flex-col">
              <FieldDisplay
                label="Retail Price"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.retail_price,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={itemInfo?.retail_price}
              />
            </section> */}
            {/* <section className="px-2 flex flex-col">
              <FieldDisplay
                label="List Price"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.list_price,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={itemInfo?.list_price}
              />
            </section> */}
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label="Unit Price"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.unit_price,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={`$ ${itemInfo?.unit_price.toFixed(2)}`}
              />
            </section>
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label="Gross Amount"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.total_amount,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={`$ ${itemInfo?.gross_amount.toFixed(2)}`}
              />
            </section>
          </div>

          <div className="flex gap-4 flex-wrap mt-2">

            <section className="px-2 flex flex-col">
              <FieldDisplay
                label="Total Discount"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.total_discount,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={`$ ${itemInfo?.total_discount.toFixed(2)}`}
              />
            </section>
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label="Total Tax"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.total_tax,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={`$ ${itemInfo?.total_tax.toFixed(2)}`}
              />
            </section>
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label="Other Charges"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.total_tax,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={`$ ${itemInfo?.other_charges.toFixed(2)}`}
              />
            </section>
          </div>
          <section className="px-2 flex flex-col mt-2">
            <span className="text-xl font-bold">
              Item Total -{' '}
              {/* {priceHelpers.formatPriceToLocaleCurrency({
                amount: itemInfo?.total_item_value,
                baseCurrency,
                userCurrency
              })}{' '} */}
              {`$ ${itemInfo?.total_item_value.toFixed(2)}`}
            </span>
          </section>
        </Card>
        <div className="mt-4 mb-2 font-bold text-base">Tag Info</div>
        <Table
          scroll={{ x: 700 }}
          bordered
          pagination={false}
          columns={[
            {
              title: 'Tag ID',
              render(value, record, index) {
                return <>{record?.tag_id}</>;
              }
            },
            {
              title: 'Tag Type',
              render(value, record, index) {
                return <>{record?.tag_type}</>;
              }
            },
            {
              title: 'Quantity',
              render(value, record, index) {
                return <>{record?.quantity?.number_of_units}</>;
              }
            }
          ]}
          dataSource={itemInfo?.tags}
        ></Table>

        <div className="mt-4 mb-2 font-bold text-base">Charges</div>
        <Table
          scroll={{ x: 700 }}
          bordered
          pagination={false}
          columns={[
            {
              title: 'Charge Category',
              render(value, record, index) {
                return <>{record?.charge_category}</>;
              }
            },
            {
              title: 'Charge Name',
              render(value, record, index) {
                return <>{record?.charge_name}</>;
              }
            },
            {
              title: 'Charge Amount',
              render(value, record, index) {
                return (
                  <>
                    {priceHelpers.formatPriceToLocaleCurrency({ amount: record?.amount, baseCurrency, userCurrency })}
                  </>
                );
              }
            },
            {
              title: 'Is Discount?',
              render(value, record, index) {
                return <>{record?.is_discount ? 'Yes' : 'No'}</>;
              }
            }
          ]}
          dataSource={itemInfo?.charges}
        ></Table>

        <div className="mt-4 mb-2 font-bold text-base">Taxes</div>
        <Table
          scroll={{ x: 700 }}
          bordered
          pagination={false}
          columns={[
            {
              title: 'Tax Code',
              render(value, record, index) {
                return <>{record?.tax_code}</>;
              }
            },
            {
              title: 'Tax Rate',
              render(value, record, index) {
                return <>{record?.tax_rate + '%'}</>;
              }
            },
            {
              title: 'HSN Code',
              render(value, record, index) {
                return <>{record?.hsn_code}</>;
              }
            },
            {
              title: 'Tax Amount',
              render(value, record, index) {
                return (
                  <>${record?.price}</>
                );
              }
            }
          ]}
          dataSource={itemInfo?.taxes || []}
        ></Table>
      </section>
    </>
  );
};

export default ItemLevelView;
