import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  TableColumnsType,
  TimePicker,
  Typography
} from 'antd';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { convertQueryStringToObj, fileHelpers, validationHelpers } from '../../../helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { useBasicDetails } from '../../../stores/use-promotion';
import { priceListService } from '../services/pricelist.service';
import { objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { TableRowSelection } from 'antd/es/table/interface';
import CustomPagination from '../../../components/custom-pagination';
import dayjs from 'dayjs';

const discountOptions = [
  { label: 'Special Price', value: 'SPECIAL-PRICE' },
  { label: 'Percentage', value: 'PERCENTAGE' },
  { label: 'Absolute Discount', value: 'FIXED' }
];

const uomOptions = [
  { label: 'EA', value: 'EA' },
  { label: 'KG', value: 'KG' },
  { label: 'LB', value: 'LB' }
];

const statusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'In-Active', value: 'IN_ACTIVE' },
  { label: 'Experied', value: 'EXPIRED' }
];

const timeZones = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
];

const newcurrencyList = [
  { label: '🇮🇳 INR', value: 'IN', country: 'India' },
  { label: '🇨🇱 CHL', value: 'CLP', country: 'Chile' },
  { label: '🇺🇸 USD', value: 'US', country: 'USA' }
];

interface IPriceEnteriesSuggestedLinesProps {}

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const PriceEnteriesSuggestedLines: React.FunctionComponent<IPriceEnteriesSuggestedLinesProps> = () => {
  //new declare
  const { parentId } = useParams();
  const navigate = useNavigate();
  const [suggestedLineForm] = Form.useForm();
  const [suggestedLineSearchForm] = Form.useForm();

  //new State
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [suggestedLineResponse, setSuggestedLineResponse] = React.useState({} as any);
  const [result, setResult] = React.useState({} as any);
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [productList, setProductList] = React.useState<any>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageParam, setPageParam] = React.useState(0);

  //other declerations
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { offset, backTabTo, isEditable } = queryStringObj;
  const isFormEditable = JSON.parse(isEditable);

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const { productData } = useBasicDetails(({ productData }) => ({
    productData
  }));

  const columns: TableColumnsType<any> = [
    {
      title: 'Item Id',
      dataIndex: 'id',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.id}</>;
      }
    },
    {
      title: 'Name',
      dataIndex: 'item_id',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Currency',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.currency}</>;
      }
    },
    {
      title: 'UOM',
      align: 'center',
      render(value: any, record: any, index: any) {
        let UOM = record?.unit_of_measure;
        return <>{UOM}</>;
      }
    },
    {
      title: 'Cost Price',
      dataIndex: 'cost_price',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.cost_price}</>;
      }
    },
    {
      title: 'Lock Sales Price',
      dataIndex: 'lock_sale_price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.lock_sale_price ? String(record?.lock_sale_price) : '';
        return <>{data}</>;
      }
    },
    {
      title: 'Overide Master Lock Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.override_master_lock_price ? String(record?.override_master_lock_price) : '';
        return <>{data}</>;
      }
    },
    {
      title: 'Minimum Discount Percent',
      dataIndex: 'minimum_markup_percent',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_markup_percent}</>;
      }
    },
    {
      title: 'Maximum Discount Percent',
      dataIndex: 'maximun_markup_percent',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximun_markup_percent}</>;
      }
    },
    {
      title: 'List Price(Unit Price)',
      dataIndex: 'unit_price',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.unit_price}</>;
      }
    },
    {
      title: 'Threshold Based',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.treshold_Based}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      dataIndex: 'minimum_quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Unit of Measure',
      dataIndex: 'unit_of_measure',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.unit_of_measure}</>;
      }
    },
    {
      title: 'Discount Type',
      dataIndex: 'discount_type',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_type}</>;
      }
    },
    {
      title: 'Discount Value',
      dataIndex: 'discount_value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Promotion Restricted',
      dataIndex: 'discount_value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Start Date of Promotion Restricted',
      dataIndex: 'discount_value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'End Date of Promotion Restricted',
      dataIndex: 'discount_value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.status}</>;
      }
    }
  ];

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = () => {
    handleOnSearch(0);
  };

  const handleOnSearchClear = () => {
    suggestedLineSearchForm.setFieldsValue({
      searchProductName: null
    });
    handleOnSearch(0);
  };

  const handleOnSearch = async (offset = 0) => {
    setLoading(true);
    let formValues = suggestedLineSearchForm.getFieldsValue();
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(formValues), offset: offset + '' };
    let params = {
      ...filteredFormValues,
      offset,
      limit: 10
    };
    const { data, errors } = await priceListService.getMasterCatalogItem(
      productData.master_catalog_id as string,
      params
    );
    if (_.isEmpty(errors)) {
      setResult(data);
      setSuggestedLineResponse(data);
      let product_Name = data?.data?.map((cv: any) => ({ label: cv.item_id, value: cv.item_id }));
      product_Name = [...product_Name, { label: 'ALL', value: 'ALL' }];
      let productName = product_Name.map((cv: any) => ({ label: cv.label, value: cv.value }));
      setProductList(productName);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current && current < dayjs().startOf('day');
  };

  const getDateString = (date: any, formatter: string) => {
    let reqDate = dayjs(date).format(formatter);
    return reqDate;
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const handleOnGoBackToPriceEnteries = () => {
    const params = {
      offset: offset,
      backTabTo: backTabTo
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/promotions/price-list/${parentId}/price-list-edit-form?${paramString}`);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const loadBodyData = (itemList: any) => {
    let sendData = itemList.map((item: any) => {
      return {
        name: item.item_id,
        item_id: item.id,
        is_product_variant: true,
        article_id: null,
        currency_code: item.currency,
        status: item.status,
        schedule_info: {
          schedule_end_date: null,
          schedule_end_time: null,
          schedule_start_date: null,
          schedule_start_time: null
        },
        price_entries: {
          cost_price: item.cost_price,
          is_threshold_based: item.treshold_Based,
          override_master_lock_price: item.override_master_lock_price,
          lock_sale_price: item.lock_sale_price,
          maximum_markup_percent: String(item.maximum_markup_percent),
          minimum_markup_percent: String(item.minimum_markup_percent),
          sale_price_info: [
            {
              discount_type: item.discount_type,
              discount_value: item.discount_value,
              list_price: null,
              minimum_quantity: item.minimum_quantity,
              original_list_price: item.unit_price,
              unit_of_measure: item.unit_of_measure
            }
          ]
        },
        addnl_promotion_info: {
          is_promotion_restricted: false, //check this
          promotion_restriction_end_date: null,
          promotion_restriction_end_time: null,
          promotion_restriction_start_date: null,
          promotion_restriction_start_time: null
        }
      };
    });
    return sendData;
  };

  const handleOnCopiedData = async () => {
    setLoading(true);
    const targetIds = selectedRowKeys;
    const foundItems = suggestedLineResponse?.data?.filter((item: any) => targetIds?.includes(item?.item_id));
    const bodyData = { line_items: loadBodyData(foundItems) };
    const { errors } = await priceListService.createListOfItems_bulk(parentId as any, bodyData);
    if (_.isEmpty(errors)) {
      setUploadModalVisible(false);
      handleOnGoBackToPriceEnteries();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getReportData = async () => {
    setLoading(true);
    const productId = productData?.master_catalog_id;
    const { data, errors } = await priceListService.getReport(productId, result);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'report_excel', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    const targetIds = selectedRowKeys;
    if (targetIds.length > 0) {
      const foundItems = suggestedLineResponse?.data?.filter((item: any) => targetIds?.includes(item?.item_id));
      const getBody = { line_items: loadBodyData(foundItems) };
      const { data, errors } = await priceListService.getExcelValidation(parentId, getBody);
      if (_.isEmpty(errors)) {
        setResult(data);
        setUploadModalVisible(true);
      } else {
        displayErrorNotifications(errors);
      }
    } else {
      displayErrorNotifications([{ message: 'Select atlest one data !' }]);
    }
    setLoading(false);
  };

  const updatePriceEntries = (copyLineResponse: any, newArray: any) => {
    return {
      ...copyLineResponse,
      data: newArray
    };
  };

  const updateProductDetails = () => {
    const {
      productName,
      currency,
      discount_type,
      discount,
      minium_quantity,
      unit_of_measure,
      status,
      minimum_markup_percent,
      maximum_markup_percent,
      // time_Zone,
      lock_sale_price,
      override_master_lock_price,
      treshold_Based
    } = suggestedLineForm.getFieldsValue();
    let updateData = suggestedLineResponse.data;
    if (selectedRowKeys.length > 0) {
      let updatedResp = updateData.map((resp: any) => {
        if (selectedRowKeys.includes(resp.item_id)) {
          return {
            ...resp,
            currency: currency,
            discount_type: discount_type,
            discount_value: discount,
            minimum_quantity: minium_quantity,
            unit_of_measure: unit_of_measure,
            status: status,
            minimum_markup_percent: minimum_markup_percent,
            maximum_markup_percent: maximum_markup_percent,
            // time_Zone: time_Zone,
            lock_sale_price: String(lock_sale_price),
            override_master_lock_price: String(override_master_lock_price),
            treshold_Based: treshold_Based
          };
        }
        return resp;
      });
      let setData = updatePriceEntries(suggestedLineResponse, updatedResp);
      setSuggestedLineResponse(setData);
      displaySuccessNotification({ message: 'update success' });
    } else {
      displayErrorNotifications([{ message: 'No data are selected !!' }]);
    }
  };

  const handleOndefaultSelectedItem = (e: any) => {
    let searchedProductId = suggestedLineForm.getFieldsValue().productName;
    console.log(suggestedLineResponse);
    if (searchedProductId === 'ALL') {
      let selectKey = suggestedLineResponse?.data?.map((item: any) => item?.item_id);
      setSelectedRowKeys(selectKey);
    } else {
      let findSearchData = suggestedLineResponse?.data.filter((item: any) => item?.item_id === searchedProductId);
      let selectKey = findSearchData?.map((item: any) => item?.item_id);
      setSelectedRowKeys(selectKey);
    }
  };

  const handleOnResetListing = () => {
    suggestedLineForm.setFieldsValue({
      productName: '',
      discount_type: null,
      discount: '',
      minium_quantity: '',
      unit_of_measure: null,
      status: null,
      minimum_markup_percent: '',
      maximum_markup_percent: '',
      currency: null,
      // time_Zone: null,
      lock_Price: '',
      treshold_Based: ''
    });
    handleOnSearch(0);
    setSelectedRowKeys([]);
  };

  const handlePageChangeNext = (current_page: number) => {
    let nextPage = pageParam + 10;
    setCurrentPage(current_page);
    handleOnSearch(nextPage);
    setPageParam(nextPage);
  };

  const handlePageChangePrevious = (current_page: number) => {
    let prevPage = pageParam - 10;
    setCurrentPage(current_page);
    handleOnSearch(prevPage);
    setPageParam(prevPage);
  };

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            Suggested Lines
          </Typography.Title>

          {isFormEditable && <Form form={suggestedLineForm} layout="vertical" onFinish={updateProductDetails}>
            <div className="pt-5">
              <Row gutter={24}>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Discount Type"
                    name="discount_type"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      allowClear
                      placeholder="Select Discount Type"
                      size="large"
                      options={discountOptions}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Discount Value"
                    name="discount"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input size="large" placeholder="Discount Value" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Minimum Quantity"
                    name="minium_quantity"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input size="large" placeholder="Minimum Quantity" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Currency"
                    name="currency"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder="Select an option"
                      size="large"
                      className="w-[100%]"
                      options={newcurrencyList}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Unit Of Measure (UOM)"
                    name="unit_of_measure"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select allowClear placeholder="Select Discount Type" size="large" options={uomOptions}></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder="Select an option"
                      size="large"
                      className="w-[100%]"
                      options={statusOptions}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Minimum Discount Percentage"
                    name="minimum_markup_percent"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input className="w-[100%]" size="large" placeholder="Minimum Discount Percentage" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="Maximum Discount Percentage"
                    name="maximum_markup_percent"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input className="w-[100%]" size="large" placeholder="Maximum Discount Percentage" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Lock Sale Price"
                    name="lock_sale_price"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Radio.Group>
                      <Radio value={'true'}>Yes</Radio>
                      <Radio value={'false'}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Is Threshold Based"
                    name="treshold_Based"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Radio.Group>
                      <Radio value={'true'}>Yes</Radio>
                      <Radio value={'false'}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Overide Master Lock Price"
                    name="override_master_lock_price"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Radio.Group>
                      <Radio value={'true'}>Yes</Radio>
                      <Radio value={'false'}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12} justify={'start'}></Row>
              <Row gutter={24} justify={'start'}>
                <Col xs={24} md={6}>
                  <Button block size="large" type="primary" htmlType="submit">
                    <BoldButtonLabel labelText="Apply" />
                  </Button>
                </Col>
                <Col xs={24} md={6}>
                  <Button block size="large" type="primary" onClick={handleOnResetListing}>
                    <BoldButtonLabel labelText="Reset" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>}
          <Form form={suggestedLineSearchForm} layout="horizontal" className="mt-6" onFinish={() => handleOnSearch(0)}>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Search Name"
                  name="name"
                  rules={[{ required: false, message: "This field can't be empty" }]}
                >
                  <Input className="w-[100%]" size="large" placeholder="Enter Name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={3}>
                <Button block size="large" htmlType="submit">
                  <BoldButtonLabel labelText="Search" />
                </Button>
              </Col>
            </Row>
            {/* <Row gutter={24}>
              <Col xs={24} md={6}>
                <Button block size="large" type="primary" htmlType="submit">
                  <BoldButtonLabel labelText="Search" />
                </Button>
              </Col>
              <Col xs={24} md={6}>
                <Button block size="large" type="primary" onClick={handleOnSearchClear}>
                  <BoldButtonLabel labelText="Clear All" />
                </Button>
              </Col>
            </Row> */}
          </Form>
          {!_.isEmpty(suggestedLineResponse?.data) && (
            <Row className="my-2" justify={'end'} gutter={[12, 12]} align={'middle'}>
              <Col>
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(suggestedLineResponse?.count / 10)}
                  handleNext={handlePageChangeNext}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChangePrevious}
                />
              </Col>
            </Row>
          )}
          <section>
            {' '}
            <Table
              rowSelection={rowSelection}
              loading={false}
              pagination={false}
              bordered
              dataSource={suggestedLineResponse?.data || []}
              columns={columns}
              rowKey={(record: any) => record?.item_id}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
          <div style={{ marginTop: '20px' }}>
            <Row className="flex">
              <Col>
                <div className="flex gap-4">
                  {isFormEditable && <Button size="large" type="primary" onClick={handleOnSubmit}>
                    <BoldButtonLabel labelText="Check Report and Add" />
                  </Button>}
                  <Button size="large" block onClick={handleOnGoBackToPriceEnteries}>
                    <BoldButtonLabel labelText="Go Back" />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Modal
            centered
            open={uploadModalVisible}
            onCancel={() => {
              setUploadModalVisible(false);
            }}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Result</Typography.Title>
              </div>
            }
          >
            <ul>
              <li>
                <Typography.Text className="text-purple-500">Total Items: {result.total_line_items}</Typography.Text>
              </li>
              <li>
                <Typography.Text className="text-red-500">Failed: {result.total_failed}</Typography.Text>
              </li>
              <li>
                <Typography.Text className="text-yellow-500">
                  Ignored/Duplicates: {result.total_duplicate}
                </Typography.Text>
              </li>
              <li>
                <Typography.Text className="text-green-500">Total Success: {result.total_success}</Typography.Text>
              </li>
            </ul>
            <Button
              block
              type="primary"
              onClick={handleOnCopiedData}
              className="mt-4"
              disabled={result.total_success < 1}
            >
              <BoldButtonLabel labelText="Confirm add" />
            </Button>
            <Button block type="primary" onClick={getReportData} className="mt-4">
              <BoldButtonLabel labelText="Download Report" />
            </Button>
          </Modal>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PriceEnteriesSuggestedLines;
