import * as React from 'react';
import { OMS_DASHBOARD } from '../../../../config/envConfig';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useAuth } from '../../../../stores/use-auth';

interface IDashboardProps {}

const NebulaOmsDashboard: React.FunctionComponent<IDashboardProps> = props => {
  const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
    tenentName,
    bussinessGroupName
  }));

  let Url = `${OMS_DASHBOARD}/${tenentName}/${bussinessGroupName}`;

  return (
    <PrimaryLayout>
      <iframe title="Igad" style={{ height: '100%', width: '100%', border: 0 }} src={Url}></iframe>
    </PrimaryLayout>
  );
};

export default NebulaOmsDashboard;
