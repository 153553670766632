import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, Form, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createRuleTriggerTypeData, triggerTypeSelectOptions } from '../../data';
import CustomPagination from '../../../../components/custom-pagination';
import { IEarnRuleListingItem, IEarnRuleListingResponse } from '../../types/earn-rules';
import _ from '../../../../helpers/lodash';
import { convertQueryStringToObj, objectHelpers } from '../../../../helpers';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { loyaltyService } from '../../services/loyalty.service';
import { useLoader } from '../../../../stores/use-loader';
import { IconInfoCircle } from '@tabler/icons';
import ActionButton from '../../../../components/ActionButton';

interface IEarnRulesListingProps {}
const statusList = [
  { value: 'OPEN', label: 'Open' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'DEFFERED', label: 'Deferred' },
  { value: 'ON_HOLD', label: 'On Hold' },
  {
    value: 'CLOSED',
    label: 'Closed'
  }
];

const EarnRulesListing: React.FunctionComponent<IEarnRulesListingProps> = props => {
  const [searchForm] = Form.useForm();
  const [listingResponse, setListingResponse] = React.useState({} as IEarnRuleListingResponse);
  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, IEarnRuleListingItem[]>);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
    await handleFilterSearch(pageControl?.currentPage - 1);
  };

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  const columns: TableColumnsType<IEarnRuleListingItem> = [
    {
      title: 'Rule Name',
      render(value, record, index) {
        return <>{record.name}</>;
      },
      align: 'center'
    },
    {
      title: 'Version',
      render(value, record, index) {
        return <>{record.version}</>;
      },
      align: 'center'
    },
    {
      title: 'Trigger Type',
      render(value, record, index) {
        return <>{record.trigger_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Applicability Type',
      render(value, record, index) {
        return <>{record.applicability_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Status',
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },

    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex gap-2 justify-center">
            <ActionButton
              onClick={() => navigate(`/loyalty/config/earn-rule/${record.id}`)}
              title="View Rule"
              action="VIEW"
            />

            <ActionButton
              onClick={() => navigate(`/loyalty/config/earn-rule/${record.id}/test`)}
              title="Test Rule"
              action="TEST"
            />

            <ActionButton
              onClick={() => navigate(`/loyalty/config/earn-rule/${record.id}/new`)}
              title="Create New Version"
              action="CREATE_NEW_VERSION"
            />
            <ActionButton
              onClick={() => handleTableExpand(record)}
              title="View All Versions"
              action="EXPAND_VERSIONS"
            />
          </section>
        );
      },
      align: 'center'
    },

    {
      title: 'Status Update',
      render(value, record, index) {
        let actions: { label: string; action: string }[] = [];

        if (record.status === 'OPEN') {
          actions.push({ label: 'Activate', action: 'ACTIVE' });
        }
        if (record.status === 'ACTIVE') {
          actions.push(
            ...[
              {
                label: 'On Hold',
                action: 'ON_HOLD'
              },

              {
                label: 'Defer',
                action: 'DEFFERED'
              }
            ]
          );
        }

        if (record.status === 'ON_HOLD') {
          actions.push(
            ...[
              { label: 'Activate', action: 'ACTIVE' },
              {
                label: 'Defer',
                action: 'DEFFERED'
              }
            ]
          );
        }
        return (
          <section className="flex justify-center">
            {actions.map(({ label, action }) => (
              <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                {label}
              </Button>
            ))}
          </section>
        );
      },
      align: 'center'
    }
  ];

  const navigate = useNavigate();

  const handleClickCreate = () => {
    navigate(`/loyalty/config/earn-rule/create`);
  };
  const handleStatusChange = async (status: string, id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.changeEarnRuleStatus(id, status);
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({ message: 'Rule update success' });
      const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;

      await Promise.allSettled([handleFilterSearch(offset), fetchEarnRuleVersions(id)]);
    }
    setLoading(false);
  };

  const fetchEarnRuleVersions = async (id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getEarnRuleVersions(id);
    if (_.isEmpty(errors)) {
      setExpandedRowData(rowData => ({ ...rowData, [id]: data }));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleTableExpand = async (record: IEarnRuleListingItem) => {
    if (expandedRowKeys.includes(record.id)) {
      return setExpandedRowKeys([]);
    }

    if (_.isEmpty(expandedRowData[record.id])) {
      await fetchEarnRuleVersions(record.id);
    }

    setExpandedRowKeys([record.id]);
  };
  const handlePageChange = (currentPage: number) => {
    const offset = (currentPage - 1) * 10;
    handleFilterSearch(offset);
  };
  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = { ...formValues, offset };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getRuleEarnList({
      limit: pageControl?.pageSize,
      offset,
      ...formValues
    });

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      const transformedItems = data?.data?.map((item: any) => ({ ...item, key: item.id }));
      const transformedData = { ...data, data: transformedItems };
      setListingResponse(transformedData);
    }
    setLoading(false);
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<IEarnRuleListingItem> = [
      {
        title: 'Rule Name',
        render(value, record, index) {
          return <>{record.name}</>;
        },
        align: 'center'
      },
      {
        title: 'Version',
        render(value, record, index) {
          return <>{record.version}</>;
        },
        align: 'center'
      },
      {
        title: 'Trigger Type',
        render(value, record, index) {
          return <>{record.trigger_type}</>;
        },
        align: 'center'
      },
      {
        title: 'Applicability Type',
        render(value, record, index) {
          return <>{record.applicability_type}</>;
        },
        align: 'center'
      },
      {
        title: 'Status',
        render(value, record, index) {
          return <>{record.status}</>;
        },
        align: 'center'
      },

      {
        title: 'Action',
        render(value, { id }, index) {
          return (
            <section className="flex gap-2 justify-center">
              <ActionButton
                action="VIEW"
                onClick={() => navigate(`/loyalty/config/earn-rule/${id}`)}
                title="View rule"
              />
              <ActionButton
                title="Test rule"
                action="TEST"
                onClick={() => navigate(`/loyalty/config/earn-rule/${id}/test`)}
              />
            </section>
          );
        },
        align: 'center'
      },

      {
        title: 'Status update',

        render(value, record, index) {
          let actions: { label: string; action: string }[] = [];

          if (record.status === 'OPEN') {
            actions.push({ label: 'Activate', action: 'ACTIVE' });
          }
          if (record.status === 'ACTIVE') {
            actions.push(
              ...[
                {
                  label: 'On Hold',
                  action: 'ON_HOLD'
                },

                {
                  label: 'Defer',
                  action: 'DEFFERED'
                }
              ]
            );
          }

          if (record.status === 'ON_HOLD') {
            actions.push(
              ...[
                { label: 'Activate', action: 'ACTIVE' },
                {
                  label: 'Defer',
                  action: 'DEFFERED'
                }
              ]
            );
          }
          return (
            <section className="flex justify-center">
              {actions.map(({ label, action }) => (
                <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                  {label}
                </Button>
              ))}
            </section>
          );
        },
        align: 'center'
      }
    ];
    return (
      <>
        <Typography.Title level={5}>Versions</Typography.Title>
        <Table bordered dataSource={expandedRowData[expandedRowKeys[0]]} pagination={false} columns={columns} />
      </>
    );
  };

  const handleOnClear = () => {
    searchForm.resetFields();
    handleFilterSearch();
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Earn Rules
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={handleClickCreate}>
                <BoldButtonLabel labelText="Create"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form layout="vertical" form={searchForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="trigger_type" label="Trigger Type">
                    <Select
                      placeholder="Select Trigger Type"
                      size="large"
                      onChange={() => searchForm.setFieldValue('applicablity_type', null)}
                      options={triggerTypeSelectOptions}
                      allowClear
                      onClear={() => {
                        searchForm.setFieldsValue({
                          trigger_type: null
                        });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.trigger_type !== curValues.trigger_type;
                    }}
                  >
                    {form => {
                      const { trigger_type } = form.getFieldsValue();
                      const options = createRuleTriggerTypeData[trigger_type]?.applicabilityTypes || [];

                      return (
                        <Form.Item name="applicablity_type" label="Applicability Type">
                          <Select
                            placeholder="Select Applicablity Type"
                            notFoundContent={<>Select a trigger type to view options</>}
                            size="large"
                            options={options}
                            allowClear
                            onClear={() => {
                              searchForm.setFieldsValue({
                                applicablity_type: null
                              });
                              handleFilterSearch();
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="status" label="Status">
                    <Select
                      placeholder="Status"
                      size="large"
                      options={statusList}
                      allowClear
                      onClear={() => {
                        searchForm.setFieldsValue({
                          status: null
                        });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={() =>
                      pageControl?.currentPage == 1
                        ? handleFilterSearch(0)
                        : setPageControl(prev => ({ ...prev, currentPage: 1 }))
                    }
                    block
                  >
                    <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button size="large" onClick={handleOnClear} block>
                    <BoldButtonLabel labelText="Clear" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
          <section className="mt-4">
            <Table
              loading={false}
              expandable={{
                expandedRowRender,
                expandedRowKeys,
                showExpandColumn: false
              }}
              pagination={{
                current: pageControl?.currentPage,
                total: listingResponse?.page_info?.total_pages * pageControl?.pageSize || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],

                onChange: (currentPage, pageSize) => {
                  setPageControl({ currentPage, pageSize });
                }
              }}
              bordered
              dataSource={listingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EarnRulesListing;
