import * as React from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
interface IActiveInactiveInfoProps {
  active: boolean;
  activeTitle?: string;
  inactiveTitle?: string;
  activeLabel?: string;
  inactiveLabel?: string;
}

const ActiveInactiveInfo: React.FunctionComponent<IActiveInactiveInfoProps> = ({
  active,
  activeTitle,
  inactiveTitle,
  inactiveLabel,
  activeLabel
}) => {
  return (
    <Tooltip title={active ? activeLabel ?? 'Active' : inactiveLabel ?? 'Inactive'}>
      <div className="flex gap-2 cursor-pointer">
        {active ? (
          <>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <Typography.Text className="text-[#5e5f66]">{activeTitle ?? 'Active'}</Typography.Text>
          </>
        ) : (
          <>
            <CloseCircleTwoTone twoToneColor="red" />
            <Typography.Text className="text-[#5e5f66]">{inactiveTitle ?? 'Inactive'}</Typography.Text>
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default ActiveInactiveInfo;
