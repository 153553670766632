import { Typography } from 'antd';
import * as React from 'react';
import PrimaryLayout from '../layouts/primary-layout';

interface I404PageProps {}

const Error404Page: React.FunctionComponent<I404PageProps> = props => {
  return (
    <PrimaryLayout>
      <div className="container mx-auto">
        <Typography.Title level={2} className="text-center">
          404 Page not found
        </Typography.Title>
      </div>
    </PrimaryLayout>
  );
};

export default Error404Page;
