import { Button, Col, Form, FormInstance, Input, Radio, Row, Select, Spin } from 'antd';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useLogisticsNodes } from '../../../promotion-engine/hooks/drop-down-hooks/use-logistics-nodes';

interface ISnnBasicDetailsProps {
  handleSubmit?: () => void;
  handleOnNodeChange?: (val: any) => void;
  handleOnStatusUpdate?: (e: any) => void;
  basicformInstance: FormInstance;
  isUpdate?: boolean;
  readOnly?: boolean;
}

const SnnBasicDetailsUpdateForm: React.FunctionComponent<ISnnBasicDetailsProps> = ({
  handleSubmit,
  handleOnNodeChange,
  handleOnStatusUpdate,
  basicformInstance,
  isUpdate,
  readOnly
}) => {
  const { debouncedFetchUserDropdownOptions, nodeDropdownFetching, nodeDropdownOptions } = useLogisticsNodes();
  return (
    <Form layout="vertical" onFinish={handleSubmit} form={basicformInstance} disabled={readOnly}>
      <section
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#a59d9d24',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Row gutter={12}>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'snn_name'} label="SNN Name" rules={[{ required: true, message: 'SNN Name is required' }]}>
              <Input size="large" placeholder="SNN Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'fulfillment_type'} label="Fulfillment Type">
              <Select
                size="large"
                disabled
                placeholder="Select Fulfillment Type"
                options={[
                  { label: 'Sales', value: 'SALES' },
                  { label: 'Transfer', value: 'TRANSFER' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'node_group'} label="Node Group">
              <Select
                mode="multiple"
                allowClear
                size="large"
                filterOption={false}
                placeholder="Search nodes"
                notFoundContent={nodeDropdownFetching ? <Spin size="small" /> : null}
                onSearch={async searchTerm => {
                  if (searchTerm) await debouncedFetchUserDropdownOptions(searchTerm);
                }}
                onChange={(data: any, val: any) => {
                  handleOnNodeChange && handleOnNodeChange(data);
                }}
                options={nodeDropdownOptions}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'is_active'} label="Status" rules={[{ required: true, message: 'Please select Status' }]}>
              <Radio.Group onChange={handleOnStatusUpdate}>
                <Radio value={true}>Active ?</Radio>
                <Radio value={false}>InActive ?</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={16} lg={12}>
            <Form.Item name={'description'} label="Description">
              <Input.TextArea size="large" rows={2} placeholder="Enter description"></Input.TextArea>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify={'end'}>
          {!readOnly && (
            <Col xs={24} md={8} lg={6}>
              <Button size="large" htmlType="submit" block type="primary">
                <BoldButtonLabel labelText={isUpdate ? 'Apply Changes' : 'Create'} />
              </Button>
            </Col>
          )}
        </Row>
      </section>
    </Form>
  );
};

export default SnnBasicDetailsUpdateForm;
