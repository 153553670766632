import React from 'react';
import { promotionService } from '../../services/promotion.service';
import _ from '../../../../helpers/lodash';

const cache: Record<
  string,
  {
    customer_id: string;
    email: string;
    first_name: string;
    last_name: string;
  }[]
> = {};

export const useCustomers = () => {
  const [userDropdownFetching, setUserDropdownFetching] = React.useState(false);
  const [userDropdownOptions, setUserDropdownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchUserDropdownOptions = async (searchQuery: string) => {
    setUserDropdownFetching(true);

    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setUserDropdownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await promotionService.searchUsersByQuery(searchQuery);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setUserDropdownOptions(() => {
          return [...transformedResponse];
        });
      }
    }
    setUserDropdownFetching(false);
  };

  const transformResponse = (data: any) => {
    return (data || []).map(
      ({
        customer_id,
        email,
        first_name,
        last_name
      }: {
        customer_id: string;
        email: string;
        first_name: string;
        last_name: string;
      }) => {
        return { label: `${first_name} ${last_name} - ${email}`, value: customer_id };
      }
    );
  };

  const debouncedFetchUserDropdownOptions = _.debounce(fetchUserDropdownOptions, 1000);

  return {
    debouncedFetchUserDropdownOptions,
    userDropdownFetching,
    userDropdownOptions
  };
};
