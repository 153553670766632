import { create } from 'zustand';

interface ILoaderStore {
  //!site wide loader
  loading: boolean;

  //!sidemenu loader

  sideMenuLoading: boolean;
  setSideMenuLoading: (loading: boolean) => void;
  setLoading: (loading: boolean) => void;
}

export const useLoader = create<ILoaderStore>()(set => ({
  loading: false,
  sideMenuLoading: false,
  setSideMenuLoading: sideMenuLoading => set({ sideMenuLoading }),
  setLoading: loading => set({ loading })
}));
