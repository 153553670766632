import { Button } from 'antd';
import * as React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface IGoBackButtonProps {
  backUrl?: string;
  onClick?: () => void;
}

const GoBackButton: React.FunctionComponent<IGoBackButtonProps> = ({ backUrl, onClick }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    if (backUrl) {
      navigate(backUrl);
    }
  };

  return (
    <Button title="Go Back" onClick={handleClick} type="ghost" size="large">
      <ArrowLeftOutlined />
    </Button>
  );
};

export default GoBackButton;
