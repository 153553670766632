import React from 'react';
import _ from '../../../../helpers/lodash';
import { logisticService } from '../../../nebula/services';

const cache: Record<
  string,
  {
    customer_id: string;
    email: string;
    first_name: string;
    last_name: string;
  }[]
> = {};

export const useLogisticsNodes = () => {
  const [nodeDropdownFetching, setNodeDropdownFetching] = React.useState(false);
  const [nodeDropdownOptions, setNodeDropDownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchUserDropdownOptions = async (
    searchQuery: string,
    filterNodes?: any,
    projection: string = '',
    caching: boolean = true
  ) => {
    setNodeDropdownFetching(true);
    if (caching && cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery], filterNodes);
      setNodeDropDownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      let params = {
        'node-name': searchQuery,
        projection
      };
      const { data, errors } = await logisticService.searchNodeByQuery(params);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        if (Array.isArray(data.nodes)) {
          const transformedResponse = transformResponse(data, filterNodes);
          if (Array.isArray(transformedResponse)) {
            setNodeDropDownOptions(() => {
              return [...transformedResponse];
            });
          }
        }
      }
    }
    setNodeDropdownFetching(false);
  };

  const transformResponse = (arrData: any, filterNodes?: any[]) => {
    return (arrData.nodes || [])
      .filter(({ node_id }: { node_id: string }) => !filterNodes || !filterNodes.includes(node_id))
      .map(({ node_name, node_id, address }: { node_name: string; node_id: string; address?: any }) => {
        return { label: node_name, value: node_id, address: convertAddressObjectTOString(address?.primary || {}) };
      });
  };

  const clearPreviousNodeDropdownOptions = () => {
    setNodeDropDownOptions([]);
  };

  const convertAddressObjectTOString = (address: any) => {
    const addressString = Object.entries(address)
      .filter(([key, value]) => key !== 'type' && key !== 'coordinates' && key !== 'addnl_attributes' && value)
      .map(([key, value]) => `${value}`)
      .join(', ');
    return addressString;
  };

  const debouncedFetchUserDropdownOptions = _.debounce(fetchUserDropdownOptions, 1000);

  return {
    debouncedFetchUserDropdownOptions,
    nodeDropdownFetching,
    nodeDropdownOptions,
    setNodeDropdownFetching,
    clearPreviousNodeDropdownOptions
  };
};
