import { Checkbox, Col, Form, FormInstance, Input, Row, Switch, Typography } from 'antd'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import _ from '../../../../helpers/lodash'
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers'
import { useLoader } from '../../../../stores/use-loader'
import { logisticService } from '../../services'

interface IDeliveryDetailsFormProps {
    readOnly?: boolean,
    deliveryDetailsForm: FormInstance
}

const DeliveryDetailsForm: React.FC<IDeliveryDetailsFormProps> = ({ readOnly, deliveryDetailsForm }) => {
    const { id } = useParams();
    const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

    const handleOnStatusChange = async (status: any, itemNodeId: string, nodeName: string) => {
        const bodyData = {
            is_active: status
        };
        const { errors } = await logisticService.patchNodeIdChanges(id as string, itemNodeId as string, bodyData)
        if (_.isEmpty(errors)) {
            displaySuccessNotification({ message: `Status Updated for ${nodeName} successfully!` });
        } else {
            displayErrorNotifications(errors);
        }
    }

    const handleOnDeliveryUpdate = async (e: any) => {
        setLoading(true);
        const bodyData = {
            is_active: e.target.checked
        };
        const { errors } = await logisticService.patchLocalDeliveryAvailable(id as string, bodyData)
        if (_.isEmpty(errors)) {
            displaySuccessNotification({ message: 'Status updated successfully!' });
        } else {
            displayErrorNotifications(errors);
        }
        setLoading(false);
    }

    return (
        <Form form={deliveryDetailsForm} disabled={readOnly} layout="vertical">
            <Typography.Title level={5} className="text-[#5e5f66]">Local Delivery Config:</Typography.Title>
            <Row gutter={12}>
                <Col xs={12} md={6}>
                    <Form.Item
                        name={'is_local_delivery_available'}
                        valuePropName="checked"
                    >
                        <Checkbox onChange={handleOnDeliveryUpdate}>Is Local Delivery Available?</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <div className='max-h-[500px] overflow-y-auto overflow-x-hidden'>
                <Form.Item noStyle shouldUpdate>
                    {form => {
                        const { local_delivery_nodes } = form.getFieldsValue()
                        return (<Row gutter={12} justify={'start'}>
                            <Form.List name="local_delivery_nodes">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, index) => {
                                            return (<>
                                                <Col xs={12} md={12} lg={6}>
                                                    <Form.Item
                                                        label="Node Name"
                                                        name={[name, 'node_info', 'node_name']}
                                                        rules={[
                                                            { required: true, message: "This field can't be empty" },
                                                        ]}
                                                    >
                                                        <Input size="large" placeholder="Node ID" disabled={true} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={12} md={12} className='flex items-center justify-center mt-10' lg={6} >
                                                    <Form.Item
                                                        name={[name, 'is_active']}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(status: any) => {
                                                            let itemNodeId = local_delivery_nodes[key]?.node_id
                                                            let nodeName = local_delivery_nodes[key]?.node_info?.node_name
                                                            handleOnStatusChange(status, itemNodeId, nodeName)
                                                        }}></Switch>
                                                    </Form.Item>
                                                </Col>
                                            </>)
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </Row>)
                    }}
                </Form.Item>
            </div>
        </Form>
    )
}

export default DeliveryDetailsForm