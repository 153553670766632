import React from 'react';

import _ from '../../../../helpers/lodash';
import { operatorService } from '../../services';

const cache: Record<string, any> = {};

export const useOperators = () => {
  const [operatorDropdownFetching, setOperatorDropdownFetching] = React.useState(false);
  const [operatorDropdownOptions, setOperatorDropdownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchOperatorDropdownOptions = async (searchQuery: string) => {
    setOperatorDropdownFetching(true);

    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setOperatorDropdownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await operatorService.searchOperators(searchQuery);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setOperatorDropdownOptions(() => {
          return [...transformedResponse];
        });
      }
    }
    setOperatorDropdownFetching(false);
  };

  const transformResponse = (data: any) => {
    const operators = data?.operators || [];
    return (operators || []).map(({ operator_id, operator_name }: { operator_id: string; operator_name: string }) => {
      return { label: `${operator_name} ${operator_id}`, value: operator_id };
    });
  };

  const debouncedFetchOperatorDropdownOptions = _.debounce(fetchOperatorDropdownOptions, 1000);

  return {
    debouncedFetchOperatorDropdownOptions,
    operatorDropdownFetching,
    operatorDropdownOptions
  };
};
