import { CaretDownOutlined, DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Dropdown, Form, Row, Select, Space, Table, TableColumnsType, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { displayErrorNotifications } from "../../../helpers/toast.helpers";
import { cbtProductService } from "../../../services/cbt-product.service";
import { useLoader } from "../../../stores/use-loader";

interface IBillingDetailsInterface {
    active: string;
}

const dummyTenentList = [
    { label: 'RMG', value: 'rmg' },
    { label: 'Dummy', value: 'dummy' }
];

const BillingDetails: React.FunctionComponent<IBillingDetailsInterface> = ({ active }) => {

    const [billingDetailsForm] = Form.useForm()

    //zustand declare
    const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
    // const { businessGroupId } =
    //     urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

    const [date, setSelectedDate] = React.useState('')
    // const [viewModal, setViewModal] = React.useState(false)
    const [subscriptionResp, setsubscriptionResp] = React.useState({} as any)
    const [subsrcibedTenentDetails, setSubscribedTenentDetails] = React.useState({} as any)
    const [billingCotrollerResp, setBillingControllerResp] = React.useState({} as any)
    const [tenentOptions, setTenentOptions] = React.useState([] as any)

    const columns: TableColumnsType<any> = [
        {
            title: 'Tenent Id',
            render(value, record, index) {
                return <>{record?.tenant_id}</>;
            },
            align: 'center'
        }, {
            title: 'Domain',
            render(value, record, index) {
                return <>{record?.domain}</>;
            },
            align: 'center'
        }, {
            title: 'Total Trial API',
            render(value, record, index) {
                return <>{record?.total_trial_api}</>;
            },
            align: 'center'
        }, {
            title: 'Total Free API',
            render(value, record, index) {
                return <>{record?.total_free_api}</>;
            },
            align: 'center'
        }, {
            title: 'Total Paid API',
            render(value, record, index) {
                return <>{record?.total_paid_api}</>;
            },
            align: 'center'
        }, {
            title: 'Amount',
            render(value, record, index) {
                return <>{`$ ${record?.total_amount}`}</>;
            },
            align: 'center'
        },
        //  {
        //     title: 'Actions',
        //     render(value, record, index) {
        //         return <> <Button
        //             onClick={() => {
        //                 setViewModal(true)
        //             }}
        //             type="link"
        //         >
        //             View
        //         </Button></>;
        //     },
        //     align: 'center'
        // },
    ]

    const ExpandRowBillBreakUpColumns: TableColumnsType<any> = [
        {
            title: 'API Count From',
            render(value, record, index) {
                return <>{record?.api_count_from}</>;
            },
            align: 'center'
        },
        {
            title: 'API Count To',
            render(value, record, index) {
                return <>{record?.api_count_to}</>;
            },
            align: 'center'
        },
        {
            title: 'Amount',
            render(value, record, index) {
                return <>{record?.amount}</>;
            },
            align: 'center'
        }
    ]

    const ExpandRowTaxBreakUpColumns: TableColumnsType<any> = [
        {
            title: 'Tax Code',
            render(value, record, index) {
                return <>{`$ ${record?.tax_code}`}</>;
            },
            align: 'center'
        },
        {
            title: 'Tax Rate',
            render(value, record, index) {
                return <>{`$ ${record?.tax_rate}`}</>;
            },
            align: 'center'
        },
        {
            title: 'Amount',
            render(value, record, index) {
                return <>{`$ ${record?.amount}`}</>;
            },
            align: 'center'
        }
    ]

    React.useEffect(() => {
        loadIntialData()
    }, [active])

    const items = [
        {
            label: 'Jun 24',
            key: 'Jun 24',
            //   icon: <UserOutlined />,
        },
        {
            label: 'Mar 24',
            key: 'Mar 24',
            //   icon: <UserOutlined />,
        },
        {
            label: 'Feb 24',
            key: 'Feb 24',
            //   icon: <UserOutlined />,
            //   danger: true,
        },
        {
            label: 'Jan 24',
            key: 'Jan 24',
            //   icon: <UserOutlined />,
            //   danger: true,
            //   disabled: true,
        },
        {
            label: 'Clear...',
            key: 'Clear',
            //   icon: <UserOutlined />,
            //   danger: true,
            //   disabled: true,
        },
    ];

    const handleMenuClick = (e: any) => {
        if (e.key !== 'Clear') {
            setSelectedDate(e.key)
        } else {
            setSelectedDate('')
        }
        // console.log('click', e);
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const loadIntialData = () => {
        billingDetailsForm.setFieldsValue({
            tenent_name: 'rmg'
        })
        getSubscriptionData()
        getBillControllerdata()
        getTenentOptionData()
    }

    const getSubscriptionData = async () => {
        setLoading(true)
        const params = {
            domain: active
        }
        const { tenent_name } = billingDetailsForm.getFieldsValue()
        const head = {
            'x-tenant-id': tenent_name
        }
        const { data, errors } = await cbtProductService.getSubscriptionswithTenent(params, head)
        if (_.isEmpty(errors)) {
            setsubscriptionResp(data)
            setSubscribedTenentDetails(data?.data[0])
        } else {
            displayErrorNotifications(errors);
        }
        setLoading(false)
    }

    const getBillControllerdata = async () => {
        setLoading(true)
        const { tenent_name } = billingDetailsForm.getFieldsValue()
        let params = {
            'x-business-group-id': tenent_name
        }
        const { data, errors } = await cbtProductService.getBillControler(params)
        if (_.isEmpty(errors)) {
            setBillingControllerResp(data)
        } else {
            displayErrorNotifications(errors)
        }
        setLoading(false)
    }

    const getTenentOptionData = async () => {
        const { data, errors } = await cbtProductService.getAllTenentData()
        if (_.isEmpty(errors)) {
            let addTenentData = (data.tenants || []).map((tenent: any) => {
                return { label: tenent, value: tenent }
            })
            setTenentOptions(addTenentData)
        } else {
            displayErrorNotifications(errors)
        }
    }

    // const handleOnClose = () => {
    //     setViewModal(false);
    // }

    const handleOnFinish = () => {

    }

    const expandedRowRender = (data: any) => {
        return (<>
            <section className="">
                <Typography.Title level={5} className="text-[#2e2a5b]">
                    API Breakup :
                </Typography.Title>
                <Table className="mt-2" bordered columns={ExpandRowBillBreakUpColumns} dataSource={data?.tenant_bill_api_break_up || []} pagination={false} />
            </section>
            <section className="mt-4">
                <Typography.Title level={5} className="text-[#2e2a5b]">
                    Tax Breakup :
                </Typography.Title>
                <Table className="mt-2" bordered columns={ExpandRowTaxBreakUpColumns} dataSource={data?.tax_break_up || []} pagination={false} />
            </section>
        </>
        )
    }

    return (
        <React.Fragment>
            <Form form={billingDetailsForm} layout='vertical' onFinish={handleOnFinish}>
                <Row gutter={12}>
                    <Col xs={12} md={6}>
                        <Form.Item
                            label="Select Tenent"
                            name="tenent_name"
                            rules={[{ required: true, message: "This field can't be empty" }]}
                        >
                            <Select
                                placeholder="Select an option"
                                size="large"
                                onChange={getSubscriptionData}
                                options={tenentOptions}
                            // disabled={!isformVisible}
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Card>
                    {subscriptionResp?.data?.length > 0 ? <div>
                        <div className="p-2 flex flex-row-reverse gap-2">
                            <Dropdown menu={menuProps}>
                                <Button type="primary">
                                    <Space>
                                        {`Billing Period`}
                                        <CaretDownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                            {/* <Button type="primary">
                                <Space>
                                    {`Bill`}
                                </Space>
                            </Button> */}
                            <Button icon={<PrinterOutlined />} onClick={() => { window.print() }}>
                                Print
                            </Button>
                            <Button icon={<DownloadOutlined />}>
                                Download All To CSV
                            </Button>
                        </div>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} md={12}>
                                <section>
                                    <Descriptions size="middle" bordered className="mt-4">
                                        <Descriptions.Item span={1.5} label="Name">
                                            <span className=""> {subsrcibedTenentDetails?.plan_dto?.name}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="status">
                                            <span className=""> {subsrcibedTenentDetails?.status}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Tenent ID">
                                            <span className=""> {subsrcibedTenentDetails?.tenant_id}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Bussiness Group Id">
                                            <span className=""> {subsrcibedTenentDetails?.business_grp_id}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Trial Days">
                                            <span className=""> {subsrcibedTenentDetails?.plan_dto?.trial_days}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Trial Days Remaning">
                                            <span className=""> {subsrcibedTenentDetails?.trial_days_remaining}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Trial Usage Limit">
                                            <span className=""> {subsrcibedTenentDetails?.plan_dto?.trial_usage_limit}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Auto Renew">
                                            <span className=""> {subsrcibedTenentDetails?.auto_renew.toString()}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Trial Usage Remaining">
                                            <span className=""> {subsrcibedTenentDetails?.trial_usage_remaining}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1.5} label="Discount Code">
                                            <span className=""> {subsrcibedTenentDetails?.plan_dto?.discount_code}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={3} label="Subscribed At">
                                            <span className=""> {new Date(subsrcibedTenentDetails?.subscribed_at).toISOString().split('T')[0]}</span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </section>
                                <div className="mt-4">
                                    <Typography.Title level={4} className="text-[#2e2a5b]">
                                        API Range :
                                    </Typography.Title>
                                    {(subsrcibedTenentDetails?.plan_dto?.api_range || []).map((item: any) => {
                                        return (
                                            <Row>
                                                <Col>
                                                    <Descriptions size="middle" bordered className="mt-2">
                                                        <Descriptions.Item span={1} label="API Limit From">
                                                            <span className=""> {item?.api_limit_from}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item span={1} label="API Limit To">
                                                            <span className=""> {item?.api_limit_to}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item span={1} label="Amoutn">
                                                            <span className=""> {item?.amount}</span>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </Col>
                            <Col xs={24} md={12} className="flex flex-col gap-3">
                                <Card className="h-[100%]">
                                    <Typography.Title level={4} className="text-[#2e2a5b]">
                                        Subscription Cycle :
                                    </Typography.Title>
                                    <Typography.Text className="flex flex-row-reverse text-red-500 cursor-pointer underline">
                                        Auto Renew
                                    </Typography.Text>
                                    <div className="flex flex-col">
                                        <Typography.Text className="flex gap-2 text-[#0d0d0c] text-base">
                                            Cycle Start At: <Typography.Text className="text-[#000000e0] text-base">{new Date(subsrcibedTenentDetails?.cycle_start_at).toISOString().split('T')[0]}</Typography.Text>
                                        </Typography.Text>
                                        <Typography.Text className="flex gap-2 text-[#0d0d0c] text-base">
                                            Cycle End At: <Typography.Text className="text-[#000000e0] text-base">{new Date(subsrcibedTenentDetails?.cycle_end_at).toISOString().split('T')[0]}</Typography.Text>
                                        </Typography.Text>
                                    </div>
                                    <div></div>
                                </Card>
                            </Col>
                        </Row>
                    </div> : <span className="flex justify-center text-lg font-semibold">Selected Tenent have no Subscription!</span>}
                    {Object.keys(billingCotrollerResp).length !== 0 ? <Card className="mt-6">
                        <div className="flex flex-col gap-2 mb-4">
                            <div className="p-2 flex flex-row-reverse">
                                <Button type="primary">Download Bill</Button>
                            </div>
                            <div className="flex gap-4">
                                <Typography.Text className="text-[#0d0d0c] text-lg">
                                    Bill Date: {new Date(billingCotrollerResp?.bill_generated_on).toISOString().split('T')[0]}
                                </Typography.Text>
                                <Typography.Text className="text-[#0d0d0c] text-lg">
                                    Bill Due On: {new Date(billingCotrollerResp?.bill_due_on).toISOString().split('T')[0]}
                                </Typography.Text>
                            </div>
                            <Typography.Text className="text-[#5e5f66] text-base">
                                Discount : {`$ ${billingCotrollerResp?.discount}`}
                            </Typography.Text>
                            <Typography.Text className="text-[#5e5f66] text-base">
                                Tax : {`${billingCotrollerResp?.total_tax}`}
                            </Typography.Text>
                            <Typography.Text className="text-[#5e5f66] text-base">
                                Total : {`$ ${billingCotrollerResp?.total_amount}`}
                            </Typography.Text>
                        </div>
                        <Typography.Title level={3} className="text-[#2e2a5b]">
                            Tenent Bill Breakup :
                        </Typography.Title>
                        <Table
                            expandable={{
                                expandedRowRender,
                                // rowExpandable: (record) => record.has_variants,
                            }}
                            expandedRowClassName={(record) => record ? 'bg-[#f0f2f5]' : ''} // Apply background color conditionally
                            loading={false}
                            pagination={false}
                            bordered
                            dataSource={billingCotrollerResp?.tenant_bill_break_up_list || []}
                            columns={columns}
                            scroll={{ x: 1000 }}
                        ></Table>
                    </Card> : <Card className="mt-4">
                        {subscriptionResp?.data?.length !== 0 && <span className="flex justify-center text-lg font-semibold">No Bill Available!</span>}
                    </Card>}
                </Card>
                {/* <Modal
                    centered
                    open={viewModal}
                    onCancel={handleOnClose}
                    footer={false}
                    width={1200}
                    title={
                        <div className="flex justify-between gap-4">
                            <Typography.Title level={4}>Tenent API Details:</Typography.Title>
                        </div>
                    }
                >
                    <TenentBillBreakUpModal viewModal={viewModal} handleOnClose={handleOnClose} />
                </Modal> */}
            </Form>
        </React.Fragment>
    )
}

export default BillingDetails