import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Card, Col, Row, Typography } from 'antd';
import PromotionForm from '../components/promotion-form';
import TenantInfo from '../../../components/TenantIdInfo';
import { useSearchParams } from 'react-router-dom';

interface ICreatePromotionPageProps {}

const CreatePromotionPage: React.FunctionComponent<ICreatePromotionPageProps> = props => {
  const [searchParams] = useSearchParams();
  const promoName = searchParams.get('promotionName');
  const promo_header_type = searchParams.get('promo_header_type');
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Promotion
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <div className="flex gap-4 mt-4 mb-2">
            <Typography.Text className="text-[#898c98]">
              Promotion Type - <span className="font-bold text-black">{promoName}</span>
            </Typography.Text>
            <Typography.Text className="text-[#898c98]">
              Trigger - <span className="font-bold text-black">{promo_header_type}</span>{' '}
            </Typography.Text>
          </div>
          <PromotionForm />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreatePromotionPage;
