import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputRef,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  TimePicker,
  Typography
} from 'antd';
import React, { useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { convertQueryStringToObj, fileHelpers, validationHelpers } from '../../../helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { TableRowSelection } from 'antd/es/table/interface';
import { objectHelpers } from '../../../helpers';
import { priceListService } from '../services/pricelist.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useBasicDetails } from '../../../stores/use-promotion';
import dayjs from 'dayjs';
import CustomPagination from '../../../components/custom-pagination';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';

const uomOptions = [
  { label: 'EA', value: 'EA' },
  { label: 'KG', value: 'KG' },
  { label: 'LB', value: 'LB' }
];

const statusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'In-Active', value: 'IN_ACTIVE' },
  { label: 'Experied', value: 'EXPIRED' }
];

const discountOptions = [
  { label: 'Special Price', value: 'SPECIAL-PRICE' },
  { label: 'Percentage', value: 'PERCENTAGE' },
  { label: 'Absolute Discount', value: 'FIXED' }
];

const timeZones = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
];

interface IPriceEnteriesCopyLinesProps {}

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const PriceEnteriesCopyLines: React.FunctionComponent<IPriceEnteriesCopyLinesProps> = () => {
  //new declare
  const { parentId } = useParams();
  const navigate = useNavigate();
  const [copyLineForm] = Form.useForm();
  const [copyLineUpdateForm] = Form.useForm();

  //new State
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [copyLineResponse, setCopyLineResponse] = React.useState({} as any);
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [result, setResult] = React.useState({} as any);
  const [productList, setProductList] = React.useState<any>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagination, setPagination] = React.useState({
    pageSize: 5,
    // total: dummydataSource.length,
    showSizeChanger: false,
    showQuickJumper: false
    // total: dataSource.length,
  });

  //other declerations
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { offset, backTabTo, isEditable } = queryStringObj;
  const isFormEditable = JSON.parse(isEditable);
  const searchInput = useRef<InputRef>(null);

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const { productData } = useBasicDetails(({ productData }) => ({
    productData
  }));

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
      return (
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Form form={copyLineForm} layout="vertical" className="mt-4" onFinish={() => handleOnSearch(0)}>
            <Row>
              <Col>
                <Form.Item
                  label="Search Name"
                  name="name"
                  rules={[{ required: false, message: "This field can't be empty" }]}
                >
                  <Input className="w-[100%]" size="large" placeholder="Search Name" />
                </Form.Item>
              </Col>
            </Row>{' '}
            <Row>
              <Col>
                <Button block size="large" htmlType="submit">
                  <BoldButtonLabel labelText="Search" />
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
  });

  const handleSearch = (selectedKeys: string[], confirm: any, dataIndex: any) => {};

  const columns: any = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Name',
      align: 'center',
      ...getColumnSearchProps('name'),
      render(value: any, record: any, index: any) {
        return <>{record?.name}</>;
      }
    },
    {
      title: 'Currency',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.currency_code}</>;
      }
    },
    {
      title: 'UOM',
      align: 'center',
      render(value: any, record: any, index: any) {
        let UOM = record.price_entries.sale_price_info[0]?.unit_of_measure;
        return <>{UOM}</>;
      }
    },
    {
      title: 'Schedule Start Date',
      align: 'center',
      render(value: any, record: any, index: any) {
        let startDate = record?.schedule_info?.schedule_start_date;
        let startTime = record?.schedule_info?.schedule_start_time;
        return <>{startDate + 'T' + startTime}</>;
      }
    },
    {
      title: 'Schedule End Date',
      align: 'center',
      render(value: any, record: any, index: any) {
        let endDate = record?.schedule_info?.schedule_end_date;
        let endTime = record?.schedule_info?.schedule_end_time;
        return <>{endDate + 'T' + endTime}</>;
      }
    },
    {
      title: 'Cost Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.price_entries?.cost_price;
        return <>{data}</>;
      }
    },
    {
      title: 'Sales Lock Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = String(record?.price_entries?.lock_sale_price).length? String(record?.price_entries?.lock_sale_price) : ''
        return <>{data}</>;
      }
    },
    {
      title: 'Override Master Lock Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = String(record?.price_entries?.override_master_lock_price).length?String(record?.price_entries?.override_master_lock_price) : ''
        return <>{data}</>;
      }
    },
    {
      title: 'Minimum Discount percentage',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.price_entries?.minimum_markup_percent;
        return <>{data}</>;
      }
    },
    {
      title: 'Maximum Discount percentage',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.price_entries?.maximum_markup_percent;
        return <>{data}</>;
      }
    },
    {
      title: 'Threshold Based',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = record?.price_entries?.is_threshold_based ? 'True' : 'False';
        return <>{data}</>;
      }
    },
    {
      title: 'Sales Price Info',
      align: 'center',
      dataIndex: 'Sales_Price_Info',
      editable: true,
      children: [
        {
          title: 'Minimum Quantity',
          dataIndex: 'minimum_quantity',
          key: 'minimum_quantity',
          align: 'center',
          render(value: any, record: any, index: any) {
            return (
              <div className="flex justify-center gap-[10px]">
                <div>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <div key={record?.item_id}>{cv.minimum_quantity}</div>;
                  })}
                </div>
                {/* {record.price_entries.sale_price_info.length > 0 && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        },
        {
          title: 'Unit of Measure',
          dataIndex: 'unit_of_measure',
          key: 'unit_of_measure',
          align: 'center',
          render(value: any, record: any, index: any) {
            return (
              <div className="flex justify-center gap-[10px]">
                <div>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <div key={record?.item_id}>{cv.unit_of_measure}</div>;
                  })}
                </div>
                {/* {record.price_entries.sale_price_info.length > 0 && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        },
        {
          title: 'Discount Type',
          dataIndex: 'discount_type',
          key: 'discount_type',
          align: 'center',
          render(value: any, record: any, index: any) {
            return (
              <div className="flex justify-center gap-[10px]">
                <div>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <div key={record?.item_id}>{cv.discount_type}</div>;
                  })}
                </div>
                {/* {record.price_entries.sale_price_info.length > 0 && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        },
        {
          title: 'Discount Value',
          dataIndex: 'discount_value',
          key: 'discount_value',
          align: 'center',
          render(value: any, record: any, index: any) {
            return (
              <div className="flex justify-center gap-[10px]">
                <div>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <div key={record?.item_id}>{cv.discount_value}</div>;
                  })}
                </div>
                {/* {record.price_entries.sale_price_info.length > 0 && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        }
      ]
    },
    {
      title: 'Additional Promotion Info',
      align: 'center',
      children: [
        {
          title: 'Promotion Restricted',
          dataIndex: 'is_promotion_restricted',
          key: 'is_promotion_restricted',
          align: 'center',
          render(value: any, record: any, index: any) {
            const data = record?.addnl_promotion_info?.is_promotion_restricted ? 'True' : 'False';
            return <>{data}</>;
          }
        },
        {
          title: 'Start Date of Promotion Restricted',
          dataIndex: 'promotion_restriction_start_date',
          key: 'promotion_restriction_start_date',
          align: 'center',
          render(value: any, record: any, index: any) {
            const date = record?.addnl_promotion_info?.promotion_restriction_start_date;
            const time = record?.addnl_promotion_info?.promotion_restriction_start_time;
            return <>{date + 'T' + time}</>;
          }
        },
        {
          title: 'End Date of Promotion Restricted',
          dataIndex: 'promotion_restriction_end_date',
          key: 'promotion_restriction_end_date',
          align: 'center',
          render(value: any, record: any, index: any) {
            const date = record?.addnl_promotion_info?.promotion_restriction_end_date;
            const time = record?.addnl_promotion_info?.promotion_restriction_end_time;
            return <>{date + 'T' + time}</>;
          }
        }
      ]
    },
    {
      title: 'Status',
      align: 'center',
      editable: false,
      dataIndex: 'status',
      render(value: any, record: any, index: any) {
        return <>{record?.status}</>;
      }
    }
  ];

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = () => {
    handleOnSearch(0);
  };

  const handleOnSearch = async (offset = 0) => {
    setLoading(true);
    let formValues = copyLineForm.getFieldsValue();
    let parentPriceListId = productData?.parent_price_list_id;
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(formValues), offset: offset + '' };
    let params = {
      ...filteredFormValues
      // offset,
      // limit: 10
    };
    const { data, errors } = await priceListService.getAllItemsOnPriceList(params, parentPriceListId);
    if (_.isEmpty(errors)) {
      setCopyLineResponse(data);
      let product_Name = data.line_items.map((cv: any) => ({ label: cv.name, value: cv.item_id }));
      product_Name = [...product_Name, { label: 'ALL', value: 'ALL' }];
      let productName = product_Name.map((cv: any) => ({ label: cv.label, value: cv.value }));
      setProductList(productName);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnGoBackToPriceEnteries = () => {
    const params = {
      offset: offset,
      backTabTo: backTabTo
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/promotions/price-list/${parentId}/price-list-edit-form?${paramString}`);
  };

  const updatePriceEntries = (copyLineResponse: any, newArray: any) => {
    return {
      ...copyLineResponse,
      line_items: newArray
    };
  };

  const updateProductDetails = (e: any) => {
    setLoading(true);
    const {
      discount,
      discount_type,
      productName,
      endDate,
      endTime,
      startDate,
      startTime,
      // uom,
      minium_quantity,
      status,
      Promotion_startDate,
      Promotion_endDate,
      Promotion_startTime,
      Promotion_endTime,
      minimum_markup_percent,
      maximum_markup_percent,
      sales_lock_Price,
      override_master_lock_price,
      treshold_Based
    } = copyLineUpdateForm.getFieldsValue();
    let changeScheduleStartDate = getDateString(startDate, 'YYYY-MM-DD');
    let changeScheduleEndDate = getDateString(endDate, 'YYYY-MM-DD');
    let changeSchedulestartTime = getTimeString(startTime, 'HH:mm:ss');
    let changeScheduleEndTime = getTimeString(endTime, 'HH:mm:ss');
    let PromotionStartDate = getDateString(Promotion_startDate, 'YYYY-MM-DD');
    let PromotionEndDate = getDateString(Promotion_endDate, 'YYYY-MM-DD');
    let PromotionStartTime = getTimeString(Promotion_startTime, 'HH:mm:ss');
    let PromotionEndTime = getTimeString(Promotion_endTime, 'HH:mm:ss');
    let updateData = copyLineResponse?.line_items || [];
    if (selectedRowKeys.length > 0) {
      let updatedResp = updateData.map((resp: any) => {
        if (selectedRowKeys.includes(resp.item_id)) {
          return {
            ...resp,
            addnl_promotion_info: {
              ...resp.addnl_promotion_info,
              promotion_restriction_start_date: PromotionStartDate,
              promotion_restriction_end_date: PromotionEndDate,
              promotion_restriction_start_time: PromotionStartTime,
              promotion_restriction_end_time: PromotionEndTime
            },
            price_entries: {
              ...resp.price_entries,
              is_threshold_based: JSON.parse(treshold_Based),
              minimum_markup_percent: minimum_markup_percent,
              maximum_markup_percent: maximum_markup_percent,
              override_master_lock_price: override_master_lock_price,
              lock_sale_price: sales_lock_Price,
              sale_price_info: resp.price_entries.sale_price_info.map((spi: any) => ({
                ...spi,
                discount_type: discount_type,
                discount_value: discount,
                minimum_quantity: minium_quantity
                // unit_of_measure: uom
              }))
            },
            schedule_info: {
              ...resp.schedule_info,
              schedule_end_date: changeScheduleEndDate,
              schedule_end_time: changeScheduleEndTime,
              schedule_start_date: changeScheduleStartDate,
              schedule_start_time: changeSchedulestartTime
            },
            status: status
          };
        }
        return resp;
      });
      let setData = updatePriceEntries(copyLineResponse, updatedResp);
      setCopyLineResponse(setData);
      displaySuccessNotification({ message: 'update success' });
    } else {
      displayErrorNotifications([{ message: 'No data are selected !!' }]);
    }

    setLoading(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    const targetIds = selectedRowKeys;
    if (targetIds.length > 0) {
      const foundItems = copyLineResponse?.line_items.filter((item: any) => targetIds.includes(item.item_id));
      const bodyData = { line_items: foundItems };
      const { data, errors } = await priceListService.getExcelValidation(parentId as any, bodyData);
      if (_.isEmpty(errors)) {
        setResult(data);
        setUploadModalVisible(true);
      } else {
        displayErrorNotifications(errors);
      }
    } else {
      displayErrorNotifications([{ message: 'No data are selected !!' }]);
    }
    setLoading(false);
  };

  const handleOnCopiedData = async () => {
    setLoading(true);
    const targetIds = selectedRowKeys;
    const foundItems = copyLineResponse?.line_items.filter((item: any) => targetIds.includes(item.item_id));
    const bodyData = { line_items: foundItems };
    const { errors } = await priceListService.createListOfItems_bulk(parentId as any, bodyData);
    if (_.isEmpty(errors)) {
      setUploadModalVisible(false);
      handleOnGoBackToPriceEnteries();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getReportData = async () => {
    setLoading(true);
    let parentPriceListId = productData?.parent_price_list_id;
    const { data, errors } = await priceListService.getReport(parentPriceListId, result);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'report_excel', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current && current < dayjs().startOf('day');
  };

  const getDateString = (date: any, formatter: string) => {
    let reqDate = dayjs(date).format(formatter);
    return reqDate;
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const handleOndefaultSelectedItem = (e: any) => {
    let searchedProductId = copyLineUpdateForm.getFieldsValue().productName;
    if (searchedProductId === 'ALL') {
      let selectKey = copyLineResponse?.data?.data?.map((item: any) => item?.item_id);
      setSelectedRowKeys(selectKey);
    } else {
      let findSearchData = copyLineResponse?.data?.data.filter((item: any) => item?.item_id === searchedProductId);
      let selectKey = findSearchData?.map((item: any) => item?.item_id);
      setSelectedRowKeys(selectKey);
    }
  };

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
    handleOnSearch(current_page);
  };

  const handleOnSearchClear = () => {
    copyLineForm.setFieldsValue({
      searchProductName: ''
    });
    handleOnSearch(0);
  };

  const handleOnReset = () => {
    copyLineUpdateForm.setFieldsValue({
      productName: '',
      discount_type: null,
      discount: '',
      minium_quantity: '',
      time_Zone: null,
      status: null,
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      Promotion_startDate: '',
      Promotion_startTime: '',
      Promotion_endDate: '',
      Promotion_endTime: '',
      sales_lock_Price: '',
      minimum_markup_percent: '',
      maximum_markup_percent: ''
    });
    handleOnSearch(0);
    setSelectedRowKeys([]);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            Copy Lines
          </Typography.Title>
          {isFormEditable && (
            <Form form={copyLineUpdateForm} layout="vertical" onFinish={updateProductDetails}>
              <div className="pt-5">
                <Row gutter={24}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Discount Type"
                      name="discount_type"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Select
                        allowClear
                        placeholder="Select Discount Type"
                        size="large"
                        options={discountOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Discount Value"
                      name="discount"
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                    >
                      <Input className="w-[100%]" size="large" placeholder="discount" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Minimum Quantity"
                      name="minium_quantity"
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                    >
                      <Input className="w-[100%]" size="large" placeholder="Minimum Quantity" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Time Zone"
                      name="time_Zone"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Select
                        placeholder="Select an option"
                        size="large"
                        className="w-[100%]"
                        options={timeZones}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Status"
                      name="status"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Select
                        placeholder="Select an option"
                        size="large"
                        className="w-[100%]"
                        options={statusOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Minimum Discount Percentage"
                      name="minimum_markup_percent"
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                    >
                      <Input className="w-[100%]" size="large" placeholder="Minimum Discount Percentage" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Maximum Discount Percentage"
                      name="maximum_markup_percent"
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                    >
                      <Input className="w-[100%]" size="large" placeholder="Maximum Discount Percentage" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Schedule Start Date"
                      name="startDate"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <DatePicker
                        size="large"
                        className="w-[100%]"
                        placeholder="Enter Start Date"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Schedule Start Time"
                      name="startTime"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <TimePicker size="large" className="w-[100%]" format={'HH:mm:ss'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Schedule End Date"
                      name="endDate"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <DatePicker
                        size="large"
                        className="w-[100%]"
                        placeholder="Enter End Date"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Schedule End Time"
                      name="endTime"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <TimePicker size="large" className="w-[100%]" format={'HH:mm:ss'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Promotion Start Date"
                      name="Promotion_startDate"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <DatePicker
                        size="large"
                        className="w-[100%]"
                        placeholder="Enter Start Date"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Promotion Start Time"
                      name="Promotion_startTime"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <TimePicker size="large" className="w-[100%]" format={'HH:mm:ss'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Promotion End Date"
                      name="Promotion_endDate"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <DatePicker
                        size="large"
                        className="w-[100%]"
                        placeholder="Enter End Date"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Promotion End Time"
                      name="Promotion_endTime"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <TimePicker size="large" className="w-[100%]" format={'HH:mm:ss'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Lock Sale Price"
                      name="sales_lock_Price"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Radio.Group>
                        <Radio value={'true'}>Yes</Radio>
                        <Radio value={'false'}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Is Threshold Based"
                      name="treshold_Based"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Radio.Group>
                        <Radio value={'true'}>Yes</Radio>
                        <Radio value={'false'}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Overide Master Lock Price"
                      name="override_master_lock_price"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Radio.Group>
                        <Radio value={'true'}>Yes</Radio>
                        <Radio value={'false'}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} md={6}>
                    <Button block size="large" type="primary" htmlType="submit">
                      <BoldButtonLabel labelText="Apply" />
                    </Button>
                  </Col>
                  <Col xs={24} md={6}>
                    <Button block size="large" type="primary" onClick={() => handleOnReset()}>
                      <BoldButtonLabel labelText="Reset" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
          <div>
            <Form form={copyLineForm} layout="vertical" className="mt-4" onFinish={() => handleOnSearch(0)}>
              <Row gutter={24}>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Search Name"
                    name="name"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input className="w-[100%]" size="large" placeholder="Search Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={3} className="mt-7">
                  <Button block size="large" htmlType="submit">
                    <BoldButtonLabel labelText="Search" />
                  </Button>
                </Col>
              </Row>{' '}
            </Form>
          </div>

          <section>
            <div>
              <Table
                rowSelection={rowSelection}
                loading={false}
                pagination={{ ...pagination, position: ['topRight'] }}
                bordered
                dataSource={copyLineResponse?.line_items || []}
                columns={columns}
                rowKey={(record: any) => record?.item_id}
                scroll={{ x: 3500 }}
              ></Table>
            </div>
          </section>

          <div style={{ marginTop: '20px' }}>
            <Row className="flex">
              <Col>
                <div className="flex gap-4">
                  {isFormEditable && (
                    <Button size="large" type="primary" onClick={handleOnSubmit}>
                      <BoldButtonLabel labelText="Check Report and Add" />
                    </Button>
                  )}
                  <Button size="large" block onClick={handleOnGoBackToPriceEnteries}>
                    <BoldButtonLabel labelText="Go Back" />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Card>

        <Modal
          centered
          open={uploadModalVisible}
          onCancel={() => {
            setUploadModalVisible(false);
          }}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Result</Typography.Title>
            </div>
          }
        >
          <ul>
            <li>
              <Typography.Text className="text-purple-500">Total Items: {result.total_line_items}</Typography.Text>
            </li>
            <li>
              <Typography.Text className="text-red-500">Failed: {result.total_failed}</Typography.Text>
            </li>
            <li>
              <Typography.Text className="text-yellow-500">
                Ignored/Duplicates: {result.total_duplicate}
              </Typography.Text>
            </li>
            <li>
              <Typography.Text className="text-green-500">Total Success: {result.total_success}</Typography.Text>
            </li>
          </ul>
          <Button
            block
            type="primary"
            onClick={handleOnCopiedData}
            className="mt-4"
            disabled={result.total_success < 1}
          >
            <BoldButtonLabel labelText="Confirm add" />
          </Button>
          <Button block type="primary" onClick={getReportData} className="mt-4">
            <BoldButtonLabel labelText="Download Report" />
          </Button>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default PriceEnteriesCopyLines;
