import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Table, TableColumnsType, Tag, Typography } from 'antd';
import { Node, NodeListResponse } from '../../types/nodes.types';
import { nodeService } from '../../services';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import CustomPagination from '../../components/custom-pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { EditOutlined } from '@ant-design/icons';
import { useLoader } from '../../stores/use-loader';
import ActionButton from '../../components/ActionButton';
import { objectHelpers } from '../../helpers';

interface INodeListProps {}

const NodeList: React.FunctionComponent<INodeListProps> = props => {
  const [nodeFilterForm] = Form.useForm();

  const [nodeListResponse, setNodeListResponse] = React.useState({} as NodeListResponse);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const currentPage = parseInt(searchParams.get('currentPage') || '1');
    const pageSize = parseInt(searchParams.get('pageSize') || '10');

    return {
      currentPage: currentPage > 0 ? currentPage : 1,
      pageSize: pageSize > 0 ? pageSize : 10
    };
  });

  const backUrl = window.location?.pathname + `?${searchParams.toString()}`;
  const nextPageParams = new URLSearchParams({ goBackTo: backUrl });

  // const currentPage = Math.floor(nodeListResponse.current_page / 10) + 1;

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const handleActiveToggle = async ({ is_active, nodeId }: { is_active: boolean; nodeId: string }) => {
    setLoading(true);
    const { errors } = await nodeService.changeNodeActiveStatus(is_active, nodeId);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Node update success' });
      fetchNodeList();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleStatusToggle = async ({
    publish,
    unpublish,
    nodeId
  }: {
    publish: boolean;
    unpublish: boolean;
    nodeId: string;
  }) => {
    setLoading(true);
    const { errors } = await nodeService.changeNodeStatus(nodeId, { publish, unpublish });

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Node update success' });
      fetchNodeList();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<Node> = [
    {
      title: 'Node Name',
      render(value, record, index) {
        return <>{record.node_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Type',
      render(value, record, index) {
        return <>{record.node_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Short Name',
      render(value, record, index) {
        return <>{record.short_name}</>;
      },
      align: 'center'
    },

    {
      title: 'Is Active?',
      render(value, record, index) {
        return (
          <>
            {record.is_active ? (
              <Tag color="success" className="w-24 text-center">
                Active
              </Tag>
            ) : (
              <Tag color="error" className="w-24 text-center">
                Inactive
              </Tag>
            )}
          </>
        );
      },
      align: 'center'
    },
    {
      title: 'Node Status',
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },

    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex gap-2 items-center justify-center">
            <ActionButton
              action="CREATE_NEW_VERSION"
              title="Edit Node"
              onClick={() => navigate(`/nodes/${record.node_id}?${nextPageParams}`)}
            ></ActionButton>
            {!record.is_active ? (
              <ActionButton
                title="Activate Node"
                action="ACTIVATE"
                onClick={() => handleActiveToggle({ is_active: true, nodeId: record.node_id })}
              ></ActionButton>
            ) : (
              <ActionButton
                action="DEACTIVATE"
                title="Deactivate Node"
                onClick={() => handleActiveToggle({ is_active: false, nodeId: record.node_id })}
              ></ActionButton>
            )}
            {!(record.status === 'PUBLISHED') ? (
              <Button
                type="primary"
                onClick={() => handleStatusToggle({ publish: true, unpublish: false, nodeId: record.node_id })}
                className="w-32"
              >
                <BoldButtonLabel labelText="Publish" />
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => handleStatusToggle({ publish: false, unpublish: true, nodeId: record.node_id })}
                className="w-32"
              >
                <BoldButtonLabel labelText="Unpublish" />
              </Button>
            )}
          </section>
        );
      },
      align: 'center'
    }
  ];

  const navigate = useNavigate();

  const fetchNodeList = async (currenPage = 1, nodeName?: string) => {
    setLoading(true);
    const { data, errors } = await nodeService.getNodeListByTenantIdV2(currenPage, pageControl.pageSize, nodeName);

    if (_.isEmpty(errors)) {
      setNodeListResponse(data);
    } else displayErrorNotifications(errors);

    setLoading(false);
  };

  React.useEffect(() => {
    const searchTerm = searchParams.get('nodeName') || undefined;
    nodeFilterForm.setFieldsValue({ searchTerm });
    fetchNodeList(pageControl?.currentPage, searchTerm);
  }, [pageControl]);

  const handleNodeSearch = async (nodeName: string) => {
    const params: any = { nodeName, currentPage: 1, pageSize: pageControl?.pageSize };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    setPageControl({
      ...pageControl,
      currentPage: 1
    });
  };

  const handlePageChange = async (currentPage: number) => {
    const params: any = { nodeName: searchParams.get('searchTerm'), currentPage };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    setPageControl({
      ...pageControl,
      currentPage: currentPage
    });
    fetchNodeList(currentPage);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="pt-">
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Nodes
              </Typography.Title>
            </Col>
          </Row>

          <Form form={nodeFilterForm}>
            <Row justify={'space-between'}>
              <Col xs={24} md={6}>
                <Form.Item name={'searchTerm'}>
                  <Input.Search onSearch={handleNodeSearch} placeholder="Search Nodes" size="large" enterButton />
                </Form.Item>
              </Col>

              <Col xs={24} md={6} lg={4}>
                <Button type="primary" size="large" onClick={() => navigate(`/nodes/create?${nextPageParams}`)} block>
                  <BoldButtonLabel labelText="Create New Node" />
                </Button>
              </Col>
            </Row>
          </Form>
          {/* {!_.isEmpty(nodeListResponse.nodes) && (
            <div className="flex justify-end my-2">`
              <CustomPagination
                currentPage={currentPage}
                totalPages={nodeListResponse.total_pages}
                handleNext={handlePageChange}
                handlePageChange={handlePageChange}
                handlePrevious={handlePageChange}
              />
            </div>
          )} */}
          <Table
            loading={false}
            pagination={{
              current: pageControl?.currentPage,
              total: nodeListResponse.total_pages * pageControl?.pageSize || 0,
              pageSize: pageControl?.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['1', '10', '20', '50', '100'],

              onChange: (currentPage, pageSize) =>
                setPageControl({
                  currentPage,
                  pageSize
                })
            }}
            bordered
            className="mt-4"
            dataSource={nodeListResponse.nodes}
            columns={columns}
            scroll={{ x: 1000 }}
          ></Table>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default NodeList;
