import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import FieldDisplay from '../../../components/FieldDisplay';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { couponService } from '../services/coupon.service';

interface IRedemptionProps {}

const AuditListing: React.FunctionComponent<IRedemptionProps> = props => {
  const navigate = useNavigate();
  const [auditListingForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [auditListingResponse, setAuditListingResponse] = React.useState({} as any);
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { reward_type, title, promotion_type, id, code } = queryStringObj;

  const columns: TableColumnsType<any> = [
    {
      title: 'Trigger',
      render(value, record, index) {
        return <>{record?.trigger}</>;
      },
      align: 'center'
    },
    {
      title: 'User Id',
      render(value, record, index) {
        return <>{record?.user_id}</>;
      },
      align: 'center'
    },
    {
      title: 'Event Type',
      render(value, record, index) {
        return <>{record?.event_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Event trigger Date',
      render(value, record, index) {
        const year = record?.event_ts[0];
        const month = record?.event_ts[1];
        const day = record?.event_ts[2];
        return <>{`${day}-${month}-${year}`}</>;
      },
      align: 'center'
    },
    {
      title: 'Event trigger Time',
      render(value, record, index) {
        const hour = record?.event_ts[3];
        const min = record?.event_ts[4];
        const second = record?.event_ts[5];
        return <>{`${hour}:${min}:${second}`}</>;
      },
      align: 'center'
    },
    {
      title: 'Status',
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },
    {
      title: 'Message',
      render(value, record, index) {
        return <>{record.message}</>;
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    auditListingForm.setFieldsValue({
      order_by: 'desc'
    })
    await handleSearch(offset);
  };

  const handlePageChange = async (current_page: number) => {
    setCurrentPage(current_page);
    await handleSearch(current_page - 1);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = auditListingForm.getFieldsValue();
    const start_date = formValues.date_range ? dayjs(formValues.date_range[0]).format('YYYY-MM-DD') : undefined;
    const end_date = formValues.date_range ? dayjs(formValues.date_range[1]).format('YYYY-MM-DD') : undefined;
    const sortKey = formValues?.order_by;
    const sortValue = formValues?.sort_by?.toString();
    const dataObject = {
      code: code,//comment this you will get all coupon
      [sortKey]: sortValue,
      customer_id: formValues.user_Id,
      status: formValues.status,
      event_from_date: start_date,
      event_to_date: end_date,
      trigger: formValues.trigger
    };
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(dataObject) };
    const Params = {
      limit: 10,
      offset,
      ...filteredFormValues
    };
    const { data, errors } = await couponService.getAuditList(Params);
    if (_.isEmpty(errors)) {
      setAuditListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnGoBackToCoupons = () => {
    const params = {
      reward_type,
      promotion_type,
      title,
      offset: '0',
      id: id
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/coupons?${paramString}`);
  };

  const handleOnClearAll = () => {
    auditListingForm.setFieldsValue({
      user_Id: null,
      date_range: null,
      status: null,
      trigger: null
    });
    handleSearch(0);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={auditListingForm} onFinish={() => handleSearch(0)}>
          <Card>
            {' '}
            <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Audit Report
                </Typography.Title>
              </Col>
            </Row>
            <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
              <Col xs={24} md={12}>
                <section className="flex gap-4 flex-wrap">
                  <FieldDisplay label="Reward Type" value={reward_type} />
                  <FieldDisplay label="Promotion Type" value={promotion_type} />
                  <FieldDisplay label="Coupon code" value={code} />
                </section>
              </Col>
            </Row>
            <Row gutter={12} className="mt-4">
              <Col xs={12} md={6}>
                <Form.Item
                  label="User Id"
                  name="user_Id"
                  rules={[{ required: false, message: "This field can't be empty" }]}
                >
                  <Input className="w-[100%]" size="large" placeholder="Customer Id" allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Select Date Range"
                  name="date_range"
                  rules={[{ required: false, message: "This field can't be empty" }]}
                >
                  <DatePicker.RangePicker size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: false, message: "This field can't be empty" }]}
                >
                  <Select
                    allowClear
                    placeholder="Search By Status"
                    size="large"
                    options={[
                      { label: 'Success', value: 'SUCCESS' },
                      { label: 'Reserved', value: 'RESERVED' },
                      { label: 'Un-Reserved', value: 'UNRESERVED' },
                      { label: 'Redeemed', value: 'REEDEMED' },
                      { label: 'Failed', value: 'FAILED' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Trigger"
                  name="trigger"
                  rules={[{ required: false, message: "This field can't be empty" }]}
                >
                  <Select
                    allowClear
                    placeholder="Search By Trigger"
                    size="large"
                    options={[
                      { label: 'Automatic', value: 'AUTOMATIC' },
                      { label: 'Manual', value: 'MANUAL' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12} md={3}>
                <Button block size="large" type="primary" htmlType="submit">
                  <BoldButtonLabel labelText="Search" />
                </Button>
              </Col>
              <Col xs={12} md={3}>
                <Button block size="large" onClick={handleOnClearAll}>
                  <BoldButtonLabel labelText="Clear All" />
                </Button>
              </Col>
            </Row>
            <Row gutter={12} className="my-4" justify={'start'}>
              <Col xs={24} md={6}>
                {' '}
                <Form.Item label="Sort By" name={'sort_by'} className="mb-0">
                  <Select
                    mode="multiple"
                    size="large"
                    placeholder="Sort By"
                    // defaultValue={'valid_from_date'}
                    options={[
                      { label: 'Code', value: 'code' },
                      { label: 'Start Date', value: 'start_date' },
                      { label: 'End Date', value: 'end_date' },
                      { label: 'Status', value: 'status' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                {' '}
                <Form.Item label="Order By" name={'order_by'} className="mb-0">
                  <Select
                    size="large"
                    placeholder="Order By"
                    options={[
                      { label: 'Ascending order', value: 'asc' },
                      { label: 'Descending order', value: 'desc' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} justify={'start'}>
              <Button htmlType="submit" type="primary" size="large">
                <BoldButtonLabel labelText="Sort" />
              </Button>
            </Row>
            {!_.isEmpty(auditListingResponse?.data) && (
              <Row className="my-2" justify={'end'} gutter={[12, 12]} align={'middle'}>
                <Col>
                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(auditListingResponse.count / 10)}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </Col>
              </Row>
            )}
            <Table
              loading={false}
              bordered
              pagination={false}
              className="mt-4"
              dataSource={auditListingResponse?.data || []}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
            <div className="mt-4">
              <Button size="large" type="primary" onClick={handleOnGoBackToCoupons}>
                <BoldButtonLabel labelText="Go back to Coupons" />
              </Button>
            </div>
          </Card>
        </Form>
      </div>
    </PrimaryLayout>
  );
};
export default AuditListing;
