export const statusIndexMap: Record<string, { key: number }> = {
  RELEASED: {
    key: 1
  },
  RELEASE_ACCEPTED: {
    key: 1
  },
  PICKLIST_GENERATED: {
    key: 1
  },
  PARTIALLY_READY_TO_PICK: {
    key: 1
  },
  READY_TO_PICK: {
    key: 1
  },
  BACKORDER_FROM_NODE: {
    key: 1
  },
  PARTIALLY_PICKED: {
    key: 1
  },
  PICK: {
    key: 2
  },
  PARTIALLY_READY_TO_PACK: {
    key: 2
  },
  READY_TO_PACK: {
    key: 2
  },
  PARTIALLY_PACKED: {
    key: 2
  },
  PACK: {
    key: 3
  },
  READY_FOR_PICKUP: {
    key: 3
  },
  PICKED_UP: {
    key: 3
  },
  PARTIALLY_READY_TO_SHIP: {
    key: 3
  },
  READY_TO_SHIP: {
    key: 3
  },
  PARTIALLY_SHIPPED: {
    key: 3
  },
  PACKED:{
    key: 3
  },
  SHIPPED: {
    key: 4
  },
  HANDED_OVER_TO_COURIER: {
    key: 4
  },
  PARTIALLY_IN_TRANSIT: {
    key: 4
  },
  IN_TRANSIT: {
    key: 5
  },
  PARTIALLY_OUT_FOR_DELIVERY: {
    key: 5
  },
  OUT_FOR_DELIVERY: {
    key: 6
  },
  DELIVERY_ATTEMPTED: {
    key: 6
  },
  PARTIALLY_DELIVERED: {
    key: 6
  },
  DELIVERED: {
    key: 7
  }
  // RELEASED: -> RELEASE_ACCEPTED, PICKLIST_GENERATED, PARTIALLY_READY_TO_PICK, READY_TO_PICK, BACKORDER_FROM_NODE, PARTIALLY_PICKED
  // PICK: -> PARTIALLY_READY_TO_PACK, READY_TO_PACK, PARTIALLY_PACKED
  // PACK: -> READY_FOR_PICKUP, PICKED_UP, PARTIALLY_READY_TO_SHIP, READY_TO_SHIP, PARTIALLY_SHIPPED
  // SHIPPED: -> HANDED_OVER_TO_COURIER, PARTIALLY_IN_TRANSIT
  // IN_TRANSIT: -> PARTIALLY_OUT_FOR_DELIVERY
  // OUT_FOR_DELIVERY: -> DELIVERY_ATTEMPTED, PARTIALLY_DELIVERED
  // DELIVERED: 
};

export const statusIndexMapForPickUp: Record<string, { key: number }> = {
  RELEASED: {
    key: 1
  },
  RELEASE_ACCEPTED: {
    key: 1
  },
  PICKLIST_GENERATED: {
    key: 1
  },
  PARTIALLY_READY_TO_PICK: {
    key: 1
  },
  READY_TO_PICK: {
    key: 1
  },
  BACKORDER_FROM_NODE: {
    key: 1
  },
  PARTIALLY_PICKED: {
    key: 1
  },
  PICK: {
    key: 2
  },
  PARTIALLY_READY_TO_PACK: {
    key: 2
  },
  READY_TO_PACK: {
    key: 2
  },
  PARTIALLY_PACKED: {
    key: 2
  },
  PACK: {
    key: 3
  },
  READY_FOR_PICKUP: {
    key: 4
  },
  PICKED_UP: {
    key: 5
  },
  // RELEASED: -> RELEASE_ACCEPTED, PICKLIST_GENERATED, PARTIALLY_READY_TO_PICK, READY_TO_PICK, BACKORDER_FROM_NODE, PARTIALLY_PICKED
  // PICK: -> PARTIALLY_READY_TO_PACK, READY_TO_PACK, PARTIALLY_PACKED
  // PACK: -> READY_FOR_PICKUP, PICKED_UP
};


export const storeStatusIndex: Record<string, { key: number }> = {
  RELEASED: {
    key: 0
  },
  PICK: {
    key: 1
  },
  PACK: {
    key: 2
  },
  READY_FOR_PICKUP: {
    key: 3
  },
  DELIVERED: {
    key: 4
  }
};

export const statusIndex: Record<string, Record<string, { key: number }>> = {
  ONLINE: statusIndexMap,
  STORE: storeStatusIndex
};

export const statusIndexForPickUp: Record<string, Record<string, { key: number }>> = {
  ONLINE: statusIndexMapForPickUp,
  STORE: storeStatusIndex
};

export const statusTimeline = [
  {
    title: 'Ordered'
  },
  {
    title: 'Released'
  },
  {
    title: 'Picked'
  },
  {
    title: 'Packed'
  },
  {
    title: 'Shipped'
  },
  {
    title: 'Out for Delivery'
  },
  {
    title: 'Delivered'
  }
];

export const storeStatusTimeLine = [
  {
    title: 'Ordered'
  },

  {
    title: 'Picked'
  },
  {
    title: 'Packed'
  },
  {
    title: 'Ready for Pickup'
  },
  {
    title: 'Delivered'
  }
];

export const timeLineSteps: Record<string, any> = {
  ONLINE: statusTimeline,
  STORE: storeStatusTimeLine
};

export const statusList = [
  { value: 'RELEASED', label: 'Released' },
  { value: 'PICK', label: 'Pick' },
  { value: 'PACK', label: 'Pack' },
  { value: 'SHIPPED', label: 'Shipped' },
  { value: 'IN_TRANSIT', label: 'In Transit' },
  { value: 'OUT_FOR_DELIVERY', label: 'Out For Delivery' },
  { value: 'READY_FOR_PICKUP', label: 'Ready For Pickup' },
  { value: 'DELIVERED', label: 'Delivered' }
];

export const getStatusesWithLowerPrecedence = (status: string, isStore = false): string[] => {
  const statuses = ['RELEASED', 'PICK', 'PACK', 'SHIPPED', 'READY_FOR_PICKUP', 'OUT_FOR_DELIVERY', 'DELIVERED'];

  if (isStore) {
    if (status === 'PACK') {
      return ['RELEASED', 'PICK', 'PACK', 'SHIPPED', 'OUT_FOR_DELIVERY', 'DELIVERED'];
    }
    if (status === 'READY_FOR_PICKUP')
      return ['RELEASED', 'PICK', 'PACK', 'SHIPPED', 'READY_FOR_PICKUP', 'OUT_FOR_DELIVERY'];
  }

  if (!isStore) {
    if (status === 'SHIPPED') return ['RELEASED', 'PICK', 'PACK', 'SHIPPED', 'READY_FOR_PICKUP', 'DELIVERED'];
  }

  const index = statuses.indexOf(status);
  if (index !== -1 && index < statuses.length - 1) {
    return statuses.slice(0, index + 1).concat(statuses.slice(index + 2));
  }

  return statuses;
};

export const statusMaster = {
  RELEASED: 'RELEASED',
  PICK: 'PICK',
  PACK: 'PACK',
  SHIPPED: 'SHIPPED',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED'
};

//!Updated status timeline

export const updatedStatusTimeline = [
  { value: 'CREATED', label: 'Ordered' },
  { value: 'RELEASED', label: 'Released' },
  { value: 'PICK', label: 'Picked' },
  { value: 'PACK', label: 'Packed' },
  { value: 'SHIPPED', label: 'Shipped' },
  { value: 'IN_TRANSIT', label: 'In Transit' },
  { value: 'OUT_FOR_DELIVERY', label: 'Out For Delivery' },
  { value: 'DELIVERED', label: 'Delivered' }
];

export const updatedStatusTimelineForPickUp = [
  { value: 'CREATED', label: 'Ordered' },
  { value: 'RELEASED', label: 'Released' },
  { value: 'PICK', label: 'Picked' },
  { value: 'PACK', label: 'Packed' },
  { value: 'READY_FOR_PICKUP', label: 'Ready For PickUp' },
  { value: 'PICKED_UP', label: 'Picked Up' },
];

export const getBaseStatusFromStatus = (status: string) => {
  if (!status) return '';
  // const isPartial = status.split('_')[0] === 'PARTIALLY';
  // if (!isPartial) return status;
  const [partial, ...baseStatus] = status.split('_');
  return status
};

export const getUpdatedStatusTimeLineWrtStatus = (status: string) => {

  if (!status) return updatedStatusTimeline;

  const isPartial = status.split('_')[0] === 'PARTIALLY';
  const [partial, ...baseStatus] = status.split('_');
  const isCancelled = status === 'CANCELLED';
  if (!isPartial) return updatedStatusTimeline;

  const transformedStatus = updatedStatusTimeline.map(statusTl => {
    if (statusTl.value === baseStatus.join(''))
      return {
        ...statusTl,
        label: 'Partially ' + statusTl.label
      };

    return statusTl;
  });
  
  return transformedStatus;
};

export const getUpdatedStatusTimeLineWrtStatusforPickUp = (status: string) => {

  if (!status) return updatedStatusTimelineForPickUp;

  const isPartial = status.split('_')[0] === 'PARTIALLY';
  const [partial, ...baseStatus] = status.split('_');
  const isCancelled = status === 'CANCELLED';
  if (!isPartial) return updatedStatusTimelineForPickUp;

  const transformedStatus = updatedStatusTimelineForPickUp.map(statusTl => {
    if (statusTl.value === baseStatus.join(''))
      return {
        ...statusTl,
        label: 'Partially ' + statusTl.label
      };

    return statusTl;
  });

  return transformedStatus;
};
