import { Button, Card, Col, Form, Input, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import FieldDisplay from '../../../components/FieldDisplay';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { couponService } from '../services/coupon.service';

interface IReportProps {}

const ReportListing: React.FunctionComponent<IReportProps> = props => {
  const navigate = useNavigate();
  const [couponListingSearchForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { reward_type, title, promotion_type, id, code } = queryStringObj;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [reportListingResponse, setReportListingResponse] = React.useState({} as any);

  const columns: TableColumnsType<any> = [
    {
      title: 'User Id',
      render(value, record, index) {
        return <>{record.user_id}</>;
      },
      align: 'center'
    },
    {
      title: 'Order Id',
      render(value, record, index) {
        return <>{record.order_id}</>;
      },
      align: 'center'
    },

    {
      title: 'Redeemed Date',
      render(value, record, index) {
        const date = dayjs(record.created_at).format('YYYY-MM-DD');
        return <>{date}</>;
      },
      align: 'center'
    },
    {
      title: 'Usage Count',
      render(value, record, index) {
        return <>{record.usage_count}</>;
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    await handleSearch(offset);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = couponListingSearchForm.getFieldsValue();
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(formValues) };
    const Params = {
      ...filteredFormValues,
      offset,
      limit: 10
    };
    const { data, errors } = await couponService.getCouponReport(code, Params);
    if (_.isEmpty(errors)) {
    
      setReportListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
  };

  const handleOnGoBackToCoupons = () => {
    const params = {
      reward_type,
      promotion_type,
      title,
      offset: '0',
      id: id
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/coupons?${paramString}`);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={couponListingSearchForm} onFinish={() => handleSearch(0)}>
          <Card>
            <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Report
                </Typography.Title>
              </Col>
            </Row>
            <div className="flex gap-4">
              <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
                <Col xs={24} md={12}>
                  <section className="flex gap-4 flex-wrap">
                    <FieldDisplay label="Reward Type" value={reward_type} />
                    <FieldDisplay label="Promotion Type" value={promotion_type} />
                    <FieldDisplay label="Coupon code" value={code} />
                    <FieldDisplay label="Status" value={reportListingResponse?.status} />
                  </section>
                </Col>
              </Row>
            </div>
            <Row className="mt-4" gutter={12}>
              <Col xs={12} md={6}>
                {' '}
                <Form.Item name="user_id" label="User ID">
                  <Input size="large" placeholder="User ID" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                {' '}
                <Form.Item name="order_id" label="Order ID">
                  <Input size="large" placeholder="Order ID" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="my-2" gutter={12}>
              <Button htmlType="submit" type="primary" size="large">
                <BoldButtonLabel labelText="Search" />
              </Button>
            </Row>
            {!_.isEmpty(reportListingResponse?.code_usage) && (
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item label="Sort By" name={'sort_by'} className="mb-0">
                    <Select
                      size="large"
                      placeholder="Sort By"
                      defaultValue={'created_at'}
                      onChange={value => {
                        handleSearch(offset);
                      }}
                      options={[
                        { label: 'created at', value: 'created_at' },
                        { label: 'usage count', value: 'usage_count' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {!_.isEmpty(reportListingResponse?.code_usage) && (
              <Row className="my-2" justify={'end'} gutter={[12, 12]} align={'middle'}>
                <Col>
                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(reportListingResponse.code_usage.length / 10)}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </Col>
              </Row>
            )}
            <Table
              loading={false}
              bordered
              pagination={false}
              className="mt-4"
              dataSource={reportListingResponse.code_usage}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
            <div className="mt-4">
              <Button size="large" type="primary" onClick={handleOnGoBackToCoupons}>
                <BoldButtonLabel labelText="Go back to Coupons" />
              </Button>
            </div>
          </Card>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default ReportListing;
