import * as React from "react";

interface IBoldButtonLabelProps {
  labelText: string;
}

const BoldButtonLabel: React.FunctionComponent<IBoldButtonLabelProps> = ({
  labelText,
}) => {
  return <span className="font-inter text-base font-normal leading-6 text-left">{labelText}</span>;
};

export default BoldButtonLabel;
