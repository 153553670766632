import { Button, Checkbox, Col, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Typography } from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TextArea from 'antd/es/input/TextArea';
import { validationHelpers } from '../../../../helpers';

interface IProductFormProps {
  // initialFormValues?: any;
  isUpdate: boolean;
  formInstance: FormInstance<any>;
  handleSave: () => void;
  handleGoBack: () => void;
  resetData: () => void;
}

const ProductForm: React.FunctionComponent<IProductFormProps> = ({
  formInstance,
  isUpdate,
  handleSave,
  handleGoBack,
  resetData
}) => {
  return (
    <Form form={formInstance} layout="vertical" onFinish={handleSave}>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Product Identifier Type"
            name="product_identifier_type"
            rules={[
              {
                required: true,
                message: 'Please enter Product Identifier Type'
              }
            ]}
          >
            <Select
              placeholder="Product Identifier Type"
              size="large"
              allowClear
              options={[
                { label: 'SKU', value: 'SKU' },
                { label: 'GTIN', value: 'GTIN' },
                { label: 'UPC', value: 'UPC' },
                { label: 'EAN', value: 'EAN' },
                { label: 'MPN', value: 'MPN' }
              ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="item_id"
            label="Item ID"
            rules={[
              {
                required: true,
                message: 'Please enter Product Item ID'
              },
              {
                max: 30,
                message: 'cannot exceed 30 characters'
              }
            ]}
          >
            <Input size="large" placeholder="Item ID" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <div className="ml-[25px]">
            <Form.Item name="is_variant" label="Is Variant?">
              <Radio.Group>
                <Radio value={'TRUE'}>Yes</Radio>
                <Radio value={'FALSE'}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.is_variant !== curValues.is_variant}>
            {({ getFieldValue }) => {
              const isVariant = getFieldValue('is_variant') === 'TRUE';
              return (
                <Form.Item
                  name="article_id"
                  label="Article ID"
                  rules={[
                    {
                      required: isVariant,
                      message: 'Please enter Product Article ID'
                    },
                    {
                      max: 30,
                      message: 'Cannot exceed 30 characters'
                    }
                  ]}
                >
                  <Input size="large" placeholder="Article ID" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="vendor_code"
            label="Vendor Code"
            rules={[
              {
                required: false,
                message: 'Please enter Product Vendor Code'
              },
              {
                max: 30,
                message: 'cannot exceed 30 characters'
              }
            ]}
          >
            <Input size="large" placeholder="Vendor Code" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Supply Type Code"
            name="supply_type_code"
            rules={[
              {
                required: true,
                message: 'Please enter Supply Type Code'
              }
            ]}
          >
            <Select
              placeholder="Supply Type Code"
              size="large"
              allowClear
              options={[
                { label: 'B2B', value: 'B2B' },
                { label: 'B2C', value: 'B2C' }
              ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="product_name"
            label="Product Name"
            rules={[
              {
                required: true,
                message: 'Please enter Product Name'
              },
              {
                max: 100,
                message: 'cannot exceed 100 characters'
              }
            ]}
          >
            <Input size="large" placeholder="Product Name" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="product_type"
            label="Product Type"
            rules={[
              {
                required: false,
                message: 'Please enter Product Type'
              },
              {
                max: 50,
                message: 'cannot exceed 50 characters'
              }
            ]}
          >
            <Input size="large" placeholder="Product Type" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="department"
            label="Department"
            rules={[
              {
                required: true,
                message: 'Please enter Department'
              },
              {
                max: 50,
                message: 'cannot exceed 50 characters'
              }
            ]}
          >
            <Input size="large" placeholder="Department" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name="category"
            label="Category"
            rules={[
              {
                required: true,
                message: 'Please enter Category'
              },
              {
                max: 50,
                message: 'cannot exceed 50 characters'
              }
            ]}
          >
            <Input size="large" placeholder="Category" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <div className="ml-[25px]">
            <Form.Item name="branded" label="Branded">
              <Radio.Group>
                <Radio value={'TRUE'}>Yes</Radio>
                <Radio value={'FALSE'}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Col>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.branded !== curValues.branded;
          }}
        >
          {({ getFieldValue, setFieldsValue }) => {
            const branded = getFieldValue('branded');
            if (branded === 'FALSE') {
              return null;
            }
            return (
              <Col xs={12} md={6}>
                <Form.Item
                  name="brand"
                  label="Brand"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Brand'
                    }
                  ]}
                >
                  <Input size="large" placeholder="Brand" />
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>
      </Row>
      <Row gutter={12}>
        <Col xs={24} md={24}>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: false, message: "This field can't be empty" },
              { max: 500, message: 'Max limit is 500 characters' }
            ]}
          >
            <TextArea rows={4} placeholder="Description" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}></Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name="country_of_origin"
            label="Country Of Origin"
            rules={[
              {
                required: true,
                message: 'Please enter Country Of Origin'
              },
              { max: 100, message: 'Max limit is 100 characters' }
            ]}
          >
            <Input size="large" placeholder="Country Of Origin" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="lyt_earn"
            label="Earn"
            rules={[
              {
                required: false,
                message: 'Please enter Earn'
              },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message: 'Enter up to 10 digits and 2 decimals..'
              })
            ]}
          >
            <InputNumber className="w-full" size="large" placeholder="Earn" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="lty_redeem"
            label="Redeem"
            rules={[
              {
                required: false,
                message: 'Please enter Redeem'
              },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message: 'Enter up to 10 digits and 2 decimals..'
              })
            ]}
          >
            <InputNumber className="w-full" size="large" placeholder="Redeem" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12} className="py-4">
        <Col xs={12} md={6}>
          <Form.Item name="active_status" label="Active">
            <Radio.Group>
              <Radio value={'TRUE'}>Yes</Radio>
              <Radio value={'FALSE'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Form.Item
            label="Unit Price Ref"
            name={'unit_price'}
            rules={[
              { required: false, message: "This field can't be empty" },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message: 'Enter up to 10 digits and 2 decimals..'
              })
            ]}
          >
            <InputNumber placeholder="Unit Price" className="w-full" min={0} size="large"></InputNumber>
          </Form.Item>
        </Col>{' '}
        <Col xs={24} md={6}>
          <Form.Item
            label="List Price Ref"
            name={'list_price'}
            rules={[
              { required: false, message: "This field can't be empty" },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message: 'Enter up to 10 digits and 2 decimals..'
              })
            ]}
          >
            <InputNumber placeholder="List Price" className="w-full" min={0} size="large"></InputNumber>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            label="Cost Price Ref"
            name={'cost_price'}
            rules={[
              { required: false, message: "This field can't be empty" },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message: 'Enter up to 10 digits and 2 decimals..'
              })
            ]}
          >
            <InputNumber placeholder="Cost Price" className="w-full" min={0} size="large"></InputNumber>
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={5} className="text-[#2e2a5b]">
        Channel Option
      </Typography.Title>
      <Row>
        <div style={{ display: 'flex' }}>
          <Form.Item name="sold_Online" valuePropName="checked">
            <Checkbox> ECom </Checkbox>
          </Form.Item>{' '}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Item name="sold_Offline" valuePropName="checked">
            <Checkbox>Store</Checkbox>
          </Form.Item>
        </div>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Button size="large" block type="primary" htmlType="submit">
            <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'} />
          </Button>
        </Col>{' '}
        {isUpdate && (
          <Col xs={24} md={6}>
            <Button size="large" block onClick={resetData}>
              <BoldButtonLabel labelText={'Reset'} />
            </Button>
          </Col>
        )}
        <Col xs={24} md={6}>
          <Button size="large" block onClick={handleGoBack}>
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;
