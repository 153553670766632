import { Button, Col, Form, FormInstance, Input, Row } from "antd";
import BoldButtonLabel from "../../../components/BoldButtonLabel";

interface IUserUpdateForm {
    formInstance: FormInstance<any>;
    handleOnUpdate: () => void
}

const UserUpdateForm: React.FC<IUserUpdateForm> = ({ formInstance, handleOnUpdate }) => {
    return (
        <Form form={formInstance} layout='vertical' onFinish={() => { handleOnUpdate() }}>
            <Row gutter={12}>
                <Col xs={12} md={6}>
                    <Form.Item name={'first_name'} label={'First Name'}>
                        <Input className='w-full' size='large' allowClear placeholder={'First Name'} />
                    </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Item name={'last_name'} label={'Last Name'}>
                        <Input className='w-full' size='large' allowClear placeholder={'Last Name'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col xs={12} md={6} className="mt-7">
                    <Button type="primary" htmlType="submit" size="large" block>
                        <BoldButtonLabel labelText="Update"></BoldButtonLabel>
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default UserUpdateForm;