import { useEffect, useState } from 'react';
import _ from '../helpers/lodash';
import { nodeService } from '../services';

export const useNodes = (tenantId: string = '123') => {
  const [nodes, setNodes] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    fetchNodes();
  }, [tenantId]);

  const fetchNodes = async () => {
    const { data, errors } = await nodeService.getNodeListByTenantId();
    if (_.isEmpty(errors)) {
      setNodes(data);
    }
  };

  return { nodes };
};
