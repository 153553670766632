import { Rule } from 'antd/es/form';

export const getRequiredValidator = ({ message }: { message: string }): Rule => {
  return {
    required: true,
    message
  };
};

export const getNumericValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (isNumeric(value) || !value) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value');
    }
  };
};

export const getNumeric2DecimalPointValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (isNumericWithTwoDecimalPlaces(value) || !value) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value with only 2 decimal places. Eg: (1.23) ');
    }
  };
};

export const isNumeric = (value: string) => {
  // Use a regular expression to check if the value is numeric
  return /^\d+$/.test(value);
};

const isNumericWithTwoDecimalPlaces = (value: string) => {
  // Use a regular expression to check if the value is numeric with two decimal places
  return /^\d+(\.\d{2})?$/.test(value);
};

export const getTitleValidator = ({
  requiredMessage,
  maxLength,
  maxLengthMessage,
  allowedSymbolsMessage
}: {
  requiredMessage: string;
  maxLength: number;
  maxLengthMessage: string;
  allowedSymbolsMessage: string;
}): Rule[] => {
  return [
    {
      required: true,
      message: requiredMessage
    },
    {
      validator: (_, value) => {
        if (value && value.length > maxLength) {
          return Promise.reject(new Error(maxLengthMessage));
        }
        if (value && /[^a-zA-Z0-9\s@]/.test(value)) {
          return Promise.reject(new Error(allowedSymbolsMessage));
        }
        return Promise.resolve();
      }
    }
  ];
};

export const getFormattedNumeric2DecimalPointValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (!value || /^\d+(\.\d{0,2})?$/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)');
    }
  };
};

// export const formatToTwoDecimalPlaces = (event: React.FocusEvent<HTMLInputElement>) => {
//   const { value } = event.target;

//   // Check if the value is a whole number
//   if (value && /^\d+$/.test(value)) {
//     event.target.value = parseFloat(value).toFixed(2); // Format to 2 decimal places
//   } else if (value && /^\d+\.\d{1}$/.test(value)) {
//     event.target.value = parseFloat(value).toFixed(2); // Format to 2 decimal places if only 1 decimal digit is provided
//   }
// };

export const formatToTwoDecimalPlaces = (event: React.FocusEvent<HTMLInputElement>) => {
  const { value } = event.target;

  if (value && /^\d+$/.test(value)) {
    event.target.value = parseFloat(value).toFixed(2); // Format to 2 decimal places
  } else if (value && /^\d+\.\d{1}$/.test(value)) {
    event.target.value = parseFloat(value).toFixed(2); // Format to 2 decimal places if only 1 decimal digit is provided
  }
};

export const getMaxTenDigitTwoDecimalPointValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (!value) {
        return Promise.resolve(); // Allow empty values if it's not required
      }

      const valueStr = value.toString();
      const [integerPart, decimalPart] = valueStr.split('.');

      // Check if integer part exceeds 10 digits
      if (integerPart.length > 10) {
        return Promise.reject(new Error(message || 'Maximum 10 digits allowed before the decimal point.'));
      }

      // Check if decimal part exceeds 2 digits (if it exists)
      if (decimalPart && decimalPart.length > 2) {
        return Promise.reject(new Error(message || 'Maximum 2 decimal places allowed.'));
      }

      return Promise.resolve();
    }
  };
};
