import { COUPONS_URI } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';

const getCouponsList = (params: any) => {
  const url = `${COUPONS_URI}/promo-exec/v3/projection`;
  ///promo-exec/v1/projection
  return httpClient.get({ url, params });
};

const patchCouponStatus = (status: string, id: number, code: string, config_id: number) => {
  const url = `${COUPONS_URI}/promo-exec/v1/promotions/${config_id}/codes/${code}/status/${status}/`;
  return httpClient.patch({ url });
};

const getCoupons = (config_id: number, params: any) => {
  const url = `${COUPONS_URI}/promo-exec/coupons/${config_id}/download`
  return httpClient.get({ url, params, responseType: 'arraybuffer' });
}

const getCouponReport = (code: string, params: any) => {
  const url = `${COUPONS_URI}/promo-exec/v1/coupon/${code}/report`
  return httpClient.get({ url, params });
}

const getAuditList = (params: any) => {
  const url = `${COUPONS_URI}/promo-exec/v1/coupons/audit`
  return httpClient.get({ url, params });
}

export const couponService = { getCouponsList, patchCouponStatus, getCoupons, getCouponReport, getAuditList };
