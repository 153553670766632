import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Alert, Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, TimePicker, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import { createEarnRulesInitialXml, createRuleTriggerTypeData, triggerTypeSelectOptions } from '../../data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import DmnModeler from 'dmn-js/lib/Modeler';

import dayjs from 'dayjs';
import { IEarnRuleDetails, IRuleDescription } from '../../types/earn-rules';
import { useTimezone } from '../../../../hooks/useTimezone';

interface IEarnRulesCreateNewVersionProps {}

const EarnRulesCreateNewVersion: React.FunctionComponent<IEarnRulesCreateNewVersionProps> = props => {
  const [createForm] = Form.useForm();
  const [ruleDescription, setRuleDescription] = React.useState({} as IRuleDescription);
  const [selectedRuleInputField, setSelectedRuleInputField] = React.useState('');
  const [selectedRuleRewardField, setSelectedRewardField] = React.useState('');
  const [earnRuleDetails, setEarnRuleDetails] = React.useState({} as IEarnRuleDetails);

  const handleApplicabilityChange = async () => {
    setLoading(true);
    const { trigger_type, applicablity_type } = createForm.getFieldsValue();

    const { errors, data } = await loyaltyService.getRuleDescriptions({
      applicabilityType: applicablity_type,
      triggerType: trigger_type
    });

    if (_.isEmpty(errors)) {
      setRuleDescription(data);
      if (data.input?.length) setSelectedRuleInputField(data.input[0].field);
      if (data.result?.length) setSelectedRewardField(data.result[0].field);
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const modellerRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (_.isEmpty(earnRuleDetails)) {
      fetchRuleEarnDetails();
    }

    if (modellerRef.current === null && earnRuleDetails.xml) {
      initializeModeller();
    }

    return () => {
      if (modellerRef.current !== null) {
        modellerRef.current.detach();
        modellerRef.current = null;
      }
    };
  }, [earnRuleDetails]);

  const fetchRuleEarnDetails = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getEarnRuleDetail(id as string);
    if (_.isEmpty(errors)) {
      setEarnRuleDetails(data);
      //!Add api values to form remaining
      let formValues = {
        trigger_type: data.trigger_type,
        applicablity_type: data.applicability_type,
        name: data.name,
        date_range: [dayjs(data.start_date), dayjs(data.end_date)],
        timezone: data.timezone
      };

      if (data.scheduled_time) {
        const scheduled = {
          scheduled_time: dayjs(data.scheduled_time),
          scheduled_date: dayjs(data.scheduled_time)
        };
        formValues = { ...formValues, ...scheduled };
      }
      createForm.setFieldsValue(formValues);

      handleApplicabilityChange();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const initializeModeller = async () => {
    const modeller = new DmnModeler({
      container: '#createEarnRulesNewVersionCanvas'
    });
    modellerRef.current = modeller;

    try {
      const { warnings } = await modeller.importXML(earnRuleDetails.xml);

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };
  const handleCreate = async () => {
    setLoading(true);
    const { name, applicablity_type, trigger_type, schedule_time, schedule_date, date_range, timezone } =
      createForm.getFieldsValue();

    const { xml } = await modellerRef.current.saveXML();
    const request: any = {
      id,
      name,
      applicability_type: applicablity_type,
      trigger_type,
      xml,
      timezone
    };

    if (schedule_time) {
      const datePart = schedule_date.format('YYYY-MM-DD');
      const timePart = schedule_time.format('HH:mm:ss');

      // Combine date and time to create a new Day.js object
      const combinedDateTime = dayjs(`${datePart}T${timePart}`);

      request.scheduled_time = combinedDateTime.format();
    }

    if (date_range?.length) {
      const [start_date, end_date] = date_range;
      request.start_date = start_date.format();
      request.end_date = end_date.format();
    }

    const { errors } = await loyaltyService.createEarnRuleNewVersion(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'New Version created successfully' });
      navigate('/loyalty/config/earn-rule');
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const ruleInputFieldsSelectOption = (ruleDescription?.input || []).map(({ expressions, field }) => {
    return {
      label: field,
      value: field
    };
  });

  const ruleRewardFieldSelectOptions = (ruleDescription?.result || []).map(({ expressions, field }) => {
    return {
      label: field,
      value: field
    };
  });

  const selectedInputFieldObject = (ruleDescription?.input || []).find(item => item.field === selectedRuleInputField);
  const selectedRewardFieldObject = (ruleDescription?.result || []).find(
    item => item.field === selectedRuleRewardField
  );

  const inputFieldTableData = (selectedInputFieldObject?.expressions || []).map(
    ({ description, expression, values }) => {
      return {
        description,
        expression,
        values
      };
    }
  );

  const rewardFieldTableData = (selectedRewardFieldObject?.expressions || []).map(
    ({ description, expression, values, output, type }) => {
      return {
        description,
        expression,
        values,
        output,
        type
      };
    }
  );
  const { timezoneSelectOptions } = useTimezone();
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create New Version - {earnRuleDetails.name}
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
          </div>

          <section className="mt-4">
            <Form layout="vertical" onFinish={handleCreate} form={createForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[{ message: 'This field is required', required: true }]}
                    name="trigger_type"
                    label="Trigger Type"
                  >
                    <Select
                      disabled
                      placeholder="Select Trigger Type"
                      size="large"
                      onChange={() => createForm.setFieldValue('applicablity_type', null)}
                      options={triggerTypeSelectOptions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.trigger_type !== curValues.trigger_type;
                    }}
                  >
                    {form => {
                      const { trigger_type } = form.getFieldsValue();
                      const options = createRuleTriggerTypeData[trigger_type]?.applicabilityTypes || [];

                      return (
                        <Form.Item
                          rules={[{ message: 'This field is required', required: true }]}
                          name="applicablity_type"
                          label="Applicability Type"
                        >
                          <Select
                            disabled
                            placeholder="Select Applicablity Type"
                            size="large"
                            onChange={handleApplicabilityChange}
                            options={options}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[
                      { message: 'This field is required', required: true },
                      {
                        validator: async (_, ruleName: string) => {
                          if (/\s/.test(ruleName)) {
                            return Promise.reject(new Error('No spaces allowed in rule name'));
                          }
                        }
                      }
                    ]}
                    name="name"
                    label="Rule Name"
                  >
                    <Input disabled placeholder="Enter Rule Name" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.applicablity_type !== curValues.applicablity_type;
                  }}
                >
                  {form => {
                    const { applicablity_type } = form.getFieldsValue();

                    return applicablity_type === createRuleTriggerTypeData.SCHEDULED.label ? (
                      <>
                        <Col xs={24} md={6}>
                          <Form.Item
                            rules={[{ message: 'This field is required', required: true }]}
                            name="schedule_date"
                            label="Schedule Date"
                          >
                            <DatePicker className="w-full" size="large" />
                          </Form.Item>
                        </Col>{' '}
                        <Col xs={24} md={6}>
                          <Form.Item
                            rules={[{ message: 'This field is required', required: true }]}
                            name="schedule_time"
                            label="Schedule Time"
                          >
                            <TimePicker className="w-full" size="large" format={'hh:mm A'} />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null;
                  }}
                </Form.Item>{' '}
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[{ message: 'This field is required', required: true }]}
                    name="timezone"
                    label="Timezone"
                  >
                    <Select options={timezoneSelectOptions} placeholder="Select timezone" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label="Date">
                    <DatePicker.RangePicker size="large" />
                  </Form.Item>
                </Col>
              </Row>
              {!_.isEmpty(ruleDescription) && (
                <Row gutter={12} className="my-4">
                  <Col xs={24} md={10}>
                    <Typography.Title level={4} className="text-[#2e2a5b]">
                      Rule Description - Inputs
                    </Typography.Title>
                    <section className="my-2">
                      <Row>
                        <Col xs={24} md={12}>
                          <Select
                            placeholder="Select Field"
                            value={selectedRuleInputField}
                            options={ruleInputFieldsSelectOption}
                            onChange={value => setSelectedRuleInputField(value)}
                            className="w-full"
                            size="large"
                          ></Select>
                        </Col>
                      </Row>
                    </section>

                    <Table
                      loading={false}
                      bordered
                      pagination={false}
                      dataSource={inputFieldTableData}
                      columns={[
                        {
                          title: 'Description',
                          render(value, record, index) {
                            return <>{record.description}</>;
                          }
                        },
                        {
                          title: 'Expression',
                          render(value, record, index) {
                            return <Typography.Text copyable>{record.expression}</Typography.Text>;
                          }
                        },
                        {
                          title: 'Example Value(s)',
                          render(value, record, index) {
                            return <>{record.values}</>;
                          }
                        }
                      ]}
                    ></Table>
                  </Col>{' '}
                  <Col xs={24} md={14}>
                    <Typography.Title level={4} className="text-[#2e2a5b]">
                      Rule Description - Reward
                    </Typography.Title>
                    <section className="my-2">
                      <Row gutter={[12, 12]}>
                        <Col xs={24} md={8}>
                          <Select
                            placeholder="Select Field"
                            value={selectedRuleRewardField}
                            options={ruleRewardFieldSelectOptions}
                            onChange={value => setSelectedRewardField(value)}
                            className="w-full"
                            size="large"
                          ></Select>
                        </Col>
                        {selectedRewardFieldObject?.mandatory && (
                          <Col xs={24} md={8}>
                            <Alert message="This field is mandatory" type="info" showIcon />
                          </Col>
                        )}
                      </Row>
                    </section>
                    <Table
                      loading={false}
                      pagination={false}
                      bordered
                      scroll={{ x: 500 }}
                      dataSource={rewardFieldTableData}
                      columns={[
                        {
                          title: 'Output',
                          render(value, record, index) {
                            return <Typography.Text copyable>{record.output}</Typography.Text>;
                          }
                        },
                        {
                          title: 'Type',
                          render(value, record, index) {
                            return <>{record.type}</>;
                          }
                        },

                        {
                          title: 'Example Value(s)',
                          render(value, record, index) {
                            return <>{record.values}</>;
                          }
                        }
                      ]}
                    ></Table>
                  </Col>
                </Row>
              )}
              <div id="createEarnRulesNewVersionCanvas" className="h-[400px]"></div>
              <Row gutter={12}>
                <Col xs={24} md={8} lg={6}>
                  <Button block htmlType="submit" size="large" type="primary">
                    <BoldButtonLabel labelText={'Create New Version'} />
                  </Button>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <Button block size="large" onClick={() => navigate(`/loyalty/config/earn-rule`)}>
                    <BoldButtonLabel labelText={'Cancel'} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EarnRulesCreateNewVersion;
