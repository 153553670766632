import * as React from 'react';
import { IProfileDetailsResponse } from '../../../../types/profile.types';
import { Card, Col, Divider, Form, InputNumber, Row, Switch, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';

interface INebulaConfigurationProps {
  profileDetails: IProfileDetailsResponse;
}

const NebulaConfiguration: React.FunctionComponent<INebulaConfigurationProps> = ({ profileDetails }) => {
  const [configForm] = useForm();

  React.useEffect(() => {
    populateConfigForm();
  }, [profileDetails]);

  const populateConfigForm = () => {
    const {
      ignore_shipment_consolidation,
      local_delivery_max_distance_radius,
      retry_intervals,
      cancel_order_for_inventory_shortage,
      constraints,
      inventory_control,
      optimization,
      priorities,
      reservations
    } = profileDetails?.tenant_details?.subscriptions?.fulfillment_settings || {};
    const { back_ordered_in_hrs } = retry_intervals || {};
    const {
      allow_max_shipment_delay_window,
      combine_with_future_inventory,
      item_line_ship_complete,
      item_line_ship_from_single_node,
      maximum_release_lead_days,
      ship_complete,
      ship_from_single_node,
      ship_when_they_are_available,
      fulfil_bundle_products_from_same_node
    } = constraints || {};

    const {
      apply_future_safety_factor,
      apply_onhand_safety_factor,
      consider_future_inventory_inquiry,
      consider_future_inventory_sales
    } = inventory_control || {};

    const { allow_item_reservation } = reservations;
    const { by_delivery_date, by_landed_cost, by_number_of_shipments, by_priority, by_ship_to_distance, show_courier_option, max_sourcing_dg_group, check_availability_first } = optimization;
    const {
      distance_weightage_factor,
      node_priority_based,
      node_priority_factor_multiplier,
      node_weightage_factor,
      ship_to_distance_based
    } = priorities;
    const formValues = {
      distance_weightage_factor,
      node_priority_based,
      node_priority_factor_multiplier,
      node_weightage_factor,
      ship_to_distance_based,
      by_delivery_date,
      by_landed_cost,
      by_number_of_shipments,
      by_priority,
      by_ship_to_distance,
      allow_item_reservation,
      apply_future_safety_factor,
      apply_onhand_safety_factor,
      consider_future_inventory_inquiry,
      consider_future_inventory_sales,
      allow_max_shipment_delay_window,
      combine_with_future_inventory,
      item_line_ship_complete,
      item_line_ship_from_single_node,
      maximum_release_lead_days,
      ship_complete,
      ship_from_single_node,
      ship_when_they_are_available,
      back_ordered_in_hrs,
      cancel_order_for_inventory_shortage,
      ignore_shipment_consolidation,
      local_delivery_max_distance_radius,
      fulfil_bundle_products_from_same_node,
      show_courier_option,
      max_sourcing_dg_group,
      check_availability_first
    };

    configForm.setFieldsValue(formValues);
  };
  return (
    <section>
      <Form form={configForm} layout="vertical" disabled className='nebula-config'>

        <Card className='rounded-none'>
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Fulfillment Settings</h2>
        </Card>
        <Card className="mt-6 p-0">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Constraints</h2>
          <Divider className='bg-[#ffffff]' />

          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={12} lg={6} xl={8} xxl={3} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Dispatch the entire order at once.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Ship Completely ?" valuePropName="checked" name="ship_complete">
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8} xl={8} xxl={5} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Send each item from the same location.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Ship line item from single node ?" valuePropName="checked" name="ship_from_single_node">
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8} xl={8} xxl={5} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Dispatch items as they become available.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Ship when they are available ?"
                valuePropName="checked"
                name="ship_when_they_are_available"
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
            {/* <Col xs={12} lg={8} xl={8} xxl={5}>
              <Form.Item
                label="Combine with future inventory ?"
                valuePropName="checked"
                name={'combine_with_future_inventory'}
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>{' '} */}
            <Col xs={12} lg={8} xxl={4} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Maximum days allowed to prepare for shipment.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Maximum release lead days" name={'maximum_release_lead_days'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8} xxl={4} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Maximum allowable delay time for shipping.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Max shipment delay window" name="allow_max_shipment_delay_window">
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={10} xl={8} xxl={5} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Ship bundled products from one location.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Fulfill bundle products from same node ?"
                valuePropName="checked"
                name={'fulfil_bundle_products_from_same_node'}
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>{' '}
          </Row>
        </Card>
        <Card className="mt-6">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Inventory Control</h2>
          <Divider className='bg-[#ffffff]' />

          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={24} md={12} xl={8} xxl={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Include future inventory in availability checks.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Consider future inventory enquiry?"
                name={'consider_future_inventory_inquiry'}
                valuePropName="checked"
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8} xxl={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Use a buffer for future stock considerations.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Apply future safety factor?"
                name={'apply_future_safety_factor'}
                valuePropName="checked"
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card className="mt-6">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Optimize By</h2>
          <Divider className='bg-[#ffffff]' />
          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Arrange shipments based on priority levels.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Priority" name={'by_priority'}>
                <InputNumber className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Prioritize shipping based on delivery distance.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Ship to distance" name={'by_ship_to_distance'} >
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Organize shipments to meet delivery deadlines.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Delivery Dates" name={'by_delivery_date'} >
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Minimize the number of shipments.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Number of shipments" name={'by_number_of_shipments'}>
                <InputNumber className="w-full" min={0} />
              </Form.Item>
            </Col>{' '}
          </Row>
          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Optimize based on total shipping costs.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Landed costs" name={'by_landed_cost'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>

            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Use the maximum number of sources for fulfillment.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Max Sourcing" name={'max_sourcing_dg_group'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Display available courier options for optimization.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Show Courier Options ?"
                name={'show_courier_option'}
                valuePropName="checked"
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>{' '}
            <Col xs={12} md={6} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Prioritize items that are currently in stock.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Check Availability First"
                name={'check_availability_first'}
                valuePropName="checked"
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>{' '}
          </Row>
        </Card>
        <Card className="mt-6">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Node Priorities</h2>
          <Divider className='bg-[#ffffff]' />
          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={12} lg={8} xxl={4} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Factor in distance when prioritizing nodes.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Distance weightage factor" name={'distance_weightage_factor'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8} xxl={4} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Weight nodes based on specific criteria.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Node weightage factor" name={'node_weightage_factor'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8} xxl={4} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Multiply node priority factors for impact.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Node priority factor multiplier" name={'node_priority_factor_multiplier'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8} xxl={4} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Prioritize nodes by shipping distance.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Ship to distance based ?" name={'ship_to_distance_based'} valuePropName="checked">
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8} xxl={4} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Rank nodes based on assigned priority.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Node priority based ?" name={'node_priority_based'} valuePropName="checked">
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
          </Row>
        </Card>{' '}
        <Card className="mt-6">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Reservations</h2>
          <Divider className='bg-[#ffffff]' />

          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={24} md={12} lg={8} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Permit reservations for specific items.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Allow Item Reservations?" name={'allow_item_reservation'} valuePropName="checked">
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card className="mt-6">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Retry Intervals</h2>
          <Divider className='bg-[#ffffff]' />

          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={24} md={12} lg={8}>
              <Form.Item label="Back Ordered in Hours" name={'back_ordered_in_hrs'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card className="mt-6">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">Other</h2>
          <Divider className='bg-[#ffffff]' />
          <Row gutter={[12, 12]} className='p-6'>
            <Col xs={24} md={12} lg={8} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Maximum distance for local deliveries.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item label="Local delivery max distance radius in KM" name={'local_delivery_max_distance_radius'}>
                <InputNumber  className="w-full" min={0} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Cancel orders if stock is insufficient.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Cancel order for inventory shortage ?"
                name={'cancel_order_for_inventory_shortage'}
                valuePropName="checked"
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8} className='flex'>
              <Tooltip
                placement="top"
                title={
                  'Skip combining multiple shipments into one.'
                }
              >
                <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />&nbsp;&nbsp;
              </Tooltip>
              <Form.Item
                label="Ignore shipment consolidation?"
                name={'ignore_shipment_consolidation'}
                valuePropName="checked"
              >
                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </section>
  );
};

export default NebulaConfiguration;
