import { Card, Tabs } from "antd"
import TabPane from "antd/es/tabs/TabPane"
import * as React from 'react'
import _ from "../../../helpers/lodash"
import { displayErrorNotifications } from "../../../helpers/toast.helpers"
import PrimaryLayout from "../../../layouts/primary-layout"
import { cbtProductService } from "../../../services/cbt-product.service"
import { useLoader } from "../../../stores/use-loader"
import BillingDetails from "./BillingDetails"

interface IBillingPayment { }

const BillingPayments: React.FunctionComponent<IBillingPayment> = props => {

    const [tabList, setTablist] = React.useState([] as any)
    const [currentTab, setCurrentTab] = React.useState("");

    //zustand declare
    const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

    React.useEffect(() => {
        loadIntialValue()
    }, [])

    const loadIntialValue = () => {
        getTabData()
    }

    const getTabData = async (domain = '') => {
        setLoading(true)
        const { data, errors } = await cbtProductService.getProduct();
        if (_.isEmpty(errors)) {
            let tabList = (data || []).map((item: any) => item?.name)
            let selectFirstProduct = data?.length ? data[0]?.name : ''
            setCurrentTab(selectFirstProduct)
            setTablist(tabList)
        } else {
            displayErrorNotifications(errors);
        }
        setLoading(false)
    }

    const handleTabChange = (key: string) => {
        setCurrentTab(key);
    };

    return (
        <PrimaryLayout>
            <div className="w-full mx-auto px-4">
                <Card>
                    <Tabs type="card" onChange={handleTabChange}>
                        {(tabList || []).map((tabName: any) => {
                            return (
                                <TabPane tab={tabName} key={tabName}>
                                    <BillingDetails active={currentTab} />
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </Card>
            </div>
        </PrimaryLayout>
    )
}

export default BillingPayments