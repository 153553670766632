import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { urlHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { cbtProductService } from '../../../services/cbt-product.service';
import { useLoader } from '../../../stores/use-loader';

interface IAddSubscriptionPageProps { }
const businessGroupListOptions: any = [{ label: 'rmg', value: 'rmg' }];
const tenantListOptions: any = [{ label: 'rmg', value: 'rmg' }];

const AddSubscriptionPage: React.FunctionComponent<IAddSubscriptionPageProps> = props => {
  const [subscriptionForm] = Form.useForm();
  const navigate = useNavigate()

  const { businessGroupId } = urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

  const [businessGroupList, setBusinsesGroupList] = React.useState([]);
  const [tenantList, setTenantList] = React.useState([]);
  const [planId, setPlanId] = React.useState([])

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  React.useEffect(() => {
    loadIntialData()
  }, [])

  const loadIntialData = async () => {
    subscriptionForm.setFieldsValue({
      auto_renew: true
    })
    getPlanIdData()
  }

  const getPlanIdData = async () => {
    setLoading(true)
    const params = {
      business_grp_id: businessGroupId
    }
    const { data, errors } = await cbtProductService.getAllPlan(params)
    if (_.isEmpty(errors)) {
      let ids = [] as any;

      Object.keys(data).forEach(key => {
        data[key].forEach((item: any) => {
          if (item.id) {
            let labelData = `Domain: ${item.domain} Name: ${item.name} Id: ${item.id}`
            ids.push({ label: labelData, value: item.id });
          }
        });
      });
      setPlanId(ids)
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false)
  }

  const handleOnFinish = async () => {
    setLoading(true)
    const { plan_id, tenant, business_grp_id, auto_renew, expire_after_cycle } = subscriptionForm.getFieldsValue()
    const bodyData = {
      plan_id,
      tenant,
      business_grp_id,
      auto_renew,
      expire_after_cycle
    }
    const { errors } = await cbtProductService.createSubscription(bodyData)
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Subscription Created' })
      navigate(-1)
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false)
  }


  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-2">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                New Subscription
              </Typography.Title>
            </Col>
          </Row>
          <div className="mt-4">
            <Form form={subscriptionForm} layout="vertical" scrollToFirstError onFinish={handleOnFinish}>
              <Row gutter={12}>
                <Col xs={24} md={8}>
                  <Form.Item name="plan_id" label={'Plan Id'} rules={[{ required: true, message: 'Field is required' }]}>
                    <Select size="large" placeholder="Select Plan Id" options={planId || []} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item name="business_grp_id" label={'Bussiness Group Id'} rules={[{ required: true, message: 'Field is required' }]}>
                    <Input placeholder="Bussiness Group Id" size="large" />
                    {/* <Select size="large" placeholder="Select Business Group ID" options={businessGroupListOptions} /> */}
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item name="tenant" label={'Tenent Id'} rules={[{ required: true, message: 'Field is required' }]}>
                    <Input placeholder="Tenent Id" size="large" />
                    {/* <Select size="large" placeholder="Select Tenant ID" options={tenantListOptions} /> */}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} md={8}>
                  <Form.Item name="expire_after_cycle" label="Expire After Cycle" rules={[{ required: true, message: 'Field is required' }]}>
                    <InputNumber className="w-full" placeholder="Enter Expire After Cycle" min={0} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} className="mt-8">
                  <Form.Item name='auto_renew' valuePropName="checked">
                    <Checkbox>Enable Auto Renew ?</Checkbox>
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={12} >
                <Col xs={24} md={4}>
                  <Button block type="primary" htmlType="submit">
                    <BoldButtonLabel labelText="Subscribe" />
                  </Button>
                </Col>
                <Col xs={24} md={4}>
                  <Button block onClick={() => navigate(-1)}>
                    <BoldButtonLabel labelText="Go Back" />
                  </Button>
                </Col>
              </Row>
            </Form>{' '}
          </div>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default AddSubscriptionPage;
