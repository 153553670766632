import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  TimePicker,
  Tooltip,
  Typography
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { validationHelpers } from '../../../helpers';
import { useLoader } from '../../../stores/use-loader';

//creating this because in constant page(intl-data.ts) header(localeList) Chile value is different and currencyList is different
const newcurrencyList = [
  { label: '🇮🇳 INR', value: 'IN', country: 'India' },
  { label: '🇨🇱 CHL', value: 'CLP', country: 'Chile' },
  { label: '🇺🇸 USD', value: 'US', country: 'USA' }
];

const timeZones = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
];

const roundingPolicyData = [
  { label: 'Up', value: 'UP' },
  { label: 'Down', value: 'DOWN' },
  { label: 'Nearest', value: 'TO-NEAREST' },
  { label: 'Others', value: 'NONE' }
];

const roundingOption = [
  { label: 'Precision Cap', value: 'ENDS' },
  { label: 'Proximity', value: 'MULTIPLE-OF' }
];

const pos = [
  { label: 'S2001', value: 'S2001' },
  { label: 'S2002', value: 'S2002' },
  { label: 'S2003', value: 'S2003' },
  { label: 'S2004', value: 'S2004' },
  { label: 'S2005', value: 'S2005' }
];

const tireLevelOptions = [
  { label: 'Everyone', value: 'EVERYONE' },
  { label: 'Standard', value: 'Standard' },
  { label: 'Gold', value: 'Gold' },
  { label: 'Silver', value: 'Silver' },
  { label: 'Bronze', value: 'Bronze' }
];

interface IPriceListBasicDetailsProps {
  referenceBlockVisiblity: boolean;
  isformVisible: boolean;
  formInstance: FormInstance<any>;
  basicDetailsForm: FormInstance<any>;
  handleOnSave: (operation: string) => void;
  paretListDropDown: any;
  masterCatalogOptions: any;
  handleGoBack: () => void;
}

const PriceListBasicDetails: React.FunctionComponent<IPriceListBasicDetailsProps> = ({
  referenceBlockVisiblity,
  isformVisible,
  formInstance,
  basicDetailsForm,
  handleOnSave,
  paretListDropDown,
  masterCatalogOptions,
  handleGoBack
}) => {
  //new declare
  const navigate = useNavigate();
  const [testBasicForm] = Form.useForm();

  //new State
  const [tireOptions, setTireOptions] = React.useState<any>(tireLevelOptions);

  //other declerations

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current && current < dayjs().startOf('day');
  };

  const handleOnListChange = (e: RadioChangeEvent) => {
    basicDetailsForm.setFieldsValue({ suggestion_list: e.target.value });
  };

  const handleOnProductStatus = (e: RadioChangeEvent) => {
    basicDetailsForm.setFieldsValue({ is_active: e.target.value });
  };

  const onChangePriceListExe = (e: RadioChangeEvent) => {
    basicDetailsForm.setFieldsValue({ isPriceListResolvable: e.target.value });
  };

  const onChangePriceListResovable = (e: RadioChangeEvent) => {
    basicDetailsForm.setFieldsValue({ isPriceListResolvable: e.target.value });
  };

  const handleOnSubmitBasicDetails = () => {};

  return (
    <>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Row gutter={12} className="flex justify-between">
          <Col xs={12} md={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "This field can't be empty" },
                { max: 50, message: 'Max limit is 50 characters' }
              ]}
            >
              <Input size="large" placeholder="Name" disabled={!isformVisible} />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item label="Status" name="status" rules={[{ required: false, message: "This field can't be empty" }]}>
              <Input size="large" placeholder="Status" disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={24} md={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: false, message: "This field can't be empty" },
                { max: 200, message: 'Max limit is 200 characters' }
              ]}
            >
              <TextArea rows={4} placeholder="Description" disabled={!isformVisible} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="Currency"
              name="currency"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select an option"
                size="large"
                options={newcurrencyList}
                disabled={!isformVisible}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              label="Rank"
              name="rank"
              rules={[
                { required: true, message: "This field can't be empty" },
                validationHelpers.getNumericValidatorForInput({})
              ]}
            >
              <Input size="large" placeholder="Rank" disabled={!isformVisible} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="Time Zone"
              name="time_Zone"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select an option"
                size="large"
                className="w-[100%]"
                options={timeZones}
                disabled={!isformVisible}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="Enter Start Date"
              name="startDate"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <DatePicker
                size="large"
                className="w-[100%]"
                placeholder="Enter Start Date"
                disabled={!isformVisible}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              label="Enter Start Time"
              name="startTime"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <TimePicker
                size="large"
                className="w-[100%]"
                onBlur={e => {
                  let startingTime = e.target.value.length > 1 ? dayjs(e.target.value, 'HH:mm:ss') : null;
                  formInstance.setFieldsValue({ startTime: startingTime });
                }}
                disabled={!isformVisible}
                format={'HH:mm:ss'}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="Enter End Date"
              name="endDate"
              rules={[{ required: false, message: "This field can't be empty" }]}
            >
              <DatePicker
                size="large"
                className="w-[100%]"
                placeholder="Enter End Date"
                disabled={!isformVisible}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              label="Enter End Time"
              name="endTime"
              rules={[{ required: false, message: "This field can't be empty" }]}
            >
              <TimePicker
                size="large"
                className="w-[100%]"
                onBlur={e => {
                  let EndingTime = e.target.value.length > 1 ? dayjs(e.target.value, 'HH:mm:ss') : null;
                  formInstance.setFieldsValue({ endTime: EndingTime });
                }}
                disabled={!isformVisible}
                format={'HH:mm:ss'}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="Rounding Policy"
              name="rounding_Policy"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                size="large"
                placeholder="Select Policy"
                options={roundingPolicyData}
                disabled={!isformVisible}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.rounding_Policy !== curValues.rounding_Policy;
              }}
            >
              {form => {
                const { rounding_Policy } = form.getFieldsValue();
                return rounding_Policy === 'NONE' ? (
                  <Form.Item
                    label="Rounding Option"
                    name="rounding_Option"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      size="large"
                      placeholder="Select from drop down"
                      options={roundingOption}
                      disabled={!isformVisible}
                    ></Select>
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.rounding_Policy !== curValues.rounding_Policy;
              }}
            >
              {form => {
                const { rounding_Policy } = form.getFieldsValue();
                return rounding_Policy === 'NONE' ? (
                  <Form.Item
                    label="Rounding Amount"
                    name="rounding_amount"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input className="w-[100%]" size="large" placeholder="Rounding Amount" disabled={!isformVisible} />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Form.Item
            label="Is Price List Child of another parent list?"
            name="suggestion_list"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <Radio.Group onChange={handleOnListChange} disabled={referenceBlockVisiblity}>
              <Radio value={'list_parent'}>Parent List</Radio>
              <Radio value={'list_master'}>Master Catalog</Radio>
              <Radio value={'list_other'}>Catalog Ref Name</Radio>
            </Radio.Group>
          </Form.Item>
        </Row>

        <Row gutter={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.list_master !== curValues.list_master;
            }}
          >
            {form => {
              const { suggestion_list } = form.getFieldsValue();
              if (suggestion_list === 'list_master') {
                return (
                  <Col className="w-full" xs={24} md={8}>
                    <Form.Item
                      label="Select Master Catalog"
                      name="select_from_master_catalog_dropDown"
                      rules={[
                        {
                          required: true,
                          message: "This field can't be empty"
                        }
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Select an option"
                        className="w-[100%]"
                        size="large"
                        options={masterCatalogOptions}
                        disabled={referenceBlockVisiblity}
                      ></Select>
                    </Form.Item>
                  </Col>
                );
              } else if (suggestion_list === 'list_parent') {
                return (
                  <Col className="w-full" xs={24} md={8}>
                    <Form.Item
                      label="Select Parent Price List"
                      name="parent_price_list"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Select
                        placeholder="Select an option"
                        size="large"
                        options={paretListDropDown}
                        disabled={referenceBlockVisiblity}
                      ></Select>
                    </Form.Item>
                  </Col>
                );
              } else {
                return (
                  <Col className="w-full" xs={24} md={8}>
                    <Form.Item
                      label="Enter Catalog Ref Name"
                      name="select_from_master_catalog_TextBox"
                      rules={[
                        {
                          required: true,
                          message: "This field can't be empty"
                        }
                      ]}
                    >
                      <Input
                        size="large"
                        className="w-[100%]"
                        placeholder="Enter Value for Catalog Ref"
                        disabled={!isformVisible}
                      />
                    </Form.Item>
                  </Col>
                );
              }
            }}
          </Form.Item>
        </Row>

        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="Is Active?"
              name="is_active"
              rules={[{ required: false, message: "This field can't be empty" }]}
            >
              <Radio.Group onChange={handleOnProductStatus} disabled={!isformVisible}>
                <Radio value={'active'}>Yes</Radio>
                <Radio value={'deactive'}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <div>
          <Row>
            <Typography>Is Price List Exclusive?</Typography>&nbsp;&nbsp;
            <Tooltip
              placement="top"
              title={
                'Customer can only view and purchase products that are explicility included in the price list and any of its parents'
              }
            >
              <InfoCircleOutlined style={{ color: '#1b6ef3' }} />
            </Tooltip>
          </Row>
          <Form.Item
            label=""
            name="isPriceListExclusive"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <Radio.Group onChange={onChangePriceListExe} disabled={!isformVisible}>
              <Radio value={'priceListExe_yes'}>Yes</Radio>
              <Radio value={'priceListExe_no'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div>
          <Row gutter={12}>
            <Typography>Is Price List resolvable?</Typography>&nbsp;&nbsp;
            <Tooltip
              placement="top"
              title={`when resolvable is 'False' then the price list is only used for inheritance i.e. price list only exists as another price list's parent`}
            >
              <InfoCircleOutlined style={{ color: '#1b6ef3' }} />
            </Tooltip>
          </Row>
          <Form.Item
            label=""
            name="isPriceListResolvable"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <Radio.Group onChange={onChangePriceListResovable} disabled={!isformVisible}>
              <Radio value={'priceListRes_yes'}>Yes</Radio>
              <Radio value={'priceListRes_no'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          return prevValues.POS !== curValues.POS;
        }}
      >
        {form => {
          const { isPriceListResolvable } = form.getFieldsValue();
          return isPriceListResolvable === 'priceListRes_yes' ? (
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Row justify={'space-between'} className="mb-4">
                <Col>
                  <Typography.Title level={5} className="text-[#2e2a5b]">
                    Qualifying Conditions
                  </Typography.Title>
                </Col>
              </Row>
              <Row>
                <Col>Channel restriction</Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name="POS" valuePropName="checked">
                    <Checkbox value={'pos'} disabled={!isformVisible}>
                      POS
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.POS !== curValues.POS;
                  }}
                >
                  {form => {
                    const { POS } = form.getFieldsValue();
                    return POS ? (
                      <div className="flex ">
                        <Col xs={18} md={24} className="w-[225px]">
                          <Form.Item
                            label="Specific POS"
                            name={'specific_pos'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              size="large"
                              placeholder="Select one or more specific pos"
                              options={pos}
                              defaultValue={pos}
                              disabled={!isformVisible}
                            ></Select>
                          </Form.Item>
                        </Col>
                      </div>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6} className="w-[225px]">
                  <Form.Item name="Ecommerce" valuePropName="checked">
                    <Checkbox value={'ecommerce'} disabled={!isformVisible}>
                      Ecommerce
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={40}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.Ecommerce !== curValues.Ecommerce;
                  }}
                >
                  {form => {
                    const { Ecommerce } = form.getFieldsValue();
                    return Ecommerce ? (
                      <div className="flex ">
                        <Col xs={18} md={24} className="w-[240px]">
                          <Form.Item
                            label="Specific Ecommerce"
                            name={'specific_ecommerce'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              size="large"
                              placeholder="Select one or more Ecommerce"
                              options={pos}
                              disabled={!isformVisible}
                            ></Select>
                          </Form.Item>
                        </Col>
                      </div>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
              <Form.List name="customer_restrictions_filters">
                {(fields, { add, remove }) => {
                  //!NOT SURE WHY THIS NEEDED
                  // const removeAddItem = (e: any, name: any) => {
                  //   let deletedValue =
                  //     basicDetailsForm.getFieldsValue()?.customer_restrictions_filters[name]?.restriction_value;
                  //   let allTire = tireLevelOptions;
                  //   if (deletedValue) {
                  //     if (
                  //       !tireOptions.some((obj: any) => obj.label === deletedValue) &&
                  //       allTire.some((obj: any) => obj.label === deletedValue)
                  //     ) {
                  //       let matchingObject = allTire.find((obj: any) => obj.label === deletedValue);
                  //       setTireOptions([matchingObject, ...tireOptions]);
                  //     }
                  //   }
                  //   remove(name);
                  // };
                  return (
                    <>
                      <Typography.Title level={5} className="text-[#2e2a5b] m-0">
                        Customer Restriction:
                      </Typography.Title>
                      {fields.map(({ key, name, ...restField }, index) => {
                        return (
                          <React.Fragment key={key}>
                            {' '}
                            <Row gutter={12}>
                              <Col xs={24} md={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'restriction_type']}
                                  label="Type"
                                  rules={[{ required: true, message: "This field can't be empty" }]}
                                >
                                  <Select
                                    allowClear
                                    options={[
                                      { label: 'Tier Level', value: 'TIER' },
                                      { label: 'Segments', value: 'SEGMENT' }
                                    ]}
                                    size="large"
                                    placeholder="Select Discount Type"
                                    disabled={!isformVisible}
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Form.Item noStyle shouldUpdate>
                                {form => {
                                  const isDataPresent = form.getFieldsValue()?.customer_restrictions_filters?.length;
                                  const attributeType = isDataPresent
                                    ? form.getFieldsValue()?.customer_restrictions_filters[index]?.restriction_type
                                    : '';
                                  const inputMap: Record<string, any> = {
                                    TIER: {
                                      name: 'restriction_value',
                                      label: 'Tier Restriction',
                                      component: (
                                        <Select
                                          placeholder="Select"
                                          size="large"
                                          disabled={!isformVisible}
                                          options={[
                                            { label: 'Everyone', value: 'EVERYONE' },
                                            { label: 'Standard', value: 'Standard' },
                                            { label: 'Gold', value: 'Gold' },
                                            { label: 'Silver', value: 'Silver' },
                                            { label: 'Bronze', value: 'Bronze' }
                                          ]}
                                        ></Select>
                                      )
                                    },
                                    SEGMENT: {
                                      name: 'restriction_value',
                                      label: 'Segment',
                                      component: (
                                        <Select
                                          placeholder="Select / Enter"
                                          mode="tags"
                                          size="large"
                                          disabled={!isformVisible}
                                          options={[]}
                                        ></Select>
                                      )
                                    }
                                  };

                                  const componentInfo = inputMap[attributeType];
                                  return (
                                    componentInfo && (
                                      <Col xs={24} md={8}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, componentInfo?.name]}
                                          label={componentInfo?.label}
                                          rules={[
                                            {
                                              required: true,
                                              message: `${componentInfo?.label} is required`
                                            }
                                          ]}
                                        >
                                          {componentInfo?.component}
                                        </Form.Item>
                                      </Col>
                                    )
                                  );
                                }}
                              </Form.Item>
                              {!!isformVisible && (
                                <div className="w-full md:w-[50px] md:mx-8 flex justify-center items-center">
                                  <DeleteOutlined
                                    className="text-red-500 text-xl"
                                    title={`Delete`}
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              )}
                            </Row>
                          </React.Fragment>
                        );
                      })}
                      <Form.Item>
                        <Row justify={'start'}>
                          <Col xs={24} md={4}>
                            <Button disabled={!isformVisible} onClick={() => add()} icon={<PlusOutlined />}>
                              <BoldButtonLabel labelText="Add Customer Restriction" />
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>
          ) : null;
        }}
      </Form.Item>
      <Row gutter={[12, 12]}>
        {isformVisible && (
          <Col xs={24} md={6}>
            <Button htmlType="submit" block type="primary" size="large" className="mb-4">
              <BoldButtonLabel labelText="Save" />
            </Button>
          </Col>
        )}
        {isformVisible && (
          <Col xs={24} md={6}>
            <Button size="large" block className="mb-4">
              <BoldButtonLabel labelText="Reset" />
            </Button>
          </Col>
        )}
        <Col xs={24} md={6}>
          <Button size="large" block className="mb-4" onClick={handleGoBack}>
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PriceListBasicDetails;
