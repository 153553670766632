import * as React from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Typography
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { currencyList } from '../../../../data/intl-data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProductCategoryTreeSelect from './product-category-tree-select';
import DisplayCategoryTree from './display-category-tree';
import { useLoader } from '../../../../stores/use-loader';
import { productService } from '../../services';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { validationHelpers } from '../../../../helpers';
import ProductCategoryAccordion from './product-category-accordion';

interface IShippingProfileFormProps {
  shippingProfileResponse?: any;
  isUpdate?: boolean;
  formInstance: FormInstance;
  readOnly?: boolean;
  mandtCreation?: boolean;
  renderState?: boolean;
  BasicDetailsFormInstance?: FormInstance;
  renderTree?: () => any;
  shippingProfileTree?: () => void;
  onFinish?: () => void;
  availableSku?: any;
  handleOnCloseCategory?: (product: any, category: any) => void;
  handleOnDetailsUpdate?: (e: any) => void;
  saveResp?: any
}

const shippingChargeTypes: { label: string; value: string }[] = [
  { label: 'Free', value: 'FREE' },
  { label: 'Flat', value: 'FLAT' },
  { label: 'Base Weight', value: 'BASE_WEIGHT' },
  { label: 'Slab Weight', value: 'SLAB_WEIGHT' },
  { label: 'Order Price', value: 'ORDER_PRICE' }
];

const ShippingProfileForm: React.FunctionComponent<IShippingProfileFormProps> = ({
  formInstance,
  isUpdate,
  readOnly,
  mandtCreation,
  BasicDetailsFormInstance,
  handleOnDetailsUpdate,
  renderTree,
  availableSku,
  shippingProfileResponse,
  renderState,
  shippingProfileTree,
  handleOnCloseCategory,
  onFinish,
  saveResp
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();

  const [showProductModal, setShowProductModal] = React.useState(false);
  const [catagoryRespData, setCategoryRespData] = React.useState({} as any)

  const handleOnCloseCategorySelection = async (product: any, category: any) => {
    setLoading(true);
    handleOnCloseCategory && handleOnCloseCategory(product, category);
    setShowProductModal(false);
    setLoading(false);
  };

  const handleModalClose = () => {
    setShowProductModal(false);
  }

  const fetchAllCategories = async (page: number) => {
    setLoading(true);
    let params = {
      page: page,
      search_key: ''
    }
    const { data, errors } = await productService.getAllCategories(params);
    if (_.isEmpty(errors)) {
      let modifyCategory = data?.categories?.map((item: any, index: any) => {
        let presentInResponse = saveResp?.categories?.includes(item.category)
        return {
          ...item,
          key: index,
          available: presentInResponse,
          children: {}
        }
      })
      let modifiedData = {
        ...data,
        categories: modifyCategory
      }
      setCategoryRespData(modifiedData)
      setShowProductModal(true)
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  }

  const handleOnPageUpdate = (page: number) => {
    // setShowProductModal(false)
    fetchAllCategories(page)
  }

  return (
    <Form form={formInstance} layout="vertical" onFinish={onFinish} disabled={readOnly}>
      <Card className="my-2">
        <Row gutter={12}>
          <Col xs={24} md={8} lg={6}>
            <Form.Item
              name={'shipping_profile_name'}
              label="Shipping Profile Name"
              rules={[
                validationHelpers.getRequiredValidator({
                  message: 'This field is required'
                })
              ]}
            >
              <Input size="large" placeholder="Shipping Profile Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'shipping_profile_type'} label="Shipping Profile Type">
              <Select
                disabled={mandtCreation}
                allowClear
                size="large"
                placeholder="Select shipping profile type"
                options={[
                  { label: 'Default', value: 'DEFAULT' },
                  { label: 'Custom', value: 'CUSTOM' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'is_active'} label="Status :">
              <Radio.Group>
                <Radio value={true}>Active ?</Radio>
                <Radio value={false}>InActive ?</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={'is_local_delivery_available'} label="Local Delivery :">
              <Radio.Group>
                <Radio value={true}>Available</Radio>
                <Radio value={false}>UnAvailable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={16} lg={12}>
            <Form.Item name={'description'} label="Description">
              <Input.TextArea size="large" rows={2} placeholder="Enter description"></Input.TextArea>
            </Form.Item>
          </Col>
        </Row>{' '}
      </Card>
      <Card className="my-2">
        <Typography className="text-[#2e2a5b]">Product & Category Information create</Typography>
        <section className="my-2">
          <Row gutter={12}>
            <Col xs={24} md={8} lg={6}>
              <div className="flex gap-2 items-center h-full">
                <Button
                  size="large"
                  onClick={() => { fetchAllCategories(1) }}
                // onClick={() => setShowProductModal(true)}
                >
                  <BoldButtonLabel labelText="Add Products / Categories" />
                </Button>
              </div>
            </Col>
          </Row>
        </section>
      </Card>
      <Card className="my-2">
        <section>
          <Typography.Title level={5} className="text-[#2e2a5b]">
            Shipping Charges :
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                name={['shipping_charge', 'name']}
                label="Shipping Charges Name"
                rules={[
                  // validationHelpers.getRequiredValidator({
                  //   message: 'This field is required'
                  // })
                ]}
              >
                <Input size="large" placeholder="Shipping Charges Name"></Input>
              </Form.Item>
            </Col>

            <Col xs={24} md={8} lg={6}>
              <Form.Item
                name={['currency']}
                label="Currency"
                rules={[
                  // validationHelpers.getRequiredValidator({
                  //   message: 'This field is required'
                  // })
                ]}
              >
                <Select allowClear size="large" placeholder="Select currency" options={currencyList}></Select>
              </Form.Item>
            </Col>
          </Row>{' '}
          <Row>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                name={['shipping_charge', 'shipping_charge_type']}
                label="Shipping Charge Type"
                rules={[
                  // validationHelpers.getRequiredValidator({
                  //   message: 'This field is required'
                  // })
                ]}
              >
                <Select allowClear size="large" placeholder="Select type" options={shippingChargeTypes}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev?.shipping_charge?.shipping_charge_type !== curr.shipping_charge?.shipping_charge_type
            }
          >
            {form => {
              const { shipping_charge } = form.getFieldsValue();
              const chargeType = shipping_charge?.shipping_charge_type;

              if (chargeType === 'FLAT') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Flat charge configuration
                    </Typography.Title>
                    <Row gutter={12}>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item
                          name={['flat_charge_amount']}
                          label="Flat charge amount"
                          rules={[
                            validationHelpers.getRequiredValidator({
                              message: 'This field is required'
                            })
                          ]}
                        >
                          <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item
                          name={['flat_charge_fraction']}
                          label="Flat charge Fraction"
                          rules={[{ required: true, message: 'Field is required' }]}
                        >
                          <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                );
              }

              if (chargeType === 'BASE_WEIGHT') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Base weight charge configuration
                    </Typography.Title>
                    <>
                      <Row gutter={12}>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item
                            name={['base_weight_in_kg']}
                            label="Base weight in KG"
                            rules={[
                              validationHelpers.getRequiredValidator({
                                message: 'This field is required'
                              })
                            ]}
                          >
                            <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item
                            name={['base_weight_charge_amount']}
                            label="Amount"
                            rules={[
                              validationHelpers.getRequiredValidator({
                                message: 'This field is required'
                              })
                            ]}
                          >
                            <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item
                            name={['base_weight_charge_fraction']}
                            label="Fraction"
                            rules={[{ required: true, message: 'Field is required' }]}
                          >
                            <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item
                            name={['additional_weight_in_kg']}
                            label="Additional weight in KG"
                            rules={[
                              validationHelpers.getRequiredValidator({
                                message: 'This field is required'
                              })
                            ]}
                          >
                            <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item
                            name={['additional_weight_charge_amount']}
                            label="Amount"
                            rules={[
                              validationHelpers.getRequiredValidator({
                                message: 'This field is required'
                              })
                            ]}
                          >
                            <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item
                            name={['additional_base_weight_charge_fraction']}
                            label="Fraction"
                            rules={[{ required: true, message: 'Field is required' }]}
                          >
                            <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  </>
                );
              }

              if (chargeType === 'SLAB_WEIGHT') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Slab weight charge configuration
                    </Typography.Title>

                    <Form.List name="slab_weight_conditions">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => {
                            return (
                              <React.Fragment key={key}>
                                <Row>
                                  <Col xs={12}>
                                    <Divider orientation="left" plain>
                                      <span className="text-lg">Condition - {index + 1} </span>
                                    </Divider>
                                  </Col>
                                </Row>

                                <Row gutter={12}>
                                  <Col xs={12} md={5}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'min_weight_in_kg']}
                                      rules={[{ required: true, message: 'Min weight in KG is required' }]}
                                      label="Min weight in KG"
                                    >
                                      <Input className="w-full" size="large" placeholder="Enter weight"></Input>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={5}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'max_weight_in_kg']}
                                      rules={[{ required: true, message: 'Max weight in KG is required' }]}
                                      label="Max weight in KG"
                                    >
                                      <Input className="w-full" size="large" placeholder="Enter weight"></Input>
                                    </Form.Item>
                                  </Col>{' '}
                                  <Col xs={12} md={5}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'cost']}
                                      rules={[{ required: true, message: 'Cost is required' }]}
                                      label="Cost"
                                    >
                                      <Input className="w-full" size="large" placeholder="Enter cost"></Input>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={8} lg={6}>
                                    <Form.Item
                                      name={[name, 'fraction']}
                                      label="Slab charge Fraction"
                                      rules={[{ required: true, message: 'Field is required' }]}
                                    >
                                      <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                                    </Form.Item>
                                  </Col>
                                  <div className="ml-2 my-2 flex justify-center items-center">
                                    <DeleteOutlined
                                      className="text-red-500 text-xl"
                                      title="Delete condition"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                </Row>
                              </React.Fragment>
                            );
                          })}

                          <Form.Item>
                            <Row justify={'start'}>
                              <Col xs={24} md={4}>
                                <Button onClick={() => add()} icon={<PlusOutlined />}>
                                  <BoldButtonLabel labelText="Add condition" />
                                </Button>
                              </Col>
                            </Row>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </>
                );
              }

              if (chargeType === 'ORDER_PRICE') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Order price charge configuration
                    </Typography.Title>

                    <Form.List name="order_price_conditions">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => {
                            return (
                              <React.Fragment key={key}>
                                <Row>
                                  <Col xs={12}>
                                    <Divider orientation="left" plain>
                                      <span className="text-lg">Condition - {index + 1} </span>
                                    </Divider>
                                  </Col>
                                </Row>

                                <Row gutter={12}>
                                  <Col xs={12} md={5}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'min_price']}
                                      rules={[{ required: true, message: 'Min order price is required' }]}
                                      label="Minimum Order Price"
                                    >
                                      <Input className="w-full" size="large" placeholder="Enter price"></Input>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={5}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'max_price']}
                                      rules={[{ required: true, message: 'Max order price is required' }]}
                                      label="Maximum Order Price"
                                    >
                                      <Input className="w-full" size="large" placeholder="Enter price"></Input>
                                    </Form.Item>
                                  </Col>{' '}
                                  <Col xs={12} md={5}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'cost']}
                                      rules={[{ required: true, message: 'Cost is required' }]}
                                      label="Cost"
                                    >
                                      <Input className="w-full" size="large" placeholder="Enter cost"></Input>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={5}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'fraction']}
                                      rules={[{ required: true, message: 'Fraction is required' }]}
                                      label="Fraction"
                                    >
                                      <Input className="w-full" size="large" placeholder="Enter Fraction"></Input>
                                    </Form.Item>
                                  </Col>
                                  <div className="ml-2 my-2 flex justify-center items-center">
                                    <DeleteOutlined
                                      className="text-red-500 text-xl"
                                      title="Delete condition"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                </Row>
                              </React.Fragment>
                            );
                          })}

                          <Form.Item>
                            <Row justify={'start'}>
                              <Col xs={24} md={4}>
                                <Button onClick={() => add()} icon={<PlusOutlined />}>
                                  <BoldButtonLabel labelText="Add condition" />
                                </Button>
                              </Col>
                            </Row>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </>
                );
              }

              return null;
            }}
          </Form.Item>
        </section>{' '}
        <Row gutter={[12, 12]}>
          {!readOnly && (
            <Col xs={24} md={8} lg={6}>
              <Button size="large" htmlType="submit" block type="primary">
                <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'} />
              </Button>
            </Col>
          )}
          <Col xs={24} md={8} lg={6}>
            <Button
              size="large"
              disabled={false}
              block
              onClick={() => {
                if (searchParams.get('backUrl')) {
                  navigate(searchParams.get('backUrl') as string);
                } else {
                  navigate(-1);
                }
              }}
            >
              <BoldButtonLabel labelText="Go Back" />
            </Button>
          </Col>
        </Row>
        <Modal
          maskClosable={false}
          width={900}
          centered
          open={showProductModal}
          onCancel={handleModalClose}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Select Categories & Product</Typography.Title>
            </div>
          }
        >
          {/* <ProductCategoryTreeSelect
            handleOnCloseCategorySelection={handleOnCloseCategorySelection}
            selectedSku={availableSku}
          /> */}
          <ProductCategoryAccordion catagoryRespData={catagoryRespData} saveResp={saveResp} handleOnCloseCategorySelection={handleOnCloseCategorySelection} handleOnPageUpdate={handleOnPageUpdate} />
        </Modal>
      </Card>
    </Form>
  );
};

export default ShippingProfileForm;
