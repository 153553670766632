import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import merge from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import mergeWith from 'lodash.mergewith';
import debounce from 'lodash.debounce';
import groupBy from 'lodash.groupby';

export const replaceArrayValueWithSourceCustomiser = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
};
const customMergeWithArrayReplace = (object: any, sources: any) => {
  return mergeWith(object, sources, replaceArrayValueWithSourceCustomiser);
};

const _ = {
  isEmpty,
  get,
  isEqual,
  merge,
  customMergeWithArrayReplace,
  cloneDeep,
  debounce,
  groupBy
};

export default _;
