import React from 'react';
import { promotionService } from '../../services/promotion.service';
import _ from '../../../../helpers/lodash';

const cache: Record<string, string[]> = {};

export const usePos = () => {
  const [posDropdownFetching, setPosDropdownFetching] = React.useState(false);
  const [posDropdownOptions, setPosDropdownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchPosDropdownOptions = async (searchQuery: string) => {
    setPosDropdownFetching(true);

    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setPosDropdownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await promotionService.getLocations(searchQuery, 'STORE');
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setPosDropdownOptions(() => {
          return [...transformedResponse];
        });
      }
    }
    setPosDropdownFetching(false);
  };

  const transformResponse = (data: any) => {
    return (data || []).map((value: string) => {
      return { label: value, value };
    });
  };

  const debouncedFetchPosDropdownOptions = _.debounce(fetchPosDropdownOptions, 1000);

  return {
    debouncedFetchPosDropdownOptions,
    posDropdownFetching,
    posDropdownOptions
  };
};
