import { OMS_DASHBOARD, OMS_PROMISE_DASHBOARD } from "../../../../config/envConfig";
import PrimaryLayout from "../../../../layouts/primary-layout"
import { useAuth } from "../../../../stores/use-auth";

interface IPromiseDashboard { }

const PromiseDashboard: React.FunctionComponent<IPromiseDashboard> = props => {
    const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
        tenentName,
        bussinessGroupName
      }));
    
      let Url = `${OMS_PROMISE_DASHBOARD}/${tenentName}/${bussinessGroupName}`;
    return (
        <PrimaryLayout>
        <iframe title="Igad" style={{ height: '100%', width: '100%', border: 0 }} src={Url}></iframe>
      </PrimaryLayout>
    )
}

export default PromiseDashboard