import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertQueryStringToObj, fileHelpers, objectHelpers } from '../../../helpers';
import TenantInfo from '../../../components/TenantIdInfo';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { IPromotionListing } from '../types/promotion-report-types';
import ActionButton from '../../../components/ActionButton';
import CustomPagination from '../../../components/custom-pagination';
import _ from 'lodash';
import { useLoader } from '../../../stores/use-loader';
import { promotionReportService } from '../services/promotionreport.service';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';

interface IPromotionReportProps {}

const dummyListingData = {
  count: 9,
  customer_redeemption: [
    {
      customer_id: '123',
      total_redemption: '200',
      total_redemption_value: '500'
    }
  ],
  page_info: {
    current_page: 1,
    total_pages: 1
  }
};

const PromotionReport: React.FunctionComponent<IPromotionReportProps> = props => {
  const navigate = useNavigate();
  const [PromotionReportForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [promotionReportResponse, setPromotionReportResponse] = useState({} as any);

  const columns: TableColumnsType<IPromotionListing> = [
    {
      title: 'Customer ID',
      render(value, record, index) {
        return <Typography.Text>{record.customer_id}</Typography.Text>;
      },
      align: 'center'
    },
    {
      title: 'Total Redemption',
      render(value, record, index) {
        return <Typography.Text>{record.total_redemption}</Typography.Text>;
      },
      align: 'center'
    },
    {
      title: 'Total Redemption Value',
      render(value, record, index) {
        return <Typography.Text>{record.total_redemption_value}</Typography.Text>;
      },
      align: 'center'
    },
    {
      title: <></>,
      render(value, record, index) {
        return (
          <>
            <ActionButton
              action="VIEW"
              title="View Details"
              onClick={() => handleOnRedemptionClick(record)}
            ></ActionButton>
          </>
        );
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, [searchParams]);

  const loadInitialData = async () => {
    const page_number = parseInt(searchParams.get('page_number') || '1');
    const page_size = parseInt(searchParams.get('page_size') || '10');
    await handleSearch(page_number - 1, page_size);
  };

  const handleSearch = async (offset = 0, limit = 10) => {
    setLoading(true);
    const formValues = PromotionReportForm.getFieldsValue();
    let params = { offset, limit, ...formValues };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const { data, errors } = await promotionReportService.getReport(filteredParams);
    if (_.isEmpty(errors)) {
      setPromotionReportResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnRedemptionClick = (row: IPromotionListing) => {
    const { customer_id } = row;
    navigate(`/promotion-engine/promotions/report/redemptionDetails/${customer_id}`);
  };

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
  };

  const getReportData = async () => {
    setLoading(true);
    const { data, errors } = await promotionReportService.DownloadReport();
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'Redemption History', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Form
            layout="vertical"
            form={PromotionReportForm}
            onFinish={() => {
              if (parseInt(searchParams.get('page_number') || '') === 1) {
                const page_size = parseInt(searchParams.get('page_size') || '10');
                handleSearch(0, page_size);
              } else {
                setSearchParams(prev => {
                  prev.set('page_number', '1');
                  return prev;
                });
              }
            }}
          >
            <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Redemption History
                </Typography.Title>
              </Col>
              <Col xs={24} md={6} lg={4}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    getReportData();
                  }}
                  block
                >
                  <BoldButtonLabel labelText="Download Report" />
                </Button>
              </Col>
            </Row>
            <TenantInfo />
            <section className="mt-4">
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name="customer_id" label="Customer Id">
                    <Input placeholder="Customer Id" size="large" />
                  </Form.Item>
                </Col>
                {/* <Col xs={12} md={6}>
                  <Form.Item name="date_range" label="Date">
                    <DatePicker.RangePicker size="large" />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={12} md={6}>
                  <Form.Item name="status" label="Status">
                    <Select
                      placeholder="Status"
                      size="large"
                      allowClear
                      options={[
                        { label: 'Reserved', value: 'RESERVED' },
                        { label: 'Active', value: 'ACTIVE' },
                        { label: 'In-Active', value: 'INACTIVE' },
                        { label: 'Redeemed', value: 'REDEEMED' },
                        { label: 'Expired', value: 'EXPIRED' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Button htmlType="submit" type="primary" size="large">
                    <BoldButtonLabel labelText="Search" />
                  </Button>
                </Col>
              </Row>
            </section>
            <section className="mt-4">
              {/* {!_.isEmpty(promotionReportResponse.customer_redeemption) && (
                <div className="flex justify-end my-2">
                  <CustomPagination
                    currentPage={promotionReportResponse.page_info.current_page}
                    totalPages={promotionReportResponse.page_info.total_pages}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </div>
              )}{' '} */}
              <Table
                loading={false}
                bordered
                pagination={{
                  current: parseInt(searchParams.get('page_number') as string) || 1,
                  pageSize: parseInt(searchParams.get('page_size') as string) || 10,
                  total: promotionReportResponse?.page_info?.total_pages
                    ? promotionReportResponse.page_info?.total_pages *
                        parseInt(searchParams.get('page_size') as string) || 10
                    : 0,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  onChange: (currentPage, pageSize) => {
                    setSearchParams(prev => {
                      prev.set('page_number', currentPage + '');
                      prev.set('page_size', pageSize + '');
                      return prev;
                    });
                  }
                }}
                className="mt-4"
                dataSource={promotionReportResponse.customer_redemption || []} //promotionReportResponse
                columns={columns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PromotionReport;
