import { Button, Card, Col, Divider, Form, FormInstance, Input, InputNumber, Row, Select, Typography } from 'antd';
import React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { currencyList } from '../../../../data/intl-data';

interface IShippingChargesForm {
  shippingFormInstance: FormInstance;
  readOnly?: boolean;
  handleOnUpdateCharges?: () => void;
}

const shippingChargeTypes: { label: string; value: string }[] = [
  { label: 'Free', value: 'FREE' },
  { label: 'Flat', value: 'FLAT' },
  { label: 'Base Weight', value: 'BASE_WEIGHT' },
  { label: 'Slab Weight', value: 'SLAB_WEIGHT' },
  { label: 'Order Price', value: 'ORDER_PRICE' }
];

const ShippingChargesForm: React.FunctionComponent<IShippingChargesForm> = ({
  shippingFormInstance,
  readOnly,
  handleOnUpdateCharges
}) => {
  return (
    <Form form={shippingFormInstance} layout="vertical" disabled={readOnly} onFinish={handleOnUpdateCharges}>
      <Card className="my-2">
        <section>
          <Typography.Title level={5} className="text-[#2e2a5b]">
            Shipping Charges :
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={24} md={8} lg={6}>
              <Form.Item name={['shipping_charge', 'name']} label="Shipping Charges Name">
                <Input size="large" placeholder="Shipping Charges Name"></Input>
              </Form.Item>
            </Col>

            <Col xs={24} md={8} lg={6}>
              <Form.Item name={['currency']} label="Currency">
                <Select allowClear size="large" placeholder="Select currency" options={currencyList}></Select>
              </Form.Item>
            </Col>
          </Row>{' '}
          <Row>
            <Col xs={24} md={8} lg={6}>
              <Form.Item name={['shipping_charge', 'shipping_charge_type']} label="Shipping Charge Type">
                <Select allowClear size="large" placeholder="Select type" options={shippingChargeTypes}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev?.shipping_charge?.shipping_charge_type !== curr.shipping_charge?.shipping_charge_type
            }
          >
            {form => {
              const { shipping_charge } = form.getFieldsValue();
              const chargeType = shipping_charge?.shipping_charge_type;

              if (chargeType === 'FLAT') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Flat charge configuration
                    </Typography.Title>
                    <Row gutter={12}>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['flat_charge_amount']} label="Flat charge amount">
                          <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['flat_charge_fraction']} label="Flat charge Fraction">
                          <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                );
              }

              if (chargeType === 'BASE_WEIGHT') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Base weight charge configuration
                    </Typography.Title>
                    <>
                      <Row gutter={12}>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item name={['base_weight_in_kg']} label="Base weight in KG">
                            <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item name={['base_weight_charge_amount']} label="Amount">
                            <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item name={['base_weight_charge_fraction']} label="Fraction">
                            <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item name={['additional_weight_in_kg']} label="Additional weight in KG">
                            <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item name={['additional_weight_charge_amount']} label="Amount">
                            <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item name={['additional_base_weight_charge_fraction']} label="Fraction">
                            <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  </>
                );
              }

              if (chargeType === 'SLAB_WEIGHT') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Slab weight charge configuration
                    </Typography.Title>

                    <>
                      <Form.List name="slab_weight_conditions">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => {
                              return (
                                <React.Fragment key={key}>
                                  <Row>
                                    <Col xs={12}>
                                      <Divider orientation="left" plain>
                                        <span className="text-lg">Condition - {index + 1} </span>
                                      </Divider>
                                    </Col>
                                  </Row>

                                  <Row gutter={12}>
                                    <Col xs={12} md={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'min_weight_in_kg']}
                                        rules={[{ required: true, message: 'Min weight in KG is required' }]}
                                        label="Min weight in KG"
                                      >
                                        <Input className="w-full" size="large" placeholder="Enter weight"></Input>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={12} md={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'max_weight_in_kg']}
                                        rules={[{ required: true, message: 'Max weight in KG is required' }]}
                                        label="Max weight in KG"
                                      >
                                        <Input className="w-full" size="large" placeholder="Enter weight"></Input>
                                      </Form.Item>
                                    </Col>{' '}
                                    <Col xs={12} md={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'cost']}
                                        rules={[{ required: true, message: 'Cost is required' }]}
                                        label="Cost"
                                      >
                                        <Input className="w-full" size="large" placeholder="Enter cost"></Input>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                      <Form.Item name={[name, 'fraction']} label="Slab charge Fraction">
                                        <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                                      </Form.Item>
                                    </Col>
                                    <div className="ml-2 my-2 flex justify-center items-center">
                                      <DeleteOutlined
                                        className="text-red-500 text-xl"
                                        title="Delete condition"
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                  </Row>
                                </React.Fragment>
                              );
                            })}

                            <Form.Item>
                              <Row justify={'start'}>
                                <Col xs={24} md={4}>
                                  <Button onClick={() => add()} icon={<PlusOutlined />}>
                                    <BoldButtonLabel labelText="Add condition" />
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </>
                  </>
                );
              }

              if (chargeType === 'ORDER_PRICE') {
                return (
                  <>
                    <Typography.Title level={5} className="text-[#2e2a5b]">
                      Order price charge configuration
                    </Typography.Title>

                    <>
                      <Form.List name="order_price_conditions">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => {
                              return (
                                <React.Fragment key={key}>
                                  <Row>
                                    <Col xs={12}>
                                      <Divider orientation="left" plain>
                                        <span className="text-lg">Condition - {index + 1} </span>
                                      </Divider>
                                    </Col>
                                  </Row>

                                  <Row gutter={12}>
                                    <Col xs={12} md={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'min_price']}
                                        rules={[{ required: true, message: 'Min order price is required' }]}
                                        label="Minimum Order Price"
                                      >
                                        <Input className="w-full" size="large" placeholder="Enter price"></Input>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={12} md={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'max_price']}
                                        rules={[{ required: true, message: 'Max order price is required' }]}
                                        label="Maximum Order Price"
                                      >
                                        <Input className="w-full" size="large" placeholder="Enter price"></Input>
                                      </Form.Item>
                                    </Col>{' '}
                                    <Col xs={12} md={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'cost']}
                                        rules={[{ required: true, message: 'Cost is required' }]}
                                        label="Cost"
                                      >
                                        <Input className="w-full" size="large" placeholder="Enter cost"></Input>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={12} md={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'fraction']}
                                        rules={[{ required: true, message: 'Cost is required' }]}
                                        label="Fraction"
                                      >
                                        <Input className="w-full" size="large" placeholder="Enter Fraction"></Input>
                                      </Form.Item>
                                    </Col>
                                    <div className="ml-2 my-2 flex justify-center items-center">
                                      <DeleteOutlined
                                        className="text-red-500 text-xl"
                                        title="Delete condition"
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                  </Row>
                                </React.Fragment>
                              );
                            })}

                            <Form.Item>
                              <Row justify={'start'}>
                                <Col xs={24} md={4}>
                                  <Button onClick={() => add()} icon={<PlusOutlined />}>
                                    <BoldButtonLabel labelText="Add condition" />
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </>
                  </>
                );
              }

              return null;
            }}
          </Form.Item>
        </section>{' '}
        <Row gutter={[12, 12]} justify={'end'}>
          <Col xs={24} md={8} lg={6}>
            <Button size="large" htmlType="submit" block type="primary">
              <BoldButtonLabel labelText={'Update Charges'} />
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ShippingChargesForm;
