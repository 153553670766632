import { fetchReqData } from "./file.helpers";

export const getFullName = (orderDetails: any) => {
  let data = fetchReqData(orderDetails?.address, 'address_tag', 'buyer-details')
  const parts = [data?.first_name, data?.middle_name, data?.last_name].filter(Boolean);
  return parts.join(' ');
};

export function convertToTitleCase(inputString: string): string {
  // Split the input string at underscores
  const words = inputString?.split('_');

  // Capitalize each word
  const capitalizedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase());

  // Join the capitalized words back together with spaces
  const result = capitalizedWords?.join(' ');

  return result;
}
