import * as React from 'react';

import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Select, Switch, Table, TableColumnsType, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertQueryStringToObj, objectHelpers, urlHelpers } from '../../../../helpers';
import { useLoader } from '../../../../stores/use-loader';
import _ from '../../../../helpers/lodash';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import CustomPagination from '../../../../components/custom-pagination';
import ActionButton from '../../../../components/ActionButton';
import { logisticService } from '../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { useFulfillmentType } from '../../../../stores/use-order';

interface ISnnListingProps {}

const SnnListing: React.FunctionComponent<ISnnListingProps> = props => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [snnListingResponse, setSnnListingResponse] = React.useState({} as any);
  const { setFulfillmentType } = useFulfillmentType();
  const [currentPage, setCurrentPage] = React.useState<number>(() => {
    const page = parseInt(searchParams.get('offset') || '0');
    return page > 0 ? page + 1 : 1;
  });
  const [pageSize, setPageSize] = React.useState<number>(() => {
    const limit = parseInt(searchParams.get('limit') || '10');
    return limit > 0 ? limit : 10;
  });
  const [searchForm] = Form.useForm();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const loadInitialData = async () => {
    await handleFilterSearch(currentPage - 1);
  };

  React.useEffect(() => {
    if (currentPage && pageSize) loadInitialData();
  }, [currentPage, pageSize]);

  React.useEffect(() => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
  }, [searchParams, searchForm]);

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = {
      ...formValues,
      offset,
      limit: pageSize,
      'fulfilment-type': formValues['fulfilment-type'] || 'SALES'
    };
    setFulfillmentType(params['fulfilment-type']);

    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await logisticService.getSNN(filteredParams);
    if (_.isEmpty(errors)) {
      setSnnListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const backUrlParamString = urlHelpers.getBackUrlParamString(searchParams);

  const columns: TableColumnsType<any> = [
    {
      title: 'SNN Name',
      render(value, record, index) {
        return <>{record.snn_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Description',
      render(value, record, index) {
        return <>{record.description}</>;
      },
      align: 'center'
    },

    {
      title: 'Shipping Profiles Configured',
      render(value, record, index) {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(
                `/logistics/shipping-profile/${record.snn_name}?snn_id=${record.id}&fulfilment-type=${searchParams.get(
                  'fulfilment-type'
                )}`
              );
            }}
          >
            {record.shipping_profile_count}
          </Button>
        );
      },
      align: 'center'
    },
    {
      title: 'Active',
      render(value, record, index) {
        return (
          <section className="flex justify-center items-center">
            <Switch
              checkedChildren={'Yes'}
              unCheckedChildren={'No'}
              onChange={(value: boolean) => {
                setLoading(true);
                handleOnChaneStatus(value, record);
                setLoading(false);
              }}
              checked={record?.is_active ? true : false}
            />
          </section>
        );
      },
      align: 'center'
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="VIEW"
              onClick={() => {
                navigate(`/logistics/snn/${record.id}?${backUrlParamString}`);
              }}
              title="View SNN"
            />

            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                navigate(`/logistics/snn/${record.id}/edit?${backUrlParamString}`);
              }}
              title="Edit SNN"
            />
            <ActionButton
              action="DELETE"
              title={'Delete SNN'}
              onClick={() => handleSnnDelete(record.id)}
            ></ActionButton>
          </section>
        );
      },
      align: 'center'
    }
  ];

  const handleOnChaneStatus = async (action: boolean, record: any) => {
    const bodyData = {
      is_active: action
    };
    const { errors } = await logisticService.updateSNNState(record.id as string, bodyData);
    if (_.isEmpty(errors)) {
      handleFilterSearch(0);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const handlePageChange = (currentPage: number) => {
    setCurrentPage(currentPage);
    handleFilterSearch(currentPage);
  };

  const handleSnnDelete = async (recordId: string) => {
    setLoading(true);
    const { errors } = await logisticService.deleteSNN(recordId);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'SNN Deleted Successfully!' });
      handleFilterSearch(0);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Source Node Networks
              </Typography.Title>
            </Col>

            <Col>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  navigate(`/logistics/snn/create?${backUrlParamString}`);
                }}
              >
                <BoldButtonLabel labelText="Create" />
              </Button>
            </Col>
          </Row>

          <div className="my-4">
            <Form
              onFinish={() => {
                currentPage == 1 ? handleFilterSearch(0) : setCurrentPage(1);
              }}
              form={searchForm}
              layout="vertical"
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'name'} label="SNN Name">
                    <Input placeholder="SNN Name" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'fulfilment-type'} label="Fulfillment Type">
                    <Select
                      size="large"
                      filterOption={false}
                      placeholder="Fulfillment Type"
                      options={[
                        { label: 'Sales', value: 'SALES' },
                        { label: 'Transfer', value: 'TRANSFER' }
                      ]}
                      defaultValue={'SALES'}
                    ></Select>
                  </Form.Item>
                </Col>

                <Col xs={12} md={6}>
                  <div className="h-full flex justify-center items-center">
                    <Button type="primary" htmlType="submit" size="large" block>
                      <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>

          <section className="mt-4">
            {/* {snnListingResponse?.snns?.length > 0 && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(snnListingResponse?.snns?.length / 10)}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '} */}
            <Table
              loading={false}
              pagination={{
                current: currentPage,
                total: snnListingResponse?.total_pages * pageSize || 0,
                pageSize: pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onShowSizeChange: (current, size) => {
                  setPageSize(size);
                  setCurrentPage(1); // Reset to first page
                },
                onChange: page => setCurrentPage(page)
              }}
              bordered
              dataSource={snnListingResponse.snns || []}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default SnnListing;
