import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import NodeCreationForm from '../../components/NodeCreationForm';
import { Card, Col, Row, Typography } from 'antd';

interface ICreateNodeProps {}

const CreateNode: React.FunctionComponent<ICreateNodeProps> = props => {
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="pt-2 min-h-[82vh]">
          <Row justify={'space-between'} className="mb-2">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Create Node
              </Typography.Title>
            </Col>
          </Row>
          <NodeCreationForm loading={false} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreateNode;
