const ENVIRONMENTS = {
  DEVELOPMENT: 'DEVELOPMENT',
  BETA: 'BETA',
  PRODUCTION: 'PRODUCTION'
};

const toggles = {
  common: {
    ENABLE_SIDE_MENU_AUTHORIZATION: false,
    ENABLE_PRICELIST: true,
    ENABLE_LOGISTICS: false
  },
  [ENVIRONMENTS.DEVELOPMENT]: {
    ENABLE_SIDE_MENU_AUTHORIZATION: false,
    ENABLE_PRICELIST: false,
    ENABLE_LOGISTICS: true
  },
  [ENVIRONMENTS.BETA]: { ENABLE_SIDE_MENU_AUTHORIZATION: true, ENABLE_PRICELIST: false, ENABLE_LOGISTICS: false },
  [ENVIRONMENTS.PRODUCTION]: { ENABLE_LOGISTICS: false }
};

const environment = process.env.REACT_APP_WEB_ENV as string;

const getEnvSpecificToggles = () => {
  return {
    ...toggles.common,
    ...toggles[environment]
  };
};

const FEATURE_FLAGS = { ...getEnvSpecificToggles() };

export { FEATURE_FLAGS };
