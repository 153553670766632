import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import {
  Card,
  Col,
  Form,
  Row,
  Typography,
  Select,
  Radio,
  Input,
  Button,
  InputNumber,
  DatePicker,
  TimePicker
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { promotionService } from '../services/promotion.service';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useLoader } from '../../../stores/use-loader';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';

interface IGenerateCouponProps {}

const forSelectOptions = [
  { label: 'Anonymous User', value: 'ANONYMOUS' },
  { label: 'Registered User', value: 'REGISTERED_USER' },
  { label: 'Staff', value: 'STAFF' }
];

const buyerSelectionOptions = [
  { label: 'John', value: 'RMG0000000001' },
  { label: 'Janet', value: 'RMG0000000002' },
  { label: 'Jason', value: 'RMG0000000003' },
  { label: 'Julia', value: 'RMG0000000004' },
  { label: 'Jennifer', value: 'RMG0000000005' },
  { label: 'Jeremy', value: 'RMG0000000006' },
  { label: 'Jodi', value: 'RMG0000000007' }
];
const allSpecificSelect = [
  { label: 'All', value: 'ALL' },
  { label: 'Specific', value: 'SPECIFIC' }
];
const staffSelectionOptions = [
  { label: 'Pavan Kumar', value: 'emp001' },
  { label: 'Dhanush Amin', value: 'emp002' },
  { label: 'Ankur Dixit', value: 'emp003' }
];

const tierRestrictionValues = [
  { label: 'Gold', value: 'Gold' },
  { label: 'Silver', value: 'Silver' },
  { label: 'Bronze', value: 'Bronze' },
  { label: 'Everyone', value: 'Everyone' }
];
type RangeType = 'start' | 'end';

type DisabledTimes = {
  disabledHours?: () => number[];
  disabledMinutes?: (selectedHour: number) => number[];
  disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
};

const disabledTime = (_: any | null, type: RangeType): DisabledTimes => {
  if (type === 'start') {
    return {
      disabledHours: () => Array.from<number>({ length: dayjs().hour() + 1 }).map((_, index) => index),
      disabledMinutes: selectedHour => {
        if (selectedHour === dayjs().hour()) {
          return Array.from<number>({ length: dayjs().minute() + 1 }).map((_, index) => index);
        }
        return [];
      },
      disabledSeconds: (selectedHour, selectedMinute) => {
        if (selectedHour === dayjs().hour() && selectedMinute === dayjs().minute()) {
          return Array.from<number>({ length: dayjs().second() + 1 }).map((_, index) => index);
        }
        return [];
      }
    };
  }

  return {};
};

const GenerateCoupon: React.FunctionComponent<IGenerateCouponProps> = props => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const handleSave = async () => {
    const {
      visibility,
      code_type,
      code,
      buyer_selection,
      buyers,
      staff_selection,
      staffs,
      generate_count,
      tier_restriction,
      date_range,
      time_range,
      pattern,
      time_range_startTime,
      time_range_endTime
    } = form.getFieldsValue();
    const request: any = {
      code_type,
      code: code_type === 'MULTIPLE' ? pattern : code,
      buyer_selection,
      buyers,
      staff_selection,
      staffs,
      visibility,
      generate_count: generate_count || 1,
      tier_restriction: visibility === "ANONYMOUS" ? "EVERYONE" : tier_restriction
    };

    if (date_range?.length) {
      request.start_date = dayjs(date_range[0]).format('YYYY-MM-DD');
      request.end_date = dayjs(date_range[1]).format('YYYY-MM-DD');
    }

    // if (time_range?.length) {
    request.start_time = dayjs(time_range_startTime).format('HH:mm:ss');
    request.end_time = dayjs(time_range_endTime).format('HH:mm:ss');
    // }
    setLoading(true);
    const { data, errors } = await promotionService.createCouponCodes(id as string, request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Coupons generated successfully!' });
      form.resetFields();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Disable dates before today
    return current && current < dayjs().startOf('day');
  };

  const disabledTime = (current: Dayjs | null) => {
    if (!current) {
      // allow all times if no value is selected
      return {};
    }

    const now = dayjs();
    const isBeforeCurrentDate = current.isBefore(now, 'day');
    const isSameCurrentDate = current.isSame(now, 'day');

    if (isBeforeCurrentDate) {
      // disable all times if the date is before the current date
      return {
        disabledHours: () => Array.from({ length: 24 }).map((_, index) => index),
        disabledMinutes: () => Array.from({ length: 60 }).map((_, index) => index),
        disabledSeconds: () => Array.from({ length: 60 }).map((_, index) => index)
      };
    } else if (isSameCurrentDate) {
      // disable hours, minutes, and seconds that are before the current time
      return {
        disabledHours: () => Array.from({ length: now.hour() }).map((_, index) => index),
        disabledMinutes: (selectedHour: number) =>
          selectedHour === now.hour() ? Array.from({ length: now.minute() }).map((_, index) => index) : [],
        disabledSeconds: (selectedHour: number, selectedMinute: number) =>
          selectedHour === now.hour() && selectedMinute === now.minute()
            ? Array.from({ length: now.second() }).map((_, index) => index)
            : []
      };
    }

    // allow all times for future dates
    return {};
  };

  const handleGenerateRandomString = () => {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }

    form.setFieldsValue({ code: result });
  };

  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Generate Coupons
              </Typography.Title>
            </Col>
          </Row>

          <Form onFinish={handleSave} form={form} layout="vertical">
            <Row gutter={12}>
              <Col xs={24} md={6}>
                <Form.Item
                  name="visibility"
                  label="For"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Select placeholder="Select coupon for" size="large" options={forSelectOptions} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              {' '}
              <Col xs={24} md={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(previousValues, currentValues) =>
                    previousValues.visibility !== currentValues.visibility
                  }
                >
                  {form => {
                    const { visibility } = form.getFieldsValue();
                    return (
                      visibility === 'REGISTERED_USER' && (
                        <Form.Item name={'tier_restriction'} label="Tier Restriction">
                          <Select size="large" placeholder="Select Tier restriction" options={tierRestrictionValues} />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col xs={24} md={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(previousValues, currentValues) =>
                    previousValues.visibility !== currentValues.visibility
                  }
                >
                  {form => {
                    const { visibility } = form.getFieldsValue();
                    return (
                      <>
                        {visibility === 'REGISTERED_USER' && (
                          <>
                            <Form.Item
                              name="buyer_selection"
                              label="Buyer Selection"
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Select placeholder="Select buyer selection" size="large" options={allSpecificSelect} />
                            </Form.Item>
                          </>
                        )}
                        {visibility === 'STAFF' && (
                          <Form.Item
                            name="staff_selection"
                            label="Staff Selection"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Select placeholder="Select staff selection" size="large" options={allSpecificSelect} />
                          </Form.Item>
                        )}
                      </>
                    );
                  }}
                </Form.Item>{' '}
              </Col>
              <Col xs={24} md={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(previousValues, currentValues) =>
                    previousValues.buyer_selection !== currentValues.buyer_selection ||
                    previousValues.visibility !== currentValues.visibility
                  }
                >
                  {form => {
                    const { buyer_selection, visibility } = form.getFieldsValue();
                    return (
                      buyer_selection === 'SPECIFIC' &&
                      visibility === 'REGISTERED_USER' && (
                        <Form.Item name={'buyers'} label="Buyers">
                          <Select
                            mode="multiple"
                            placeholder="Select Buyers"
                            size="large"
                            options={buyerSelectionOptions}
                          />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(previousValues, currentValues) =>
                    previousValues.staff_selection !== currentValues.staff_selection ||
                    previousValues.visibility !== currentValues.visibility
                  }
                >
                  {form => {
                    const { staff_selection, visibility } = form.getFieldsValue();
                    return (
                      staff_selection === 'SPECIFIC' &&
                      visibility === 'STAFF' && (
                        <Form.Item name={'staff'} label="Staff">
                          <Select
                            mode="multiple"
                            placeholder="Select Staff"
                            size="large"
                            options={staffSelectionOptions}
                          />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>

            <section className="my-2">
              <Form.Item
                name="code_type"
                label="Discount Code"
                rules={[{ required: true, message: 'This Field is required' }]}
              >
                <Radio.Group size="large">
                  <Radio value={'SINGLE'}>Single</Radio>
                  <Radio value={'MULTIPLE'}>Multiple</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(previousValues, currentValues) => previousValues.code_type !== currentValues.code_type}
              >
                {form => {
                  const { code_type } = form.getFieldsValue();

                  return (
                    <>
                      {code_type === 'SINGLE' && (
                        <Row gutter={[12, 12]} align={'middle'}>
                          <Col xs={24} md={6}>
                            <Form.Item
                              name="code"
                              label="Code"
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Input placeholder="Enter discount code" size="large" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={6}>
                            <Button block size={'large'} onClick={handleGenerateRandomString}>
                              <BoldButtonLabel labelText="Autogenerate code"></BoldButtonLabel>
                            </Button>
                          </Col>
                        </Row>
                      )}

                      {code_type === 'MULTIPLE' && (
                        <Row gutter={[12, 12]}>
                          <Col xs={24} md={6}>
                            <Form.Item
                              name="pattern"
                              label="Pattern"
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Input placeholder="Enter code pattern" size="large" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={6}>
                            <Form.Item
                              name="generate_count"
                              label="Quantity to generate"
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <InputNumber
                                placeholder="Enter quantity to generate"
                                className="w-full"
                                min={1}
                                size="large"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </section>
            <section className="my-2">
              <div className="mb-2">
                {' '}
                <Typography.Text>Valid Until</Typography.Text>
              </div>
              <Row gutter={[12, 12]}>
                <Col xs={24} md={6}>
                  <Form.Item name={'date_range'} rules={[{ required: true, message: 'This field is required' }]}>
                    <DatePicker.RangePicker disabledDate={disabledDate} size="large" />
                  </Form.Item>
                </Col>{' '}
                {/* REMOVED TIME RANGE BECAUSE of Time swap issue */}
                {/* <Col xs={24} md={6}>
                  <Form.Item name={'time_range'} rules={[{ required: true, message: 'This field is required' }]}>
                    <TimePicker.RangePicker disabledTime={disabledTime} size="large" />
                  </Form.Item>
                </Col> */}
                <Col xs={24} md={6}>
                  <Form.Item
                    name="time_range_startTime"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <TimePicker
                      className="w-[100%]"
                      onBlur={e => {
                        let startingTime = e.target.value.length > 1 ? dayjs(e.target.value, 'HH:mm:ss') : null;
                        form.setFieldsValue({ time_range_startTime: startingTime });
                      }}
                      placeholder="Select Start Time"
                      size="large"
                      format={'HH:mm:ss'}
                      disabledTime={disabledTime}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="time_range_endTime" rules={[{ required: true, message: 'This field is required' }]}>
                    <TimePicker
                      className="w-[100%]"
                      onBlur={e => {
                        let endTime = e.target.value.length > 1 ? dayjs(e.target.value, 'HH:mm:ss') : null;
                        form.setFieldsValue({ time_range_endTime: endTime });
                      }}
                      placeholder="Select End Time"
                      size="large"
                      format={'HH:mm:ss'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </section>
            <Row gutter={[12, 12]}>
              <Col xs={12} md={6}>
                <Button type="primary" block htmlType="submit" size="large">
                  <BoldButtonLabel labelText="Generate Coupon" />
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <Button
                  block
                  size="large"
                  onClick={() => {
                    const params = new URLSearchParams(search);

                    const back_to_promotion_listing = params.get('back_to_promotion_listing');

                    if (back_to_promotion_listing) {
                      navigate(`/promotion-engine/promotions${search}`);
                    } else navigate(`/promotion-engine/coupons${search}`);
                  }}
                >
                  <BoldButtonLabel labelText="Go Back" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default GenerateCoupon;
