import * as fileHelpers from './file.helpers';
import * as urlHelpers from './url.helpers';
import * as validationHelpers from './validation.helpers';

export const convertQueryStringToObj = (queryString: string) => {
  return Object.fromEntries(new URLSearchParams(queryString));
};

export const deleteUndefinedValuesFromObject = (obj: Record<any, any>): Record<any, any> => {
  const filteredObj: Record<any, any> = {};
  Object.keys(obj).forEach((key: string) => {
    if (obj[key] !== undefined && obj[key] !== '' && obj[key] !== null) {
      filteredObj[key] = obj[key];
    }
  });

  return filteredObj;
};

function convertObjectKeysSnakeToCamel(obj: Record<string, any>): Record<string, any> {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      newObj[camelKey] = obj[key];
    }
  }

  return newObj;
}
const objectHelpers = { deleteUndefinedValuesFromObject, convertQueryStringToObj, convertObjectKeysSnakeToCamel };

export { objectHelpers, fileHelpers, urlHelpers, validationHelpers };
