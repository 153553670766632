import { ENV, TENANT_DOMAIN_INDEX } from '../config/envConfig';

export const getBackUrl = (searchParams: URLSearchParams) => {
  return window.location?.pathname + `?${searchParams.toString()}`;
};

export const getBackUrlParamString = (searchParams: URLSearchParams) => {
  const backUrl = window.location?.pathname + `?${searchParams.toString()}`;
  const params = new URLSearchParams({ backUrl });
  const paramString = params.toString();

  return paramString;
};

export const getTenantIdAndBusinessGroupIdFromUrl = () => {
  //!development override
  if (ENV === 'DEVELOPMENT') return { tenantId: 'rmg', businessGroupId: 'rmg' };
  if (ENV === 'PRODUCTION') return { tenantId: 'bumblegifts', businessGroupId: 'bumblegifts' };
  const url = `${window.location.host}`.replace('www.', '');
  const urlParts = url.split('.');
  const tenantIndex = parseInt(TENANT_DOMAIN_INDEX as string);
  const tenantId = urlParts[tenantIndex] || 'ejje';
  const businessGroupId = tenantId || 'ejje'; //! For now
  return { tenantId, businessGroupId };
};
