import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  TimePicker,
  Typography
} from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../BoldButtonLabel';
import { countryList, currencyList, phoneCountryCodeList } from '../../data/intl-data';
import { stateList } from '../../data';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { DAYS_OF_THE_WEEK } from '../../data/date-data';
import dayjs from 'dayjs';

interface IOperatorDetailsProps {
  operatorDetailsForm: any;
  operatorDetails: any;
  handleClickNext: (data: any) => void;
  handleClickBack: (data: any) => void;
}

const OperatorDetails: React.FunctionComponent<IOperatorDetailsProps> = ({
  handleClickBack,
  handleClickNext,
  operatorDetails,
  operatorDetailsForm
}) => {
  React.useEffect(() => {
    operatorDetailsForm.setFieldsValue(operatorDetails);
  }, [operatorDetails]);

  return (
    <Form layout="vertical" form={operatorDetailsForm}>
      <Row gutter={12}>
        <Col xs={12} md={6} xl={6}>
          <Form.Item
            name="operator_name"
            label="Operator Name"
            rules={[{ required: true, message: 'Operator name is required' }]}
          >
            <Input placeholder="Enter Node Name" size="large"></Input>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xl={6}>
          <Form.Item
            name="operator_type"
            label="Operator Type"
            rules={[{ required: true, message: 'Operator type is required' }]}
          >
            <Select
              options={[{ label: 'Transport', value: 'TRANSPORT' }]}
              placeholder="Select Node type"
              size="large"
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xl={6}>
          <Form.Item
            initialValue={dayjs.tz.guess()}
            name="timezone"
            label="Timezone"
            rules={[{ required: true, message: 'Timezone is required' }]}
          >
            <Select
              placeholder="Select Timezone"
              options={[
                {
                  label: dayjs.tz.guess(),
                  value: dayjs.tz.guess()
                },
                {
                  label: 'America/Santiago',
                  value: 'America/Santiago'
                },
                {
                  label: 'America/Punta_Arenas',
                  value: 'America/Punta_Arenas'
                },
                {
                  label: 'Pacific/Easter',
                  value: 'Pacific/Easter'
                }
              ]}
              size="large"
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xl={6}>
          <Form.Item
            name="business_email"
            rules={[{ required: true, message: 'Business email is required' }]}
            label="Business Email"
          >
            <Input type="email" placeholder="Enter Business Email" size="large"></Input>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            label="Phone Number"
            name={['phone_number', 'number']}
            rules={[{ required: true, message: 'Phone number is required' }]}
          >
            <Input
              addonBefore={
                <Form.Item
                  initialValue={phoneCountryCodeList[0]?.value}
                  noStyle
                  name={['phone_number', 'country_code']}
                  rules={[{ required: true, message: 'Country code is requiered' }]}
                >
                  <Select style={{ width: 120 }} options={phoneCountryCodeList} size="large"></Select>
                </Form.Item>
              }
              min={0}
              placeholder="Enter Phone Number"
              size="large"
            ></Input>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xl={6}>
          <Form.Item
            name="phone_availability"
            label="Phone Availability"
            rules={[{ required: true, message: 'Availability is required' }]}
          >
            <TimePicker.RangePicker
              placeholder={['From', 'To']}
              format={'H:mm A'}
              size="large"
            ></TimePicker.RangePicker>
          </Form.Item>
        </Col>
      </Row>{' '}
      <section>
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          Address
        </Typography.Title>
        <div>
          <div className="mb-2">
            <span className="text-lg font-medium">Primary Address</span>
          </div>

          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                name={['primary', 'type']}
                label="Address type"
                rules={[{ required: true, message: 'Address Type is required' }]}
              >
                <Select
                  size="large"
                  options={[
                    { label: 'Business', value: 'BUSINESS' },
                    { label: 'Mail', value: 'MAIL' },
                    { label: 'Registered', value: 'REGISTERED' },
                    { label: 'Personal', value: 'PERSONAL' },
                    { label: 'Other', value: 'OTHER' }
                  ]}
                  placeholder="Select Address type"
                ></Select>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.primary?.type !== curValues.primary?.type;
              }}
            >
              {() => {
                const addressType = operatorDetailsForm.getFieldsValue()?.primary?.type;

                const showCustomType = addressType === 'OTHER';
                return (
                  showCustomType && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        name={['primary', 'custom_type']}
                        label="Custom Address Type"
                        rules={[{ required: true, message: 'Custom address type is required' }]}
                      >
                        <Input size="large" placeholder="Enter custom address type"></Input>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Col xs={12} md={6}>
              <Form.Item
                name={['primary', 'address_line1']}
                label="Address Line 1"
                rules={[{ required: true, message: 'Address line 1 is required' }]}
              >
                <Input size="large" placeholder="Enter Address line 1"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item name={['primary', 'address_line2']} label="Address Line 2">
                <Input size="large" placeholder="Enter Address line 2"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item name={['primary', 'address_line3']} label="Address Line 3">
                <Input size="large" placeholder="Enter Address line 3"></Input>
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item name={['primary', 'landmark']} label="Landmark">
                <Input size="large" placeholder="Enter Landmark"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={['primary', 'city']}
                label="City"
                rules={[{ required: true, message: 'City is required' }]}
              >
                <Input size="large" placeholder="Enter City"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={['primary', 'postal_code']}
                rules={[{ required: true, message: 'Postal code is required' }]}
                label="Postal Code"
              >
                <Input size="large" placeholder="Enter Postal Code"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={['primary', 'coordinates', 'latitude']}
                rules={[{ required: true, message: 'Latitude is required' }]}
                label="Latitude"
              >
                <Input size="large" placeholder="Enter latitude"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={['primary', 'coordinates', 'longitude']}
                rules={[{ required: true, message: 'Longitude is required' }]}
                label="Longitude"
              >
                <Input size="large" placeholder="Enter longitude"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={['primary', 'country']}
                label="Country"
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <Select
                  size="large"
                  onChange={() => operatorDetailsForm.setFieldValue(['primary', 'state'], null)}
                  options={countryList}
                  placeholder="Select country"
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item noStyle shouldUpdate={true}>
                {form => {
                  return (
                    <Form.Item
                      name={['primary', 'state']}
                      label="State"
                      rules={[{ required: true, message: 'State is required' }]}
                    >
                      <Select
                        size="large"
                        showSearch
                        options={stateList[operatorDetailsForm.getFieldsValue()?.primary?.country]}
                        placeholder="Select State"
                      ></Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <span className="text-lg font-medium">Other Address</span>
          <div className="my-4">
            <Form.List name="other_address">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <React.Fragment key={key}>
                      <Row>
                        <Col xs={12}>
                          <Divider orientation="left" plain>
                            <span className="text-lg">Address - {index + 1} </span>
                          </Divider>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            label="Address type"
                            rules={[{ required: true, message: 'Address Type is required' }]}
                          >
                            <Select
                              size="large"
                              placeholder="Select Address type"
                              options={[
                                { label: 'Business', value: 'BUSINESS' },
                                { label: 'Mail', value: 'MAIL' },
                                { label: 'Registered', value: 'REGISTERED' },
                                { label: 'Personal', value: 'PERSONAL' },
                                { label: 'Other', value: 'OTHER' }
                              ]}
                            ></Select>
                          </Form.Item>
                        </Col>

                        <Form.Item noStyle shouldUpdate={true}>
                          {() => {
                            const isDataPresent = operatorDetailsForm.getFieldsValue()?.other_address?.length;

                            const addressType = isDataPresent
                              ? operatorDetailsForm.getFieldsValue()?.other_address[index]?.type
                              : '';

                            const showCustomType = addressType === 'OTHER';
                            return (
                              showCustomType && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'custom_type']}
                                    label="Custom Address Type"
                                    rules={[{ required: true, message: 'Custom address type is required' }]}
                                  >
                                    <Input size="large" placeholder="Enter custom address type"></Input>
                                  </Form.Item>
                                </Col>
                              )
                            );
                          }}
                        </Form.Item>
                        <Col xs={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'address_line1']}
                            label="Address Line 1"
                            rules={[{ required: true, message: 'Address line 1 is required' }]}
                          >
                            <Input size="large" placeholder="Enter Address line 1"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item {...restField} name={[name, 'address_line2']} label="Address Line 2">
                            <Input size="large" placeholder="Enter Address line 2"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item {...restField} name={[name, 'address_line3']} label="Address Line 3">
                            <Input size="large" placeholder="Enter Address line 3"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item {...restField} name={[name, 'landmark']} label="Landmark">
                            <Input size="large" placeholder="Enter Landmark"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'city']}
                            label="City"
                            rules={[{ required: true, message: 'City is required' }]}
                          >
                            <Input size="large" placeholder="Enter City"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'postal_code']}
                            label="Postal Code"
                            rules={[{ required: true, message: 'Postal code is required' }]}
                          >
                            <Input size="large" placeholder="Enter Postal Code"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            name={[name, 'coordinates', 'latitude']}
                            rules={[{ required: true, message: 'Latitude is required' }]}
                            label="Latitude"
                          >
                            <Input size="large" placeholder="Enter latitude"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            name={[name, 'coordinates', 'longitude']}
                            rules={[{ required: true, message: 'Longitude is required' }]}
                            label="Longitude"
                          >
                            <Input size="large" placeholder="Enter longitude"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'country']}
                            label="Country"
                            rules={[{ required: true, message: 'Country is required' }]}
                          >
                            <Select
                              options={countryList}
                              onChange={() => {
                                const { other_address, ...rest } = operatorDetailsForm.getFieldsValue();
                                Object.assign(other_address[index], { ...other_address[index], state: null });
                                operatorDetailsForm.setFieldsValue({ ...rest, other_address });
                              }}
                              size="large"
                              placeholder="Select country"
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item noStyle shouldUpdate={true}>
                            {() => {
                              const isDataPresent = operatorDetailsForm.getFieldsValue()?.other_address?.length;
                              const stateValue = isDataPresent
                                ? stateList[operatorDetailsForm.getFieldsValue()?.other_address[index]?.country]
                                : '';
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'state']}
                                  label="State"
                                  rules={[{ required: true, message: 'State is required' }]}
                                >
                                  <Select options={stateValue} size="large" placeholder="Select State"></Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Col>
                        <div className="mx-8 flex justify-center items-center">
                          <DeleteOutlined
                            className="text-red-500 text-xl"
                            title="Delete Address"
                            onClick={() => remove(name)}
                          />
                        </div>
                      </Row>
                    </React.Fragment>
                  ))}
                  <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                  <Form.Item>
                    <Row justify={'start'}>
                      <Col xs={24} md={6}>
                        <Button onClick={() => add()} icon={<PlusOutlined />}>
                          <BoldButtonLabel labelText="Add Address" />
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </div>
      </section>
      <div className="mb-2">
        <span className="text-lg font-medium">Operator Roles</span>
      </div>
      <Form.Item name="roles">
        <Checkbox.Group options={[{ label: 'Carrier', value: 'CARRIER' }]}></Checkbox.Group>
      </Form.Item>
      <div>
        <div className="mb-4">
          <span className="text-lg font-medium">Work Week</span>
        </div>

        <Form.List
          name="work_week"
          rules={[
            {
              validator: async (_, workWeek) => {
                if (!workWeek || workWeek.length < 1) {
                  return Promise.reject(new Error('Please enter work week info'));
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <React.Fragment>
              <Row gutter={[12, 12]}>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Col xs={24} xl={12} key={key}>
                    <Card className="my-2" key={key}>
                      <div className="relative">
                        <Row>
                          <Col xs={24}>
                            <Divider orientation="left" plain>
                              <span className="text-lg">Day - {index + 1} </span>
                            </Divider>
                          </Col>
                        </Row>

                        <Row gutter={12}>
                          <Col xs={12} md={8}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) => {
                                return true;
                              }}
                            >
                              {form => {
                                const selectedDays = (form.getFieldsValue().work_week || []).map((workWeek: any) => {
                                  if (
                                    workWeek?.day_of_the_week !==
                                    form.getFieldsValue().work_week[index]?.day_of_the_week
                                  )
                                    return workWeek?.day_of_the_week;
                                });
                                //!Remove current selected Day

                                const filteredDays = DAYS_OF_THE_WEEK.filter(day => !selectedDays.includes(day.value));

                                return (
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'day_of_the_week']}
                                    label="Day of the week"
                                    rules={[{ required: true, message: 'Day is required' }]}
                                  >
                                    <Select size="large" placeholder="Select Day">
                                      {filteredDays.map(({ label, value }) => (
                                        <Select.Option value={value} key={value}>
                                          {label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'shift_time']}
                              label="Shift time"
                              rules={[{ required: true, message: 'Shift time is required' }]}
                            >
                              <TimePicker.RangePicker format={'H:mm A'} className="w-full" size="large" />
                            </Form.Item>
                          </Col>

                          <Col xs={24} md={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'cut_off_time']}
                              rules={[{ required: true, message: 'Shift cut off time is required' }]}
                              label="Shift Cut Off Time"
                            >
                              <TimePicker format={'H:mm A'} className="w-full" size="large" />
                            </Form.Item>
                          </Col>

                          <Col xs={24}>
                            <Form.List
                              name={[name, 'capacities']}
                              rules={[
                                {
                                  validator: async (_, capacities) => {
                                    if (!capacities || capacities.length < 1) {
                                      return Promise.reject(new Error('At least 1 capacity info is required'));
                                    }
                                  }
                                }
                              ]}
                            >
                              {(fields, { add: capacityAdd, remove: capacityRemove }, { errors: capacityErrors }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }, index) => (
                                    <React.Fragment key={key}>
                                      <Row gutter={6} justify={'space-between'}>
                                        <Col xs={12}>
                                          <Divider className="m-0 p-0" orientation="left" plain>
                                            <span className="text-sm">Capacity - {index + 1} </span>
                                          </Divider>
                                        </Col>
                                        <Col>
                                          <div className="mx-4 h-full flex justify-center items-center">
                                            <DeleteOutlined
                                              className="text-red-500 text-xl cursor-pointer"
                                              role="button"
                                              title="Remove capacity"
                                              onClick={() => capacityRemove(name)}
                                            ></DeleteOutlined>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row gutter={6}>
                                        <Col xs={24} md={12}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'capacity_type']}
                                            rules={[{ required: true, message: 'Capacity type is required' }]}
                                            label="Capacity Type"
                                          >
                                            <Select
                                              size="large"
                                              placeholder={'Select capacity type'}
                                              options={[{ label: 'Ship Ready', value: 'SHIP_READY' }]}
                                            ></Select>
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'capacity_uom']}
                                            rules={[{ required: true, message: 'Capacity UOM is required' }]}
                                            label="Capacity UOM"
                                          >
                                            <Select
                                              size="large"
                                              showSearch
                                              placeholder={'Select capacity UOM'}
                                              options={[
                                                { label: 'Line', value: 'LINE' },
                                                { label: 'Unit', value: 'UNIT' },
                                                { label: 'Release', value: 'RELEASE' }
                                              ]}
                                            ></Select>
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                          <Form.List
                                            name={[name, 'slots']}
                                            rules={[
                                              {
                                                validator: async (_, slots) => {
                                                  if (!slots || slots.length < 1) {
                                                    return Promise.reject(
                                                      new Error('At least 1 slot  info is required')
                                                    );
                                                  }
                                                }
                                              }
                                            ]}
                                          >
                                            {(fields, { add, remove }, { errors }) => (
                                              <>
                                                {fields.map(({ key, name, ...restField }, index) => (
                                                  <React.Fragment key={key}>
                                                    <Row justify={'space-between'}>
                                                      <Col xs={12}>
                                                        <Divider orientation="left" plain>
                                                          <span className="text-sm">Slot - {index + 1} </span>
                                                        </Divider>
                                                      </Col>
                                                      <Col>
                                                        <div className="mx-4 h-full flex justify-center items-center">
                                                          <DeleteOutlined
                                                            className="text-red-500 text-xl"
                                                            title="Delete Slot"
                                                            onClick={() => remove(name)}
                                                          />
                                                        </div>
                                                      </Col>
                                                    </Row>

                                                    <Row gutter={12}>
                                                      <Col xs={24} md={12}>
                                                        <Form.Item
                                                          {...restField}
                                                          name={[name, 'capacity']}
                                                          rules={[{ required: true, message: 'Capacity is required' }]}
                                                          label="Capacity"
                                                        >
                                                          <InputNumber
                                                            className="w-full"
                                                            size={'large'}
                                                            min={0}
                                                            placeholder="Enter capacity"
                                                          />
                                                        </Form.Item>
                                                      </Col>

                                                      <Col xs={24} md={12}>
                                                        <Form.Item
                                                          {...restField}
                                                          name={[name, 'default_required_capacity_for_uom_line']}
                                                          // rules={[{ required: true, message: 'Capacity is required' }]}
                                                          label="Default Capacity for UOM Line"
                                                        >
                                                          <InputNumber
                                                            className="w-full"
                                                            size={'large'}
                                                            min={0}
                                                            placeholder="Enter capacity"
                                                          />
                                                        </Form.Item>
                                                      </Col>

                                                      <Col xs={24}>
                                                        <Form.Item
                                                          {...restField}
                                                          name={[name, 'slot_time']}
                                                          label="Slot Time"
                                                          rules={[{ required: true, message: 'Slot time is required' }]}
                                                        >
                                                          <TimePicker.RangePicker
                                                            format={'H:mm A'}
                                                            className="w-full"
                                                            size="large"
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                    </Row>
                                                  </React.Fragment>
                                                ))}
                                                <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />

                                                <Col xs={24}>
                                                  <Form.Item>
                                                    <Button
                                                      onClick={() => add()}
                                                      size="small"
                                                      type="link"
                                                      icon={<PlusOutlined />}
                                                    >
                                                      <BoldButtonLabel labelText="Add Slot" />
                                                    </Button>
                                                  </Form.Item>{' '}
                                                </Col>
                                              </>
                                            )}
                                          </Form.List>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  ))}
                                  <Form.ErrorList className="text-[#ff4d4f]" errors={capacityErrors} />
                                  <Form.Item>
                                    <Row justify={'start'}>
                                      <Col xs={24} md={4}>
                                        <Button
                                          disabled={fields.length > 1}
                                          onClick={() => capacityAdd()}
                                          icon={<PlusOutlined />}
                                          size="small"
                                        >
                                          <BoldButtonLabel labelText="Add Capacity" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Col>
                          <div className="absolute top-0 right-0  flex justify-center items-center">
                            <DeleteOutlined
                              className="text-red-500 text-xl"
                              title="Delete Work Day"
                              onClick={() => remove(name)}
                            />
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>{' '}
              <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
              <Form.Item>
                <Row justify={'start'}>
                  <Col xs={24} md={4}>
                    <Button disabled={fields.length > 6} onClick={() => add()} icon={<PlusOutlined />}>
                      <BoldButtonLabel labelText="Add Work Day" />
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </React.Fragment>
          )}
        </Form.List>
      </div>
      <div>
        <div className="mb-4">
          <span className="text-lg font-medium">Holidays</span>
        </div>

        <Form.List name="holidays">
          {(fields, { add, remove }, { errors }) => (
            <>
              <Row>
                {fields.map(({ key, name, ...restField }, index) => (
                  <React.Fragment key={index}>
                    <Col xs={24} md={12}>
                      <Row>
                        <Col xs={12}>
                          <Divider orientation="left" plain>
                            <span className="text-lg">Holiday - {index + 1} </span>
                          </Divider>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={12} md={10}>
                          <Form.Item
                            {...restField}
                            name={[name, 'date']}
                            rules={[{ required: true, message: 'Holiday date is required' }]}
                            label="Holiday Date"
                          >
                            <DatePicker.RangePicker className="w-full" size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'time']}
                            rules={[{ required: true, message: 'Holiday time is required' }]}
                            label="Time"
                          >
                            <TimePicker.RangePicker format={'H:mm A'} className="w-full" size="large" />
                          </Form.Item>
                        </Col>

                        <Col xs={12} md={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'reason']}
                            rules={[{ required: true, message: 'Reason is required' }]}
                            label="Reason"
                          >
                            <Input className="w-full" size="large" placeholder="..."></Input>
                          </Form.Item>
                        </Col>

                        <div className="ml-4 flex justify-center items-center">
                          <DeleteOutlined
                            className="text-red-500 text-xl"
                            title="Delete Holiday"
                            onClick={() => remove(name)}
                          />
                        </div>
                      </Row>
                    </Col>
                  </React.Fragment>
                ))}{' '}
              </Row>
              <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
              <Form.Item>
                <Row justify={'start'}>
                  <Col xs={24} md={4}>
                    <Button onClick={() => add()} icon={<PlusOutlined />}>
                      <BoldButtonLabel labelText="Add Holiday" />
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <section className="my-2">
        <div className="mb-2">
          <span className="text-lg font-medium">Packages</span>
        </div>
        <Form.Item name={'is_package_provided'} valuePropName="checked">
          <Checkbox>Is Package Provided ?</Checkbox>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.is_package_provided !== curr.is_package_provided}>
          {form => {
            const { is_package_provided } = form.getFieldsValue();
            return is_package_provided ? (
              <>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'currency'}
                      rules={[{ required: true, message: 'Currency is required' }]}
                      label="Currency"
                    >
                      <Select options={currencyList} className="w-full" size="large" placeholder="..."></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.List name="packages">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <Row>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <React.Fragment key={index}>
                            <Col md={24}>
                              <Row>
                                <Col xs={12}>
                                  <Divider orientation="left" plain>
                                    <span className="text-lg">Package - {index + 1} </span>
                                  </Divider>
                                </Col>
                              </Row>

                              <Row gutter={12}>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'type']}
                                    rules={[{ required: true, message: 'Type is required' }]}
                                    label="Package Type"
                                  >
                                    <Select
                                      options={[{ label: 'Envelope', value: 'ENVELOPE' }]}
                                      className="w-full"
                                      size="large"
                                      placeholder="..."
                                    ></Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    rules={[{ required: true, message: 'Package name is required' }]}
                                    label="Package Name"
                                  >
                                    <Input className="w-full" size="large" placeholder="..."></Input>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'description']}
                                    rules={[{ required: true, message: 'Package description is required' }]}
                                    label="Package Description"
                                  >
                                    <Input.TextArea rows={1} size="large"></Input.TextArea>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Form.Item valuePropName="checked" name={[name, 'is_dimension_variable']}>
                                <Checkbox>Is Dimension Variable?</Checkbox>
                              </Form.Item>
                              <Row gutter={[12, 12]}>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'length_in_mm']}
                                    label="Package Length in MM"
                                    dependencies={[[name, 'is_dimension_variable']]}
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                            value !== undefined
                                          ) {
                                            return Promise.resolve();
                                          }

                                          return Promise.reject(new Error('Length is required'));
                                        }
                                      })
                                    ]}
                                  >
                                    <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'width_in_mm']}
                                    label="Width Length in MM"
                                    dependencies={[[name, 'is_dimension_variable']]}
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                            value !== undefined
                                          ) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Width is required'));
                                        }
                                      })
                                    ]}
                                  >
                                    <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'height_in_mm']}
                                    label="Package Height in MM"
                                    dependencies={[[name, 'is_dimension_variable']]}
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                            value !== undefined
                                          ) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Height is required'));
                                        }
                                      })
                                    ]}
                                  >
                                    <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'weight_in_gm']}
                                    label="Package Weight in GMs"
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                            value !== undefined
                                          ) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Weight is required'));
                                        }
                                      })
                                    ]}
                                  >
                                    <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'cost']}
                                    rules={[{ required: true, message: 'Cost is required' }]}
                                    label="Cost"
                                  >
                                    <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                  </Form.Item>
                                </Col>

                                <div className="ml-4 flex justify-center items-center">
                                  <DeleteOutlined
                                    className="text-red-500 text-xl"
                                    title="Delete Package"
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              </Row>
                            </Col>
                          </React.Fragment>
                        ))}{' '}
                      </Row>
                      <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                      <Form.Item>
                        <Row justify={'start'}>
                          <Col xs={24} md={4}>
                            <Button onClick={() => add()} icon={<PlusOutlined />}>
                              <BoldButtonLabel labelText="Add Package" />
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            ) : null;
          }}
        </Form.Item>
      </section>
      <Row gutter={[12, 12]}>
        <Col xs={12} md={4}>
          <Button size="large" onClick={() => handleClickBack(operatorDetailsForm.getFieldsValue())} block>
            <BoldButtonLabel labelText="Back"></BoldButtonLabel>
          </Button>
        </Col>
        <Col xs={12} md={4}>
          <Button
            type="primary"
            size="large"
            onClick={async () => {
              await operatorDetailsForm.validateFields();
              handleClickNext(operatorDetailsForm.getFieldsValue());
            }}
            block
          >
            <BoldButtonLabel labelText="Next"></BoldButtonLabel>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OperatorDetails;
