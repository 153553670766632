import { Button, Card, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';

import * as React from 'react';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../../../../stores/use-loader';
import { logisticService } from '../../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import _ from '../../../../../helpers/lodash';
import { useOperators } from '../../../hooks/drop-down-hooks/use-operators';

interface IDnnCreateProps {}

const DnnCreate: React.FunctionComponent<IDnnCreateProps> = props => {
  const [createForm] = Form.useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const handleSubmit = async () => {
    setLoading(true);
    const { country_code, description, dnn_name, operator_id, fulfillment_type } = createForm.getFieldsValue();
    const bodyData = {
      dnn_name: dnn_name,
      country_code: country_code,
      description: description,
      operator_id: operator_id,
      is_active: true,
      postcode_group: null,
      fulfilment_type: fulfillment_type
    };
    const { errors } = await logisticService.createDnn(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'DNN Created' });
      handleOnGoBack();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const customValidateFormInputs = async () => {
    const { postcode_group } = createForm.getFieldsValue();
    let filteredPostcodes = postcode_group
      .split(',')
      .map((item: string) => item.trim())
      .join(',');

    console.log({ postcode_group, filteredPostcodes });

    return [];
  };

  const handleOnGoBack = () => {
    if (searchParams.get('backUrl')) {
      navigate(searchParams.get('backUrl') as string);
    } else {
      navigate('/logistics/dnn');
    }
  };

  const { debouncedFetchOperatorDropdownOptions, operatorDropdownFetching, operatorDropdownOptions } = useOperators();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Distribution Node Network
              </Typography.Title>
            </Col>
          </Row>

          <Form layout="vertical" onFinish={handleSubmit} form={createForm}>
            <Row gutter={12}>
              <Col xs={24} md={8} lg={6}>
                <Form.Item
                  name={'dnn_name'}
                  label="DNN Name"
                  rules={[{ required: true, message: 'DNN Name is required' }]}
                >
                  <Input size="large" placeholder="DNN Name"></Input>
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={6}>
                <Form.Item
                  name={'fulfillment_type'}
                  label="Fulfillment Type"
                  rules={[{ required: true, message: 'Please select Fulfillment Type' }]}
                >
                  <Select
                    size="large"
                    placeholder="Select Fulfillment Type"
                    options={[
                      { label: 'Sales', value: 'SALES' },
                      { label: 'Transfer', value: 'TRANSFER' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8} lg={6}>
                <Form.Item
                  name={'operator_id'}
                  label="Operator Name"
                  rules={[{ required: true, message: 'Please select Operator Name' }]}
                >
                  <Select
                    allowClear
                    showSearch
                    size="large"
                    filterOption={false}
                    placeholder="Search operators"
                    notFoundContent={operatorDropdownFetching ? <Spin size="small" /> : null}
                    onSearch={async searchTerm => {
                      if (searchTerm) await debouncedFetchOperatorDropdownOptions(searchTerm);
                    }}
                    options={operatorDropdownOptions}
                  ></Select>
                </Form.Item>
              </Col>
              {/* <Col xs={24} md={8} lg={6}>
                <Form.Item name={'operator_id'} label="Operator ID">
                  <Select
                    // mode="multiple"
                    allowClear
                    size="large"
                    filterOption={false}
                    placeholder="Search nodes"
                    options={[
                      { label: 'b2a97efc-311b-4eea-afec-4fa3540b711a', value: 'b2a97efc-311b-4eea-afec-4fa3540b711a' },
                      { label: '0d5dff25-5437-4df9-b8fe-12e744489469', value: '0d5dff25-5437-4df9-b8fe-12e744489469' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col> */}
              <Col xs={24} md={8} lg={6}>
                <Form.Item
                  name={'country_code'}
                  label="Country"
                  rules={[{ required: true, message: 'Please select Country' }]}
                >
                  <Select
                    size="large"
                    placeholder="Select Country"
                    options={[
                      { label: 'India', value: 'IN' },
                      { label: 'Chile', value: 'CL' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <Form.Item
                  name={'description'}
                  label="Description"
                  rules={[{ required: true, message: 'Description is required' }]}
                >
                  <Input.TextArea size="large" placeholder="Enter Description"></Input.TextArea>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col xs={24} md={8} lg={6}>
                <Button block type="primary" htmlType="submit" size="large">
                  <BoldButtonLabel labelText="Create" />
                </Button>
              </Col>{' '}
              <Col xs={24} md={8} lg={6}>
                <Button block size="large" onClick={handleOnGoBack}>
                  <BoldButtonLabel labelText="Go Back" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default DnnCreate;
