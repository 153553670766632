import { useEffect, useState } from 'react';
import { IRewardListItem } from '../types/rewards';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';

export const useRewardNames = (rewardType?: string) => {
  const [rewardList, setRewardList] = useState([] as IRewardListItem[]);

  useEffect(() => {
    loadRewardList();
  }, []);

  const loadRewardList = async () => {
    const { data, errors } = await loyaltyService.getRewardsByType('POINTS');

    if (_.isEmpty(errors)) {
      setRewardList(data);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const rewardNameSelectOptions = rewardList.map(({ name, id }) => ({ label: name, value: id }));

  return { rewardNameSelectOptions };
};
