import { Button, Card, Col, Row, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import { convertQueryStringToObj } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { cbtProductService } from '../../../services/cbt-product.service';
import { useLoader } from '../../../stores/use-loader';

interface ISubscriptionsPageProps {}

const SubscriptionsPage: React.FunctionComponent<ISubscriptionsPageProps> = props => {
  const navigate = useNavigate();

  const [subscriptionData, setSubscription] = React.useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>({
    pageSize: 10,
    currentPage: 1
  });

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const subscriptionColumns: TableColumnsType<any> = [
    {
      title: 'Tenant',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.tenant_id}</>;
      }
    },
    {
      title: 'Domain',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.domain}</>;
      }
    },
    {
      title: 'Start Date',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{new Date(record?.cycle_start_at).toISOString().split('T')[0]}</>;
      }
    },
    {
      title: 'End Date',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{new Date(record?.cycle_end_at).toISOString().split('T')[0]}</>;
      }
    },
    {
      title: 'Status',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.status}</>;
      }
    },
    {
      title: 'Trial',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.trial.toString()}</>;
      }
    },
    {
      title: 'Trial Days Remaining',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.trial_days_remaining}</>;
      }
    },
    {
      title: 'Trial Usage Remaining',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.trial_usage_remaining}</>;
      }
    },
    {
      title: 'Auto Renew',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.auto_renew.toString()}</>;
      }
    },
    {
      title: 'Expire After Cycle',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.expire_after_cycle}</>;
      }
    },
    {
      title: 'Subscription Cycle',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.subscription_cycle}</>;
      }
    },
    {
      title: 'Subscribed At',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{new Date(record?.subscribed_at).toISOString().split('T')[0]}</>;
      }
    },
    {
      title: 'plan_dto Name',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.plan_dto?.name}</>;
      }
    },
    {
      title: 'plan_dto Domain',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.plan_dto?.domain}</>;
      }
    },
    {
      title: 'plan_dto Interval',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.plan_dto?.interval}</>;
      }
    },
    {
      title: 'plan_dto Interval Count',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.plan_dto?.interval_count}</>;
      }
    },
    {
      title: 'plan_dto Trial Days',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.plan_dto?.trial_days}</>;
      }
    },
    {
      title: 'plan_dto Trial Usage Limit',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.plan_dto?.trial_usage_limit}</>;
      }
    }
  ];

  React.useEffect(() => {
    loadIntialData();
  }, [pageControl]); // Reload data when page size or current page changes

  const loadIntialData = () => {
    getSubscriptionData(pageControl.currentPage - 1, pageControl.pageSize);
  };

  const getSubscriptionData = async (offset = 0, limit = 10) => {
    setLoading(true);
    let params = { offset, limit };
    const { data, errors } = await cbtProductService.getSubscriptions(params);
    if (_.isEmpty(errors)) {
      setSubscription(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-2">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                All subscriptions
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={() => navigate('/admin/subscriptions/new')}>
                <BoldButtonLabel labelText="Add New subscription" />
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-6">
            <Table
              pagination={{
                current: pageControl.currentPage,
                total: subscriptionData.page_info?.total_pages * pageControl.pageSize || 0,
                pageSize: pageControl.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize })
              }}
              bordered
              dataSource={subscriptionData?.data}
              columns={subscriptionColumns}
              scroll={{ x: 1000 }}
            />
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default SubscriptionsPage;
