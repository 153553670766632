import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { Button, Card, Col, Input, Result, Row, Table, Tooltip, Typography } from 'antd';
import { IDNNDetailResponseType, PostcodeGroup } from '../../../types/dnn-types';
import { useLoader } from '../../../../../stores/use-loader';
import { logisticService } from '../../../services';
import _ from '../../../../../helpers/lodash';
import FieldDisplay from '../../../../../components/FieldDisplay';
import ActiveInactiveInfo from '../../../../../components/ActiveInactiveInfo';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';

interface IDNNViewProps {}

const DNNView: React.FunctionComponent<IDNNViewProps> = props => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [dnnDetail, setDnnDetail] = React.useState({} as IDNNDetailResponseType);
  const [filteredPostCodes, setFilteredPostcodes] = React.useState([] as PostcodeGroup[]);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    fetchDnnDetails(id as string);
  }, []);

  const fetchDnnDetails = async (id: string) => {
    setLoading(true);

    const { data, errors } = await logisticService.getDNNbyDNNId(id);
    if (_.isEmpty(errors)) {
      setDnnDetail(data);
      setFilteredPostcodes(data.postcode_group);
    }
    setLoading(false);
  };

  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearchPostcode = (searchTerm: string) => {
    if (!searchTerm) setFilteredPostcodes(dnnDetail.postcode_group);
    else {
      const filteredData = (dnnDetail?.postcode_group || []).filter(({ postcode_id }) =>
        postcode_id.toLowerCase().includes(searchTerm)
      );

      setFilteredPostcodes(filteredData);
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Distribution Node Network - {dnnDetail.dnn_name}
              </Typography.Title>
            </Col>
            <Col>
              <ActiveInactiveInfo active={dnnDetail.is_active} />
            </Col>
          </Row>
          <section className="flex flex-wrap flex-col gap-1">
            <FieldDisplay label="DNN Name" value={dnnDetail.dnn_name} />
            <FieldDisplay label="Description" value={dnnDetail.description} />
            <FieldDisplay label="Operator ID" value={dnnDetail.operator_id} />
          </section>
          <section className="mt-4">
            <Row className="my-4">
              <Col xs={24} md={6}>
                <Input.Search
                  placeholder="Search Postcode"
                  size="large"
                  enterButton
                  onSearch={handleSearchPostcode}
                ></Input.Search>
              </Col>
            </Row>

            <Table
              loading={false}
              pagination={{ pageSize: 5 }}
              bordered
              dataSource={filteredPostCodes}
              columns={[
                { title: 'Postcode', dataIndex: 'postcode_id' },
                {
                  title: 'Active',
                  render(value, record, index) {
                    return <>{record.is_active ? 'Yes' : 'No'}</>;
                  }
                }
              ]}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={8} lg={6}>
              <Button
                block
                size="large"
                onClick={() => {
                  if (searchParams.get('backUrl')) {
                    navigate(searchParams.get('backUrl') as string);
                  } else {
                    navigate('/logistics/dnn');
                  }
                }}
              >
                <BoldButtonLabel labelText="Go Back" />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default DNNView;
