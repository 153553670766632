import { Typography } from 'antd';
import * as React from 'react';

interface IFieldDisplayProps {
  label: React.ReactNode;
  value: React.ReactNode;
}

const FieldDisplay: React.FunctionComponent<IFieldDisplayProps> = ({ label, value }) => {
  return (
    <div className='flex gap-1'>
    <Typography.Text className="font-bold text-black">
      {label} </Typography.Text> : <span className="text-black">{value}</span>    
    </div>
  );
};

export default FieldDisplay;
