import { NODE_URI } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';

const JAVA_MAX_INT = 2147483647;
export const getNodeBySearch = (searchTerm: string, offset = 0, limit = JAVA_MAX_INT) => {
  const url = `${NODE_URI}/v1/nodes`;

  return httpClient.get({
    url,
    params: {
      'operator-name': searchTerm,
      limit,
      offset
    }
  });
};
