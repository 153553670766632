import { LOGISTICS } from "../../../config/envConfig";
import httpClient from "../../../http/http.client";

export const updateShippingProfileBasicDetailsById = (id: string, body: any) => {
    const url = `${LOGISTICS}/v1/snns/shipping-profiles/${id}`;
    return httpClient.patch({ url, body });
};

export const updateShippingChargesDetailsByID = (id: string, body: any) => {
    const url = `${LOGISTICS}/v1/snns/shipping-profiles/${id}/charges/shipping`
    return httpClient.post({ url, body });
}

export const createShippingCharges = (body: any) => {
    const url = `${LOGISTICS}/v1/snns/shipping-profiles`
    return httpClient.post({ url, body })
}