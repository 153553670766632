import { create } from 'zustand';

interface ISidemenuStore {
  activeKeys: string[];
  sideMenuCollapsedDesktop: boolean;
  toggleDesktopSideMenuExpanded: () => void;
  setActiveKeys: (activeKeys: string[]) => void;
}

export const useSideMenu = create<ISidemenuStore>()(set => ({
  activeKeys: [],
  setActiveKeys: keys => set({ activeKeys: keys }),
  sideMenuCollapsedDesktop: false,
  toggleDesktopSideMenuExpanded: () => set(state => ({ sideMenuCollapsedDesktop: !state.sideMenuCollapsedDesktop }))
}));
