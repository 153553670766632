import dayjs from 'dayjs';
const todayPlus3 = dayjs().add(3, 'days').toISOString();
const tomorrow = dayjs().add(1, 'days').toISOString();
const todayPlus2 = dayjs().add(2, 'days').toISOString();

function formatDateToCustomString(isoString: string) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateObject = new Date(isoString);
  return dateObject.toLocaleDateString('en-IN');
}

export const promiseOptions = [
  {
    promise_group_id: '1',
    delivery_method: 'HOME_DELIVERY',
    delivery_type: 'STANDARD',
    delivery_cost: 50,
    promise_date: `${dayjs().add(3, 'days').format('DD MMMM')} 2024 By 8PM`,
    pickup_date: '',
    store: '',
    is_recommened: true,
    node_id: '1001',
    node_uuid: '2321973c-5df8-472f-b09b-7bb6c3e3acdd',//'a9c56ec5-33cd-4449-a199-b0e14805e4f7',//changing for now
    delivery_date_iso: todayPlus3,
    expected_date: todayPlus2
  },
  {
    promise_group_id: '2',
    delivery_method: 'HOME_DELIVERY',
    delivery_type: 'EXPRESS',
    delivery_cost: 100,
    promise_date: `${dayjs().add(1, 'days').format('DD MMMM')} 2024 By 8PM`,
    pickup_date: '',
    store: '',
    node_id: '1001',
    node_uuid: '2321973c-5df8-472f-b09b-7bb6c3e3acdd',//'a9c56ec5-33cd-4449-a199-b0e14805e4f7',//changing for now
    delivery_date_iso: tomorrow,
    expected_date: tomorrow
  },
  {
    promise_group_id: '3',
    delivery_method: 'PICK_UP',
    delivery_type: 'PICK_UP',
    delivery_cost: 0,
    promise_date: `${dayjs().add(1, 'days').format('DD MMMM')} 2024`,
    pickup_date: `${dayjs().add(1, 'days').format('DD MMMM')} 2024`,
    store: 'Bangalore',
    node_id: '1002',
    node_uuid: '5ae13c83-cd3c-451d-aa1a-3c3badafdf21',
    delivery_date_iso: tomorrow,
    expected_date: tomorrow
  }
];

export const promiseIdToCourierMap: Record<string, any> = {
  '1': [
    { name: 'Beetrack', igst_rate: 18, delivery_cost: 30 },
    { name: 'Bluedart', igst_rate: 18, delivery_cost: 60 },
    { name: 'Correos de Chile', igst_rate: 18, delivery_cost: 70 }
  ],
  '2': [
    { name: 'Beetrackr Express', igst_rate: 18, delivery_cost: 60 },
    { name: 'Bluedart Express', igst_rate: 18, delivery_cost: 120 },
    { name: 'Correos de Chile Express', igst_rate: 18, delivery_cost: 140 }
  ],
  '3': [{ name: 'Store Pickup', igst_rate: 18, delivery_cost: 0 }]
};

export const promiseIdtoStorePickupMap: Record<string, { slot_id: string; slot: string }[]> = {
  '3': [
    { slot_id: '1', slot: '10:00 AM - 10:30 AM' },
    { slot_id: '2', slot: '10:30 AM - 11:00 AM' },
    { slot_id: '3', slot: '11:00 AM - 11:30 AM' },
    { slot_id: '4', slot: '11:30 AM - 12:00 PM' }
  ]
};

export const promiseValueToLabel: Record<string, string> = {
  PICK_UP: 'Store Pickup',
  HOME: 'Home Delivery',
  EXPRESS: 'Express Delivery',
  STANDARD: 'Standard'
};
