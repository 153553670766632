import { LOGISTICS, NODE_URI } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';

export const getDNNbyDNNId = (id: string) => {
  const url = `${LOGISTICS}/v1/dnns/${id}`;
  return httpClient.get({ url });
};

export const getDNN = (params: any) => {
  const url = `${LOGISTICS}/v1/dnns`;
  return httpClient.get({ url, params });
};

export const getDNNForDebounce = (params: any, fulfillmentType?: string) => {
  const url = `${LOGISTICS}/v1/dnns`;
  let bodyParam = {
    name: params,
    'fulfilment-type': fulfillmentType
  };
  return httpClient.get({ url, params: bodyParam });
};

export const editDNN = (request: any, id: string) => {
  const url = `${LOGISTICS}/v1/dnns/${id}`;
  return httpClient.patch({ url, body: request });
};

export const deleteDNNByDNNId = (id: string) => {
  const url = `${LOGISTICS}/v1/dnns/${id}`;

  return httpClient.delete({ url });
};

export const getSNN = (params: any) => {
  const url = `${LOGISTICS}/v1/snns`;
  return httpClient.get({ url, params });
};

export const createSNN = (body: ISnnPostRequest) => {
  const url = `${LOGISTICS}/v1/snns`;
  return httpClient.post({ url, body });
};

export const deleteSNN = (id: string) => {
  const url = `${LOGISTICS}/v1/snns/${id}`;
  return httpClient.delete({ url });
};

export const getSNNById = (id: string) => {
  const url = `${LOGISTICS}/v1/snns/${id}`;
  return httpClient.get({ url });
};

export const updateSSNChargesById = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/${id}/charges/shipping`;
  return httpClient.put({ url, body });
};

export const updateSSNBasicById = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/${id}`;
  return httpClient.patch({ url, body });
};

export const nodeUpdateChanges = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/${id}/nodes`;
  return httpClient.patch({ url, body });
};

export const updateSNNState = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/${id}/state`;
  return httpClient.patch({ url, body });
};

export const searchNodeByQuery = (params: any) => {
  const url = `${NODE_URI}/v1/nodes`;
  return httpClient.get({ url, params });
};

export const getShippingCharges = (id: string, params: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles`;
  return httpClient.get({ url, params });
};

export const createDnn = (body: any) => {
  const url = `${LOGISTICS}/v1/dnns`;
  return httpClient.post({ url, body });
};

export const updateDnnState = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/dnns/${id}/state`;
  return httpClient.patch({ url, body });
};

export const getPostCodeListDNN = (id: string, params: any) => {
  const url = `${LOGISTICS}/v1/dnns/${id}/postcodes`;
  // /v1/dnns/${id}/postcodes(correct API but not working)
  // /v1/postal-codes(Wrong API but working)
  return httpClient.get({ url, params });
};

export const getNodeListDNN = (id: string, params: any) => {
  const url = `${LOGISTICS}/v1/dnns/${id}/nodes`;
  return httpClient.get({ url, params });
};

export const updateNodeListDNN = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/dnns/${id}/nodes`;
  return httpClient.patch({ url, body });
};

export const createPostalCode = (body: any) => {
  const url = `${LOGISTICS}/v1/postal-codes`;
  return httpClient.post({ url, body });
};

export const removePostCode = (id: string) => {
  const url = `${LOGISTICS}/v1/postal-codes/${id}`;
  return httpClient.delete({ url });
};

export const updateStatusOfPostCode = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/postal-codes/${id}/state`;
  return httpClient.patch({ url, body });
};

export const batchUploadValidation = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/dnns/${id}/postal-code/batch/validations`;
  return httpClient.post({ url, body });
};

export const batchUploadConfirm = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/dnns/${id}/postal-code/batch`;
  return httpClient.post({ url, body });
};

export const updateShippingSNNState = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/${id}/state`;
  return httpClient.patch({ url, body });
};

export const getShippingChargesIdDetails = (id: string) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/${id}`;
  return httpClient.get({ url });
};

export const downloadReport = (body: any) => {
  const url = `${LOGISTICS}/v1/dnns/postal-code/validation-report`;
  return httpClient.post({ url, body: body, responseType: 'arraybuffer' });
};

export const getServiceNetwork = (params: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/service-networks`;
  return httpClient.get({ url, params });
};

export const getServiceNetworkById = (service_network_id: string) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/service-networks/${service_network_id}`;
  return httpClient.get({ url });
};

export const createNewServiceNetwork = (body: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/service-networks`;
  return httpClient.post({ url, body: body });
};

export const removeServiceNetwork = (id: string) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/service-networks/${id}`;
  return httpClient.delete({ url });
};

export const updateServiceNetwork = (id: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/service-networks/${id}`;
  return httpClient.put({ url, body: body });
};

export const removeShippingProfile = (id: string) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/${id}`;
  return httpClient.delete({ url });
};

export const fetchData = (url: any) => {
  return httpClient.get({ url });
};

export const patchLocalDeliveryAvailable = async (nodeID: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/${nodeID}/local-delivery/state`;
  const { data, errors } = await httpClient.patch({ url, body });
  return { data, errors };
};

export const patchNodeIdChanges = async (id: string, nodeId: string, body: any) => {
  const url = `${LOGISTICS}/v1/snns/shipping-profiles/${id}/local-delivery/nodes/${nodeId}/state`;
  const { data, errors } = await httpClient.patch({ url, body });
  return { data, errors };
};
