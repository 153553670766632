import * as React from 'react';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { Card, Col, Form, Row, Typography } from 'antd';
import ServiceNetworkForm from '../../../components/service-network-form';
import { useParams } from 'react-router-dom';
import { useLoader } from '../../../../../stores/use-loader';
import { logisticService } from '../../../services';
import { displayErrorNotifications } from '../../../../../helpers/toast.helpers';
import _ from '../../../../../helpers/lodash';
import { getDayJsObjectWithFormattedString } from '../../../../../helpers/date.helpers';

interface IServiceNetworkViewProps {}

const ServiceNetworkView: React.FunctionComponent<IServiceNetworkViewProps> = props => {
  const [viewForm] = Form.useForm();
  const { id } = useParams();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    getSelectedNetworkDataById();
  }, []);

  const getSelectedNetworkDataById = async () => {
    setLoading(true);
    const { data, errors } = await logisticService.getServiceNetworkById(id as string);
    if (_.isEmpty(errors)) {
      setLoadedDataIntoForm(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setLoadedDataIntoForm = (data: any) => {
    const { service_category, dnn_id, shipping_profile_id, description } = data;
    viewForm.setFieldsValue({
      service_category: service_category,
      dnn_id: dnn_id,
      shipping_profile_id: shipping_profile_id,
      description: description,
      policies: setPolicies(data.policies)
    });
  };

  const setPolicies = (data: any) => {
    let policy = data.map((item: any) => {
      return {
        ...item,
        cut_off_time: getDayJsObjectWithFormattedString(item.cut_off_time, 'HH:mm:ss')
      };
    });
    return policy;
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Service Network
              </Typography.Title>
            </Col>
          </Row>

          <ServiceNetworkForm formInstance={viewForm} readOnly />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ServiceNetworkView;
