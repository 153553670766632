import * as React from 'react';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { Card, Col, Row, Typography, Form } from 'antd';

import ServiceNetworkForm from '../../../components/service-network-form';
import { logisticService } from '../../../services';
import { useParams } from 'react-router-dom';
import { useLoader } from '../../../../../stores/use-loader';
import _ from '../../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import { getDayJsObjectWithFormattedString } from '../../../../../helpers/date.helpers';
import dayjs from 'dayjs';

interface IServiceNetworkEditProps { }

const ServiceNetworkEdit: React.FunctionComponent<IServiceNetworkEditProps> = props => {
  const { id } = useParams();
  const [updateForm] = Form.useForm();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    getSelectedNetworkDataById();
  }, []);


  const getSelectedNetworkDataById = async () => {
    setLoading(true);
    const { data, errors } = await logisticService.getServiceNetworkById(id as string);
    if (_.isEmpty(errors)) {
      setLoadedDataIntoForm(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setLoadedDataIntoForm = (data: any) => {
    const { service_category, dnn_id, shipping_profile_id, description } = data;
    updateForm.setFieldsValue({
      service_category: service_category,
      dnn_id: dnn_id,
      shipping_profile_id: shipping_profile_id,
      description: description,
      policies: setPolicies(data.policies)
    });
  };

  const setPolicies = (data: any) => {
    let policy = data.map((item: any) => {
      return {
        ...item,
        cut_off_time: getDayJsObjectWithFormattedString(item.cut_off_time, 'HH:mm:ss')
      };
    });
    return policy;
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const getShippingChargeForOP = (OrderData: any) => {
    let shipData = OrderData?.map((item: any) => {
      return {
        ...item,
        currency: item?.cost?.currency || 'GET CURRENCY'
      }
    })

    return shipData
  }

  const getShpippingData = (data: any) => {
    if (data?.shipping_charge_type === 'ORDER_PRICE') {
      return {
        ...data,
        order_price_slab_charge_config: getShippingChargeForOP(data?.order_price_slab_charge_config)
      }
    } else if (data?.shipping_charge_type === 'SLAB_WEIGHT') {
      return {
        ...data
      }
    } else if (data?.shipping_charge_type === 'BASE_WEIGHT') {
      return {
        ...data,
        base_weight_charge_config: {
          ...data.base_weight_charge_config,
          additional_weight: {
            ...data.base_weight_charge_config.additional_weight,
            cost: {
              ...data.base_weight_charge_config.additional_weight.cost,
              currency: data?.base_weight_charge_config?.base_weight?.cost?.currency,
            }
          }
        }
      }
    } else if (data?.shipping_charge_type === 'FLAT') {
      return {
        ...data
      }
    } else {
      return {
        ...data
      }
    }
  }

  const getPolicy = (data: any) => {
    return data.map((item: any) => {
      return {
        cut_off_time: getTimeString(item.cut_off_time, 'HH:mm:ss'),
        delivery_option: item.delivery_option,
        is_active: item.is_active,
        max_volume_in_cc: item.max_volume_in_cc,
        max_weight_in_kg: item.max_weight_in_kg,
        min_volume_in_cc: item.min_volume_in_cc,
        min_weight_in_kg: item.min_weight_in_kg,
        shipping_mode: item?.shipping_mode,
        service_execution_lead_time_in_hours: item.service_execution_lead_time_in_hours,
        tat_time_in_hours: item?.tat_time_in_hours,
        shipping_charge: item?.shipping_charge?.shipping_charge_type ? getShpippingData(item?.shipping_charge) : null,
      };
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const bodyData = {
      description: updateForm.getFieldsValue().description,
      dnn_id: updateForm.getFieldsValue().dnn_id,
      policies: getPolicy(updateForm.getFieldsValue().policies),
      service_category: updateForm.getFieldsValue().service_category,
      shipping_profile_id: updateForm.getFieldsValue().shipping_profile_id
    };
    const { errors } = await logisticService.updateServiceNetwork(id as string, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Updated Sucessfully!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Service Network
              </Typography.Title>
            </Col>
          </Row>

          <ServiceNetworkForm formInstance={updateForm} handleSubmit={handleSubmit} isUpdate />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ServiceNetworkEdit;
