import React from 'react';
import _ from '../../../../helpers/lodash';
import { loyaltyService } from '../../../loyalty-engine/services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';

export const useReward = () => {
  const [rewardDropDownFetch, setRewardDropDownFetch] = React.useState(false);
  const [rewardDropDownOptions, setRewardDropDownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchRewardDropDownOptions = async (searchQuery: string) => {
    setRewardDropDownFetch(true);
    let params = {
      name: searchQuery
    };
    const { data, errors } = await loyaltyService.getRewardList(params);
    if (_.isEmpty(errors)) {
      if (Array.isArray(data)) {
        const transformedResponse = data.map(({ name }: { name: string }) => {
          return { label: name, value: name };
        });
        if (Array.isArray(transformedResponse)) {
          setRewardDropDownOptions(() => {
            return [...transformedResponse];
          });
        }
      }
    } else {
      displayErrorNotifications(errors);
    }
    setRewardDropDownFetch(false);
  };

  const debouncedFetchRewarsDropdownOptions = _.debounce(fetchRewardDropDownOptions, 1000);

  return {
    debouncedFetchRewarsDropdownOptions,
    rewardDropDownFetch,
    rewardDropDownOptions
  };
};
