import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import PromotionForm from '../components/promotion-form';
import { Card, Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { promotionServiceCreate } from '../services/promotionCreate.service';
import { IPromotionResponse } from '../types/create-promo';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';

interface IViewPromotionProps {}

const ViewPromotion: React.FunctionComponent<IViewPromotionProps> = props => {
  const { id } = useParams();
  const [promotionResponse, setPromotionResponse] = React.useState({} as IPromotionResponse);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  React.useEffect(() => {
    loadPromotionDetails();
  }, []);
  const loadPromotionDetails = async () => {
    setLoading(true);
    const { data, errors } = await promotionServiceCreate.getPromotionByPromotionId(id as string);

    if (_.isEmpty(errors)) {
      setPromotionResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Promotion
              </Typography.Title>
            </Col>
          </Row>

          <PromotionForm promotionFormInitialValues={promotionResponse} viewOnly />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ViewPromotion;
