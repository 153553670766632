import * as React from 'react';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Select, Spin, Switch, Table, TableColumnsType, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertQueryStringToObj, objectHelpers, urlHelpers } from '../../../../../helpers';
import { useLoader } from '../../../../../stores/use-loader';
import _ from '../../../../../helpers/lodash';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';
import CustomPagination from '../../../../../components/custom-pagination';
import ActionButton from '../../../../../components/ActionButton';
import { logisticService } from '../../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import { IDnnListingItem, IDnnListingResponse } from '../../../types/dnn-types';
import { useOperators } from '../../../hooks/drop-down-hooks/use-operators';
import { getCountryNameByCountry } from '../../../../../data';

interface IDNNListingProps {}

const DNNListing: React.FunctionComponent<IDNNListingProps> = props => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = React.useState<number>(() => {
    const page = parseInt(searchParams.get('offset') || '0');
    return page > 0 ? page + 1 : 1;
  });
  const [pageSize, setPageSize] = React.useState<number>(() => {
    const limit = parseInt(searchParams.get('limit') || '10');
    return limit > 0 ? limit : 10;
  });
  const [searchForm] = Form.useForm();
  const [dnnListingResponse, setDNNListingResponse] = React.useState({} as IDnnListingResponse);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const loadInitialData = async () => {
    await handleFilterSearch(currentPage - 1);
  };

  React.useEffect(() => {
    if (currentPage && pageSize) loadInitialData();
  }, [currentPage, pageSize]);

  React.useEffect(() => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
  }, [searchParams, searchForm]);

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = {
      ...formValues,
      offset,
      limit: pageSize,
      'fulfilment-type': formValues['fulfilment-type'] || 'SALES'
    };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await logisticService.getDNN(filteredParams);
    if (_.isEmpty(errors)) {
      setDNNListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const backUrlParamString = urlHelpers.getBackUrlParamString(searchParams);

  const columnsForPostCodes: TableColumnsType<any> = [
    {
      title: 'DNN Name',
      render(value, record, index) {
        return <>{record.dnn_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Operator Name',
      render(value, record, index) {
        return <>{record.operator_name}</>;
      },
      align: 'center'
    },
    // {
    //   title: 'Country',
    //   render(value, record, index) {
    //     return <>{getCountryNameByCountry(record.country_code)}</>;
    //   },
    //   align: 'center'
    // },
    {
      title: 'Total Postcodes',
      render(value, record, index) {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(`/logistics/dnn/${record.dnn_id}/postcodes?${backUrlParamString}`);
            }}
          >
            {record?.total_postcode}
          </Button>
        );
      },
      align: 'center'
    },
    {
      title: 'Total Active Postcodes',
      render(value, record, index) {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(`/logistics/dnn/${record.dnn_id}/postcodes?${backUrlParamString}`);
            }}
          >
            {record?.total_active_postcode}
          </Button>
        );
      },
      align: 'center'
    },
    {
      title: 'Active',
      render(value, record, index) {
        return (
          <section className="flex justify-center items-center">
            <Switch
              checkedChildren={'Yes'}
              unCheckedChildren={'No'}
              onChange={(value: boolean) => {
                setLoading(true);
                handleStatusChange(value, record);
                setLoading(false);
              }}
              checked={record?.is_active ? true : false}
            />
          </section>
        );
      },
      align: 'center'
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="MANAGE"
              title="Manage postcodes"
              onClick={() => {
                navigate(`/logistics/dnn/${record.dnn_id}/postcodes?${backUrlParamString}`);
              }}
            />
            {/* <ActionButton
              action="VIEW"
              onClick={() => {
                navigate(`/logistics/dnn/${record.dnn_id}?${backUrlParamString}`);
              }}
              title="View DNN"
            /> */}

            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                navigate(`/logistics/dnn/${record.dnn_id}/edit?${backUrlParamString}`);
              }}
              title="Edit DNN"
            />
            {/* <ActionButton
              action={record.is_active ? 'ACTIVATE' : 'DEACTIVATE'}
              onClick={() => handleStatusChange(!record.is_active, record)}
              title={record.is_active ? 'Activate' : 'Deactivate'}
            /> */}
            <ActionButton action="DELETE" title={'Delete DNN'} onClick={() => handleDnnDelete(record)}></ActionButton>
          </section>
        );
      },
      align: 'center'
    }
  ];

  const columnsForNodes: TableColumnsType<any> = [
    {
      title: 'DNN Name',
      render(value, record, index) {
        return <>{record.dnn_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Operator Name',
      render(value, record, index) {
        return <>{record.operator_name}</>;
      },
      align: 'center'
    },
    // {
    //   title: 'Country',
    //   render(value, record, index) {
    //     return <>{getCountryNameByCountry(record.country_code)}</>;
    //   },
    //   align: 'center'
    // },
    {
      title: 'Total Nodes',
      render(value, record, index) {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(`/logistics/dnn/${record.dnn_id}/nodes?${backUrlParamString}`);
            }}
          >
            {record?.total_node}
          </Button>
        );
      },
      align: 'center'
    },
    {
      title: 'Total Active Nodes',
      render(value, record, index) {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(`/logistics/dnn/${record.dnn_id}/nodes?${backUrlParamString}`);
            }}
          >
            {record?.total_active_node}
          </Button>
        );
      },
      align: 'center'
    },
    {
      title: 'Active',
      render(value, record, index) {
        return (
          <section className="flex justify-center items-center">
            <Switch
              checkedChildren={'Yes'}
              unCheckedChildren={'No'}
              onChange={(value: boolean) => {
                setLoading(true);
                handleStatusChange(value, record);
                setLoading(false);
              }}
              checked={record?.is_active ? true : false}
            />
          </section>
        );
      },
      align: 'center'
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="MANAGE"
              title="Manage postcodes"
              onClick={() => {
                navigate(`/logistics/dnn/${record.dnn_id}/nodes?${backUrlParamString}`);
              }}
            />
            {/* <ActionButton
            action="VIEW"
            onClick={() => {
              navigate(`/logistics/dnn/${record.dnn_id}?${backUrlParamString}`);
            }}
            title="View DNN"
          /> */}

            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                navigate(`/logistics/dnn/${record.dnn_id}/edit?${backUrlParamString}`);
              }}
              title="Edit DNN"
            />
            {/* <ActionButton
            action={record.is_active ? 'ACTIVATE' : 'DEACTIVATE'}
            onClick={() => handleStatusChange(!record.is_active, record)}
            title={record.is_active ? 'Activate' : 'Deactivate'}
          /> */}
            <ActionButton action="DELETE" title={'Delete DNN'} onClick={() => handleDnnDelete(record)}></ActionButton>
          </section>
        );
      },
      align: 'center'
    }
  ];

  const handleStatusChange = async (action: boolean, existingDnnDetail: IDnnListingItem) => {
    setLoading(true);
    const bodyData = {
      is_active: action
    };
    const { errors } = await logisticService.updateDnnState(existingDnnDetail.dnn_id as string, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: `DNN ${action ? 'activated' : 'deactivated'}!` });
      handleFilterSearch(0);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleDnnDelete = async (record: any) => {
    setLoading(true);
    const { errors } = await logisticService.deleteDNNByDNNId(record.dnn_id);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'DNN Deleted!' });
      handleFilterSearch(0);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  // const handlePageChange = (currentPage: number) => {
  //   const offset = (currentPage - 1) * 10;

  //   handleFilterSearch(offset);
  // };

  const { debouncedFetchOperatorDropdownOptions, operatorDropdownFetching, operatorDropdownOptions } = useOperators();
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Distribution Node Networks
              </Typography.Title>
            </Col>

            <Col>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  navigate('/logistics/dnn/create');
                }}
              >
                <BoldButtonLabel labelText="Create" />
              </Button>
            </Col>
          </Row>

          <div className="my-4">
            <Form
              onFinish={() => {
                currentPage == 1 ? handleFilterSearch(0) : setCurrentPage(1);
              }}
              form={searchForm}
              layout="vertical"
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'name'} label="DNN Name">
                    <Input placeholder="DNN Name" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'operator-id'} label="Operator Name">
                    <Select
                      allowClear
                      showSearch
                      size="large"
                      filterOption={false}
                      placeholder="Search operators"
                      notFoundContent={operatorDropdownFetching ? <Spin size="small" /> : null}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchOperatorDropdownOptions(searchTerm);
                      }}
                      options={operatorDropdownOptions}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'fulfilment-type'} label="Fulfillment Type">
                    <Select
                      size="large"
                      filterOption={false}
                      placeholder="Fulfillment Type"
                      options={[
                        { label: 'Sales', value: 'SALES' },
                        { label: 'Transfer', value: 'TRANSFER' }
                      ]}
                      defaultValue={'SALES'}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <div className="h-full flex justify-center items-center">
                    <Button type="primary" htmlType="submit" size="large" block>
                      <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>

          <section className="mt-4">
            {/* {dnnListingResponse?.dnns?.length > 0 && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={dnnListingResponse?.current_page + 1 || 0}
                  totalPages={dnnListingResponse.total_pages || 0}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '} */}
            <Table
              loading={false}
              pagination={{
                current: currentPage,
                total: dnnListingResponse?.total_pages * pageSize || 0,
                pageSize: pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onShowSizeChange: (current, size) => {
                  setPageSize(size);
                  setCurrentPage(1); // Reset to first page
                },
                onChange: page => setCurrentPage(page)
              }}
              bordered
              dataSource={dnnListingResponse.dnns}
              columns={searchParams.get('fulfilment-type') === 'SALES' ? columnsForPostCodes : columnsForNodes}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default DNNListing;
