import { COUPONS_URI, LOYALTY_URI, PRICING_URI, PROMISE } from '../config/envConfig';
import httpClient from '../http/http.client';

function sleep(ms = 1000) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const getFulfillmentOptions = async (lines: any) => {
  await sleep();
  return {
    data: {
      business_grp_id: 'BGI0000001',
      tenant_id: 'TID0000001',
      promise_id: 'uuid',
      source_trigger: 'CART', //!Cart
      cart_id: 'ORD0000001',
      order_no: 'ORD0000001',
      order_type: 'FRW',
      channel: 'ONLINE',
      checkout_datetime: 'iso',
      currency_code: 'INR/USD',
      customer_profile_info: {
        profile_id: 'PR0000001',
        profile_type: 'CONSUMER',
        customer_preference: [
          {
            preference_type: 'DELIVERY_METHOD',
            preference_value: 'HOME_DELIVERY'
          }
        ]
      },

      promise_groups: [
        {
          promise_group_id: 'uuid', //!uuid
          delivery_method: 'SHIP',
          delivery_speed: 'Standard',
          ship_node: 'Bangalore',
          promise_delivery_date: '1st June 2023',
          delivery_cost: 100,
          is_recommended: true
        },
        {
          promise_group_id: 'uuid', //!uuid
          delivery_method: 'PICKUP',
          delivery_speed: 'Pickup',
          ship_node: 'Bangalore',
          promise_delivery_date: '29th May',
          delivery_cost: 0,
          is_recommended: true
        }
      ]
    }
  };
};

export const getCustomerLoyaltyData = (customerId: string) => {
  const headers = {
    'x-customer-id': customerId
  };

  const url = `${LOYALTY_URI}/loyalty/v1/customers/rewards/aggregate`;

  return httpClient.get({ url, headers });
};

export const getCouponsPromo = (request: any) => {
  const url = `${COUPONS_URI}/promo-exec/v2/applicability`;
  const params = {
    app: 'CART',
    trigger: 'MANUAL'
  };

  return httpClient.post({ url, body: request, params });
};

export const getLoyaltyEarnInfo = (request: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/customers/earn/check`;
  const params = {
    'applicability-type': 'Order'
  };

  return httpClient.post({ url, params, body: request });
};

export const getLoyaltyPromoFromPricingEngine = (request: any) => {
  const url = `${PRICING_URI}/orders/price/options/aggregate`;
  return httpClient.post({
    url,
    body: request
  });
};

export const getLoyaltyEarnInfoNew = (request: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/customers/earnings/applicability`;
  const params = {
    'applicability-type': 'Order'
  };
  return httpClient.post({
    url,
    body: request,
    params
  });
};

export const getPromosForCart = async (body: any) => {
  const url = `${COUPONS_URI}/promo-exec/v1/transactions/recommend`;
  return httpClient.post({ url, body });
};

export const postApplyCouponFromPromotionService = (request: any) => {
  const url = `${COUPONS_URI}/promo-exec/v2/transactions`;
  const params = {
    action: 'reserve'
  };

  // return {
  //   data: {
  //     coupon_code: 'ORDER',

  //     header_promo_applied: [
  //       {
  //         id: 48,
  //         application_type: 'ORDER_TOTAL',
  //         application: 'CART',
  //         status: 'ACTIVE',
  //         customer_tier: 'everyone',
  //         new_buyer: false,
  //         available_count: 96,
  //         reserved_count: 0,
  //         applied_count: 4,
  //         buyer_selection: 'ALL',
  //         seller_selection: 'ALL',
  //         product_selection: null,
  //         start_date_time_utc: [2023, 11, 15, 18, 30],
  //         end_date_time_utc: [2024, 11, 15, 18, 30],
  //         discount: {
  //           discounts: [
  //             {
  //               type: 'PERCENTAGE',
  //               thresholdSelection: 'MOV',
  //               discountValue: 50.0,
  //               minimumValue: 100.0,
  //               capValue: 200.0,
  //               currency: null,
  //               thresholdType: null,
  //               appliesToPrice: '',
  //               productReward: {
  //                 product_info: []
  //               },
  //               thresholdPercentage: null,
  //               deleted: false,
  //               maxDiscountItemCount: 0,
  //               maxDiscount: 9.0e7
  //             },
  //             {
  //               type: 'PERCENTAGE',
  //               thresholdSelection: 'MOV',
  //               discountValue: 30.0,
  //               minimumValue: 201.0,
  //               capValue: 300.0,
  //               currency: null,
  //               thresholdType: null,
  //               appliesToPrice: '',
  //               productReward: {
  //                 product_info: []
  //               },
  //               thresholdPercentage: null,
  //               deleted: false,
  //               maxDiscountItemCount: 0,
  //               maxDiscount: 999999.0
  //             }
  //           ]
  //         },
  //         usage: {
  //           type: 'REGISTERED_USER',
  //           maxUsage: 100,
  //           limitPerUser: 1,
  //           couponUsageLimit: 100
  //         },
  //         criterias: {
  //           criteriaList: [
  //             {
  //               criteriaOperation: 'INCLUDE',
  //               type: 'COUNTRY',
  //               values: ['INDIA']
  //             },
  //             {
  //               criteriaOperation: 'INCLUDE',
  //               type: 'CUSTOMER_TIER',
  //               values: ['EVERYONE']
  //             }
  //           ]
  //         },
  //         validation: 'UNKNOWN',
  //         decision_reason: null,
  //         combine_with_other_promo: false,
  //         cause: null,
  //         response_type: null,
  //         failed_reason: '',
  //         code: 'ORDER',
  //         priority: 48,
  //         promotion_type: 'SUB_TTL_DIS',
  //         reward_type: 'DISCOUNT',
  //         transaction_apply_count: 1,
  //         is_pos: false,
  //         is_online: false,
  //         buyer_list: [],
  //         seller_list: [],
  //         pos_list: [],
  //         online_list: [],
  //         schedule: null,
  //         trigger: 'MANUAL',
  //         title: 'next 3 orders promotion order level',
  //         discount_info: {
  //           discount: 10.0,
  //           total_reward_amount: 0.0,
  //           combine_with_other_promo: false,
  //           config_id: 0,
  //           promotion_type: 'NONE',
  //           priority: 0,
  //           applicable_sellers: [],
  //           reward_items: [],
  //           next_order_coupon: ''
  //         },
  //         visible_on_pop_up: true,
  //         applies_to_product_in_sale: false,
  //         ignore_price_lock: false,
  //         applicable_action: null,
  //         max_item_discount_count: null,
  //         next_order_applicable: true
  //       }
  //     ]
  //   },
  //   errors: []
  // };

  return httpClient.post({
    body: request,
    params,
    url
  });
};

export const postRedeemAllPromotions = (request: any) => {
  const url = `${COUPONS_URI}/promo-exec/v1/transactions/redeem`;

  return httpClient.post({ url, body: request });
};

export const promiseCreation = (body:any, hashCode:any) => {
  const url = `${PROMISE}/v1/promise`
  const headers = {
    revision: hashCode
  };
  return httpClient.post({ url, body, headers})
}

export const getFullFilmentOption = (promiseId: any, hashCode:any) => {
  const url = `${PROMISE}/v1/promise/${promiseId}/fulfillment-options`
  const headers = {
    revision: hashCode
  };

  return httpClient.post({ url, headers });
}

export const selectionApi = (promiseId:any, bodyData:any, hashCode:any) => {
  const url = `${PROMISE}/v1/promise/${promiseId}/selection`
  const headers = {
    revision: hashCode
  };


  return httpClient.post({ url, body: bodyData, headers });
}

export const updateDelivery = (promiseId: any, bodyData: any, hashCode:any) => {
  const url = `${PROMISE}/v1/promise/${promiseId}/address`
  const headers = {
    revision: hashCode
  };

  return httpClient.put({url, body:bodyData, headers})
}

export const replaceAllProduct = (promiseId:any, bodyData: any, hashCode:any) => {
  const url = `${PROMISE}/v1/promise/${promiseId}/products`
  const headers = {
    revision: hashCode
  };

  return httpClient.put({url, body:bodyData, headers})
}

export const promiseStateCommit = (promiseId: any, statusData:string) => {
  const url =`${PROMISE}/v1/promise/${promiseId}/state`
  const bodyData = {
    status: statusData//'COMMITTED'//OPEN
  }
  return httpClient.post({url, body: bodyData})
}

export const deleteSelectedOptions = (promiseId: any) => {
  const url = `${PROMISE}/v1/promise/${promiseId}`
  return httpClient.delete({url})
}


export const getPromise = (promiseId: any) => {
  const url = `${PROMISE}/v1/promise/${promiseId}`
  return httpClient.get({url})
}