import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { localeCurrencyMap } from '../data/intl-data';
interface ICurrencyStore {
  baseCurrency: string;
  orderNumber: number;
  incrementOrderNumber: () => void;
  promiseNumber: string;
  setPromiseNumber: (promiseNumber: string) => void;
  setUserCurrency: (currency: string) => void;
  userCurrency: string;
  setUserLocale: (locale: string) => void;
  locale: string;
}

export const useLocale = create<ICurrencyStore>()(
  persist(
    set => ({
      baseCurrency: 'INR',
      setUserCurrency: userCurrency => set({ userCurrency }),
      userCurrency: 'CLP',
      locale: 'CL',
      setUserLocale: (locale: string) => set({ userCurrency: localeCurrencyMap[locale], locale }),
      orderNumber: 1000000000,
      incrementOrderNumber: () => set(({ orderNumber }) => ({ orderNumber: orderNumber + 1 })),
      promiseNumber: '',
      setPromiseNumber: promiseNumber => set({ promiseNumber })
    }),
    {
      name: 'createOrderTokens'
    }
  )
);
