import { Form } from 'antd';
import { useState } from 'react';
import { formatDate } from '../../../helpers/date.helpers';
import _ from '../../../helpers/lodash';

interface IOrderReceivingLine {
  delivery_method?: string;
  order_number: string;
  created_date: string;
  fo_number: string;
  ro_number: string;
  node_id: string;
  sku_id: string;
  sku_description: string;
  status: string;
  total_amount: string;
  id: string;
  co_line_id: string;
  tags: any[];
  total_requested_quantity: string | number;
  total_shipped_quantity: string | number;
  total_receivable_quantity: string | number;
}

export const useOrderReceiving = () => {
  // State management
  const [orderReceivingData, setOrderReceivingData] = useState<{
    receiving_lines: IOrderReceivingLine[];
    ro_number: string;
    tenant_id: string;
    business_group_id: string;
    address: any;
    destination_node_id: string;
    destination_node_name: string;
  }>({
    receiving_lines: [],
    ro_number: '',
    tenant_id: '',
    business_group_id: '',
    address: {},
    destination_node_id: '',
    destination_node_name: ''
  });

  const [orderReceivingModalVisible, setOrderReceivingModalVisible] = useState(false);
  const [receivingQuantityChangeModalVisible, setReceivingQuantityChangeModalVisible] = useState(false);
  const [currentEditingId, setCurrentEditingId] = useState<string | null>(null);
  const [receiptNumbers, setReceiptNumbers] = useState<{ [key: string]: string }>({});

  const [receiveQuantityFormInstance] = Form.useForm();

  // Helper function to calculate shipped quantity
  const getShippedQuantity = (tags: any[]): number =>
    tags.reduce(
      (total, tag) =>
        tag.statuses?.reduce(
          (sum: number, status: any) =>
            status.status_description === 'SHIPPED' ? sum + (status.quantity?.number_of_units || 0) : sum,
          total
        ),
      0
    );

  // Transform and set order receiving data from the given record
  const transformAndSetOrderReceivingData = (record: any) => {
    const orderReceivingLines: IOrderReceivingLine[] = [];
    const delivery_method = record?.fulfilment_info?.delivery_method;

    record?.fo_details.forEach((fo: any) => {
      fo.item_list.forEach((item: any) => {
        const tags = item.tags.map((tag: any) => {
          const existingReceivedQuantity = tag?.statuses.find(
            (status: any) => status.status_description === 'RECEIVED'
          )?.quantity;

          return {
            tag_id: tag.tag_id,
            receivable_quantity: tag.quantity.number_of_units - (existingReceivedQuantity?.number_of_units || 0),
            already_received_quantity: existingReceivedQuantity?.number_of_units || 0,
            serial_tag: item.serial_tag,
            destination_node_name:record?.destination_node_name,
          };
        });

        orderReceivingLines.push({
          id: `${fo.fo_number}-${fo.order_number}-${item.co_line_id}`,
          delivery_method,
          order_number: fo.order_number,
          created_date: fo.order_start_trans_datetime,
          fo_number: fo.fo_number,
          ro_number: record.ro_number,
          node_id: record?.node_id,
          sku_id: item.sku_id,
          sku_description: item.product_desc,
          status: item.status_description,
          total_amount: item.total_amount,
          co_line_id: item.co_line_id,
          total_requested_quantity: item.quantity.number_of_units,
          total_shipped_quantity: getShippedQuantity(item.tags || []),
          total_receivable_quantity: tags.reduce((total: any, tag: any) => total + tag.receivable_quantity, 0),
          tags
        });
      });
    });

    setOrderReceivingData({
      receiving_lines: orderReceivingLines,
      ro_number: record.ro_number,
      destination_node_id: record.destination_node_id,
      destination_node_name: record.destination_node_name,
      business_group_id: record.business_group_id,
      tenant_id: record.tenant_id,
      address: record.address.find((address: any) => address.address_tag === 'ship-to-details')
    });
  };

  // Reset local states
  const resetLocalStates = () => {
    setOrderReceivingModalVisible(false);
    setReceivingQuantityChangeModalVisible(false);
    setCurrentEditingId(null);
    setOrderReceivingData({
      receiving_lines: [],
      ro_number: '',
      tenant_id: '',
      business_group_id: '',
      address: {},
      destination_node_id: '',
      destination_node_name: ''
    });
  };

  // Change modal visibility and reset states if closing
  const changeOrderReceivingModalVisibility = (visibility: boolean) => {
    setOrderReceivingModalVisible(visibility);
    if (!visibility) {
      resetLocalStates();
      setReceiptNumbers({});
    }
  };

  // Calculate total received quantity for each item line
  const getTotalReceivedQuantityForEachItemLine = (tags: any[]): number =>
    tags.reduce(
      (total, tag) =>
        total +
        (tag?.quantityInput?.reduce((sum: number, item: any) => sum + (item?.received_quantity_input || 0), 0) || 0),
      0
    );

  // Validate and set edited received line
  const saveTheRecievedQuantityOnceConfirm = () => {
    const lineDetails = orderReceivingData?.receiving_lines.find(line => line.id === currentEditingId);
    if (!lineDetails) return;
    const updatedLines = orderReceivingData?.receiving_lines.map(line =>
      line.id === currentEditingId
        ? {
            ...line,
            tags: receiveQuantityFormInstance.getFieldValue('tags'),
            received_quantity: getTotalReceivedQuantityForEachItemLine(
              receiveQuantityFormInstance.getFieldValue('tags')
            )
          }
        : line
    );

    setOrderReceivingData({
      ...orderReceivingData,
      receiving_lines: updatedLines
    });

    closeReceiveEditModal();
  };

  const getReceiptNumber = (getReceiptNumberOnReceivedQuantityConfirmed: Function) => {
    const lineDetails = orderReceivingData?.receiving_lines.find(line => line.id === currentEditingId);
    if (!lineDetails) return;
    getReceiptNumberOnReceivedQuantityConfirmed(
      lineDetails.order_number,
      lineDetails.ro_number,
      lineDetails.fo_number,
      orderReceivingData.destination_node_id
    );
  };

  // Handle click to edit receive quantity
  const handleClickReceiveEditQuantity = (id: string) => {
    const currentReceivingSkuDetails = orderReceivingData.receiving_lines.find(line => line.id === id);
    const data = (currentReceivingSkuDetails?.tags || []).map(tag => ({
      tag_id: tag.tag_id,
      already_received_quantity: tag.already_received_quantity || 0,

      quantityInput: tag?.quantityInput?.map((item: any) => ({
        received_quantity_input: item.received_quantity_input || 0,
        disposition_code: item.disposition_code || null
      })) || [
        {
          received_quantity_input: 0,
          disposition_code: 'GOOD'
        }
      ],
      ...tag
    }));

    receiveQuantityFormInstance.setFieldsValue({
      tags: data,
      receipt_number:
        receiptNumbers[
          `${currentReceivingSkuDetails?.order_number}-${currentReceivingSkuDetails?.ro_number}-${currentReceivingSkuDetails?.fo_number}`
        ]
    });
    setCurrentEditingId(id);
    setReceivingQuantityChangeModalVisible(true);
  };

  // Close the receive edit modal
  const closeReceiveEditModal = () => {
    receiveQuantityFormInstance.resetFields();
    setCurrentEditingId(null);
    setReceivingQuantityChangeModalVisible(false);
  };

  // Create the request body for receiving
  const createReceiveRequest = () => {
    const foGroupedData = _.groupBy(orderReceivingData.receiving_lines, 'fo_number');

    const getFoLineItems = (items: IOrderReceivingLine[]) =>
      items.map(item => ({
        sku_id: item.sku_id,
        co_line_id: item.co_line_id,
        received_quantity: item.tags.flatMap(tag =>
          tag?.quantityInput
            ?.map((quantityItem: any) =>
              quantityItem.received_quantity_input > 0
                ? {
                    tag_id: tag.tag_id,
                    serial_id: '',
                    disposition_code: quantityItem.disposition_code,
                    quantity: {
                      unit_size: 1,
                      unit_of_measure: 'BAG',
                      number_of_units: quantityItem.received_quantity_input,
                      unit_fraction: 1
                    }
                  }
                : null
            )
            .filter(Boolean)
        )
      }));

    const foLines = Object.entries(foGroupedData).map(([foNumber, items]) => {
      const foLine = items[0];
      return {
        order_number: foLine.order_number,
        fo_number: foNumber,
        receipt_number: receiptNumbers[`${foLine.order_number}-${foLine.ro_number}-${foLine.fo_number}`],
        fo_lines: getFoLineItems(items)
      };
    });

    return {
      ro_number: orderReceivingData.ro_number,
      receiving_node_id: orderReceivingData.destination_node_id,
      receiving_node_name: orderReceivingData.destination_node_name,
      receiving_address: orderReceivingData.address,
      status_datetime: new Date().toISOString(),
      status: 'RECEIVED',
      lines: foLines.filter(line => line.fo_lines.some(foLine => foLine?.received_quantity?.length > 0))
    };
  };

  return {
    orderReceivingData,
    setOrderReceivingData,
    orderReceivingModalVisible,
    changeOrderReceivingModalVisibility,
    validateOrderReceivingData: () => {}, // Placeholder for validation logic
    transformAndSetOrderReceivingData,
    saveTheRecievedQuantityOnceConfirm,
    receivingQuantityChangeModalVisible,
    getReceiptNumber,
    currentReceiveEditingId: currentEditingId,
    setCurrentReceiveEditingId: setCurrentEditingId,
    handleClickReceiveEditQuantity,
    setReceivingQuantityChangeModalVisible,
    closeReceiveEditModal,
    currentReceivingSkuDetails: orderReceivingData.receiving_lines.find(line => line.id === currentEditingId),
    receiveQuantityFormInstance,
    createReceiveRequest,
    setReceiptNumbers
  };
};
