import { CloudUploadOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  TableColumnsType,
  Tag,
  Typography
} from 'antd';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import { convertQueryStringToObj, fileHelpers, objectHelpers, urlHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import { IProductListingResponse, IProducts } from '../../types/product';

interface IProductListingPageProps {}

const ProductListing: React.FunctionComponent<IProductListingPageProps> = props => {
  const navigate = useNavigate();
  const [searchForm] = Form.useForm();
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [productListingResponse, setProductListingResponse] = React.useState({} as IProductListingResponse);
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerUploadResp, setCustomerUploadResp] = React.useState({} as any);
  const [confirmUploadData, setConfirmUploadData] = React.useState<any>(null);

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const backUrlParamString = urlHelpers.getBackUrlParamString(searchParams);

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: TableColumnsType<IProducts> = [
    {
      title: 'Product ID',
      align: 'center',
      render(value, record, index) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Vendor Code',
      align: 'center',
      render(value, record, index) {
        return <>{record?.vendor_code}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <>{record?.product_name}</>;
      }
    },
    {
      title: 'Product Type',
      align: 'center',
      render(value, record, index) {
        return <>{record?.product_type === '' ? '-' : record?.product_type}</>;
      }
    },
    {
      title: 'Supply Type',
      align: 'center',
      render(value, record, index) {
        return <>{record?.supply_type_code}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value, record, index) {
        return (
          <section className="flex justify-center items-center">
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleOnChaneStatus(record?.is_active ? false : true, record?.item_id)}
            >
              <Switch size="small" checked={record?.is_active ? true : false} />
            </Popconfirm>
            &nbsp;&nbsp;
            <div className="cursor-pointer">
              <EditOutlined
                className="cursor-pointer"
                onClick={() => navigate(`/loyalty/products/${record.item_id}?${backUrlParamString}`)}
              />
            </div>
          </section>
        );
      }
    }
  ];

  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  const handleOnChaneStatus = async (value: boolean, itemId: any) => {
    setLoading(true);
    const { errors } = await loyaltyService.updateProductStatus(itemId, value);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Product update success' });
      loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (pageControl?.currentPage && pageControl?.pageSize) loadInitialData();
  }, [pageControl]);

  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      let formParams: any = { ...queryStringObj };
      // if (queryStringObj.sign_up_date_from) {
      //   const date_range = [dayjs(queryStringObj.sign_up_date_from), dayjs(queryStringObj.sign_up_date_to)];
      //   formParams = { ...formParams, date_range };
      // }
      searchForm.setFieldsValue(formParams);
    }

    await handleSearch(pageControl?.currentPage - 1);
  };

  const handleClickUploadCustomers = () => {
    setUploadModalVisible(true);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    let params = { ...formValues, offset, limit: pageControl?.pageSize };
    //DO WE NEED DATE CHECK ONCE
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await loyaltyService.searchProduct(filteredParams);
    if (_.isEmpty(errors)) {
      setProductListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handlePageChange = (currrentPage: number) => {
    handleSearch(currrentPage - 1);
  };

  const handleDownloadTemplate = async () => {
    const { data, errors } = await loyaltyService.downloadSampleProductUploadFile();

    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'Product Upload Template', extension: '.xlsx' });
    }
  };

  const getItemRequest = (rowData: any) => {
    let data = {
      item_id: rowData?.item_id ? rowData?.item_id : null,
      product_identifier_type: rowData?.product_identifier_type ? rowData?.product_identifier_type : null,
      product_name: rowData?.product_name ? rowData?.product_name : null,
      supply_type_code: rowData?.supply_type_code ? rowData?.supply_type_code : null,
      article_id: rowData?.article_id ? rowData?.article_id : null,
      is_variant: rowData?.is_variant ? rowData?.is_variant : null,
      variant_id: rowData?.variant_id ? rowData?.variant_id : null,
      vendor_code: rowData?.vendor_code ? rowData?.vendor_code : null,
      product_type: rowData?.product_type ? rowData?.product_type : null,
      department: rowData?.department ? rowData?.department : null,
      description: rowData?.description ? rowData?.description : null,
      is_branded: rowData?.is_branded ? rowData?.is_branded : null,
      brand: rowData?.brand ? rowData?.brand : null,
      country_of_origin: rowData?.country_of_origin ? rowData?.country_of_origin : null,
      sold_online: rowData?.sold_online ? rowData?.sold_online : null,
      sold_offline: rowData?.sold_offline ? rowData?.sold_offline : null,
      is_active: rowData?.is_active ? rowData?.is_active : null,
      unit_price: rowData?.unit_price ? rowData?.unit_price : null,
      list_price: rowData?.list_price ? rowData?.list_price : null,
      cost_price: rowData?.cost_price ? rowData?.cost_price : null,
      lty_earn: rowData?.lty_earn ? rowData?.lty_earn : null,
      lty_redeem: rowData?.lty_redeem ? rowData?.lty_redeem : null,
      category: rowData?.category ? rowData?.category : null
    };
    return data;
  };

  const loadExcelBodyData = (excelData: any) => {
    let reqData = excelData.map((currentData: any) => {
      let data = getItemRequest(currentData);
      return data;
    });
    return reqData;
  };

  const getValidData = async (excelData: any) => {
    setLoading(true);
    if (excelData.length >= 25000) {
      displayErrorNotifications([{ message: 'Data Cannot Be More Than 25,000 Record!' }]);
    } else if (excelData.length === 0) {
      displayErrorNotifications([{ message: 'No Data Found In Excel!' }]);
    } else {
      let bodyData = loadExcelBodyData(excelData);
      let formatData = { product_list: bodyData };
      const { data, errors } = await loyaltyService.getProductExcelValidation(formatData);
      if (_.isEmpty(errors)) {
        setConfirmUploadData(formatData);
        setCustomerUploadResp(data);
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const handleProductUpload = async () => {
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);
    if (fileList) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed?.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            getValidData(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = fileList[0];
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  const removeModalData = () => {
    setFileList([]);
    setCustomerUploadResp({});
  };

  const handleOnConfirmUpload = async () => {
    const bodyData = confirmUploadData;
    const { errors } = await loyaltyService.confirmUploadProduct(bodyData);
    if (_.isEmpty(errors)) {
      removeModalData();
      setUploadModalVisible(false);
      displaySuccessNotification({ message: `${customerUploadResp?.total_success} Items Added Successfully!!` });
      handleSearch();
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getReportData = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getProductReport(customerUploadResp);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'product_report_excel', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Products
              </Typography.Title>
            </Col>

            <Col>
              <div className="flex gap-4">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    const backUrl = window.location?.pathname;
                    navigate(`/loyalty/products/create?goBackTo=${backUrl}`);
                  }}
                >
                  Create Product
                  <BoldButtonLabel labelText="  " />
                </Button>
                <Button size="large" type="primary" onClick={handleClickUploadCustomers}>
                  <BoldButtonLabel labelText="Upload Products" />
                </Button>
              </div>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form
              layout="vertical"
              onFinish={() =>
                pageControl?.currentPage == 1 ? handleSearch(0) : setPageControl(prev => ({ ...prev, currentPage: 1 }))
              }
              form={searchForm}
            >
              <Row gutter={12} align={'middle'}>
                <Col xs={12} md={6}>
                  <Form.Item name="itemId" label="Item ID">
                    <Input
                      size="large"
                      placeholder="Itme ID"
                      allowClear
                      onBlur={(e: any) => {
                        if (e.target.value === '') {
                          handleSearch(0);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name="vendor_code" label="Vendor Code">
                    <Input
                      size="large"
                      placeholder="Vendor Code"
                      allowClear
                      onBlur={(e: any) => {
                        if (e.target.value === '') {
                          handleSearch(0);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name="productName" label="Product Name">
                    <Input
                      size="large"
                      placeholder="Product Name"
                      allowClear
                      onBlur={(e: any) => {
                        if (e.target.value === '') {
                          handleSearch(0);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name="product_type" label="Product Type">
                    <Input
                      size="large"
                      placeholder="Product Type"
                      allowClear
                      onBlur={(e: any) => {
                        if (e.target.value === '') {
                          handleSearch(0);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} align={'middle'}>
                <Col xs={12} md={6}>
                  <Form.Item label="Status" name="active">
                    <Select
                      placeholder="Status"
                      size="large"
                      allowClear
                      options={[
                        { label: 'Active', value: true },
                        { label: 'Inactive', value: false }
                      ]}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          active: null
                        });
                        handleSearch(0);
                      }}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Supply Type" name="supply_type">
                    <Select
                      placeholder="Supply Type"
                      size="large"
                      allowClear
                      options={[
                        { label: 'B2B', value: 'B2B' },
                        { label: 'B2C', value: 'B2C' }
                      ]}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          supply_type: null
                        });
                        handleSearch(0);
                      }}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Button htmlType="submit" type="primary" size="large">
                    <BoldButtonLabel labelText="Search" />
                  </Button>
                </Col>
                <Col xs={24} md={6}>
                  <Button
                    size="large"
                    onClick={() => {
                      searchForm.resetFields();
                      handleSearch(0);
                    }}
                    block
                  >
                    <BoldButtonLabel labelText="Clear" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
          <section className="mt-4">
            {/* {!_.isEmpty(productListingResponse.data) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={productListingResponse.page_info.current_page}
                  totalPages={productListingResponse.page_info.total_pages}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '} */}
            <Table
              loading={false}
              pagination={{
                current: pageControl?.currentPage,
                total: productListingResponse?.page_info?.total_pages * pageControl?.pageSize || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],

                onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize })
              }}
              bordered
              dataSource={productListingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>

        <Modal
          centered
          open={uploadModalVisible}
          onCancel={() => {
            setUploadModalVisible(false);
            removeModalData();
          }}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Upload Products</Typography.Title>
            </div>
          }
        >
          <section className="flex flex-col justify-center">
            <Button type="link" className="mb-2" onClick={handleDownloadTemplate}>
              Download Sample Template
            </Button>
            <div className="flex justify-center mb-2">
              <Tag color="warning" role="button" className="cursor-pointer">
                Upload CSV files only with a maximum of 25k products per file
              </Tag>
            </div>
            <Dragger
              beforeUpload={file => {
                setFileList([file]);
                return false;
              }}
              onRemove={() => {
                removeModalData();
              }}
              fileList={fileList}
              name="file"
              accept={'.csv,.xlsx'}
            >
              <p>
                <CloudUploadOutlined className="text-9xl text-[#008080]" />
              </p>
              <p>Click or Drag Product Excel File to upload</p>
            </Dragger>

            <Button
              disabled={_.isEmpty(fileList)}
              block
              size="large"
              type="primary"
              onClick={handleProductUpload}
              className="mt-4"
            >
              <BoldButtonLabel labelText="Validate" />
            </Button>
            {Object.keys(customerUploadResp).length !== 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {' '}
                <Typography.Text className="text-blue-500">
                  Total Items: {customerUploadResp?.total_items}
                </Typography.Text>
                <Typography.Text className="text-green-500">
                  Total Success: {customerUploadResp?.total_success}
                </Typography.Text>
                <Typography.Text className="text-red-500">
                  Uploaded File duplicate Enteries : {customerUploadResp?.total_duplicate}
                </Typography.Text>
                <Typography.Text className="text-red-500">
                  Total Failed: {customerUploadResp?.total_failed}
                </Typography.Text>
                <div className="flex gap-5">
                  <Button
                    disabled={customerUploadResp?.total_success === 0}
                    block
                    type="primary"
                    onClick={handleOnConfirmUpload}
                    className="mt-4"
                  >
                    <BoldButtonLabel labelText="Confirm Upload" />
                  </Button>
                  <Button block type="primary" className="mt-4" onClick={getReportData}>
                    <BoldButtonLabel labelText="Download Report" />
                  </Button>
                </div>
              </div>
            )}
          </section>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default ProductListing;
