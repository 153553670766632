import React from 'react';
import _ from '../../../../helpers/lodash';
import { loyaltyService } from '../../../loyalty-engine/services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';

export const useChannel = () => {
  const [channelDropdownFetching, setChannelDropdownFetching] = React.useState(false);
  const [channelDropDownOptions, setChannelDropDownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchChannelDropdownOptions = async (searchQuery: string) => {
    setChannelDropdownFetching(true);
    let params = {
      'channel-name': searchQuery
    };
    const { data, errors } = await loyaltyService.getChannelList(params);
    if (_.isEmpty(errors)) {
      if (Array.isArray(data?.data)) {
        const transformedResponse = data.data.map(
          ({ channel_name, channel_type }: { channel_name: string; channel_type: string }) => {
            return {
              label: `${channel_name}-Type: ${channel_type}`,
              value: channel_name,
              channel_type: channel_type
            };
          }
        );
        if (Array.isArray(transformedResponse)) {
          setChannelDropDownOptions(() => {
            return [...transformedResponse];
          });
        }
      }
    } else {
      displayErrorNotifications(errors);
    }
    setChannelDropdownFetching(false);
  };

  const debouncedFetchChannelDropdownOptions = _.debounce(fetchChannelDropdownOptions, 1000);

  return {
    debouncedFetchChannelDropdownOptions,
    channelDropdownFetching,
    channelDropDownOptions
  };
};
