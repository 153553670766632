import { Card, Col, Form, Row, Typography } from 'antd';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { convertQueryStringToObj, fileHelpers, objectHelpers } from '../../../helpers';
import { getDayJsObjectWithFormattedString } from '../../../helpers/date.helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { useBasicDetails } from '../../../stores/use-promotion';
import { priceListService } from '../services/pricelist.service';
import { IMasterCatalogListing, IPriceListListing } from '../types/price-list-types.s';
import PriceListDiscountForm from './price-list-discount-form';

interface IPriceListViewFormProps {}

const PriceListView: React.FunctionComponent<IPriceListViewFormProps> = () => {
  //new declare
  const { parentId } = useParams();
  const [priceListCreateForm] = Form.useForm();
  const [priceListBasicDetailsForm] = Form.useForm();
  const navigate = useNavigate();
  //new State
  const [referenceBlockVisiblity, setReferenceBlockVisiblity] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paretListDropDown, setParentListDropDown] = React.useState<any>([]);
  const [masterCatalogOptions, setMasterCatalogOptions] = React.useState<any[]>([]);
  const [basicDetailsResp, setBasicDetailsResp] = React.useState({} as any);

  //other declerations
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { offset, backTabTo } = queryStringObj;

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const { productData, setProductData, masterOptions, setMasterOptions, parentOptions, setParentOptions } =
    useBasicDetails(
      ({ productData, setProductData, masterOptions, setMasterOptions, parentOptions, setParentOptions }) => ({
        productData,
        setProductData,
        masterOptions,
        setMasterOptions,
        parentOptions,
        setParentOptions
      })
    );

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = async () => {
    setLoading(true);
    if (backTabTo === 'BASIC-DETAILS') {
      loadbasicDetailstab();
    } else {
      loadPriceEnteriestab();
    }
    setLoading(false);
  };

  const onTabChange = (tabName: any) => {
    setLoading(true);
    if (tabName === 'BASIC-DETAILS') {
      loadbasicDetailstab();
    } else {
      loadPriceEnteriestab();
    }
    setLoading(false);
  };

  const loadbasicDetailstab = async () => {
    priceListCreateForm.setFieldsValue({
      price_list_form_tab: 'BASIC-DETAILS'
    });
    loadTabData();
    getAllMasterCatalogData(0);
    getAllParentCatalogData(0);
  };

  const loadTabData = async () => {
    const { data, errors } = await priceListService.getSelectedItemPriceListData(parentId as string);
    if (_.isEmpty(errors)) {
      setBasicDetailsResp(data);
      setProductData(data);
      setDataToPriceListBasicDetailsForm(data);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getAllMasterCatalogData = async (offset = 0) => {
    let params = { offset, limit: 100 };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const { data, errors } = await priceListService.getMasterData(filteredParams);
    if (_.isEmpty(errors)) {
      let masterCatalogList = data?.data?.map((cv: IMasterCatalogListing) => ({ label: cv.name, value: cv.id }));
      setMasterCatalogOptions(masterCatalogList);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getAllParentCatalogData = async (offset = 0) => {
    const params = { offset, limit: 100 };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const { data, errors } = await priceListService.getPriceList(filteredParams);
    if (_.isEmpty(errors)) {
      let parentCatalogList = data?.price_lists?.map((cv: IPriceListListing) => ({
        label: cv.price_list_name,
        value: cv.id
      }));
      setParentListDropDown(parentCatalogList);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const loadPriceEnteriestab = async () => {
    priceListCreateForm.setFieldsValue({
      price_list_form_tab: "PRICE-ENTERIES"
    });
  };

  const setDataToPriceListBasicDetailsForm = (basicDetailsData: IPriceListListing) => {
    const {
      price_list_name,
      status,
      description,
      currency_code,
      start_date,
      end_date,
      master_catalog_id,
      rank,
      is_exclusive,
      is_resolvable,
      is_pos,
      pos,
      is_online,
      online,
      customer_restrictions,
      id,
      parent_price_list_id,
      start_time,
      end_time,
      timezone,
      rounding,
      is_active,
      ref_update_block
    } = basicDetailsData;
    setReferenceBlockVisiblity(ref_update_block);
    const sd = getDayJsObjectWithFormattedString(start_date, 'YYYY-MM-DD');
    const ed = end_date ? getDayJsObjectWithFormattedString(end_date, 'YYYY-MM-DD') : '';
    const st = getDayJsObjectWithFormattedString(start_time, 'HH:mm:ss');
    const et = end_time ? getDayJsObjectWithFormattedString(end_time, 'HH:mm:ss') : '';
    let POS_Data = pos.map((cv: any) => cv.site_name);
    let ONLINE_Data = online.map((cv: any) => cv.site_name);
    const isCustomeRestrictionsArray = Array.isArray(customer_restrictions);
    priceListCreateForm.setFieldsValue({
      name: price_list_name,
      status: status,
      description: description,
      currency: currency_code,
      rank: rank,
      time_Zone: timezone,
      startDate: sd,
      endDate: ed,
      startTime: st,
      endTime: et,
      rounding_Policy: rounding?.rounding_policy,
      suggestion_list: master_catalog_id ? 'list_master' : 'list_parent',
      select_from_master_catalog_dropDown: master_catalog_id ? master_catalog_id : null,
      parent_price_list: parent_price_list_id ? parent_price_list_id : null,
      is_active: is_active ? 'active' : 'deactive',
      isPriceListExclusive: is_exclusive ? 'priceListExe_yes' : 'priceListExe_no',
      isPriceListResolvable: is_resolvable ? 'priceListRes_yes' : 'priceListRes_no',
      POS: is_pos,
      specific_pos: POS_Data,
      Ecommerce: is_online,
      specific_ecommerce: ONLINE_Data,
      customerRestriction: isCustomeRestrictionsArray ? 'Everyone' : 'Specific',
      customer_restrictions_filters: customer_restrictions
    });
  };

  const handleOnSave = () => {};

  const handleGoBack = () => {
    navigate('/promotion-engine/promotions/price-list');
  };

  const handleOnClickDownload = async () => {
    setLoading(true);
    const { data, errors } = await priceListService.getDownloadData(parentId as string);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'price_enteries_download', extension: '.xlsx' });
      displaySuccessNotification({ message: 'Download success!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const onClickSuggestedLines = () => {
    const params = {
      offset: '0',
      backTabTo: 'PRICE-ENTERIES',
      isEditable: 'false'
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/promotions/price-list/${parentId}/price-list-edit-form/suggested-lines?${paramString}`);
  };

  const onClickCopyLines = () => {
    const params = {
      offset: '0',
      backTabTo: 'PRICE-ENTERIES',
      isEditable: 'false'
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/promotions/price-list/${parentId}/price-list-edit-form/copy-lines?${paramString}`);
  }

  const getProductName = () => {
    let responseData = basicDetailsResp ? basicDetailsResp : productData;
    let masterResp = masterCatalogOptions ? masterCatalogOptions : masterOptions;//array
    let parentResp = paretListDropDown ? paretListDropDown : parentOptions;
    let id = responseData?.master_catalog_id ? responseData?.master_catalog_id : responseData?.parent_price_list_id;
    let masterVal = masterResp.find((item: any) => item.value === id) || null;
    let parentVal = parentResp.find((item: any) => item.value === id) || null;
    let found_Id = responseData?.master_catalog_id ? masterVal : parentVal;
    let referedButton = responseData?.parent_price_list_id ? 'CopyLine' : 'Import';
    referedButton = responseData?.master_catalog_id ? 'SuggestedLine' : referedButton;
    let returnParams = {
      product_Name: found_Id ? found_Id.label : responseData?.import_catalog_ref_name || '',
      prefered_button: referedButton
    };
    return returnParams;
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row>
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                View Price List
              </Typography.Title>
            </Col>
          </Row>
          <PriceListDiscountForm
            istabVisible={true}
            isformVisible={false}
            referenceBlockVisiblity={referenceBlockVisiblity}
            formInstance={priceListCreateForm}
            basicDetailsForm={priceListBasicDetailsForm}
            handleOnSave={handleOnSave}
            handleGoBack={handleGoBack}
            onClickSuggestedLines={onClickSuggestedLines}
            onClickCopyLines={onClickCopyLines}
            paretListDropDown={paretListDropDown}
            masterCatalogOptions={masterCatalogOptions}
            getProductName={getProductName}
            handleOnClickDownload={handleOnClickDownload}
            loadIntialData={onTabChange}
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PriceListView;
