import { useEffect, useState } from 'react';
import { profileService } from '../services';
import _ from '../helpers/lodash';
import { useLoader } from '../stores/use-loader';
import { IProfileDetailsResponse } from '../types/profile.types';

export const useProfile = () => {
  const [profileDetails, setProfileDetails] = useState({} as IProfileDetailsResponse);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const fetchProfileInfo = async () => {
    setLoading(true);
    const { data, errors } = await profileService.getProfileDetails();
    if (_.isEmpty(errors)) {
      setProfileDetails(data);
    }
    setLoading(false);
  };

  return {
    profileDetails,
    fetchProfileInfo
  };
};
