import React, { useState } from 'react';
import { Collapse, Checkbox, Typography, Button, InputRef, Input, Select } from 'antd';
import { productService } from '../../services';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { useLoader } from '../../../../stores/use-loader';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useParams } from 'react-router-dom';
import CustomPagination from '../../../../components/custom-pagination';

const { Panel } = Collapse;

interface IProductCategoryAccordion {
    catagoryRespData: any;
    saveResp?: any
    handleOnCloseCategorySelection?: (product: any, category: any) => void;
    handleOnPageUpdate: (pageNum: number) => void;
}

const ProductCategoryAccordion: React.FunctionComponent<IProductCategoryAccordion> = ({ catagoryRespData, saveResp, handleOnCloseCategorySelection, handleOnPageUpdate }) => {
    const [CategoryData, setCategoryData] = useState(catagoryRespData)
    const [skuResponse, setSkuResponse] = useState({} as any)
    const [SelectedCategory, setSelectedCategory] = useState((saveResp?.categories || []) as any)
    const [SelectedSkus, setSelectedSkus] = useState((saveResp?.skus || []) as any)
    const [activeKey, setActiveKey] = useState([] as any);
    const [currentPage, setCurrentPage] = React.useState(1 as number)
    const [currentSkuPage, setCurrentSkuPage] = React.useState(1 as number)
    const [headCategory, setHeadCategory] = React.useState('' as string)
    const { setLoading } = useLoader();
    const { id } = useParams();

    const handleCheckboxChange = (e: any, key: string) => {
        e.stopPropagation()
        let modifyData = CategoryData?.categories.map((item: any) => {
            if (item?.key === key) {
                if (e.target.checked) {
                    setSelectedCategory((prevState: any) => [...prevState, item?.category])
                } else {
                    setSelectedCategory((prevState: any) => {
                        return prevState.filter((dBItem: any) => dBItem !== item?.category);
                    });
                }
                return {
                    ...item,
                    available: e.target.checked
                }
            } else {
                return item
            }
        })
        let data = {
            ...CategoryData,
            categories: modifyData
        }
        setCategoryData(data)
    };

    const handleSkuCheckboxChange = (e: any, category: any, skuItem: any) => {
        let updateSku = category?.children?.skus.map((item: any) => {
            if (item.sku_id === skuItem.sku_id) {
                if (e.target.checked) {
                    setSelectedSkus((prevState: any) => [...prevState, { sku_id: skuItem.sku_id, category: category.category }])
                } else {
                    setSelectedSkus((prevState: any) => {
                        return prevState.filter((item: any) => item.sku_id !== skuItem.sku_id);
                    });
                }
                return {
                    ...item,
                    skuChecked: e.target.checked
                }
            } else {
                return item
            }
        })

        let modifyData = CategoryData?.categories.map((item: any) => {
            if (item?.key === category.key) {
                return {
                    ...item,
                    children: {
                        ...item.children,
                        skus: updateSku
                    }
                }
            } else {
                return item
            }
        })

        let data = {
            ...CategoryData,
            categories: modifyData
        }

        setCategoryData(data)
    }

    const handleOnClickCollapse = async (SearchKey: any, offset = 1, skuSearchKey = '') => {
        setLoading(true)

        if (offset === 1) {
            setCurrentSkuPage(1)
        }
        setHeadCategory(SearchKey)

        let theKey = Number(SearchKey)
        if (SearchKey.length === 0) {
            setActiveKey([])
        } else {
            setActiveKey([theKey])
        }

        let foundData = CategoryData?.categories?.find((item: any) => item.key === theKey)

        let bodyData = {
            categories: [foundData.category],
            sku_ids: skuSearchKey.length === 0 ? [] : [skuSearchKey]
        }

        const { data, errors } = await productService.getAllProductsFromCategories(bodyData, offset);
        if (_.isEmpty(errors)) {
            setSkuResponse(data)
            let modifySku = data?.skus?.map((item: any) => {
                let presentInResp = SelectedSkus.map((item: any) => item.sku_id).includes(item.sku_id)
                return {
                    ...item,
                    skuChecked: presentInResp
                }
            })
            let skuRespModi = {
                ...data,
                skus: modifySku
            }
            let modifyData = CategoryData?.categories.map((item: any) => {
                if (item?.key === foundData?.key) {
                    return {
                        ...item,
                        children: skuRespModi
                    }
                } else {
                    return item
                }
            })
            let newData = {
                ...CategoryData,
                categories: modifyData
            }
            setCategoryData(newData)
        } else {
            displayErrorNotifications(errors)
        }
        setLoading(false)
    }

    const handleOnSubmit = async () => {
        setLoading(true)
        setActiveKey([]);
        if (handleOnCloseCategorySelection) {
            handleOnCloseCategorySelection(SelectedSkus, SelectedCategory)
        } else {
            const bodyData = {
                categories: SelectedCategory || [],
                skus: SelectedSkus || []
            };
            const { errors } = await productService.patchCategoryAndProduct(id as string, bodyData);
            if (_.isEmpty(errors)) {
                displaySuccessNotification({ message: 'Category / Products updated successfully !' });
            } else {
                displayErrorNotifications(errors);
            }
        }
        setLoading(false)
    }

    const handleCategorySearch = (searchTerm: string) => {
        setLoading(true)
        handlePageChange(1, searchTerm)
        setLoading(false)
    };

    const handleSkuSearch = (searchTerm: string) => {
        handleOnClickCollapse(headCategory, 1, searchTerm)
    }

    const handlePageChange = async (current_page: number, searchTerm = '') => {
        setLoading(true);
        setActiveKey([])
        let params = {
            page: current_page || 1,
            search_key: searchTerm
        }
        let page = current_page || 1
        setCurrentPage(page)
        const { data, errors } = await productService.getAllCategories(params);
        if (_.isEmpty(errors)) {
            let modifyCategory = data?.categories?.map((item: any, index: any) => {
                let presentInResponse = saveResp?.categories?.includes(item.category)
                return {
                    ...item,
                    key: index,
                    available: presentInResponse,
                    children: {}
                }
            })
            let modifiedData = {
                ...data,
                categories: modifyCategory
            }
            setCategoryData(modifiedData)
        }
        // handleOnPageUpdate(page) //!call back not working so directly called API for urgent
        setLoading(false);
    }

    const handlePageChangeforSku = async (current_page: number) => {
        setCurrentSkuPage(current_page)
        handleOnClickCollapse(headCategory, current_page)
    }

    return (
        <>
            <Input.Search
                className='mb-4'
                placeholder="Search Categories"
                onSearch={handleCategorySearch}
                allowClear
                enterButton
                size="large"
            ></Input.Search>
            <div className='flex flex-row-reverse mb-2'>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={CategoryData?.total_pages}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                />
            </div>
            <Collapse accordion activeKey={activeKey} onChange={handleOnClickCollapse}>
                {(CategoryData?.categories || []).map((item: any, index: any) => {
                    return (
                        <Panel
                            header={
                                <Checkbox
                                    onChange={e => handleCheckboxChange(e, item.key)}
                                    checked={item.available}
                                >
                                    <Typography className='font-bold'>{item.category}</Typography>
                                </Checkbox>
                            }
                            key={index}
                        >
                            <div className='flex justify-between items-center mb-2'>
                                <Input.Search
                                    className='mb-4 w-[250px]'
                                    placeholder="Search Sku"
                                    onSearch={handleSkuSearch}
                                    allowClear
                                    enterButton
                                    size="large"
                                ></Input.Search>
                                <CustomPagination
                                    currentPage={currentSkuPage}
                                    totalPages={skuResponse?.total_pages}
                                    handleNext={handlePageChangeforSku}
                                    handlePageChange={handlePageChangeforSku}
                                    handlePrevious={handlePageChangeforSku}
                                />
                            </div>
                            {item?.children?.skus?.map((skuItems: any) => {
                                return (
                                    <div className='flex'>
                                        <Checkbox
                                            onChange={e => handleSkuCheckboxChange(e, item, skuItems)}
                                            checked={skuItems?.skuChecked}
                                        >
                                            <p className='font-semibold'>{skuItems?.sku_id}</p>
                                        </Checkbox>
                                    </div>
                                )
                            })}
                        </Panel>
                    )
                })}
            </Collapse>
            <section className="my-4 px-4">
                <Button block type="primary" size="large" onClick={handleOnSubmit}>
                    <BoldButtonLabel labelText="Submit" />
                </Button>
            </section>
        </>
    );
};

export default ProductCategoryAccordion;
