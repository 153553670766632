import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select, Spin, Typography } from 'antd';
import * as React from 'react';
import { countryList, phoneCountryCodeList } from '../../../../data/intl-data';
import { stateList } from '../../../../data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { validationHelpers } from '../../../../helpers';
import { useChannel } from '../../../promotion-engine/hooks/drop-down-hooks/use-channel';
import { useProducts } from '../../../promotion-engine/hooks/drop-down-hooks/use-products';
import { DeleteOutlined } from '@ant-design/icons';
import { useReward } from '../../../promotion-engine/hooks/drop-down-hooks/use-rewards';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import _ from '../../../../helpers/lodash';

interface ICustomerFormProps {
  initialFormValues?: any;
  isUpdate?: boolean;
  formInstance: FormInstance<any>;
  handleFinish?: () => void;
  handleGoBack?: () => void;
}

const CustomerForm: React.FunctionComponent<ICustomerFormProps> = ({
  initialFormValues,
  formInstance,
  handleFinish,
  isUpdate,
  handleGoBack
}) => {
  const [allChannelOptions, setAllChannelOptions] = React.useState([] as any);
  const { debouncedFetchChannelDropdownOptions, channelDropdownFetching, channelDropDownOptions } = useChannel();
  const { debouncedFetchRewarsDropdownOptions, rewardDropDownFetch, rewardDropDownOptions } = useReward();

  React.useEffect(() => {
    getChannelList();
  }, []);

  const getChannelList = async () => {
    const { data, errors } = await loyaltyService.getAllChannelList();
    if (_.isEmpty(errors)) {
      let optionData = data?.data?.map((item: any) => {
        let nameStr = `${item?.channel_name} type - ${item?.channel_type}`;
        let obj = {
          label: nameStr,
          value: nameStr,
          channel_type: item?.channel_type
        };
        return obj;
      });
      setAllChannelOptions(optionData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  return (
    <Form form={formInstance} onFinish={handleFinish} layout="vertical" scrollToFirstError={true}>
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          Basic Details
        </Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_id"
              label="Customer Id"
              rules={[
                {
                  required: true,
                  message: 'Please enter Customer ID'
                },
                {
                  max: 20,
                  message: 'Max 20 characters'
                }
              ]}
            >
              <Input disabled={isUpdate} size="large" placeholder="Customer ID" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your first name'
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: 'First name can only contain letters and spaces'
                },
                {
                  min: 2,
                  message: 'First name must be at least 2 characters long'
                },
                {
                  max: 20,
                  message: 'Max 20 characters'
                }
              ]}
            >
              <Input size="large" placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your last name'
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: 'Last name can only contain letters and spaces'
                },
                {
                  max: 20,
                  message: 'Max 20 characters'
                }
              ]}
            >
              <Input size="large" placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email'
                },
                {
                  type: 'email',
                  message: 'Invalid email format'
                },
                {
                  max: 50,
                  message: 'Max 50 characters'
                }
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Phone Number"
              name={['phone_number']}
              rules={[
                {
                  required: false,
                  message: 'Phone number is required'
                },
                {
                  pattern: /^[0-9\s\-\+\(\)]+$/,
                  message: 'Phone number must be a valid format'
                },
                {
                  min: 8,
                  message: 'min should be 8 Number'
                },
                {
                  max: 15,
                  message: 'Max 15 characters'
                }
              ]}
            >
              <Input
                addonBefore={
                  <Form.Item
                    initialValue={phoneCountryCodeList[0]?.value}
                    noStyle
                    name={['dial_code']}
                    rules={[
                      { required: true, message: 'Dial code is required' },
                      {
                        max: 15,
                        message: 'Max 15 characters'
                      }
                    ]}
                  >
                    <Select style={{ width: 120 }} options={phoneCountryCodeList} size="large" />
                  </Form.Item>
                }
                placeholder="Enter Phone Number"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="date_of_birth" label="Date of Birth">
              <DatePicker size="large" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="sign_up_date" label="Sign-up Date">
              <DatePicker size="large" className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_type"
              label="Customer Type"
              rules={[{ required: true, message: 'Customer type is required' }]}
            >
              <Select size="large" placeholder="Select Customer Type">
                <Select.Option value="CUS">Customer</Select.Option>
                <Select.Option value="EMP">Employee</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="refered_by" label="Referred By">
              <Input size="large" placeholder="Enter Referred by" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="supply_type_code"
              label="Supply Type"
              rules={[{ required: true, message: 'This Field is required' }]}
            >
              <Select size="large" placeholder="Select supply type">
                <Select.Option value="B2C">B2C</Select.Option>
                <Select.Option value="B2B">B2B</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'This Field Is Required'
                }
              ]}
            >
              <Select
                options={countryList}
                placeholder="Select Country"
                allowClear
                size="large"
                onChange={() => {
                  formInstance.setFieldsValue({ state: null });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => {
                return prev.country !== curr.country;
              }}
            >
              {form => {
                const { country } = form.getFieldsValue();

                const stateOptions = stateList[country] || [];

                return (
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: 'This Field Is Required'
                      }
                    ]}
                  >
                    <Select size="large" placeholder="Select State" options={stateOptions} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                {
                  max: 50,
                  message: 'Max 50 characters'
                }
              ]}
            >
              <Input size="large" placeholder="Enter location" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="postal_code"
              label="Postal Code"
              rules={[
                {
                  required: false,
                  message: 'This Field Is Required'
                }
              ]}
            >
              <Input size="large" placeholder="Enter Postal Code" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="member_type" label="Source Member Type">
              <Input size="large" placeholder="Enter Source Member Type" />
            </Form.Item>
          </Col>
        </Row>{' '}
      </div>
      {!isUpdate && (
        <div
          style={{
            height: 'fit-content',
            background: '#9b9b9b29',
            padding: '20px',
            marginBottom: '1rem',
            borderRadius: '10px'
          }}
        >
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            Registered Channel Info
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                name={'registered_channel_name'}
                label={`Registered Channel`}
                rules={[
                  {
                    required: true,
                    message: 'This Field Is Required'
                  }
                ]}
              >
                <Select
                  options={allChannelOptions}
                  placeholder="Registered Channel Name"
                  allowClear
                  size="large"
                  onChange={(value: any, objData: any) => {
                    formInstance.setFieldsValue({
                      registered_channel_type: objData?.channel_type
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item name={'registered_channel_type'} label={`Registered Channel Type`}>
                <Input size="large" placeholder={`Registered Channel Type`} disabled />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={'site_id'}
                label={`Site Id`}
                rules={[{ required: false, message: 'Site Id is required' }]}
              >
                <Input size="large" placeholder={`Site Id`} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          Alternate Customer Identitity
        </Typography.Title>
        <Form.List name="channel_identity_info">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row gutter={12} key={key}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={[name, 'channel_name']}
                      label={`Channel ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: 'This Field Is Required'
                        }
                      ]}
                    >
                      <Select
                        options={allChannelOptions}
                        placeholder="Channel Name"
                        allowClear
                        size="large"
                        onChange={(value: any, objData: any) => {
                          formInstance.setFields([
                            {
                              name: ['channel_identity_info', name, 'channel_type'],
                              value: objData?.channel_type
                            }
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item name={[name, 'channel_type']} label="Channel Type">
                      <Input size="large" placeholder="Channel Type" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={[name, 'alternate_id']}
                      label={`Alternate Id ${index + 1}`}
                      rules={[
                        { required: true, message: 'Alternate Id is required' },
                        { max: 50, message: 'Max 50 characters' }
                      ]}
                    >
                      <Input size="large" placeholder={`Alternate Id ${index + 1}`} />
                    </Form.Item>
                  </Col>
                  <Col className="ml-4 mt-8">
                    <DeleteOutlined
                      className="text-red-500 text-xl"
                      title={`Delete Channel ${index + 1}`}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button
                  type="primary"
                  onClick={async () => {
                    try {
                      const fieldsToValidate: any[] = [];

                      // Populate fieldsToValidate with paths for channel_identity_info fields
                      fields.forEach((_, index) => {
                        fieldsToValidate.push(['channel_identity_info', index, 'channel_name']);
                        fieldsToValidate.push(['channel_identity_info', index, 'alternate_id']);
                      });

                      // Validate only the specific fields in channel_identity_info
                      await formInstance.validateFields(fieldsToValidate);

                      // If validation passes, add a new field
                      add();
                    } catch (error) {
                      console.log('Validation failed:', error);
                    }
                  }}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      {!isUpdate && (
        <div
          style={{
            height: 'fit-content',
            background: '#9b9b9b29',
            padding: '20px',
            marginBottom: '1rem',
            borderRadius: '10px'
          }}
        >
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            Loyality Reward
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item label="Reward Name" name={'reward_name'}>
                <Select
                  // mode="multiple"
                  showSearch
                  allowClear
                  size="large"
                  filterOption={false}
                  placeholder={`Reward Name`}
                  notFoundContent={rewardDropDownFetch ? <Spin size="small" /> : null}
                  onSearch={async searchTerm => {
                    if (searchTerm) await debouncedFetchRewarsDropdownOptions(searchTerm);
                  }}
                  options={rewardDropDownOptions}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Reward Points"
                name="reward_points"
                rules={[
                  { required: false, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder="Reward Points" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Active in days"
                name="active_in_days"
                rules={[
                  { required: false, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder="Active in days" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Expire in days"
                name="expire_in_days"
                rules={[
                  { required: false, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder="Expire in days" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Button size="large" block onClick={handleGoBack}>
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
        {typeof handleFinish === 'function' && (
          <Col xs={24} md={6}>
            <Button size="large" block type="primary" htmlType="submit">
              <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'} />
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default CustomerForm;
