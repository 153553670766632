import React from 'react';

import _ from '../../../../helpers/lodash';
import { nodeService } from '../../services';
// import { operatorService } from '../../services';

const cache: Record<string, any> = {};

export const useNodeDropdownSearch = () => {
  const [nodeDropdownFetching, setNodeDropdownFetching] = React.useState(false);
  const [nodeDropdownOptions, setNodeDropdownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchOperatorDropdownOptions = async (searchQuery: string) => {
    setNodeDropdownFetching(true);

    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setNodeDropdownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await nodeService.getNodeBySearch(searchQuery);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setNodeDropdownOptions(() => {
          return [...transformedResponse];
        });
      }
    }
    setNodeDropdownFetching(false);
  };

  const transformResponse = (data: any) => {
    const operators = data?.nodes || [];
    return (operators || []).map(({ node_id, node_name }: { node_id: string; node_name: string }) => {
      return { label: `${node_name}`, value: node_id };
    });
  };

  const debouncedFetchNodeDropdownOptions = _.debounce(fetchOperatorDropdownOptions, 1000);

  return {
    debouncedFetchNodeDropdownOptions,
    nodeDropdownFetching,
    nodeDropdownOptions
  };
};
