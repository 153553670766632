import { Card, Form } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CatalogForm from './catalog-form';
import React from 'react';
import { useLoader } from '../../../stores/use-loader';
import { priceListService } from '../services/pricelist.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';

interface ICatalogEditProps {}

const CatalogEdit: React.FunctionComponent<ICatalogEditProps> = props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    loadInitialCustomeDetails();
  }, []);

  const loadFieldData = async () => {
    const { data, errors } = await priceListService.getSpecificCatalog(id as string);
    if (_.isEmpty(errors)) {
      populateEditFormWithData(data);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const loadInitialCustomeDetails = async () => {
    setLoading(true);
    loadFieldData();
    setLoading(false);
  };

  const handleSave = async () => {
    const { name, description } = editForm.getFieldsValue();
    const bodyData = {
      name: name,
      description: description
    };
    const { data, errors } = await priceListService.updateMasterData(bodyData);
    if (_.isEmpty(errors)) {
      populateEditFormWithData(data);
      displaySuccessNotification({ message: 'Successfully updated!' });
    } else {
      displayErrorNotifications(errors);
    }
  };

  const resetData = () => {
    loadFieldData()
  }

  const handleGoBack = () => {
    const backUri = searchParams.get('goBackTo');
    if (backUri) {
      navigate(`${backUri}`);
    } else {
      navigate(`/promotion-engine/promotions/catalog`);
    }
  };

  const populateEditFormWithData = (data: any) => {
    const { name, description } = data;
    editForm.setFieldsValue({
      name: name,
      description: description
    });
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <CatalogForm
            buttonLabel={'Update'}
            isUpdate={true}
            formInstance={editForm}
            handleSave={handleSave}
            handleGoBack={handleGoBack}
            resetData={resetData}
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CatalogEdit;
