export const triggerFileDownload = ({
  data,
  extension,
  fileName
}: {
  fileName?: string;
  data: any;
  extension: string;
}) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName || 'Sample'}${extension}`);
  document.body.appendChild(link);
  link.click();

  link.remove();
};


export const parseQueryString = (queryString: string): { [key: string]: string } => {
  // Remove the leading '?' if present
  const query = queryString.startsWith('?') ? queryString.substring(1) : queryString;

  // Use URLSearchParams to parse the query string
  const params = new URLSearchParams(query);

  // Convert the URLSearchParams object to a regular object
  const paramsObject: { [key: string]: string } = {};
  Array.from(params.entries()).forEach(([key, value]) => {
    paramsObject[key] = value;
  });

  return paramsObject;
};

export const fetchReqData = (current_arr_Record: any, key: any, value: any) => {
  let data = current_arr_Record?.find((item: any) => {
    return item[key] === value
  })
  return data
}

