import { envConfig } from '../../../config';
import { ASSETS, ASSETS_NEBULA, ORCHESTRATION_URI, ORDERS_BASE_URI } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';

export const getRoDetailsByRoNumber = async (roNumber: string) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/release-orders`;
  const params = {
    ro_number: roNumber
  };

  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors };
};

export const getReleaseOrderAggregates = async (nodeId: string, deliveryOption: string, created_on?: string) => {
  const params = {
    node_id: nodeId,
    created_after: created_on,
    delivery_method: deliveryOption
  };
  const url = `${envConfig.ORDERS_BASE_URI}/v1/release-orders/status-summary`;

  return httpClient.get({ url, params });
};

export const fetchRoList = async (params: any) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/release-orders/search`;

  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors };
};

export const fetchToList = async (params: any) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/fulfillment-order/search`;

  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors };
};
export const fetchShipmentList = (params: any) => {
  const url = `${envConfig.SHIPMENTS_BASE_URI}/shipments/v1/search`;
  return httpClient.get({ params, url });
};

export const fetchShipmentByRoId = (roId: string) => {
  const url = `${envConfig.SHIPMENTS_BASE_URI}/ros/v1/orders/${roId}/shipments
  `;
  return httpClient.get({ url });
};

export const createShipment = (request: any, roId: string) => {
  const url = `${envConfig.SHIPMENTS_BASE_URI}/ros/v1/orders/${roId}/shipments`;

  return httpClient.post({ body: request, url });
};

const wait = () => {
  return new Promise(resolve => {
    setTimeout(resolve, 2000);
  });
};

export const pickOrder = async (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/release-orders/status/fulfillments`;

  return httpClient.post({ url, body: request });

  // return httpClient.post({ body: request, url });
};

export const changeShipmentStatus = (shipmentId: string, roId: string, status: string) => {
  const url = `${envConfig.SHIPMENTS_BASE_URI}/ros/v1/orders/${roId}/shipments/${shipmentId}/statuses/${status}`;

  return httpClient.patch({ url });
};

export const handleRoStatusChange = (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/release-orders/status/acknowledgment`;
  return httpClient.patch({ url, body: request });
};

export const handleRoStatusUpdate = (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/release-orders/status/fulfillments`;

  return httpClient.post({ url, body: request });
};

export const parcelRo = (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/release-orders/parcels`;

  return httpClient.post({ url, body: request });
};

export const carrierUpdate = (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/release-orders/carrier/status`;

  return httpClient.post({ url, body: request });
};

// export const cancelRo = (request: any) => {
//   const url = `${envConfig.ORDERS_BASE_URI}/v1/release-orders/cancel`;
//   return httpClient.post({ url, body: request });
// };

export const cancelROFO = (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/orders/cancel`;
  return httpClient.post({ url, body: request });
};

export const generateInvoice = (request: any) => {
  const url = `${envConfig.INVOICE_URL}/v1/invoices`;

  return httpClient.post({ url, body: request });
};

export const generateInvoiceNew = (ro_number: any) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/release-orders/invoices/${ro_number}`;
  return httpClient.patch({ url });
};

export const docGenInvoice = (request: any) => {
  const url = `${envConfig.DOC_GEN_URL}/v1/generate`;

  return httpClient.post({ url, body: request });
};

export const generateShiplabel = (request: any) => {
  const url = `${envConfig.DOC_GEN_URL}/v1/generate`;
  const headers = {
    format: 'PDF'
  };

  return httpClient.post({ url, body: request, headers });
};

export const generateShiplabelNew = (ro_number: any) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/release-orders/labels/${ro_number}`;

  return httpClient.patch({ url });
};

export const downloadPDF = async (invoiceLink: any) => {
  const url = `${ASSETS}/assets/v1/view`;
  const headers = {
    bucket_name: `${ASSETS_NEBULA}`,
    file_path: `${invoiceLink}`,
    download: true
  };
  return httpClient.get({ url, headers, responseType: 'arraybuffer' });
};
export const downloadShipLabel = async (ShipLink: any) => {
  const url = `${ASSETS}/assets/v1/view`;
  const headers = {
    bucket_name: `${ASSETS_NEBULA}`,
    file_path: `${ShipLink}`,
    download: true
  };
  return httpClient.get({ url, headers, responseType: 'arraybuffer' });
};

export const releaseOrderPackUpdate = (bodyData: any) => {
  const url = `${ORCHESTRATION_URI}/v1/release-orders/pack`;
  return httpClient.post({ url, body: bodyData });
};

export const getReceiptNumber = (bodyData: any) => {
  const url = `${ORDERS_BASE_URI}/v1/receipts/transfer/receive`;
  return httpClient.post({ url, body: bodyData });
};

export const receiveOrder = (bodyData: any) => {
  const url = `${ORDERS_BASE_URI}/v1/transfer-release-orders/receiving`;
  return httpClient.patch({ url, body: bodyData });
};
