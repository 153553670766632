import { notification } from 'antd';
import { ApiError } from '../types/error-types';

export const displayErrorNotifications = (errors: ApiError[]) => {
  errors.forEach(error => {
    notification.error({ message: error.message, placement: 'top' });
  });
};

export const displaySuccessNotification = ({ message }: { message: string }) => {
  notification.success({ placement: 'top', message });
};

export const displayWarningMessage = ({ message }: { message: string }) => {
  notification.warning({ placement: 'top', message });
};

export const displayInfoMessage = ({ message, time = 10000 }: { message: string; time?: number }) => {
  notification.info({ placement: 'top', message });
};
