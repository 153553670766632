import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Table,
  TableColumnsType,
  Tag,
  Typography
} from 'antd';
import * as React from 'react';
import { flushSync } from 'react-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { countryCodeMap, countryStateMap, stateList } from '../../data';
import { currencyList, priceHelpers } from '../../data/intl-data';
import { presetProductDetailsMap, presetProductList } from '../../data/products';
import { promiseValueToLabel } from '../../data/promise';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { generateUUIDV4 } from '../../helpers/uuid.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { orderService } from '../../services';
import { useLocale } from '../../stores/use-locale';
import { ProductMasterType } from '../../types/create-order-types';

import FieldDisplay from '../../components/FieldDisplay';
import {
  deleteSelectedOptions,
  getCouponsPromo,
  getCustomerLoyaltyData,
  getFullFilmentOption,
  getLoyaltyEarnInfoNew,
  getPromise,
  getPromosForCart,
  postApplyCouponFromPromotionService,
  postRedeemAllPromotions,
  promiseCreation,
  promiseStateCommit,
  replaceAllProduct,
  selectionApi,
  updateDelivery
} from '../../services/create-order.service';

import { IconTag } from '@tabler/icons';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { loyaltyService } from '../../modules/loyalty-engine/services/loyalty.service';
import { priceService } from '../../services/price.service';
import { useAuth } from '../../stores/use-auth';
import { useLoader } from '../../stores/use-loader';
import { useOrder } from '../../stores/use-order';
import { customerData } from './data';

const STATIC_GIFT_CHARGE = 20;

interface IOrderCreationProps {}

interface Option {
  option_id: string;
  grp_Id: string;
}

const OrderCreation: React.FunctionComponent<IOrderCreationProps> = props => {
  const [loader, setLoader] = React.useState(false);
  const [selectedFullfillmentOptions, setselectedFullfillmentOptions] = React.useState<any[]>([]);
  const [feasibleData, setFeasibleData] = React.useState({} as any);
  const [colineId, setColineId] = React.useState({} as any);
  const [customerInfoForm] = Form.useForm();
  const [shippingInfoForm] = Form.useForm<{
    address_line1: string;
    address_line2: string;
    country: string;
    country_code: string;
    location: string;
    place_of_supply: string;
    postal_code: string;
    state: string;
    state_code: string;
    ship_together: boolean;
    multipart_shipment: boolean;
    max_shipments?: number;
  }>();
  const [billingInfoForm] = Form.useForm<{
    address_line1: string;
    address_line2: string;
    country: string;
    country_code: string;
    location: string;
    place_of_supply: string;
    postal_code: string;
    state: string;
    state_code: string;
    ship_together: boolean;
    multipart_shipment: boolean;
    max_shipments?: number;
  }>();
  const [customerSelectionForm] = Form.useForm();

  const [promiseInfo, setPromiseInfo] = React.useState<{
    promise_group_id: string;
    delivery_date: string;
    delivery_type: string;
    delivery_method: string;
    delivery_cost: number;
    store?: string;
    pickup_date: string;
    courier_name?: string;
    node_id: string;
    node_uuid: string;
    slot_id?: string;
    delivery_date_iso?: string;
    slot?: string;
    expected_date: string;
  }>({} as any);

  const [promiseModalVisible, setPromiseModalVisible] = React.useState(false);

  const [editableItemInfo, setEditableItemInfo] = React.useState<
    Record<
      string,
      {
        quantity?: number;
        uom?: string;
        discount?: number;
        product_total?: number;
        is_gift?: boolean;
        misc_charge?: number;
        convenience_charge?: number;
        gifting_instructions?: string;
      }
    >
  >({});

  const [itemList, setItemList] = React.useState<ProductMasterType[]>([]);

  const [customerLoyaltyData, setCustomerLoyaltyData] = React.useState(
    {} as { reward_value: number; cash_value: number; redeemable_value: number; redeemable_cash_value: number }
  );
  const [giftingForm] = Form.useForm();

  const [customerDetails, setCustomerDetails] = React.useState({} as any);

  const [loyaltyModalVisible, setLoyaltyModalVisible] = React.useState(false);

  const [loyaltyRedeemValue, setLoyaltyRedeemValue] = React.useState(0);

  const [loyaltyEarnData, setLoyaltyEarnData] = React.useState(
    {} as { reward_value: number; point_conversion_factor: number }
  );

  const [redeemForm] = Form.useForm();

  const { bussinessGroupName, tenentName } = useAuth(({ bussinessGroupName, tenentName }) => ({
    bussinessGroupName,
    tenentName
  }));

  const tableColumns: TableColumnsType<ProductMasterType> = [
    {
      title: 'SKU',
      width: 200,
      align: 'center',
      render: (_, record) => {
        return (
          <Select
            showSearch
            // className="w-full w-[40px]"
            style={{ minWidth: '90px' }}
            size="large"
            options={articleIdOptions}
            inputValue={record?.sku_id}
            placeholder="Search Product ID"
            onChange={value => {
              handleSelectChange(value, record.id as string, record.sku_id as string);
            }}
          />
        );
      }
    },

    {
      title: 'Item',
      align: 'center',
      render: (_, record) => {
        return <>{record.product_name}</>;
      },
      width: '15%'
    },
    {
      title: 'Category',
      width: 150,
      align: 'center',
      render: (_, record) => {
        return <>{record.product_class}</>;
      }
    },
    {
      title: 'HSN',
      width: 150,
      align: 'center',
      render: (_, record) => {
        return <>{record.hsn_code}</>;
      }
    },
    {
      title: 'Tax',
      align: 'center',
      render: (_, record) => {
        if (record.tax_rate) return <> {18}%</>;
        return null;
      }
    },
    {
      title: 'Deals',
      align: 'center',
      render: (curr, record) => {
        const promotionInfo = promotionReccomendation?.articleIdPromoMap
          ? promotionReccomendation?.articleIdPromoMap[record.sku_id as string]
          : {};

        const showApplied = !_.isEmpty(promotionInfo?.line_promo_applied);
        const showReccom = !_.isEmpty(promotionInfo?.line_promo_reccoms);
        const showOthers = !_.isEmpty(promotionInfo?.line_other_offers);

        const appliedPromoList = promotionInfo?.line_promo_applied || [];
        const reccomendedPromoList = promotionInfo?.line_promo_reccoms || [];
        const otherPromoList = promotionInfo?.line_other_offers || [];

        const otherPromoContent = (
          <section className="flex flex-col gap-4">
            {otherPromoList.map((promotion: any) => {
              return (
                <Card>
                  <section className="flex  gap-4">
                    <div className="my-auto">
                      <IconTag className="text-[#274472]" />
                    </div>
                    <section className="my-auto">
                      <Typography.Text className="font-bold">{promotion?.title}</Typography.Text>
                    </section>
                    {/* <Button type="dashed">
                      <Typography.Text copyable>{promotion?.code}</Typography.Text>
                    </Button> */}
                  </section>
                </Card>
              );
            })}
          </section>
        );

        const showAppliedContent = (
          <section className="flex flex-col gap-4">
            {appliedPromoList.map((promotion: any) => {
              return (
                <Card>
                  <section className="flex gap-4">
                    <div className="my-auto">
                      <IconTag className="text-[#274472]" />
                    </div>
                    <section className="my-auto">
                      <Typography.Text className="font-bold">{promotion?.title}</Typography.Text>
                    </section>
                    {/* <Button type="dashed">
                      <Typography.Text copyable>{promotion?.code}</Typography.Text>
                    </Button> */}
                  </section>
                  <div className="mt-2">
                    <FieldDisplay
                      label={'Discount'}
                      value={priceHelpers.formatPriceToLocaleCurrency({
                        amount: promotion?.discount_info?.discount || 0,
                        baseCurrency,
                        userCurrency
                      })}
                    />
                  </div>
                </Card>
              );
            })}
          </section>
        );

        const showRecommendedContent = (
          <section className="flex flex-col gap-4 max-h-[400px] overflow-auto">
            {reccomendedPromoList.map((promotion: any) => {
              return (
                <Card>
                  <section className="flex gap-4">
                    <div className="my-auto">
                      <IconTag className="text-[#274472]" />
                    </div>
                    <section className="my-auto">
                      <Typography.Text className="font-bold">{promotion?.title}</Typography.Text>
                    </section>
                    {/* <Button type="dashed">
                      <Typography.Text copyable>{promotion?.code}</Typography.Text>
                    </Button> */}
                  </section>
                  <section className="mt-2">
                    <Alert message={getRecommendedActionText(promotion)} type="warning" showIcon />

                    {/* <Typography.Text className="text-[#8990a2] text-xs font-semibold"></Typography.Text> */}
                  </section>
                  {/* <div className="mt-2">
                    <FieldDisplay
                      label={'Discount'}
                      value={priceHelpers.formatPriceToLocaleCurrency({
                        amount: promotion?.discount_info?.discount || 0,
                        baseCurrency,
                        userCurrency
                      })}
                    />
                  </div> */}
                </Card>
              );
            })}
          </section>
        );

        return (
          <section className="flex flex-col gap-4 justify-center items-center">
            {showApplied ? (
              <Popover
                placement="bottom"
                title={'Applied Promotions'}
                content={<section className="w-[400px]">{showAppliedContent}</section>}
                trigger="click"
                getPopupContainer={() => document.querySelector('#popup') || document.body}
              >
                <Tag color="green" role="button" className="cursor-pointer">
                  {appliedPromoList?.length || 1} Deal(s) Applied
                </Tag>
              </Popover>
            ) : null}
            {showReccom ? (
              <Popover
                placement="bottom"
                title={'Recommended Promotions'}
                content={<section className="w-[400px]">{showRecommendedContent}</section>}
                trigger="click"
                getPopupContainer={() => document.querySelector('#popup') || document.body}
              >
                <Tag color="cyan" role="button" className="cursor-pointer">
                  {reccomendedPromoList?.length || 1} Deal(s) Recommended
                </Tag>
              </Popover>
            ) : null}
            {showOthers ? (
              <Popover
                placement="bottom"
                title={'Other Promotions'}
                content={<section className="w-[400px]">{otherPromoContent}</section>}
                trigger="click"
                getPopupContainer={() => document.querySelector('#popup') || document.body}
              >
                <Tag color="blue" role="button" className="cursor-pointer">
                  {otherPromoList?.length || 1} Deal(s) Available
                </Tag>
              </Popover>
            ) : null}
          </section>
        );
        // : (
        //   <section className="flex flex-col gap-4 justify-center items-center">
        //     </section>
        // )
      }
    },

    {
      title: 'Quantity',
      width: 150,
      align: 'center',
      render: (_, { sku_id, mrp, ...record }) => {
        if (sku_id)
          return (
            <InputNumber
              // min={0}
              size="large"
              onBlur={async e => {
                if (e.target.value) {
                  // await fetchLoyaltyEarnInfo();
                  // await fetchPromotionDataForCart();
                  await fetchPricesFromPriceList();
                }
              }}
              onChange={e => {
                const value = e;
                handleEditableFieldsChange('quantity', value, sku_id as string, mrp);
              }}
              placeholder="Enter Quantity"
              value={editableItemInfo[sku_id as string]?.quantity}
            />
          );
        return null;
      }
    },
    {
      title: 'UOM',
      width: 100,
      align: 'center',
      render: (_, { sku_id, mrp }) => {
        if (sku_id)
          return (
            <Input
              size="large"
              onChange={e => {
                const value = e.target.value;
                handleEditableFieldsChange('uom', value, sku_id as string, mrp);
              }}
              placeholder="UOM"
              value={editableItemInfo[sku_id as string]?.uom}
            />
          );
        return null;
      }
    },

    {
      title: 'Unit Price',
      width: '25%',
      align: 'center',
      render: (_, record) => {
        const discountedPrice = priceListSkuMap[record?.sku_id as string]?.discounted_listing_price;
        const listingPrice = priceListSkuMap[record?.sku_id as string]?.list_price;
        return discountedPrice === listingPrice ? (
          <Typography.Text>
            {priceHelpers.formatPriceToLocaleCurrency({
              amount: listingPrice,
              baseCurrency,
              userCurrency
            })}
          </Typography.Text>
        ) : (
          <section className="flex flex-wrap gap-2 justify-center items-center">
            <Typography.Text delete style={{ color: 'red' }}>
              {priceHelpers.formatPriceToLocaleCurrency({
                amount: listingPrice,
                baseCurrency,
                userCurrency
              })}
            </Typography.Text>
            <Typography.Text style={{ color: 'green' }}>
              {priceHelpers.formatPriceToLocaleCurrency({
                amount: discountedPrice,
                baseCurrency,
                userCurrency
              })}
            </Typography.Text>
          </section>
        );
      }
    },

    {
      title: 'Requires gift wrap?',
      width: '250px',
      align: 'center',
      render(value, record, index) {
        return (
          <Select
            placeholder="Yes / No"
            defaultValue={false}
            className="w-full"
            onChange={value => {
              setEditableItemInfo(editableItemInfo => ({
                ...editableItemInfo,
                [record.sku_id as string]: { ...editableItemInfo[record.sku_id as string], is_gift: value }
              }));
              if (value) {
                //!show edit modal
                setCurrentEditingFieldId(record.sku_id as string);
                setCurrentEditingField('gifting_instructions');
              } else {
                setEditableItemInfo(editableItemInfo => ({
                  ...editableItemInfo,
                  [record.sku_id as string]: {
                    ...editableItemInfo[record.sku_id as string],
                    gifting_instructions: undefined
                  }
                }));
              }
            }}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          ></Select>
        );
      }
    },
    {
      title: 'Gift wrap instructions',
      align: 'center',
      width: '400px',
      render(value, record, index) {
        return <>{editableItemInfo[record.sku_id as string]?.gifting_instructions}</>;
      }
    },
    {
      title: 'Gross amount',
      align: 'center',
      render(value, record, index) {
        const discountedPrice = priceListSkuMap[record?.sku_id as string]?.discounted_listing_price;
        return (
          <>
            {priceHelpers.formatPriceToLocaleCurrency({
              amount: discountedPrice * (editableItemInfo[record.sku_id as string]?.quantity || 0),
              baseCurrency,
              userCurrency
            })}
          </>
        );
      }
    },
    {
      title: 'Total Discount',

      align: 'center',
      render: (_, record) => {
        //!Calculate line level discount
        return (
          <>
            {priceHelpers.formatPriceToLocaleCurrency({
              amount: calculateLineLevelDiscountPlusPriceListDiscount(record?.sku_id as string),
              baseCurrency,
              userCurrency
            })}
          </>
        );
      }
    },
    {
      title: 'Other charges',
      children: [
        {
          title: 'Misc',

          align: 'center',
          render: (_, record) => {
            //!Calculate line level discount
            return (
              <Button
                type="ghost"
                onClick={() => {
                  setCurrentEditingField('misc_charge');
                  setCurrentEditingFieldId(record.sku_id as string);
                }}
              >
                {priceHelpers.formatPriceToLocaleCurrency({
                  amount: editableItemInfo[record.sku_id as string]?.misc_charge || 0,
                  baseCurrency,
                  userCurrency
                })}

                <EditOutlined />
              </Button>
            );
          }
        },
        {
          title: 'Convenience',

          align: 'center',
          render: (_, record) => {
            //!Calculate line level discount
            return (
              <Button
                type="ghost"
                onClick={() => {
                  setCurrentEditingField('convenience_charge');
                  setCurrentEditingFieldId(record.sku_id as string);
                }}
              >
                {priceHelpers.formatPriceToLocaleCurrency({
                  amount: editableItemInfo[record.sku_id as string]?.convenience_charge || 0,
                  baseCurrency,
                  userCurrency
                })}

                <EditOutlined />
              </Button>
            );
          }
        },
        {
          title: 'Gift',

          align: 'center',
          render: (_, record) => {
            //!Calculate line level discount
            return (
              <>
                {priceHelpers.formatPriceToLocaleCurrency({
                  amount: editableItemInfo[record.sku_id as string]?.is_gift ? STATIC_GIFT_CHARGE : 0,
                  baseCurrency,
                  userCurrency
                })}
              </>
            );
          }
        }
      ]
    },

    {
      title: 'Total payable',
      align: 'center',
      render: (_, { sku_id }) => {
        return (
          //!Calculate line level discount
          <>
            {priceHelpers.formatPriceToLocaleCurrency({
              amount:
                (productTotalMap[sku_id as string]?.product_total as number) -
                calculateDiscountLineLevel(sku_id as string) +
                calculateTotalChargesForACartLine(sku_id as string),
              baseCurrency,
              userCurrency
            })}
          </>
        );
      }
    },
    {
      align: 'center',
      render: (_, record) => {
        return (
          <Button
            type="link"
            onClick={() => handleClickDeleteItem(record.id as string, record.sku_id as string)}
            className="text-red-600"
          >
            <DeleteOutlined className="text-red-500 text-xl" title="Delete Product" />
          </Button>
        );
      }
    }
  ];

  const [showPromotionModal, setShowPromotionModal] = React.useState(false);
  const [couponPromotionList, setCouponPromotionList] = React.useState({} as any);

  const [promotionReccomendation, setPromotionReccomendation] = React.useState({} as any);
  const [chargeDetailsFromPromoService, setChargeDetailsFromPromoService] = React.useState(
    {} as { headerCharges: any[]; articleChargesMap: Record<string, any> }
  );
  const [orderHeaderForm] = Form.useForm<{
    promise_status: string;
    tenant_id: string;
    order_type: string;
    business_unit: string;
    document_type: string;
    currency_code: string;
    order_number: string;
    order_datetime: string;
    promise_number: any;
  }>();

  const [selectedCouponCode, setSelectedCouponCode] = React.useState('');
  const [appliedPromotionCouponInfo, setAppliedPromotionCouponInfo] = React.useState({} as any);
  // const [headerTaxForm] = Form.useForm<{
  //   taxes: { charge_category: string; charge_name: string; price: number; tax_code: string; tax_rate: number }[];
  // }>();

  const [priceListSkuMap, setPriceListSkuMap] = React.useState({} as Record<string, any>);
  const [productTotalMap, setProductTotalMap] = React.useState({} as Record<string, any>);
  const [fulfillmentOptionsApiResponse, setFulfillmentOptionsApiResponse] = React.useState({} as any);
  const [productDetailsMap, setProductsDetailsMap] = React.useState<any>({});
  const [productList, setProductList] = React.useState<any[]>([]);

  const handleClickAddItem = () => {
    // if (itemList.length < 5)
    setItemList(itemList => {
      const id = generateUUIDV4();
      const newItem: ProductMasterType = { product_id: '', id, key: id };
      return [...itemList, newItem];
    });
  };

  const handleClickDeleteItem = async (id: string, sku_id: string) => {
    //!Not reccomended way but for demo
    await flushSync(async () => {
      setItemList(itemList => {
        const filteredList = itemList.filter(item => item.id !== id);
        return filteredList;
      });

      if (sku_id) {
        setEditableItemInfo(editableItemInfo => {
          return { ...editableItemInfo, [sku_id]: {} };
        });
      }
    });
    setselectedFullfillmentOptions([]);
  };

  React.useEffect(() => {
    if (promiseNumber.length) {
      // loadIntialPromiseData();//commented for beta
    }
  }, []);
  React.useEffect(() => {
    (async () => {
      await getAllProducts();
    })();
  }, []);

  const getAllProducts = async () => {
    setLoading(true);
    try {
      const response = await orderService.getProducts();
      if (!response?.data?.data) {
        setProductsDetailsMap({ ...presetProductDetailsMap });
        setProductList([...presetProductList]);
      } else {
        const transformedProductDetails = transformApiResponse(response.data.data);
        const getObjectWithSku = createProductDetailsMap(transformedProductDetails);
        setProductsDetailsMap({ ...getObjectWithSku, ...presetProductDetailsMap });
        const uniqueProductDetails = [...transformedProductDetails, ...presetProductList].filter(
          (product, index, self) => index === self.findIndex(p => p.sku_id === product.sku_id)
        );
        setProductList(uniqueProductDetails);
      }
    } catch (error) {
      setProductsDetailsMap({ ...presetProductDetailsMap });
      setProductList([...presetProductList]);
    } finally {
      setLoading(false);
    }

    function createProductDetailsMap(productList: any): Record<string, any> {
      const productDetailsMap: Record<string, any> = {};

      productList.forEach((product: any, index: any) => {
        productDetailsMap[product.sku_id] = product;
      });

      return productDetailsMap;
    }

    function transformApiResponse(response: any): any[] {
      return response?.map((item: any) => ({
        categories: item.category || 'Test',
        product_id: item.item_id,
        is_price_lock: false,
        id: '',
        sku_id: item.item_id,
        hsn_code: '901123',
        tax_rate: 9,
        article_id: item.article_id,
        country_origin: item?.country_of_origin || 'CHL',
        ean: item.item_id,
        barcode: item.item_id,
        mrp: item.cost_price || 10,
        unit_price: item.unit_price || 10,
        retail_price: item.list_price || 10,
        product_class: item.product_type || '',
        product_name: item.product_name || '',
        product_desc: item.description || '',
        seller_info: {
          seller_id: 'Churu',
          seller_name: 'Churu',
          seller_sku_id: 'Churu'
        }
      }));
    }
  };

  const handleTableData = (data: any, quantityData: any) => {
    let getPromiseStaticData = data;
    setItemList(itemList => {
      const updatedItemList = getPromiseStaticData.map((item: any) => {
        let dummy = { ...item, id: '1234567' };
        return dummy;
      });
      return updatedItemList;
    });

    quantityData.map((item: any, index: any) => {
      setEditableItemInfo(editableItems => ({
        ...editableItems,
        [productDetailsMap[getPromiseStaticData[index]?.sku_id]?.sku_id]: {
          uom: item?.quantity.unit_of_measure,
          quantity: item?.quantity.number_of_units
        }
      }));
    });
  };

  const loadIntialPromiseData = async () => {
    const { data, errors } = await getPromise(promiseNumber);
    if (_.isEmpty(errors)) {
      let matchedData = data.products
        .map((item2: any) => {
          let skuId2 = item2.sku_id;
          let foundItem = productList.find((item1: any) => item1.sku_id === skuId2);
          return foundItem ? foundItem : null;
        })
        .filter((item: any) => item !== null);
      orderHeaderForm.setFieldsValue({ order_number: data.cart_id });
      handleTableData(matchedData, data?.products);
      getCustomerInfo(data);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getCustomerInfo = async (promiseData: any) => {
    const currData = customerData.find(
      (value: any) =>
        value.email === promiseData.customer_profile_info.profile_id ||
        value.firstName + ' ' + value.lastName === promiseData.customer_profile_info.profile_id
    );
    customerSelectionForm.setFieldsValue({
      customerId: `${currData?.firstName} ${currData?.lastName}`
    });
    const { data } = await getCustomerLoyaltyData(currData?.email as string);
    const { data: priceListInfo } = await priceService.getPricelistForCustomer(currData?.email as string);
    fetchLoyaltyEarnInfo();
    setCustomerLoyaltyData(data || ({} as any));
    setCustomerDetails({
      email: currData?.email,
      firstName: currData?.firstName,
      lastName: currData?.lastName,
      phoneNumber: currData?.phoneNumber,
      alternateId: currData?.alternateId,
      tier: currData?.tier,
      id: currData?.id,
      date_of_birth: currData?.date_of_birth,
      new_buyer: currData?.new_buyer,
      customer_segments: currData?.customer_segments,
      priceListDetails: priceListInfo?.price_list
    });
    customerInfoForm.setFieldsValue({
      first_name: currData?.firstName,
      last_name: currData?.lastName,
      email_id: currData?.email,
      phone_number: currData?.phoneNumber,
      date_of_birth: currData?.date_of_birth
    });
  };

  React.useEffect(() => {
    return () => {
      let orderData = {
        promiseData: '',
        itemList: '',
        shippingInfoForm: '',
        customerSelectionForm: '',
        customerInfoForm: '',
        orderHeaderForm: ''
      };
      setProductData(orderData);
    };
  }, []);

  React.useEffect(() => {
    if (!itemList?.length) {
      setLoyaltyRedeemValue(0);
      setLoyaltyEarnData({} as any);
      setPromotionReccomendation({} as any);
    }

    if (checkItemListCondition() && !_.isEmpty(customerDetails)) {
      fetchLoyaltyEarnInfo();
      fetchPromotionDataForCart();
      setSelectedCouponCode('');
      setAppliedPromotionCouponInfo((promotionInfo: any) => ({ ...promotionInfo, appliedCoupon: {} }));
      setApplyCouponResponse({});
    }
  }, [customerDetails, productTotalMap]);

  React.useEffect(() => {
    normalizeItemListAndEditableItemList();
  }, []);

  React.useEffect(() => {
    checkItemListCondition() && fetchPricesFromPriceList();
  }, [customerDetails]);

  const calculateTotalChargesForACartLine = (sku_id: string) => {
    const miscCharge = editableItemInfo[sku_id as string]?.misc_charge || 0;
    const convenienceCharge = editableItemInfo[sku_id as string]?.convenience_charge || 0;
    const giftCharge = editableItemInfo[sku_id as string]?.is_gift ? STATIC_GIFT_CHARGE : 0;
    return miscCharge + convenienceCharge + giftCharge;
  };

  const normalizeItemListAndEditableItemList = () => {
    const articleIdsInItemList = new Set(itemList.map(({ sku_id }) => sku_id as string));

    const articleIdsInEditableItemList = Object.keys(editableItemInfo);

    if (_.isEqual(Array.from(articleIdsInItemList), articleIdsInEditableItemList)) return;

    setEditableItemInfo(editableItemInfo => {
      const stateCopy = _.cloneDeep(editableItemInfo);

      articleIdsInEditableItemList.forEach(articleId => {
        // Check if the sku_id exists in the set from the second object
        if (!articleIdsInItemList.has(articleId)) {
          // Remove the sku_id from the first object
          delete stateCopy[articleId];
        }
      });

      return stateCopy;
    });
  };

  const checkItemListCondition = () => {
    if (!itemList.length) return false;
    let valid = true;
    itemList.forEach(({ product_id }) => {
      if (product_id === '') valid = false;
    });

    return valid;
  };

  const getRecommendedActionText = (promotion: any) => {
    const recommendedAction = promotion?.applicable_action || {};

    switch (recommendedAction?.failed_reason || promotion?.decision_reason) {
      case 'QTY_NOT_MET':
        return `The quantity of this item does not meet the minimum requirement for this promotion. Add ${recommendedAction?.value} more quantity of this item to apply this promotion`;
      case 'MOV_NOT_MET':
        return `Your order total does not meet the minimum value required to qualify for this promotion. Add ${recommendedAction?.value} items worth to meet the minimum order value`;
      case 'ITEM_MISSING':
        return `Item(s) required for this promotion is missing from your cart. To apply this offer, make sure to add the required item to your cart.`;

      default:
        return ``;
    }
  };

  const handleSelectChange = (value: string, id: string, sku_id: string) => {
    setItemList(itemList => {
      const updatedItemList = itemList.map(item => {
        if (item.id === id) {
          let Data = { ...item, ...productDetailsMap[value], id };
          return Data;
        }
        return item;
      });
      return updatedItemList;
    });

    setEditableItemInfo(editableItems => ({
      ...editableItems,
      [productDetailsMap[value]?.sku_id]: {
        uom: 'EA'
      }
    }));
  };

  const productIdsInCart = itemList.map(item => item.sku_id);

  const articleIdOptions = productList
    .filter(product => {
      return !productIdsInCart.includes(product.sku_id);
    })
    .map(product => {
      return {
        label: <>{product.sku_id}</>,
        value: product.sku_id
      };
    });

  const handleEditableFieldsChange = (
    editedField: 'quantity' | 'uom' | 'discount' | 'is_gift',
    editedValue: any,
    sku_id: string,
    mrp?: number
  ) => {
    setEditableItemInfo(editableItemInfo => {
      let updatedItemInfo = { ...editableItemInfo[sku_id], [editedField]: editedValue };
      return { ...editableItemInfo, [sku_id]: updatedItemInfo };
    });
  };

  const getOrderTotal = () => {
    return (
      Object.keys(editableItemInfo)
        .map(
          key =>
            (productTotalMap[key]?.product_total || 0) -
            calculateDiscountLineLevel(key) +
            calculateConvenienceTotal() +
            calculateGiftTotal() +
            calculateMiscTotal()
        )
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0) +
      headerConvenienceCharges +
      headerMiscCharges
    );
  };

  const getGrossOrderTotal = () => {
    return Object.keys(editableItemInfo)
      .map(key => productTotalMap[key]?.product_total || 0)
      .reduce((previousValue, currentValue) => {
        return previousValue + currentValue;
      }, 0);
  };

  const handleCheckout = async () => {
    setLoader(true);

    const billingInfo = billingInfoForm.getFieldsValue();
    const shippingInfo = shippingInfoForm.getFieldsValue();
    try {
      await customerInfoForm.validateFields();
    } catch (errorInfo) {
      displayErrorNotifications([{ message: 'Please fill all the required fields in Buyer Info' }]);
      setLoader(false);
      return;
    }
    const customerInfo = customerInfoForm.getFieldsValue();

    const getCoLineId = (sku_id: any) => {
      let data = (colineId || [])?.filter((cv: any) => {
        if (cv.sku_id === sku_id) {
          return cv;
        }
      });
      return data[0]?.co_line_id;
    };

    const getPriceWithTwoPrecision = (num: any) => {
      return num.toFixed(2);
    };
    const getTaxAmount = (price: number, tax: number): number => {
      return (price * tax) / 100;
    };

    const getTaxPerUnit = (price: number, tax: number, quantity: number): String => {
      return (getTaxAmount(price, tax) / quantity).toFixed(2);
    };

    const getRoundOffTaxAmount = (price: number, tax: number, quantity: number) => {
      let totalTax: number = getTaxAmount(price, tax);
      return (totalTax - Number((Number(getTaxPerUnit(price, tax, quantity)) * quantity).toFixed(2))).toFixed(2);
    };

    const ordersItemList = itemList.map(item => {
      const {
        article_id,
        barcode,
        country_origin,
        ean,
        hsn_code,

        product_class,
        product_desc,
        product_id,
        product_name,
        sku_id,

        seller_info
      } = item;

      const lineLevelAppliedInfo = appliedPromotionCouponInfo?.appliedLineLevelPromotion
        ? appliedPromotionCouponInfo?.appliedLineLevelPromotion[sku_id as string]
        : {};

      const lineLevelPromos = lineLevelAppliedInfo?.line_promo_applied || [];

      const convenienceCharge = editableItemInfo[sku_id as string].is_gift ? STATIC_GIFT_CHARGE : 0;
      const giftCharge = editableItemInfo[sku_id as string].convenience_charge;
      const miscCharge = editableItemInfo[sku_id as string].misc_charge;

      const lineLevelCharges =
        (chargeDetailsFromPromoService.articleChargesMap || {})[sku_id as string] ||
        lineLevelPromos.map((promo: any) => {
          return {
            amount: promo?.discount_info?.discount || 0,
            charge_category: 'CHARGES',
            charge_key_ref: 'Promotions',
            charge_name: 'PRICE',
            hsn_code: '123456',
            is_billable: true,
            is_discount: true,
            is_shipping_charge: false,
            is_taxable: false,
            // reference: promo?.title,
            product_id: sku_id
          };
        });

      const lineLevelGiftCharge = [
        {
          charge_name: 'GIFT_WRAP',
          amount: giftCharge,
          charge_category: 'CHARGES',
          hsn_code: '123456',
          is_billable: true,
          is_discount: true,
          is_shipping_charge: false,
          is_taxable: false,
          // reference: 'sampleRef',
          product_id: sku_id
        }
      ];
      const lineLevelMiscCharge = [
        {
          charge_name: 'MISC',
          amount: miscCharge,
          charge_category: 'CHARGES',
          hsn_code: '123456',
          is_billable: true,
          is_discount: true,
          is_shipping_charge: false,
          is_taxable: false,
          // reference: 'sampleRef',
          product_id: sku_id
        }
      ];
      const lineLevelConvenienceCharge = [
        {
          charge_name: 'CONVE',
          amount: convenienceCharge,
          charge_category: 'CHARGES',
          hsn_code: '123456',
          is_billable: true,
          is_discount: true,
          is_shipping_charge: false,
          is_taxable: false,
          // reference: 'sampleRef',
          product_id: sku_id
        }
      ];

      const combinedCharges = [
        ...lineLevelCharges,
        ...(convenienceCharge ? lineLevelConvenienceCharge : []),
        ...(miscCharge ? lineLevelMiscCharge : []),
        ...(giftCharge ? lineLevelGiftCharge : [])
      ];

      let oldCharges = combinedCharges?.length ? combinedCharges : null;
      let charges = (oldCharges || [])?.map(item => {
        return {
          ...item,
          amount: item.charge_amount
        };
      });
      (charges || []).forEach((item: any) => delete item?.reference);

      let rtData = {
        barcode,
        batch_details: {
          batch_expiry_date: '2023-08-15T10:30:00+05:30',
          batch_name: 'Batch-2023-08-15',
          batch_warranty_date: '2023-11-15T10:30:00+05:30'
        },
        charges,
        cart_line_id: getCoLineId(sku_id), //earlier it was co_line_id but it is now changed to car_line_id
        // co_line_id: generateUUIDV4(),
        co_line_key_ref: generateUUIDV4(),
        country_origin,
        ean,
        sku_id,
        hsn_code,
        is_gift: editableItemInfo[sku_id as string].is_gift,
        gift_instructions: editableItemInfo[sku_id as string].is_gift || undefined,
        is_quantity_same_as_parent: true,
        is_service: false,
        is_tax_included_in_price: true,
        is_taxable: true,
        package_details: {
          package_height_in_cms: 100,
          package_length_in_cms: 20,
          package_weight_in_kgs: 10,
          package_width_in_cms: 20
        },
        line_id: generateUUIDV4(),
        co_line_id: generateUUIDV4(),
        article_id,
        payment_status: 'RECVD',
        // parent_co_line_id: 'ParentLineID123',
        // parent_co_line_key_ref: 'ParentLineKeyRef123'
        promise_group_id: '1',
        product_id,
        product_class,
        product_desc,
        product_name,
        product_type: 'Sample',
        quantity: {
          number_of_units: editableItemInfo[sku_id as string]?.quantity,
          unit_of_measure: editableItemInfo[sku_id as string]?.uom,
          unit_size: 1,
          unit_fraction: 1
        },
        original_quantity: {
          number_of_units: editableItemInfo[sku_id as string]?.quantity,
          unit_of_measure: editableItemInfo[sku_id as string]?.uom,
          unit_size: 1,
          unit_fraction: 1
        },
        seller_info,
        taxes: [
          {
            tax_per_unit: getTaxPerUnit(
              priceListSkuMap[sku_id as string]?.discounted_listing_price,
              18,
              editableItemInfo[sku_id as string]?.quantity || 1
            ),
            round_off_amount: getRoundOffTaxAmount(
              priceListSkuMap[sku_id as string]?.discounted_listing_price,
              18,
              editableItemInfo[sku_id as string]?.quantity || 1
            ),
            charge_category: 'CHARGES', //!Wrt hsncode
            charge_key_ref: 'PRICE',
            charge_name: 'PRICE',
            hsn_code: hsn_code,
            price: getPriceWithTwoPrecision(
              getTaxAmount(priceListSkuMap[sku_id as string]?.discounted_listing_price, 18)
            ),
            product_id,
            tax_code: hsn_code,
            tax_rate: 18
          }
        ],

        unit_price: priceListSkuMap[sku_id as string]?.discounted_listing_price || 0,
        list_price: priceListSkuMap[sku_id as string]?.discounted_listing_price || 0,
        retail_price: priceListSkuMap[sku_id as string]?.list_price || 0

        // total_discount: editableItemInfo[sku_id as string].discount,
        // total_item_value:
        //   ((item.mrp as number) - (item.mrp as number) * (18 / 100)) *
        //   (editableItemInfo[sku_id as string].quantity as number),
        // total_tax: (item.mrp as number) * (18 / 100) * (editableItemInfo[sku_id as string].quantity as number),
        // total_amount: productTotalMap[sku_id as string]?.product_total
      };
      return rtData;
    });

    function convertTimeRangeToIsoTimestamps(timeRangeString: string) {
      if (!timeRangeString) {
        return { slot_start: undefined, slot_end: undefined };
      }
      const [startTime, endTime] = timeRangeString.split(' - ');

      const slot_start = dayjs(startTime, 'h:mm A').toISOString();
      const slot_end = dayjs(endTime, 'h:mm A').toISOString();

      return { slot_start, slot_end };
    }

    const { slot_start, slot_end } = convertTimeRangeToIsoTimestamps(promiseInfo?.slot as string);

    //!Promo charges addition
    const headerLevelPromos = appliedPromotionCouponInfo?.appliedHeaderPromotions || [];

    const headerLevelPromoCouponCharges = chargeDetailsFromPromoService.headerCharges || [];

    const headerLevelPromotionsCharges = headerLevelPromos.map((promo: any) => {
      return {
        amount: promo?.discount_info?.discount || 0,
        charge_category: 'PROMO',
        charge_key_ref: 'Promotions',
        charge_name: 'PRICE',
        hsn_code: '123456',
        is_billable: true,
        is_discount: true,
        is_shipping_charge: false,
        is_taxable: false
        // reference: promo?.title
      };
    });

    const couponDetails = getAppliedCouponDetails();
    const couponDiscount = getCouponDiscountFromPromoService();

    const couponPromotionCharge = {
      amount: couponDiscount,
      charge_category: 'PROMO',
      charge_key_ref: 'Coupons',
      charge_name: 'PRICE',
      hsn_code: '123456',
      is_billable: true,
      is_discount: true,
      is_shipping_charge: false,
      is_taxable: false
      // reference: couponDetails?.title
    };

    const loyaltyRedeemAmount = loyaltyRedeemValue * 0.01;

    const loyaltyPromotionCharge = {
      amount: loyaltyRedeemAmount,
      charge_category: 'PROMO',
      charge_key_ref: 'Loyalty',
      charge_name: 'PRICE',

      hsn_code: '123456',
      is_billable: true,
      is_discount: true,
      is_shipping_charge: false,
      is_taxable: false
      // reference: 'Loyalty Discount'
    };

    const headerMiscCharge = {
      charge_name: 'MISC',
      charge_amount: headerMiscCharges,
      charge_category: 'CHARGES',
      reference: 'ref',
      is_billable: true,
      hsn_code: '100099',
      is_taxable: false,
      is_discount: false,
      is_shipping_charge: false
    };

    const headerConvenienceCharge = {
      charge_name: 'CONVE',
      charge_amount: headerConvenienceCharges,
      charge_category: 'CHARGES',
      reference: 'ref',
      is_billable: true,
      hsn_code: '100099',
      is_taxable: false,
      is_discount: false,
      is_shipping_charge: false
    };

    const orderRequest = {
      business_group_id: bussinessGroupName,
      tenant_id: tenentName,
      doc_type: 'CO',
      doc_entry_type: 'DOTCOM',
      store_id: '2001',
      timezone: 'UTC+5:30',
      order_number: orderHeaderForm.getFieldsValue().order_number,
      is_promise_required: true, //Hardcoded as for now
      addnl_attributes: [
        {
          data_type: 'text',
          field_xpath: 'Sample',
          name: 'Additional Attribute',
          value: 'Example Value'
        }
      ],
      address: [
        {
          addnl_attributes: null,
          address_tag: 'bill-to-details',
          address_info: {
            address_id: generateUUIDV4(),
            address_line1: billingInfo.address_line1,
            address_line2: billingInfo.address_line2,
            coordinates: {
              latitude: '0',
              longitude: '0'
            },
            country: billingInfo.country,
            country_code: countryCodeMap[billingInfo.country],
            location: billingInfo.location,
            place_of_supply: countryStateMap[billingInfo.country][billingInfo.state]?.state_code, //!Destination
            postal_code: billingInfo.postal_code, //!Input
            state: billingInfo.state, //!Dropdown
            state_code: countryStateMap[billingInfo.country][billingInfo.state]?.state_code //!Auto populate
          },
          department: 'Sales',
          email: [
            {
              email_id: customerInfo.email_id,
              type: 'PRIMARY'
            }
          ], //!Input
          fax: [
            {
              country_code: '1',
              number: '555-1234',
              type: 'work'
            }
          ],
          first_name: customerInfo.first_name,
          gstin: 'ABC123456789', //!Pending
          http_url: 'https://example.com',
          is_addr_same_as_ship_to: false,
          is_cml_address: true,
          last_name: customerInfo.last_name,
          legal_name: customerInfo.legal_name,
          middle_name: customerInfo.middle_name,
          organization: customerInfo.organization,
          organization_code: '1234',
          phone_number: [
            {
              country_code: '+91',
              number: customerInfo.phone_number,
              type: 'PRIMARY'
            }
          ],
          suffix: 'Jr.',
          title: 'Mr.',
          trade_name: customerInfo.trade_name
        },
        {
          addnl_attributes: null,
          address_tag: 'buyer-details',
          address_info: {
            address_id: generateUUIDV4(),
            address_line1: billingInfo.address_line1,
            address_line2: billingInfo.address_line2,
            coordinates: {
              latitude: '0',
              longitude: '0'
            },
            country: billingInfo.country,
            country_code: countryCodeMap[billingInfo.country],
            location: billingInfo.location,
            place_of_supply: countryStateMap[billingInfo.country][billingInfo.state]?.state_code, //!Destination
            postal_code: billingInfo.postal_code, //!Input
            state: billingInfo.state, //!Dropdown
            state_code: countryStateMap[billingInfo.country][billingInfo.state]?.state_code //!Auto populate
          },
          department: 'Sales',
          email: [
            {
              email_id: customerInfo.email_id,
              type: 'PRIMARY'
            }
          ],
          fax: [
            {
              country_code: '1',
              number: '555-1234',
              type: 'work'
            }
          ],
          first_name: customerInfo.first_name,
          gstin: 'ABC123456789', //!Pending
          http_url: 'https://example.com',
          is_addr_same_as_ship_to: false,
          is_cml_address: true,
          last_name: customerInfo.last_name,
          legal_name: customerInfo.legal_name,
          middle_name: customerInfo.middle_name,
          organization: customerInfo.organization,
          organization_code: '123',
          phone_number: [
            {
              country_code: '+91',
              number: customerInfo.phone_number,
              type: 'PRIMARY'
            }
          ],
          profile_id: 'P123456',
          suffix: 'Jr.',
          title: 'Mr.',
          trade_name: customerInfo.trade_name
        },
        {
          addnl_attributes: null,
          address_tag: 'ship-to-details',
          address_info: {
            address_id: generateUUIDV4(),
            address_line1: shippingInfo.address_line1,
            address_line2: shippingInfo.address_line2,
            coordinates: {
              latitude: '0',
              longitude: '0'
            },
            country: shippingInfo.country,
            country_code: countryCodeMap[shippingInfo.country],
            location: shippingInfo.location,
            place_of_supply: countryStateMap[shippingInfo.country][shippingInfo.state]?.state_code,
            postal_code: shippingInfo.postal_code,
            state: shippingInfo.state,
            state_code: countryStateMap[shippingInfo.country][shippingInfo.state]?.state_code
          },
          department: 'Sales',
          email: [
            {
              email_id: customerInfo.email_id,
              type: 'Work'
            }
          ],
          fax: [
            {
              country_code: '+1',
              number: '555-1234',
              type: 'Work'
            }
          ],
          first_name: customerInfo.first_name,
          gstin: 'GSTIN12345678',
          http_url: 'http://www.example.com',
          is_addr_same_as_ship_to: false,
          is_cml_address: true,
          last_name: customerInfo.last_name,
          legal_name: customerInfo.legal_name,
          middle_name: customerInfo.middle_name,
          organization: customerInfo.organization,
          organization_code: 'EX-INC',
          phone_number: [
            {
              country_code: '+91',
              number: customerInfo.phone_number,
              type: 'Work'
            }
          ],
          suffix: '',
          title: 'Mr.',
          trade_name: 'Example'
        },
        {
          addnl_attributes: null,
          address_tag: 'seller-details',
          address_info: {
            address_id: 'ADDR-456',
            address_line1: '123 Main Street',
            address_line2: 'Apt 4B',
            coordinates: {
              latitude: '12.345678',
              longitude: '98.765432'
            },
            country: 'United States',
            country_code: 'US',
            location: 'New York',
            place_of_supply: 'New York',
            postal_code: '10001',
            state: 'New York',
            state_code: 'NY'
          },
          department: 'Sales',
          email: [
            {
              email_id: 'john.doe@example.com',
              type: 'Work'
            }
          ],
          fax: [
            {
              country_code: '+1',
              number: '555-1234',
              type: 'Work'
            }
          ],
          first_name: 'John',
          gstin: 'GSTIN12345678',
          http_url: 'http://www.example.com',
          is_addr_same_as_ship_to: false,
          is_cml_address: true,
          last_name: 'Doe',
          legal_name: 'Example Corporation',
          middle_name: '',
          organization: 'Example Corp',
          organization_code: 'EX-CORP',
          phone_number: [
            {
              country_code: '+1',
              number: '555-5678',
              type: 'Work'
            }
          ],
          profile_id: 'PROFILE-789',
          suffix: '',
          title: 'Mr.',
          trade_name: 'Example'
        },
        {
          addnl_attributes: null,
          address_tag: 'dispatch-from-details',
          address_info: {
            address_id: generateUUIDV4(),
            address_line1: shippingInfo.address_line1,
            address_line2: shippingInfo.address_line2,
            coordinates: {
              latitude: '0',
              longitude: '0'
            },
            country: shippingInfo.country,
            country_code: countryCodeMap[shippingInfo.country],
            location: shippingInfo.location,
            place_of_supply: countryStateMap[shippingInfo.country][shippingInfo.state]?.state_code,
            postal_code: shippingInfo.postal_code,
            state: shippingInfo.state,
            state_code: countryStateMap[shippingInfo.country][shippingInfo.state]?.state_code
          },
          department: 'Sales',
          email: [
            {
              email_id: customerInfo.email_id,
              type: 'Work'
            }
          ],
          fax: [
            {
              country_code: '+1',
              number: '555-1234',
              type: 'Work'
            }
          ],
          first_name: customerInfo.first_name,
          gstin: 'GSTIN12345678',
          http_url: 'http://www.example.com',
          is_addr_same_as_ship_to: false,
          is_cml_address: true,
          last_name: customerInfo.last_name,
          legal_name: customerInfo.legal_name,
          middle_name: customerInfo.middle_name,
          organization: customerInfo.organization,
          organization_code: 'EX-INC',
          phone_number: [
            {
              country_code: '+91',
              number: customerInfo.phone_number,
              type: 'Work'
            }
          ],
          suffix: '',
          title: 'Mr.',
          trade_name: 'Example',
          profile_id: 'P123456'
        }
      ],
      header_charges: [
        ...(promiseInfo.delivery_cost
          ? [
              {
                amount: promiseInfo.delivery_cost,
                charge_category: 'CHARGES',
                charge_key_ref: 'CHRG123',
                charge_name: 'SHIPPING',
                hsn_code: '123456',
                is_billable: true,
                is_discount: false,
                is_shipping_charge: true,
                is_taxable: false
                // reference: 'ABC123'
              }
            ]
          : []),

        ...headerLevelPromoCouponCharges,

        ...(loyaltyRedeemAmount ? [loyaltyPromotionCharge] : []),
        ...(headerConvenienceCharges ? [headerConvenienceCharge] : []),
        ...(headerMiscCharges ? [headerMiscCharge] : [])
      ].map(item => ({ ...item, round_off_amount: 0 })),
      created_datetime: new Date().toISOString(),
      currency_code: 'INR',
      header_tax_code_summary: [],
      igst_on_intra: false,
      is_cod: true,
      is_exchange: false,
      is_history: true,
      is_offline: false,
      is_void: true,
      item_list: ordersItemList,
      language: 'English',
      last_updated_datetime: new Date().toISOString(),
      // node_id: promiseInfo.node_id,
      // node_key_ref: promiseInfo.node_id,
      // node_type: 'Warehouse',
      order_end_trans_datetime: new Date().toISOString(),
      order_key_ref: 'ref',

      cart_id: orderHeaderForm.getFieldsValue().order_number,
      order_start_trans_datetime: new Date().toISOString(), //!Generate
      order_type: 'FORWARD',
      // order_sub_type: promiseInfo?.delivery_method !== 'STORE_PICKUP' ? 'ONLINE' : 'STORE',
      // order_version: '1',
      payment_details: {
        addnl_attributes: [
          {
            data_type: 'Text',
            field_xpath: 'FieldXPath123',
            name: 'Attribute1',
            value: 'Value1'
          }
        ],
        payment_instructions: 'Please make the payment by the due date.',
        balance_due: 0,
        due_date: '2023-06-30T23:59:59+05:30',
        // over_due_days: 10,
        payment_lines: [
          {
            account_no: '1234567890',
            amount: calculateFinalOrderTotalWithCoupon() + (promiseInfo?.delivery_cost || 0),
            branch: 'XYZ Branch',
            currency_code: 'INR',
            ifsc_code: 'ABCD1234',
            media_type: 'Online',
            payment_type: 'CAPTURE',
            paid_date: '2023-06-25T15:30:00+05:30',
            payee_first_name: customerInfo.first_name,
            payee_last_name: customerInfo.last_name,
            payee_middle_name: customerInfo.middle_name,
            payment_datetime: new Date().toISOString(),
            payment_ref_id: 'PAY-123456',
            payment_method: 'CASH',
            payment_status: 'RECVD'
            // tender_type: 'Online Transfer'
          }
        ],
        payment_status: 'RECVD',
        payment_terms: 'Net 30',
        total_lines: 1,
        total_paid_amount: calculateFinalOrderTotalWithCoupon() + (promiseInfo?.delivery_cost || 0)
      },
      pos_no: 'POS-123',
      // promise_id: 'PROMISE-987',
      promise_id: promiseNumber,
      promise_key_ref: 'PROMISE-REF-XYZ',
      promise_version: '2',
      reverse_charge_applicable: true,
      total_line_items: itemList.length,
      total_line_qty: Object.keys(editableItemInfo)
        .map(key => editableItemInfo[key].quantity)
        .reduce((prev, curr) => (prev as number) + (curr as number), 0),
      training_mode_flag: false,
      header_taxes: null,
      supply_type_code: 'B2C',
      tax_scheme: 'GST',

      is_temp_home: promiseInfo.delivery_method !== 'STORE_PICKUP',
      gift_instruction: giftingForm.getFieldsValue().gifting_instructions || undefined,
      item_value_taxes: [
        {
          tax_code: 'string',
          price: 0
        }
      ],

      // fulfilment_info: {
      //   delivery_method: promiseInfo.delivery_method,
      //   delivery_speed: promiseInfo.delivery_type,
      //   delivery_cost: {
      //     amount: promiseInfo.delivery_cost,
      //     currency_code: 'INR',
      //     fraction: 1
      //   },
      //   promise_delivery_date: promiseInfo.delivery_date_iso,
      //   expected_pickup_date: promiseInfo.delivery_date_iso,
      //   estimated_delivery_date: promiseInfo.delivery_date_iso,
      //   estimated_shipping_date: promiseInfo.expected_date,
      //   expected_picking_date: promiseInfo.expected_date,
      //   ship_node_name: promiseInfo.node_id,
      //   ship_node_id: promiseInfo.node_uuid,
      //   slot_id: promiseInfo.slot_id,
      //   slot_window_end: slot_end ?? undefined,
      //   slot_window_start: slot_start ?? undefined,
      //   transport_actions: [
      //     {
      //       is_dropship: false,
      //       transportation_mode: 'enum',
      //       carrier_operator_id: promiseInfo.courier_name,
      //       delivery_notes: 'Handle with care',
      //       delivery_price_info: {
      //         amount: promiseInfo.delivery_cost,
      //         currency_code: 'INR',
      //         fraction: 1
      //       },
      //       operator_delivery_charge: {
      //         amount: promiseInfo.delivery_cost,
      //         currency_code: 'INR',
      //         fraction: 1
      //       }
      //     }
      //   ]
      // }
      fulfilment_details: fulfillmentOptionsApiResponse
    };

    const { errors } = await orderService.createOrder(orderRequest);
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({ message: 'Order created successfully!' });
      incrementOrderNumber();
    }

    setLoader(false);
  };

  const [promotionLoader, setPromotionLoader] = React.useState(false);

  const getPromotionsRequest = () => {
    const line_items: any = itemList.map(
      ({ id, product_id, sku_id, product_name, country_origin, mrp, seller_info, article_id, categories }) => {
        const listingPriceDiscounted = priceListSkuMap[sku_id as string]?.discounted_listing_price;
        const listingPrice = priceListSkuMap[sku_id as string]?.list_price;
        return {
          co_line_id: id,
          product_name,

          item_identifier_type: 'SKU',
          item_id: sku_id,
          // sku_id: sku_id,
          seller_id: seller_info?.seller_id,
          // country_of_origin: country_origin,
          article_id,

          category: categories,
          brand: '',
          price_list_info: {
            cost_price: listingPrice,

            list_price: listingPriceDiscounted,

            unit_price: listingPriceDiscounted
          },
          quantity: {
            number_of_units: editableItemInfo[sku_id as string]?.quantity,
            unit_size: 1,
            unit_of_measure: editableItemInfo[sku_id as string]?.uom
          },
          total_discount_value: calculateDiscountLineLevel(sku_id as string) || 0,
          total_gross_amount: (listingPriceDiscounted || 0) * (editableItemInfo[sku_id as string]?.quantity || 0),
          is_gift: false,

          is_service: false,
          is_item_on_sale: priceListSkuMap[sku_id as string]?.is_product_on_sale || false,
          is_base_price_locked: priceListSkuMap[sku_id as string]?.is_sale_price_lock || false
        };
      }
    );

    const request: any = {
      // tenant_id: tenentName,
      order_date: new Date().toISOString(),
      supply_type_code: 'B2C',
      // business_group_id: bussinessGroupName,
      channel_type: 'ECOM',
      channel_name: 'ecom',
      line_items,
      customer: {
        customer_type: 'REGISTERED_USER',
        // customer_tier: customerDetails.tier,
        customer_id: customerDetails.id
        // new_buyer: customerDetails.new_buyer,
        // customer_segments: customerDetails.customer_segments,
        // date_of_birth: customerDetails.date_of_birth
      },
      //!Mock
      // ship_to: {
      //   address_info: {
      //     country_code: 'CHL',
      //     location: 'chile',
      //     postal_code: 'chile',
      //     state: 'chile'
      //   }
      // },
      order_id: orderHeaderForm.getFieldsValue().order_number,
      order_type: 'FORWARD',
      delivery_charge_info: {
        delivery_charge: 0,
        is_free_delivery: true
      },
      discount_value: calculateTotalDiscount(),
      gross_amount: getGrossOrderTotal(),
      currency: userCurrency
    };

    return request;
  };

  const calculateTotalDiscount = () => {
    let discount = 0;

    itemList.forEach(({ sku_id }) => {
      discount += calculateDiscountLineLevel(sku_id as string);
    });

    discount += calculateHeaderLevelPromoDiscount();
    discount += loyaltyRedeemValue * 0.01;

    return discount || 0;
  };

  const fetchPromotionDataForCart = async () => {
    setLoading(true);
    const request = getPromotionsRequest();

    const { data, errors } = await getPromosForCart(request);
    if (_.isEmpty(errors)) {
      const articleIdPromoMap: Record<string, any> = {};
      (data?.line_items || []).forEach((lineItem: any) => {
        articleIdPromoMap[lineItem.sku_id] = lineItem;
      });
      setPromotionReccomendation({ ...data, articleIdPromoMap });
      setAppliedPromotionCouponInfo((appliedPromotionCouponInfo: any) => {
        return {
          ...appliedPromotionCouponInfo,
          appliedHeaderPromotions: data?.header_promo_applied || [],
          appliedLineLevelPromotion: articleIdPromoMap
        };
      });

      //!Set promo charges

      const headerCharges = data?.charges || [];
      const articleChargesMap = data?.line_items?.reduce((acc: any, curr: any) => {
        acc[curr.sku_id] = curr?.charges || [];

        return acc;
      }, {});
      setChargeDetailsFromPromoService({ articleChargesMap, headerCharges });
    } else {
      setPromotionReccomendation({} as any);
    }
    setLoading(false);
  };

  const calculateDiscountLineLevel = (articleId: string) => {
    //!Check applied Promotions at line level//
    let discount = 0;

    const lineLevelAppliedInfo = appliedPromotionCouponInfo?.appliedLineLevelPromotion
      ? appliedPromotionCouponInfo?.appliedLineLevelPromotion[articleId]
      : {};

    const appliedPromos = lineLevelAppliedInfo?.line_promo_applied || [];

    appliedPromos.forEach((promo: any) => {
      discount += promo?.discount_info?.discount || 0;
    });

    return discount;
  };

  const calculateLineLevelDiscountPlusPriceListDiscount = (articleId: string) => {
    const lineLevelDiscountWithoutPriceList = calculateDiscountLineLevel(articleId) || 0;

    const skuId = itemList.find(item => item.sku_id === articleId)?.sku_id;

    const lineLevelPriceListMrpDiscounted = priceListSkuMap[skuId as string]?.discounted_listing_price || 0;
    const lineLevelPriceListMrp = priceListSkuMap[skuId as string]?.list_price || 0;
    const lineLevelDiscount =
      lineLevelPriceListMrp - lineLevelPriceListMrpDiscounted + lineLevelDiscountWithoutPriceList;

    return lineLevelDiscount;
  };

  const handleFetchApplicablePromotions = async () => {
    setPromotionLoader(true);

    const line_items: any = itemList.map(
      ({ id, product_id, sku_id, product_name, country_origin, mrp, seller_info, article_id, categories }) => {
        const listingPriceDiscounted = priceListSkuMap[sku_id as string]?.discounted_listing_price;
        const listingPrice = priceListSkuMap[sku_id as string]?.list_price;
        return {
          co_line_id: id,
          product_name,
          // product_type: 'string',
          item_identifier_type: 'SKU',
          item_id: sku_id,
          article_id,
          seller_id: seller_info?.seller_id,
          // country_of_origin: country_origin,
          // category_level: 'LEVEL1',
          category: categories,
          brand: '',
          // model: '',
          price_list_info: {
            cost_price: listingPrice,

            list_price: listingPriceDiscounted,

            unit_price: listingPriceDiscounted
          },
          quantity: {
            number_of_units: editableItemInfo[sku_id as string]?.quantity,
            unit_size: 1,
            unit_of_measure: editableItemInfo[sku_id as string]?.uom
          },
          total_discount_value: calculateDiscountLineLevel(sku_id as string),
          total_gross_amount: (listingPriceDiscounted || 0) * (editableItemInfo[sku_id as string]?.quantity || 0),
          is_gift: false,

          is_service: false,
          is_item_on_sale: priceListSkuMap[sku_id as string]?.is_product_on_sale || false,
          is_base_price_locked: priceListSkuMap[sku_id as string]?.is_sale_price_lock || false
        };
      }
    );

    const request: any = {
      supply_type_code: 'B2C',
      // tenant_id: tenentName,
      order_date: new Date().toISOString(),
      // business_group_id: bussinessGroupName,
      // site_id: 'site',
      channel_type: 'ECOM',
      channel_name: 'ecom',
      line_items,
      customer: {
        customer_type: 'REGISTERED_USER',
        // customer_tier: customerDetails.tier,
        customer_id: customerDetails.id
        // new_buyer: customerDetails.new_buyer,
        // customer_segments: customerDetails.customer_segments,
        // date_of_birth: customerDetails.date_of_birth
      },
      //!Mock
      // ship_to: {
      //   address_info: {
      //     country_code: 'CHL',
      //     location: 'chile',
      //     postal_code: 'chile',
      //     state: 'chile'
      //   }
      // },
      order_id: orderHeaderForm.getFieldsValue().order_number,
      order_type: 'FORWARD',
      delivery_charge_info: {
        delivery_charge: 0,
        is_free_delivery: true
      },
      discount_value: calculateTotalDiscount(),
      gross_amount: getGrossOrderTotal(),

      currency: userCurrency
    };

    const { data, errors } = await getCouponsPromo(request);

    if (errors?.length) {
      // displayErrorNotifications(errors);
    } else {
      setCouponPromotionList({ data });
      setShowPromotionModal(true);
    }
    setPromotionLoader(false);
  };

  const { setLoading } = useLoader(({ setLoading, loading }) => ({ setLoading, loading }));
  const { productData, setProductData } = useOrder(({ productData, setProductData }) => ({
    productData,
    setProductData
  }));

  const handleFetchLoyaltyEarnInfo = async () => {
    setLoading(true);

    const items2 = itemList.map(
      ({ id, product_id, sku_id, product_name, country_origin, mrp, seller_info, article_id, categories }) => {
        const listingPriceDiscounted = priceListSkuMap[sku_id as string]?.discounted_listing_price;
        const listingPrice = priceListSkuMap[sku_id as string]?.list_price;
        return {
          co_line_id: id,
          product_name,
          // product_type: 'Sample',

          item_id: sku_id,
          article_id,
          seller_id: seller_info?.seller_id,
          // country_of_origin: country_origin,
          is_gift: false,
          is_service: false,
          // category_level: 'string',
          category: categories,
          brand: 'string',
          // model: 'string',
          price_list_info: {
            cost_price: listingPrice,
            list_price: listingPriceDiscounted,
            unit_price: listingPriceDiscounted
          },
          quantity: {
            number_of_units: editableItemInfo[sku_id as string]?.quantity,
            unit_size: 1,
            unit_of_measure: editableItemInfo[sku_id as string]?.uom
          },
          total_discount_value: calculateDiscountLineLevel(sku_id as string),
          total_gross_amount: (listingPriceDiscounted || 0) * (editableItemInfo[sku_id as string]?.quantity || 0)
        };
      }
    );

    const newRequest: any = {
      tenant_id: tenentName,
      business_group_id: bussinessGroupName,
      supply_type_code: 'B2C',
      site_id: tenentName,
      channel: 'DOTCOM',
      channel_type: 'DOTCOM',
      channel_name: 'ecom',
      currency: userCurrency,
      customer: {
        // customer_type: 'CUS',
        customer_tier: customerDetails.tier,
        // member_type: customerDetails.tier,
        customer_id: customerDetails.id
      },
      ship_to: {
        address_info: {
          country: 'India',
          location: 'string',
          postal_code: 'string',
          state: 'Karnataka'
        }
      },
      doc_type: 'FORWARD',
      order_id: orderHeaderForm.getFieldsValue().order_number,

      line_items: items2,
      delivery_charge_info: {
        is_free_delivery: false,
        is_delivery_charge: 0
      },
      discount_value: calculateTotalDiscount(),
      gross_amount: getGrossOrderTotal()
    };

    // const { data, errors } = await getLoyaltyEarnInfo(request);

    const { data, errors } = await getLoyaltyEarnInfoNew(newRequest);

    if (_.isEmpty(errors)) setLoyaltyEarnData(data?.header_loyalty_earn || {});
    setLoading(false);
  };

  const fetchLoyaltyEarnInfo = async () => {
    if (!_.isEmpty(itemList)) await handleFetchLoyaltyEarnInfo();
  };

  const { baseCurrency, userCurrency, orderNumber, incrementOrderNumber, promiseNumber, setPromiseNumber } = useLocale(
    ({
      setUserCurrency,
      baseCurrency,
      userCurrency,
      orderNumber,
      incrementOrderNumber,
      promiseNumber,
      setPromiseNumber
    }) => ({
      setUserCurrency,
      baseCurrency,
      userCurrency,
      orderNumber,
      promiseNumber,
      incrementOrderNumber,
      setPromiseNumber
    })
  );

  const [selectedShippingCountry, setSelectedShippingCountry] = React.useState('');
  const [selectedBillingCountry, setSelectedBillingCountry] = React.useState('');

  React.useEffect(() => {
    orderHeaderForm.setFieldValue('currency_code', userCurrency);
  }, [userCurrency]);

  React.useEffect(() => {
    if (loyaltyRedeemValue) {
      fetchLoyaltyEarnInfo();
    }
  }, [loyaltyRedeemValue]);

  const handleApplyCoupon = () => {
    const appliedCouponDetails = (couponPromotionList?.data || []).find(
      (details: any) => details.code === selectedCouponCode
    );
    setAppliedPromotionCouponInfo((appliedPromotionCouponInfo: any) => {
      return {
        ...appliedPromotionCouponInfo,
        appliedCoupon: appliedCouponDetails || {}
      };
    });

    displaySuccessNotification({ message: `Coupon ${selectedCouponCode} Applied` });
    setShowPromotionModal(false);
  };

  const calculateDiscountValueFromCoupon = () => {
    const couponDetails = appliedPromotionCouponInfo?.appliedCoupon || {};
    let discount = 0;

    if (couponDetails?.discount?.type === 'PERCENTAGE') {
      const orderTotalWithoutCoupon = calculateFinalOrderTotalWithoutCoupon();
      discount = (couponDetails?.discount?.percentage / 100 || 0) * orderTotalWithoutCoupon;
    }

    discount = Math.min(discount, couponDetails?.discount?.capValue || 0);

    return discount;
  };

  const getAppliedCouponCode = () => {
    return applyCouponResponse?.coupon_code || selectedCouponCode || '';
  };

  const calculateHeaderLevelPromoDiscount = () => {
    let discount = 0;

    const headerLevelPromos = appliedPromotionCouponInfo?.appliedHeaderPromotions || [];

    headerLevelPromos.forEach((promo: any) => {
      discount += promo?.discount_info?.discount || 0;
    });

    return discount;
  };

  const calculateFinalOrderTotalWithoutCoupon = () => {
    //!Order Line total(including line level discount) - loyalty saving - headerLevelTotal
    const headerLevelPromoDiscount = calculateHeaderLevelPromoDiscount();

    return getOrderTotal() - loyaltyRedeemValue * 0.01 - headerLevelPromoDiscount;
  };

  const calculateTotalDiscountView = () => {
    return calculateTotalDiscount();
  };

  const calculateFinalOrderTotalWithCoupon = () => {
    const orderTotalWithoutCoupon = calculateFinalOrderTotalWithoutCoupon();

    const couponDiscount = getCouponDiscountFromPromoService();

    return orderTotalWithoutCoupon - couponDiscount || 0;
  };

  React.useEffect(() => {
    recalculateProductTotal();
  }, [priceListSkuMap]);

  const fetchPricesFromPriceList = async () => {
    setLoading(true);

    const skus = itemList.map(item => ({
      qty: (editableItemInfo[item.sku_id as string]?.quantity as number) || 0,
      is_price_lock: item.is_price_lock,
      item_id: item.sku_id
    }));

    const { data, errors } = await priceService.getPricesForSkus(
      skus,
      customerDetails.priceListDetails?.id || '',
      customerDetails?.tier
    );

    const priceMap: Record<string, any> = {};

    if (_.isEmpty(errors) && !_.isEmpty(data)) {
      const pricedItemIds = new Set(data.line_items_prices.map((item: any) => item.item_id));

      (data.line_items_prices || []).forEach((item: any) => {
        const { item_id, list_price, is_sale_price_lock, is_product_on_sale } = item;
        const unit_price = itemList.find(item => item.sku_id === item_id)?.mrp;

        const discountedPrice = list_price;

        const discounted_listing_price = discountedPrice;
        priceMap[item_id] = {
          sku_id: item_id,
          is_sale_price_lock: is_sale_price_lock || false,
          is_product_on_sale: is_product_on_sale || false,
          discounted_listing_price: discounted_listing_price || unit_price || 0,
          list_price: unit_price
        };
      });

      itemList.forEach(item => {
        if (item.sku_id && !pricedItemIds.has(item.sku_id)) {
          priceMap[item.sku_id] = {
            sku_id: item.sku_id,
            discounted_listing_price: item.mrp,
            list_price: item.mrp
          };
        }
      });
    } else {
      itemList.forEach(item => {
        priceMap[item?.sku_id as string] = {
          sku_id: item?.sku_id,
          discounted_listing_price: item?.mrp,
          list_price: item?.mrp
        };
      });
    }

    setPriceListSkuMap(priceMap);
    setLoading(false);
  };

  const recalculateProductTotal = () => {
    const productTotalMap: any = {};

    for (let articleId in editableItemInfo) {
      const { quantity } = editableItemInfo[articleId];
      const skuId = itemList?.find(item => item.sku_id === articleId)?.sku_id || '';

      const product_total = (priceListSkuMap[skuId]?.discounted_listing_price || 0) * (quantity || 0);

      productTotalMap[articleId] = { product_total };
    }
    setProductTotalMap(productTotalMap);
  };

  const [currentEditingField, setCurrentEditingField] = React.useState<
    null | 'gifting_instructions' | 'misc_charge' | 'convenience_charge'
  >(null);

  const [currentEditingFieldId, setCurrentEditingFieldId] = React.useState<null | string>(null);

  const calculateTotalLinesPromoDiscount = () => {
    return itemList
      .map(({ sku_id }) => calculateDiscountLineLevel(sku_id as string))
      .reduce((acc, curr) => acc + curr, 0);
  };

  const calculateConvenienceTotal = () => {
    return Object.keys(editableItemInfo)
      .map(key => {
        const { convenience_charge = 0 } = editableItemInfo[key];
        return convenience_charge;
      })
      .reduce((acc, curr) => acc + curr, 0);
  };

  const calculateMiscTotal = () => {
    return Object.keys(editableItemInfo)
      .map(key => {
        const { misc_charge = 0 } = editableItemInfo[key];
        return misc_charge;
      })
      .reduce((acc, curr) => acc + curr, 0);
  };

  const calculateGiftTotal = () => {
    return Object.keys(editableItemInfo)
      .map(key => {
        const { is_gift = false } = editableItemInfo[key];
        const charge: number = is_gift ? STATIC_GIFT_CHARGE : 0;
        return charge;
      })
      .reduce((acc, curr) => acc + curr, 0);
  };

  const [headerConvenienceCharges, setHeaderConvenienceCharges] = React.useState(0);
  const [headerMiscCharges, setHeaderMiscCharges] = React.useState(0);

  const [miscEditform] = Form.useForm();

  const [applyCouponResponse, setApplyCouponResponse] = React.useState({} as any);

  const checkIfNextOrderCoupon = (applyCouponResponse: any) => {
    if (_.isEmpty(applyCouponResponse)) return false;

    const appliedCouponDetails = !_.isEmpty(applyCouponResponse) ? applyCouponResponse?.header_promo_applied[0] : {};

    if (appliedCouponDetails?.discount_info?.next_order_coupon) return true;

    return false;
  };

  const getAppliedCouponDetails = () => {
    return !_.isEmpty(applyCouponResponse) ? applyCouponResponse?.header_promo_applied[0] : {};
  };

  const getCouponDiscountFromPromoService = () => {
    if (_.isEmpty(applyCouponResponse)) return 0;

    const appliedCouponDetails = !_.isEmpty(applyCouponResponse) ? applyCouponResponse?.header_promo_applied[0] : {};

    if (appliedCouponDetails?.discount_info?.next_order_coupon) return 0;

    return appliedCouponDetails?.discount_info?.discount || 0;
  };

  const applyCouponFromPromoService = async (customCode?: string) => {
    setLoading(true);
    const shippingAddress = shippingInfoForm.getFieldsValue();

    const line_items: any = itemList.map(
      ({ id, product_name, country_origin, mrp, seller_info, sku_id, categories, article_id }) => {
        const listingPriceDiscounted = priceListSkuMap[sku_id as string]?.discounted_listing_price;
        const listingPrice = priceListSkuMap[sku_id as string]?.list_price;
        return {
          co_line_id: id,
          product_name,
          charges: (chargeDetailsFromPromoService.articleChargesMap || {})[sku_id as string] || null,
          // product_type: 'string',
          item_identifier_type: 'SKU',
          item_id: sku_id,
          article_id,
          seller_id: seller_info?.seller_id,
          // country_of_origin: country_origin,
          // category_level: 'LEVEL1',
          category: categories,
          brand: '',
          // model: '',
          price_list_info: {
            cost_price: listingPrice,

            list_price: listingPriceDiscounted,

            unit_price: listingPriceDiscounted
          },
          quantity: {
            number_of_units: editableItemInfo[sku_id as string]?.quantity,
            unit_size: 1,
            unit_of_measure: editableItemInfo[sku_id as string]?.uom
          },
          total_discount_value: calculateDiscountLineLevel(sku_id as string),
          total_gross_amount: (listingPriceDiscounted || 0) * (editableItemInfo[sku_id as string]?.quantity || 0),
          is_gift: false,

          is_service: false,
          is_item_on_sale: priceListSkuMap[sku_id as string]?.is_product_on_sale || false,
          is_base_price_locked: priceListSkuMap[sku_id as string]?.is_sale_price_lock || false
        };
      }
    );

    const request = {
      // tenant_id: tenentName,
      // business_group_id: bussinessGroupName,
      coupon_code: customCode || getAppliedCouponCode(),
      // site_id: 'Command-center',
      channel_type: 'ECOM',
      channel_name: 'ecom',
      charges: chargeDetailsFromPromoService?.headerCharges || [],
      supply_type_code: 'B2C',
      customer: {
        customer_type: 'REGISTERED_USER',
        // customer_tier: customerDetails.tier,
        customer_id: customerDetails.id
        // new_buyer: false,
        // customer_segments: []
      },
      // ship_to: {
      //   address_info: {
      //     country_code: 'IN',
      //     location: shippingAddress.location,
      //     postal_code: shippingAddress.postal_code,
      //     state: shippingAddress.state
      //   }
      // },
      order_date: new Date().toISOString(),
      order_id: orderHeaderForm.getFieldsValue().order_number,
      order_type: 'FORWARD',
      delivery_charge_info: {
        delivery_charge: promiseInfo?.delivery_cost || 0,
        is_free_delivery: promiseInfo?.delivery_cost === 0
      },
      discount_value: calculateTotalDiscount(),
      gross_amount: getGrossOrderTotal(),
      currency: 'INR',
      line_items
    };
    const { errors, data } = await postApplyCouponFromPromotionService(request);
    if (_.isEmpty(errors)) {
      setApplyCouponResponse(data);
      const headerCharges = data?.charges || [];
      const articleChargesMap = data?.line_items?.reduce((acc: any, curr: any) => {
        acc[curr.sku_id] = curr?.charges || [];

        return acc;
      }, {});
      setChargeDetailsFromPromoService({ articleChargesMap, headerCharges });

      if (checkIfNextOrderCoupon(data)) {
        displaySuccessNotification({ message: `Coupon applied, Your next order coupon will be sent to you shortly!` });
      } else {
        displaySuccessNotification({ message: `Coupon applied successfully!` });
      }
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
    setShowPromotionModal(false);
  };

  const redeemPromotionsFromPromoService = async () => {
    //! Codes should contain
    //! header level promotion
    //! header level coupons
    //! line level promotions

    const headerLevelPromos = appliedPromotionCouponInfo?.appliedHeaderPromotions || [];

    const headerLevelPromoCodes = headerLevelPromos.map((promo: any) => {
      return {
        code: promo.code,
        promotion_type: promo.promotion_type,
        discount: promo.discount_info.discount
      };
    });

    const lineLevelPromosArr = itemList.map(item => {
      const { sku_id } = item;
      const lineLevelAppliedInfo = appliedPromotionCouponInfo?.appliedLineLevelPromotion
        ? appliedPromotionCouponInfo?.appliedLineLevelPromotion[sku_id as string]
        : {};

      const lineLevelPromos = lineLevelAppliedInfo?.line_promo_applied || [];

      return [...lineLevelPromos];
    });

    const lineLevelPromos = lineLevelPromosArr.flat(Infinity);

    const couponDetails = getAppliedCouponDetails();

    const getDiscountData = (tableData: any, code: any) => {
      let totalChargeAmount = 0; // Initialize total charge amount
      tableData.map((item: any) => {
        let charges = (chargeDetailsFromPromoService.articleChargesMap || {})[item.sku_id as string] || [];
        charges?.forEach((charge: any) => {
          if (charge.charge_category === code) {
            totalChargeAmount += charge.charge_amount;
          }
        });
      });
      return totalChargeAmount;
    };

    const couponCodes = {
      code: applyCouponResponse.coupon_code ? applyCouponResponse.coupon_code : couponDetails?.code,
      promotion_type: couponDetails?.promotion_type,
      discount: getDiscountData(
        itemList,
        applyCouponResponse.coupon_code ? applyCouponResponse.coupon_code : couponDetails?.code
      )
    };

    const lineLevelCodes = lineLevelPromos.map((promo: any) => {
      return {
        code: promo.code,
        promotion_type: promo.promotion_type,
        discount: getDiscountData(itemList, promo.code)
      };
    });

    const codes = [...headerLevelPromoCodes, ...lineLevelCodes, ...(!_.isEmpty(couponDetails) ? [couponCodes] : [])];

    let filteredCodes: any[] = [];

    codes.filter(obj => {
      if (!filteredCodes.map(code => code.code).includes(obj.code)) {
        filteredCodes.push(obj);
        return true;
      }
      return false;
    });

    const shippingAddress = shippingInfoForm.getFieldsValue();
    const line_items: any = itemList.map(
      ({ id, product_id, product_name, country_origin, mrp, seller_info, sku_id, article_id, categories }) => {
        const listingPriceDiscounted = priceListSkuMap[sku_id as string]?.discounted_listing_price;
        const listingPrice = priceListSkuMap[sku_id as string]?.list_price;
        return {
          co_line_id: id,
          product_name,
          // product_type: 'string',
          charges: (chargeDetailsFromPromoService.articleChargesMap || {})[sku_id as string] || null,
          item_identifier_type: 'SKU',
          item_id: sku_id,
          article_id: article_id,
          seller_id: seller_info?.seller_id,
          // country_of_origin: country_origin,
          // category_level: 'LEVEL1',
          category: categories,
          brand: '',
          // model: '',
          price_list_info: {
            cost_price: listingPrice,

            list_price: listingPriceDiscounted,

            unit_price: listingPriceDiscounted
          },
          quantity: {
            number_of_units: editableItemInfo[sku_id as string]?.quantity,
            unit_size: 1,
            unit_of_measure: editableItemInfo[sku_id as string]?.uom
          },
          total_discount_value: calculateDiscountLineLevel(sku_id as string),
          total_gross_amount: (listingPriceDiscounted || 0) * (editableItemInfo[sku_id as string]?.quantity || 0),
          is_gift: false,

          is_service: false,
          is_item_on_sale: priceListSkuMap[sku_id as string]?.is_product_on_sale || false,
          is_base_price_locked: priceListSkuMap[sku_id as string]?.is_sale_price_lock || false
        };
      }
    );

    const headerLevelPromotionsCharges = headerLevelPromos.map((promo: any) => {
      return {
        amount: promo?.discount_info?.discount || 0,
        charge_category: 'PROMO',
        charge_key_ref: 'Promotions',
        charge_name: 'PRICE',
        hsn_code: '123456',
        is_billable: true,
        is_discount: true,
        is_shipping_charge: false,
        is_taxable: false
        // reference: promo?.title
      };
    });

    const couponDiscount = getCouponDiscountFromPromoService();

    const headerChargesFromPromoService = chargeDetailsFromPromoService?.headerCharges || [];

    const couponPromotionCharge = {
      amount: couponDiscount,
      charge_category: 'PROMO',
      charge_key_ref: 'Coupons',
      charge_name: 'PRICE',
      hsn_code: '123456',
      is_billable: true,
      is_discount: true,
      is_shipping_charge: false,
      is_taxable: false
      // reference: couponDetails?.title
    };

    const loyaltyRedeemAmount = loyaltyRedeemValue * 0.01;

    const loyaltyPromotionCharge = {
      amount: loyaltyRedeemAmount,
      charge_category: 'PROMO',
      charge_key_ref: 'Loyalty',
      charge_name: 'PRICE',

      hsn_code: '123456',
      is_billable: true,
      is_discount: true,
      is_shipping_charge: false,
      is_taxable: false
      // reference: 'Loyalty Discount'
    };

    const headerMiscCharge = {
      charge_name: 'MISC',
      amount: headerMiscCharges,
      charge_category: 'CHARGES',
      reference: 'ref',
      is_billable: true,
      hsn_code: '100099',
      is_taxable: false,
      is_discount: false,
      is_shipping_charge: false
    };
    const headerConvenienceCharge = {
      charge_name: 'CONVE',
      amount: headerConvenienceCharges,
      charge_category: 'CHARGES',
      reference: 'ref',
      is_billable: true,
      hsn_code: '100099',
      is_taxable: false,
      is_discount: false,
      is_shipping_charge: false
    };

    const request = {
      codes: filteredCodes,
      order_info: {
        // tenant_id: tenentName,
        // business_group_id: bussinessGroupName,
        // site_id: 'command-center',
        channel_type: 'ECOM',
        channel_name: 'ecom',
        customer: {
          customer_type: 'REGISTERED_USER',
          // customer_tier: customerDetails.tier,
          customer_id: customerDetails.id
          // new_buyer: customerDetails.new_buyer,
          // customer_segments: customerDetails.customer_segments,
          // date_of_birth: customerDetails.date_of_birth
        },
        // ship_to: {
        //   address_info: {
        //     country_code: 'IN',
        //     location: shippingAddress.location,
        //     postal_code: shippingAddress.postal_code,
        //     state: shippingAddress.state
        //   }
        // },

        order_id: orderHeaderForm.getFieldsValue().order_number,
        order_date: new Date().toISOString(),
        supply_type_code: 'B2C',
        order_type: 'FORWARD',
        delivery_charge_info: {
          delivery_charge: promiseInfo?.delivery_cost || 0,
          is_free_delivery: promiseInfo?.delivery_cost === 0
        },
        discount_value: calculateTotalDiscount(),
        gross_amount: getGrossOrderTotal(),
        currency: 'INR',
        header_promo_applied: headerLevelPromos,
        line_items,
        charges: [
          ...(promiseInfo.delivery_cost
            ? [
                {
                  amount: promiseInfo.delivery_cost,
                  charge_category: 'CHARGES',
                  charge_key_ref: 'CHRG123',
                  charge_name: 'SHIPPING',
                  hsn_code: '123456',
                  is_billable: true,
                  is_discount: false,
                  is_shipping_charge: true,
                  is_taxable: false
                  // reference: 'ABC123'
                }
              ]
            : []),

          ...headerChargesFromPromoService,
          // ...(couponDiscount ? [couponPromotionCharge] : []),
          ...(loyaltyRedeemAmount ? [loyaltyPromotionCharge] : []),
          ...(headerConvenienceCharges ? [headerConvenienceCharge] : []),
          ...(headerMiscCharges ? [headerMiscCharge] : [])
        ]
      }
    };

    const { errors } = await postRedeemAllPromotions(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotions redeemed successfully!' });

      // await handleCheckout()
    }

    await handleCheckout();
  };

  const getFeasibleBodyData = () => {
    let bodyData = {
      cart_id: orderHeaderForm.getFieldsValue().order_number,
      order_type: 'FRW',
      channel: 'ONLINE',
      transaction_start_datetime: new Date().toISOString(),
      source_trigger: 'CART',
      order_number: orderHeaderForm.getFieldsValue().order_number,
      currency_code: 'CLP',
      checkout_datetime: new Date().toISOString(),
      customer_profile_info: {
        profile_id: customerSelectionForm.getFieldsValue().customerId,
        profile_type: 'BUSINESS'
      },
      store_id: generateUUIDV4(),
      pos_id: generateUUIDV4()
    };
    return bodyData;
  };

  const handleOnClickFeasibleFulfillment = async () => {
    setLoading(true);
    const AddressShipValidation = checkShipAdderss(shippingInfoForm.getFieldsValue());
    const AddressBillValidation = checkBillAdderss(billingInfoForm.getFieldsValue());
    if (AddressShipValidation || AddressBillValidation) {
      displayErrorNotifications([{ message: 'Fields are missing in Address Info !' }]);
      setLoading(false);
      return;
    } else {
      let shipToPostCode = shippingInfoForm.getFieldsValue().postal_code;
      let countryCode = shippingInfoForm.getFieldsValue().country === 'Chile' ? 'CL' : 'IN';
      let productString = JSON.stringify(itemList);
      let convertHeaderData = shipToPostCode + countryCode + productString;
      const hash = CryptoJS.MD5(convertHeaderData).toString();
      const bodyData = getFeasibleBodyData();
      const { data, errors } = await promiseCreation(bodyData, hash);
      if (!_.isEmpty(data)) {
        setPromiseNumber(data.promise_id);
        orderHeaderForm.setFieldsValue({ promise_number: data.promise_id, promise_status: data.promise_status });
        updateDeliveryLocation(data);
      } else {
        displayErrorNotifications(errors);
        setLoading(false);
      }
    }
  };

  const updateDeliveryLocation = async (data: any) => {
    const { country, postal_code } = shippingInfoForm.getFieldsValue();
    const bodyData = {
      address_id: 'string',
      country: country,
      country_code: country === 'Chile' ? 'CL' : 'IN',
      postal_code: postal_code,
      phone_numbers: [
        {
          availability: 'string',
          country_code: '+91',
          number: '11111111111',
          type: 'PERSONAL'
        }
      ]
    };

    let shipToPostCode = shippingInfoForm.getFieldsValue().postal_code;
    let countryCode = shippingInfoForm.getFieldsValue().country === 'Chile' ? 'CL' : 'IN';
    let productString = JSON.stringify(itemList);
    let convertHeaderData = shipToPostCode + countryCode + productString;
    const hash = CryptoJS.MD5(convertHeaderData).toString();

    const { errors } = await updateDelivery(data.promise_id, bodyData, hash);
    if (_.isEmpty(errors)) {
      replaceAllProducts(data);
    } else {
      displayErrorNotifications(errors);
      setLoading(false);
    }
  };

  const replaceAllProducts = async (data: any) => {
    const bodyData = getProducts(itemList);

    let shipToPostCode = shippingInfoForm.getFieldsValue().postal_code;
    let countryCode = shippingInfoForm.getFieldsValue().country === 'Chile' ? 'CL' : 'IN';
    let productString = JSON.stringify(itemList);
    let convertHeaderData = shipToPostCode + countryCode + productString;
    const hash = CryptoJS.MD5(convertHeaderData).toString();

    const { errors } = await replaceAllProduct(data.promise_id, bodyData, hash);
    if (_.isEmpty(errors)) {
      getFeasiableFullFillmentOption(data);
    } else {
      displayErrorNotifications(errors);
      setLoading(false);
    }
  };

  const getFeasiableFullFillmentOption = async (promiseData: any) => {
    let shipToPostCode = shippingInfoForm.getFieldsValue().postal_code;
    let countryCode = shippingInfoForm.getFieldsValue().country === 'Chile' ? 'CL' : 'IN';
    let productString = JSON.stringify(itemList);
    let convertHeaderData = shipToPostCode + countryCode + productString;
    const hash = CryptoJS.MD5(convertHeaderData).toString();

    const { data, errors } = await getFullFilmentOption(promiseData.promise_id, hash);
    if (!_.isEmpty(data)) {
      setFulfillmentOptionsApiResponse(data);
      const updatedPromiseGroups = data?.promise_groups?.map((group: any) => {
        const updatedPromiseOptions = group.promise_options.map((option: any) => {
          return {
            ...option,
            option_Flag: false
          };
        });
        return {
          ...group,
          promise_options: updatedPromiseOptions
        };
      });
      const newPromiseGrp = {
        ...data,
        promise_groups: updatedPromiseGroups
      };

      const productDetails = [] as any;

      data.products.forEach((product: any) => {
        productDetails.push({
          co_line_id: product.co_line_id,
          sku_id: product.sku_id
        });
      });
      setColineId(productDetails);

      setFeasibleData(newPromiseGrp);
      setPromiseModalVisible(true);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getProducts = (itemList: any) => {
    let body = itemList?.map((item: any) => {
      return {
        sku_id: item.sku_id,
        country_of_origin: item.country_origin,
        gtin: 'string',
        is_courierable: true,
        is_dangerous: true,
        is_gift: true,
        is_returnable: true,
        preference: {
          bag_required: true,
          picker_notes: 'string'
        },
        co_line_id: generateUUIDV4(),
        category: item.product_class,
        product_id: item.product_id,
        product_name: item.product_name,
        product_type: item.product_class,
        qty_same_as_parent: true,
        quantity: {
          number_of_units: editableItemInfo[item.sku_id].quantity,
          unit_of_measure: editableItemInfo[item.sku_id]?.uom,
          unit_size: 1,
          unit_size_fraction: 1
        },
        requires_shipping: true,
        article_id: item.article_id
      };
    });
    return { products: body };
  };

  const checkShipAdderss = (data: any) => {
    let result = false;
    shippingInfoForm.submit();
    if (
      !data.address_line1 ||
      data.address_line1?.length === 0 ||
      !data.country ||
      data.country?.length === 0 ||
      !data.location ||
      data.location?.length === 0 ||
      !data.postal_code ||
      data.postal_code?.length === 0 ||
      !data.state ||
      data.state?.length === 0
    ) {
      result = true;
    }
    return result;
  };

  const checkBillAdderss = (data: any) => {
    let result = false;
    billingInfoForm.submit();
    if (
      !data.address_line1 ||
      data.address_line1?.length === 0 ||
      !data.country ||
      data.country?.length === 0 ||
      !data.location ||
      data.location?.length === 0 ||
      !data.postal_code ||
      data.postal_code?.length === 0 ||
      !data.state ||
      data.state?.length === 0
    ) {
      result = true;
    }
    return result;
  };

  const handleChange = (e: any, item: any) => {
    const newData = e.target.value;
    item.promise_options.forEach((option: any) => {
      option.option_Flag = option.option_id === newData;
    });
    const FeasibleData = feasibleData.promise_groups.map((data: any) => {
      if (item.promise_group_id === data.promise_group_id) {
        return item;
      } else {
        return data;
      }
    });
    let NewFeasibleData = {
      ...feasibleData,
      promise_groups: FeasibleData
    };
    setFeasibleData(NewFeasibleData);
  };

  const handleOnSaveFeasiable = async () => {
    setLoading(true);
    let selectedOptions = [] as any;

    let shipToPostCode = shippingInfoForm.getFieldsValue().postal_code;
    let countryCode = shippingInfoForm.getFieldsValue().country === 'Chile' ? 'CL' : 'IN';
    let productString = JSON.stringify(itemList);
    let convertHeaderData = shipToPostCode + countryCode + productString;
    const hash = CryptoJS.MD5(convertHeaderData).toString();

    feasibleData.promise_groups.forEach((group: any) => {
      const { promise_group_id, promise_options } = group;
      promise_options.forEach((option: any) => {
        if (option.option_Flag) {
          selectedOptions.push({
            group_id: promise_group_id,
            selected_option_id: option.option_id
          });
        }
      });
    });
    const bodyData = {
      promise_groups: selectedOptions
    };
    const promiseId = orderHeaderForm.getFieldsValue()?.promise_number;
    if (feasibleData.promise_groups.length === bodyData.promise_groups.length) {
      const { data, errors } = await selectionApi(promiseId, bodyData, hash);
      if (!_.isEmpty(data)) {
        const promiseGroups = feasibleData.promise_groups;
        const selectedOptions = [] as any;
        promiseGroups.forEach((group: any) => {
          const selectedOptionsInGroup = group.promise_options.filter((option: any) => option.option_Flag);
          selectedOptionsInGroup.forEach((option: any) => {
            let skuname = option.skus?.map((cv: any) => cv.sku).join(', ');
            const { ship_node_id, delivery_method, delivery_speed, promise_delivery_date, delivery_cost } = option;
            selectedOptions.push({
              ship_node_id,
              skuname,
              delivery_method,
              delivery_speed,
              promise_delivery_date,
              delivery_cost
            });
          });
        });
        setselectedFullfillmentOptions(selectedOptions);
        setPromiseModalVisible(false);
        displaySuccessNotification({ message: 'Selection success !!' });
      } else {
        displayErrorNotifications(errors);
      }
    } else {
      displayErrorNotifications([{ message: 'Need to select an option for all the items !' }]);
    }
    setLoading(false);
  };

  const handleOnCommit = async (status: string) => {
    setLoading(true);
    const { data, errors } = await promiseStateCommit(promiseNumber, status);
    if (!_.isEmpty(data)) {
      displaySuccessNotification({ message: 'Status Updated!' });
      let updateStatus = data.promise_status;
      orderHeaderForm.setFieldsValue({ promise_status: updateStatus });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const removeSelectedOption = async () => {
    setLoading(true);
    const { errors } = await deleteSelectedOptions(promiseNumber);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Removed Selected Option!' });
      setselectedFullfillmentOptions([]);
    } else {
      displayErrorNotifications(errors);
      setselectedFullfillmentOptions([]);
    }
    setLoading(false);
  };

  const [customerDataFromApi, setCustomerDataFromApi] = React.useState(
    [] as {
      id: string;
      tier: string;
      alternateId: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      email: string;
      date_of_birth: string;
      new_buyer: string;
      customer_segments: any;
    }[]
  );
  const fetchCustomerDetailFromService = async () => {
    const params = {
      limit: 100
    };

    const { data, errors } = await loyaltyService.searchCustomer(params);

    if (_.isEmpty(errors)) {
      const transformedCustomerData = (data?.data || []).map((customer: any) => {
        return {
          id: customer.customer_id,
          memberType: customer.customer_tier || customer.member_type,
          tier: customer?.tier_type,
          alternateId: customer.alternate_id,
          firstName: customer.first_name,
          lastName: customer.last_name,
          phoneNumber: customer.phone_number,
          email: customer.email,
          date_of_birth: customer.date_of_birth,
          new_buyer: customer.new_buyer,
          customer_segments: customer.customer_segments || []
        };
      });

      setCustomerDataFromApi(transformedCustomerData);
    }
  };

  React.useEffect(() => {
    fetchCustomerDetailFromService();
  }, []);

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4 lg:px-2">
        <Card>
          <Typography.Title level={3}>Create Order Testing</Typography.Title>
          <section className="my-2">
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Typography.Title level={4} className="text-[#2e2a5b]">
                Order Header
              </Typography.Title>{' '}
              <Form form={orderHeaderForm} layout="vertical">
                <Row gutter={12}>
                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item name="tenant_id" initialValue={tenentName} label="Retailer Code">
                      <Input size="large" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item name="business_unit" initialValue={tenentName} label="Business Group ID">
                      <Input size="large" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item name="order_type" label="Order Type">
                      <Select
                        disabled
                        options={[{ label: 'Forward', value: 'Forward' }]}
                        defaultValue={'Forward'}
                        size="large"
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item name="currency_code" label="Currency">
                      <Select options={currencyList} defaultValue={userCurrency} disabled size="large"></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item
                      name="order_number"
                      initialValue={generateUUIDV4()}
                      label="Order Number"
                      rules={[{ required: true, message: 'Field is required' }]}
                    >
                      <Input placeholder="Enter Order Number" size="large" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item name="promise_number" initialValue={promiseNumber} label="Promise Number">
                      <Input placeholder="Promise Number" disabled size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item name="promise_status" label="Promise Status">
                      <Input placeholder="Promise Status" disabled size="large" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={8} lg={6} xl={4}>
                    <Form.Item name="order_dtatus" initialValue={'OPEN'} label="Order Status">
                      <Input placeholder="Order Status" disabled size="large" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </section>
          <div
            style={{
              height: '100%',
              width: '100%',
              background: '#9b9b9b29',
              padding: '20px',
              borderRadius: '20px',
              marginBottom: '15px'
            }}
          >
            <Typography.Title level={4} className="text-[#2e2a5b]">
              Buyer Info
            </Typography.Title>
            <Form layout="vertical" form={customerSelectionForm}>
              <Row gutter={[12, 12]} align={'middle'}>
                <Col xs={12} md={8} lg={6} xl={4}>
                  <Form.Item name={'customerId'} label="Select Customer">
                    <Select
                      size="large"
                      placeholder="Select Customer"
                      onChange={async id => {
                        setLoading(true);
                        const {
                          email,
                          firstName,
                          lastName,
                          phoneNumber,
                          alternateId,
                          date_of_birth,
                          new_buyer,
                          customer_segments,
                          tier
                        } = customerDataFromApi.find(value => value.id === id) || {};
                        const { data } = await getCustomerLoyaltyData(id as string);

                        const { data: priceListInfo } = await priceService.getPricelistForCustomer(id as string);

                        fetchLoyaltyEarnInfo();
                        setCustomerLoyaltyData(data || ({} as any));
                        setCustomerDetails({
                          email,
                          firstName,
                          lastName,
                          phoneNumber,
                          alternateId,
                          tier,
                          id,
                          date_of_birth,
                          new_buyer,
                          customer_segments,
                          priceListDetails: priceListInfo?.price_list
                        });
                        customerInfoForm.setFieldsValue({
                          first_name: firstName,
                          last_name: lastName,
                          email_id: email,
                          phone_number: phoneNumber,
                          date_of_birth: date_of_birth
                        });

                        setLoading(false);
                      }}
                      options={customerDataFromApi.map(({ id, firstName, lastName }) => ({
                        label: `${firstName} ${lastName}`,
                        value: id
                      }))}
                    />
                  </Form.Item>
                </Col>
                {!_.isEmpty(customerDetails) && (
                  <>
                    <Col xs={12} md={8} lg={6} xl={4}>
                      <Form.Item label="Customer Tier">
                        <Input size="large" placeholder="..." readOnly value={customerDetails?.tier} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6} xl={4}>
                      <Form.Item label="Pricelist">
                        <Input
                          size="large"
                          placeholder="..."
                          readOnly
                          value={customerDetails.priceListDetails?.price_list_name}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
            <section>
              <Form
                form={customerInfoForm}
                layout="vertical"
                onAbort={() => {
                  displayErrorNotifications([{ message: 'Complete Customer Infomation' }]);
                }}
              >
                <Row gutter={[12, 12]}>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item name={'first_name'} label="First Name">
                      <Input size="large" placeholder="Enter First Name" />
                    </Form.Item>
                  </Col>{' '}
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item name={'middle_name'} label="Middle Name">
                      <Input size="large" placeholder="Enter Middle Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item name={'last_name'} label="Last Name">
                      <Input size="large" placeholder="Enter Last Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item name={'legal_name'} label="Legal Name">
                      <Input size="large" placeholder="Enter Legal Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item name={'organization'} label="Organisation">
                      <Input size="large" placeholder="Enter Organisation" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item
                      name={'email_id'}
                      label="Email ID"
                      rules={[{ required: true, message: 'Email is required' }]}
                    >
                      <Input size="large" placeholder="Enter Email ID" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item
                      name={'phone_number'}
                      label="Phone Number"
                      rules={[{ required: true, message: 'Phone Number is required' }]}
                    >
                      <Input size="large" placeholder="Enter Phone Number" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item name={'trade_name'} label="Trade Name">
                      <Input size="large" placeholder="Enter Trade Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Item name={'date_of_birth'} label="Date Of Birth">
                      <Input size="large" placeholder="Enter Date Of Birth" />
                    </Form.Item>
                  </Col>{' '}
                </Row>
              </Form>
            </section>
          </div>
          <div className="my-4">
            <div
              style={{
                height: '100%',
                width: '100%',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Typography.Title level={4} className="text-[#2e2a5b]">
                Address Info
              </Typography.Title>
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      background: '#9b9b9b29',
                      padding: '20px',
                      borderRadius: '20px',
                      marginBottom: '15px'
                      // border: '1px solid gray'
                    }}
                  >
                    <Typography.Title level={4} className="text-[#2e2a5b]">
                      Ship To
                    </Typography.Title>
                    <Form form={shippingInfoForm} scrollToFirstError layout="vertical">
                      <Row gutter={12}>
                        <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                          <Form.Item
                            name={'address_line1'}
                            label="Address Line 1"
                            rules={[{ required: true, message: 'Address is required' }]}
                          >
                            <Input size="large" placeholder="Enter Address Line 1" />
                          </Form.Item>
                        </Col>{' '}
                        <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                          <Form.Item name={'address_line2'} label="Address Line 2">
                            <Input size="large" placeholder="Enter Address Line 2" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                          <Form.Item
                            name={'location'}
                            label="Location"
                            rules={[{ required: true, message: 'Location is required' }]}
                          >
                            <Input size="large" placeholder="Enter Location" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                          <Form.Item
                            name={'postal_code'}
                            label="Postal Code"
                            rules={[{ required: true, message: 'Postal Code is required' }]}
                          >
                            <Input size="large" placeholder="Enter Postal Code" />
                          </Form.Item>
                        </Col>{' '}
                        <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                          <Form.Item
                            name={'country'}
                            label="Country"
                            rules={[{ required: true, message: 'Country Field is required' }]}
                          >
                            <Select
                              size="large"
                              onChange={value => setSelectedShippingCountry(value)}
                              options={[
                                { label: '🇮🇳 India', value: 'India' },
                                { label: '🇨🇱 Chile', value: 'Chile' }
                              ]}
                              placeholder="Select Country"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                          <Form.Item
                            name={'state'}
                            label="Region"
                            rules={[{ required: true, message: 'Region is required' }]}
                          >
                            <Select
                              size="large"
                              options={stateList[selectedShippingCountry]}
                              placeholder="Select Region"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      background: '#9b9b9b29',
                      padding: '20px',
                      borderRadius: '20px',
                      marginBottom: '15px'
                      // border: '1px solid gray'
                    }}
                  >
                    <section>
                      <Typography.Title level={4} className="text-[#2e2a5b]">
                        Bill To
                      </Typography.Title>
                      <Form layout="vertical" form={billingInfoForm}>
                        <Row gutter={12}>
                          <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                            <Form.Item
                              name={'address_line1'}
                              label="Address Line 1"
                              rules={[{ required: true, message: 'Address is required' }]}
                            >
                              <Input size="large" placeholder="Enter Address Line 1" />
                            </Form.Item>
                          </Col>{' '}
                          <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                            <Form.Item name={'address_line2'} label="Address Line 2">
                              <Input size="large" placeholder="Enter Address Line 2" />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                            <Form.Item
                              name={'location'}
                              label="Location"
                              rules={[{ required: true, message: 'Location is required' }]}
                            >
                              <Input size="large" placeholder="Enter Location" />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                            <Form.Item
                              name={'postal_code'}
                              label="Postal Code"
                              rules={[{ required: true, message: 'Postal code is required' }]}
                            >
                              <Input size="large" placeholder="Enter Postal Code" />
                            </Form.Item>
                          </Col>{' '}
                          <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                            <Form.Item
                              name={'country'}
                              label="Country"
                              rules={[{ required: true, message: 'County Field is required' }]}
                            >
                              <Select
                                size="large"
                                options={[
                                  { label: '🇮🇳 India', value: 'India' },
                                  { label: '🇨🇱 Chile', value: 'Chile' }
                                ]}
                                onChange={value => setSelectedBillingCountry(value)}
                                placeholder="Select Country"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                            <Form.Item
                              name={'state'}
                              label="Region"
                              rules={[{ required: true, message: 'Region is required' }]}
                            >
                              <Select
                                size="large"
                                options={stateList[selectedBillingCountry]}
                                placeholder="Select State"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Checkbox
                          onChange={e => {
                            const checked = e.target.checked;
                            if (checked) {
                              billingInfoForm.setFieldsValue(shippingInfoForm.getFieldsValue());
                            } else {
                              billingInfoForm.resetFields();
                            }
                          }}
                        >
                          Use shipping address as billing address{' '}
                        </Checkbox>
                      </Form>
                    </section>
                  </div>
                </Col>
              </Row>
              {/* <div className="flex mt-4">
                <Button
                  className="bg-[#41729F]"
                  type="primary"
                  // disabled={itemList.length >= 5}
                  size="large"
                  // onClick={handleClickAddItem}
                >
                  <BoldButtonLabel labelText="Update Info" />
                </Button>
              </div> */}
            </div>
            <section>
              <div className="flex justify-between my-2">
                <Typography.Title level={4} className="text-[#2e2a5b]">
                  Item Info
                </Typography.Title>
                <Button
                  className="bg-[#41729F]"
                  type="primary"
                  // disabled={itemList.length >= 5}
                  disabled={selectedFullfillmentOptions.length !== 0}
                  size="large"
                  onClick={handleClickAddItem}
                >
                  <BoldButtonLabel labelText="Add Items" />
                </Button>
              </div>
              <Table
                id="popup"
                scroll={{ x: 1700 }}
                bordered
                size="small"
                pagination={false}
                columns={tableColumns}
                dataSource={itemList}
              ></Table>
            </section>

            <div>
              {selectedFullfillmentOptions.length !== 0 && (
                <Card
                  className="my-4 max-w-1/2 w-max"
                  bodyStyle={{ padding: '.5rem' }}
                  bordered
                  title={<div className="text-center h-full">Selected Options</div>}
                >
                  <div className="flex justify-end">
                    <Button type="link" onClick={() => removeSelectedOption()} className="text-red-600">
                      <DeleteOutlined className="text-red-500 text-xl" title="Delete Product" />
                    </Button>
                  </div>
                  {selectedFullfillmentOptions?.map((option: any) => {
                    return (
                      <section className="flex flex-col gap-4 bg-[#fafafa] px-4 py-4 rounded-lg">
                        <div className="flex gap-2">
                          {' '}
                          <div>
                            <Typography.Text className="text-[#898c98]">Item</Typography.Text>
                          </div>
                          <div
                            className="font-semibold"
                            style={{
                              display: 'inline-block',
                              width: '250px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {option?.skuname}
                          </div>
                        </div>
                        <div className="flex gap-2">
                          {' '}
                          <div>
                            <Typography.Text className="text-[#898c98]">Delivery Method</Typography.Text>
                          </div>
                          <div className="font-semibold">{option?.delivery_method}</div>
                        </div>
                        <div className="flex gap-2">
                          {' '}
                          <div>
                            <Typography.Text className="text-[#898c98]">Delivery Type</Typography.Text>
                          </div>
                          <div className="font-semibold">{option?.delivery_speed}</div>
                        </div>
                        <div className="flex gap-2">
                          {' '}
                          <div>
                            <Typography.Text className="text-[#898c98]">Delivery Charges</Typography.Text>
                          </div>
                          <div className="font-semibold">
                            {!option.delivery_cost ? 'FREE' : option?.delivery_cost?.amount}
                          </div>
                        </div>
                        <div className="flex gap-2">
                          {' '}
                          <div>
                            <Typography.Text className="text-[#898c98]">Estimated Delivery date</Typography.Text>
                          </div>
                          <div className="font-semibold">{option?.promise_delivery_date}</div>
                        </div>
                      </section>
                    );
                  })}
                </Card>
              )}
            </div>

            <div className="flex justify-between flex-wrap mt-4 ">
              {(!_.isEmpty(promotionReccomendation?.header_other_offers) ||
                !_.isEmpty(promotionReccomendation?.header_promo_applied) ||
                !_.isEmpty(promotionReccomendation?.header_promo_reccoms)) && (
                <section>
                  {!_.isEmpty(promotionReccomendation?.header_promo_applied) && (
                    <div>
                      <Typography.Title level={5}>Applied Promotions</Typography.Title>
                      <section className="flex flex-wrap gap-4">
                        {promotionReccomendation?.header_promo_applied.map((promotion: any, index: number) => {
                          return (
                            <Card key={index}>
                              <section className="flex  gap-4">
                                {' '}
                                <div className="my-auto">
                                  <IconTag className="text-[#274472]" />
                                </div>
                                <section className="my-auto">
                                  <Typography.Text className="font-bold">{promotion?.title}</Typography.Text>
                                </section>
                                {/* <Button type="dashed">
                                  <Typography.Text copyable>{promotion?.code}</Typography.Text>
                                </Button> */}
                              </section>
                              <div className="mt-2">
                                <FieldDisplay
                                  label={'Discount'}
                                  value={priceHelpers.formatPriceToLocaleCurrency({
                                    amount: promotion?.discount_info?.discount || 0,
                                    baseCurrency,
                                    userCurrency
                                  })}
                                />
                              </div>
                            </Card>
                          );
                        })}
                      </section>
                    </div>
                  )}
                  {!_.isEmpty(promotionReccomendation?.header_promo_reccoms) && (
                    <div className="mt-4">
                      <Typography.Title level={5}>Recommended Promotions</Typography.Title>
                      <section className="flex flex-wrap gap-4">
                        {promotionReccomendation?.header_promo_reccoms.map((promotion: any, index: number) => {
                          return (
                            <Card key={index} className="w-[400px]">
                              <section className="flex gap-4">
                                {' '}
                                <div className="my-auto">
                                  <IconTag className="text-[#274472]" />
                                </div>
                                <section className="my-auto">
                                  <Typography.Text className="font-bold">{promotion?.title}</Typography.Text>
                                </section>
                                {/* <Button type="dashed">
                                  <Typography.Text copyable>{promotion?.code}</Typography.Text>
                                </Button> */}
                              </section>
                              <div className="mt-2">
                                <Alert message={getRecommendedActionText(promotion)} type="warning" showIcon />
                              </div>
                            </Card>
                          );
                        })}
                      </section>
                    </div>
                  )}
                  {!_.isEmpty(promotionReccomendation?.header_other_offers) && (
                    <div className="mt-4">
                      <Typography.Title level={5}>Other Promotions</Typography.Title>
                      <section className="flex flex-wrap gap-4">
                        {promotionReccomendation?.header_other_offers.map((promotion: any, index: number) => {
                          return (
                            <Card key={index}>
                              <section className="flex  gap-4">
                                <div className="my-auto">
                                  <IconTag className="text-[#274472]" />
                                </div>
                                <section className="my-auto">
                                  <Typography.Text className="font-bold">{promotion?.title}</Typography.Text>
                                </section>
                                {/* <Button type="dashed">
                                  <Typography.Text copyable>{promotion?.code}</Typography.Text>
                                </Button> */}
                              </section>
                              <div className="mt-2">
                                <FieldDisplay
                                  label={'Discount'}
                                  value={priceHelpers.formatPriceToLocaleCurrency({
                                    amount: promotion?.discount_info?.discount || 0,
                                    baseCurrency,
                                    userCurrency
                                  })}
                                />
                              </div>
                            </Card>
                          );
                        })}
                      </section>
                    </div>
                  )}
                </section>
              )}
              <section>
                {!_.isEmpty(promiseInfo) && (
                  <Card className="my-4" bordered title="Fulfillment Option Summary">
                    <section className="flex flex-wrap flex-col gap-4">
                      <FieldDisplay label="Delivery Type" value={promiseValueToLabel[promiseInfo.delivery_type]} />
                      <FieldDisplay label="Delivery Method" value={promiseValueToLabel[promiseInfo.delivery_method]} />
                      <FieldDisplay label="Delivery Date" value={promiseInfo.delivery_date} />
                      <FieldDisplay
                        label="Delivery Charge"
                        value={priceHelpers.formatPriceToLocaleCurrency({
                          amount: promiseInfo.delivery_cost,
                          baseCurrency,
                          userCurrency
                        })}
                      />
                    </section>
                  </Card>
                )}
              </section>

              {!_.isEmpty(itemList) && (
                <Card
                  className="my-4"
                  bodyStyle={{ padding: '.5rem' }}
                  bordered
                  title={<div className="text-center h-full">Order Summary</div>}
                >
                  <section className="flex gap-4 bg-[#fafafa] px-4 py-4 rounded-lg">
                    <div className="w-[100px]">
                      <Typography.Text className="font-bold">Item level charges</Typography.Text>
                    </div>
                    <section className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <div>
                          <Typography.Text className="text-[#898c98]">Discount</Typography.Text>
                        </div>

                        <div>Promotion</div>
                        <div className="font-semibold">
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: calculateTotalLinesPromoDiscount(),
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <Typography.Text className="text-[#898c98]">Charges </Typography.Text>
                        <div>Misc</div>
                        <div className="font-semibold">
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: calculateMiscTotal(),
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <Typography.Text className="text-[#898c98]">Charges </Typography.Text>
                        <div>Convenience</div>
                        <div className="font-semibold">
                          {' '}
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: calculateConvenienceTotal(),
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <Typography.Text className="text-[#898c98]">Charges </Typography.Text>
                        <div>Gift</div>
                        <div className="font-semibold">
                          {' '}
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: calculateGiftTotal(),
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>
                    </section>
                  </section>

                  <section className="flex gap-4 mt-2 bg-[#fafafa] px-4 py-4 rounded-lg">
                    <div className="w-[100px]">
                      <Typography.Text className="font-bold">Header level charges</Typography.Text>
                    </div>
                    <section className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <div>
                          <Typography.Text className="text-[#898c98]">Discount</Typography.Text>
                        </div>

                        <div>Promotion</div>
                        <div className="font-semibold">
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: calculateHeaderLevelPromoDiscount(),
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <div>
                          <Typography.Text className="text-[#898c98]">Discount</Typography.Text>
                        </div>

                        <div>Coupon {!_.isEmpty(applyCouponResponse) ? `(${getAppliedCouponCode()})` : ''}</div>

                        <div className="font-semibold flex gap-2">
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: getCouponDiscountFromPromoService(),
                            baseCurrency,
                            userCurrency
                          })}
                          {!_.isEmpty(applyCouponResponse) && (
                            <DeleteOutlined
                              className="text-red-500 text-sm"
                              role="button"
                              title="Remove Coupon"
                              onClick={() => {
                                const selectedCode = selectedCouponCode;
                                setSelectedCouponCode('');
                                setApplyCouponResponse({});

                                displaySuccessNotification({ message: `Coupon ${selectedCode} removed` });
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <Typography.Text className="text-[#898c98]">Discount </Typography.Text>

                        <div>Loyalty Points</div>
                        <div className="font-semibold">
                          {`${priceHelpers.formatPriceToLocaleCurrency({
                            amount: loyaltyRedeemValue * 0.01, //!API need to send converison factor
                            baseCurrency,
                            userCurrency
                          })} (${loyaltyRedeemValue} Pts redeemed)`}
                        </div>
                      </div>

                      <FieldDisplay label="Loyalty Earned" value={loyaltyEarnData?.reward_value || 0 + ' Pts'} />

                      <div className="flex gap-2">
                        <Typography.Text className="text-[#898c98]">Charges</Typography.Text>
                        <div>Shipping</div>
                        <div className="font-semibold">
                          {' '}
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: promiseInfo?.delivery_cost || 0,
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>
                      <div className="flex gap-2 items-center">
                        <Typography.Text className="text-[#898c98]">Charges</Typography.Text>
                        <div>Misc</div>
                        <div className="font-semibold">
                          <InputNumber
                            placeholder="..."
                            value={headerMiscCharges}
                            onChange={e => {
                              setHeaderMiscCharges(e as number);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-2 items-center">
                        <Typography.Text className="text-[#898c98]">Charges </Typography.Text>
                        <div>Convenience</div>
                        <div className="font-semibold">
                          <InputNumber
                            placeholder="..."
                            value={headerConvenienceCharges}
                            onChange={e => {
                              setHeaderConvenienceCharges(e as number);
                            }}
                          />
                        </div>
                      </div>
                    </section>
                  </section>

                  <section className="flex gap-4 mt-2 bg-[#fafafa] px-4 py-4 rounded-lg">
                    <div className="w-[100px]">
                      <Typography.Text className="font-bold">Total Value</Typography.Text>
                    </div>
                    <section className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <div>
                          <Typography.Text className="text-[#898c98]">Total Discount</Typography.Text>
                        </div>

                        <div className="font-semibold">
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount: calculateTotalDiscountView(),
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <Typography.Text className="text-[#898c98]">Total Other Charges</Typography.Text>

                        <div className="font-semibold">
                          {' '}
                          {priceHelpers.formatPriceToLocaleCurrency({
                            amount:
                              calculateConvenienceTotal() +
                              calculateMiscTotal() +
                              calculateGiftTotal() +
                              (promiseInfo?.delivery_cost || 0) +
                              headerConvenienceCharges +
                              headerMiscCharges,
                            baseCurrency,
                            userCurrency
                          })}
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <Typography.Text className="text-xl font-bold">
                          Total Payable Amount -{' '}
                          <span className="font-bold text-black">
                            {priceHelpers.formatPriceToLocaleCurrency({
                              amount: calculateFinalOrderTotalWithCoupon() + (promiseInfo?.delivery_cost || 0),
                              baseCurrency,
                              userCurrency
                            })}
                          </span>
                        </Typography.Text>
                      </div>
                    </section>
                  </section>
                </Card>
              )}
            </div>

            <section className="mt-2">
              <div className="flex gap-4 flex-wrap">
                <Button
                  size="large"
                  type="primary"
                  disabled={itemList.length === 0}
                  loading={false}
                  onClick={() => {
                    setLoyaltyModalVisible(true);
                  }}
                >
                  <BoldButtonLabel labelText="Redeem Loyalty"></BoldButtonLabel>
                </Button>

                <Button
                  size="large"
                  type="primary"
                  disabled={itemList.length === 0}
                  loading={promotionLoader}
                  onClick={() => {
                    handleFetchApplicablePromotions();
                  }}
                >
                  <BoldButtonLabel labelText="View Applicable Coupons"></BoldButtonLabel>
                </Button>
                {/* <FieldDisplay label="Total Point Balance" value={customerLoyaltyData.reward_value} /> */}

                <Button
                  disabled={itemList.length === 0}
                  size="large"
                  type="primary"
                  onClick={handleOnClickFeasibleFulfillment}
                >
                  <BoldButtonLabel labelText="Get Feasible Fulfillment Options"></BoldButtonLabel>
                </Button>

                {orderHeaderForm.getFieldsValue()?.promise_status === 'COMMITTED' && (
                  <Button
                    disabled={itemList.length === 0 || loader}
                    size="large"
                    type="primary"
                    loading={loader}
                    // onClick={handleCheckout}
                    onClick={redeemPromotionsFromPromoService}
                  >
                    <BoldButtonLabel labelText="Checkout"></BoldButtonLabel>
                  </Button>
                )}

                {selectedFullfillmentOptions.length !== 0 &&
                  orderHeaderForm.getFieldsValue()?.promise_status === 'OPEN' && (
                    <Button
                      disabled={itemList.length === 0 || loader}
                      size="large"
                      type="primary"
                      loading={loader}
                      onClick={() => handleOnCommit('COMMITTED')}
                    >
                      <BoldButtonLabel labelText="Commit"></BoldButtonLabel>
                    </Button>
                  )}
                {orderHeaderForm.getFieldsValue()?.promise_status === 'COMMITTED' && (
                  <Button
                    disabled={itemList.length === 0 || loader}
                    size="large"
                    type="primary"
                    loading={loader}
                    onClick={() => handleOnCommit('OPEN')}
                  >
                    <BoldButtonLabel labelText="UnCommit"></BoldButtonLabel>
                  </Button>
                )}
              </div>
            </section>
          </div>
        </Card>
      </div>

      <Modal
        open={loyaltyModalVisible}
        footer={null}
        title={<Typography.Title level={4}>Redeem Loyalty Points</Typography.Title>}
        width={500}
        centered
        closable
        onCancel={() => setLoyaltyModalVisible(false)}
      >
        {' '}
        <Form form={redeemForm}>
          <section className="my-4 flex flex-col gap-4 flex-wrap">
            <FieldDisplay label="Total Point Balance" value={customerLoyaltyData.reward_value} />
            <FieldDisplay label="Redeemable Points" value={customerLoyaltyData.redeemable_value} />

            <Form.Item
              name="redeem_value"
              label={<span className="text-[#898c98]">Redeem Points</span>}
              className="mb-0"
            >
              <InputNumber
                min={0}
                max={customerLoyaltyData.redeemable_value}
                className="w-full"
                size="large"
                placeholder="Eg: 100"
              ></InputNumber>
            </Form.Item>

            <FieldDisplay label="Points Multiplier" value="0.01" />
            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.redeem_value !== curr.redeem_value}>
              {form => {
                const { redeem_value } = form.getFieldsValue();
                return (
                  <>
                    <FieldDisplay label="Total Discount Amount" value={((redeem_value || 0) * 0.01).toFixed(2)} />
                    <FieldDisplay
                      label="Remaining Points"
                      value={(customerLoyaltyData.redeemable_value || 0) - (redeem_value || 0)}
                    />
                  </>
                );
              }}
            </Form.Item>
          </section>
          <Button
            block
            onClick={async () => {
              setLoyaltyRedeemValue(redeemForm.getFieldsValue().redeem_value || 0);
              displaySuccessNotification({ message: 'Loyalty Applied!' });

              setLoyaltyModalVisible(false);
            }}
            size="large"
            type="primary"
          >
            <BoldButtonLabel labelText="Apply" />
          </Button>{' '}
        </Form>
      </Modal>

      <Modal
        open={showPromotionModal}
        footer={false}
        width={900}
        centered
        closable
        onCancel={() => {
          setShowPromotionModal(false);
          setSelectedCouponCode('');
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b]">
          Apply coupons
        </Typography.Title>
        <Form
          layout="vertical"
          onFinish={({ custom_coupon_code }) => {
            setSelectedCouponCode(custom_coupon_code);
            applyCouponFromPromoService(custom_coupon_code);
          }}
        >
          <div className="flex gap-2">
            <Form.Item name={'custom_coupon_code'}>
              <Input size="large" placeholder="Enter coupon code"></Input>
            </Form.Item>
            <Button htmlType="submit" type="primary" size="large">
              <BoldButtonLabel labelText="Apply" />
            </Button>
          </div>
        </Form>

        <Typography.Title level={4} className="text-[#2e2a5b]">
          Applicable Coupons
        </Typography.Title>
        <Radio.Group
          className="w-full"
          // value={[]}
          value={selectedCouponCode}
          onChange={e => {
            const value = e.target.value;
            setSelectedCouponCode(value);
          }}
        >
          <Row gutter={[12, 12]}>
            {couponPromotionList.data?.map(
              ({ title, code, promotionType }: { title: any; code: any; promotionType: any }, index: number) => {
                return (
                  <Col xs={24} md={12} key={title}>
                    <Card bordered>
                      <div className="relative">
                        <Radio className="w-full" value={code}>
                          <div className="flex  gap-4 items-center justify-between w-full">
                            <div className="font-bold">
                              <div>{title}</div>
                            </div>
                            {promotionType === 'COUPON' && (
                              <Button type="dashed">
                                <Typography.Text copyable>{code}</Typography.Text>
                              </Button>
                            )}{' '}
                          </div>

                          {index === 0 && (
                            <div className="absolute -top-8 -right-8">
                              <Tag color="green">Recommended</Tag>
                            </div>
                          )}
                        </Radio>
                      </div>
                    </Card>
                  </Col>
                );
              }
            )}
          </Row>
        </Radio.Group>
        {!couponPromotionList.data?.length && (
          <div className="flex items-center justify-center">
            <div className="mx-auto">
              <h2 className="text-center">No Coupons found</h2>
            </div>
          </div>
        )}

        {couponPromotionList.data?.length !== 0 && (
          <Row gutter={[12, 12]} className="mt-4">
            <Col xs={12}>
              <Button size="large" block type="primary" onClick={() => applyCouponFromPromoService()}>
                <BoldButtonLabel labelText="Apply" />
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                size="large"
                block
                onClick={() => {
                  setShowPromotionModal(false);
                  setSelectedCouponCode('');
                }}
              >
                <BoldButtonLabel labelText="Cancel" />
              </Button>
            </Col>
          </Row>
        )}
      </Modal>
      <Modal
        open={promiseModalVisible}
        footer={null}
        width={1400}
        centered
        closable
        onCancel={() => setPromiseModalVisible(false)}
      >
        <div style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
          <Typography.Title level={4} className="text-[#2e2a5b]">
            Select Fulfilment Option
          </Typography.Title>
          <Row gutter={[16, 16]}>
            {(feasibleData.promise_groups || []).map((items: any, index: number) => {
              let productName = items?.lines.map((name: any) => name.sku_id).join(', ');
              let selectedValue =
                items?.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id).length === 0
                  ? ''
                  : items.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id)[0]?.option_id;
              // const anyFalse = items?.promise_options?.some((option: any) =>
              //   option.skus?.some((sku: any) => sku.fulfillable_state === false)
              // );

              const anyFalse = items?.promise_options?.some((option: any) => option?.fulfillable_state === false); //changed this for transfer order
              return (
                <Col xs={24} sm={12} key={index}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      background: '#9b9b9b29',
                      padding: '20px',
                      borderRadius: '20px',
                      marginBottom: '15px'
                    }}
                  >
                    <div
                      style={{
                        display: 'inlineBlock',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      <div className="mb-2">
                        <Typography.Text className="font-bold">Items</Typography.Text> : {productName}
                      </div>
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          background: '#9b9b9b29',
                          padding: '20px',
                          borderRadius: '20px',
                          marginBottom: '15px',
                          maxHeight: '330px',
                          overflow: 'auto'
                        }}
                      >
                        {items?.promise_options.length === 0 ? (
                          'No Data Found'
                        ) : (
                          <Radio.Group value={selectedValue}>
                            <div className="flex flex-col">
                              {(items?.promise_options || []).map((option: any, index: any) => {
                                let fulfilableState = !option?.fulfillable_state;
                                return (
                                  <Radio
                                    key={index}
                                    value={option?.option_id}
                                    onChange={(e: any) => handleChange(e, items)}
                                    disabled={fulfilableState}
                                  >
                                    <div className="mb-3">
                                      <div>
                                        <span className="font-bold">Ship Node Name - </span>
                                        <span> {option?.ship_node_name}</span>
                                      </div>
                                      <div>
                                        <span className="font-bold">Delivery Method -</span>{' '}
                                        <span>{option?.delivery_method}</span>
                                      </div>
                                      {option.delivery_method !== 'PICK_UP' && (
                                        <div>
                                          <span className="font-bold">Delivery Type - </span>
                                          <span> {option?.delivery_speed}</span>
                                        </div>
                                      )}
                                      <div>
                                        <span className="font-bold">Delivery Charge -</span>{' '}
                                        <span> {!option?.delivery_cost ? 'Free' : option?.delivery_cost?.amount} </span>
                                      </div>
                                      <div>
                                        <span className="font-bold">Estimated Delivery Date -</span>{' '}
                                        <span>{option?.promise_delivery_date}</span>
                                      </div>
                                      {anyFalse && (
                                        <div
                                          className="flex flex-col"
                                          style={{ padding: '10px', background: '#cac8c8' }}
                                        >
                                          <Typography.Text className="font-bold underline">
                                            Not Fulfillable Items
                                          </Typography.Text>
                                          <div></div>
                                          <div>
                                            {option?.inventory_sources?.skus?.map((skuItems: any) => {
                                              let calculation = skuItems.tag_details
                                                .map((cv: any) => cv.fulfillable_quantity)
                                                .map(
                                                  (item: any) =>
                                                    item.number_of_units * (item.unit_size / item.unit_size_fraction)
                                                )
                                                .reduce((acc: any, cv: any) => acc + cv);

                                              return (
                                                skuItems?.fulfillable_state === true && (
                                                  <div className="flex flex-row gap-2">
                                                    <div>
                                                      <Typography.Text className="font-bold">Sku:</Typography.Text>
                                                      {skuItems.sku}
                                                    </div>
                                                    <div>
                                                      <Typography.Text className="font-bold">
                                                        Available quantity:{' '}
                                                      </Typography.Text>
                                                      {calculation}
                                                    </div>
                                                  </div>
                                                )
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </Radio>
                                );
                              })}
                            </div>
                          </Radio.Group>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="my-2 flex justify-center gap-4">
            <Button type="primary" onClick={() => handleOnSaveFeasiable()} className="w-[300px] mt-2">
              <BoldButtonLabel labelText="Save"></BoldButtonLabel>
            </Button>
            <Button type="primary" onClick={() => setPromiseModalVisible(false)} className="w-[300px] mt-2">
              <BoldButtonLabel labelText="Close"></BoldButtonLabel>
            </Button>
          </div>
        </div>
        {/* </Form> */}
      </Modal>

      <Modal
        centered
        open={currentEditingField !== null}
        footer={null}
        closable
        onCancel={() => {
          miscEditform.resetFields();
          setCurrentEditingFieldId(null);
          setCurrentEditingField(null);
        }}
      >
        <Form
          layout="vertical"
          form={miscEditform}
          onFinish={values => {
            setEditableItemInfo(editableItemInfo => ({
              ...editableItemInfo,
              [currentEditingFieldId as string]: {
                ...editableItemInfo[currentEditingFieldId as string],
                [currentEditingField as string]: values[currentEditingField as string]
              }
            }));
            miscEditform.resetFields();
            setCurrentEditingFieldId(null);
            setCurrentEditingField(null);
          }}
        >
          {currentEditingField === 'gifting_instructions' && (
            <Form.Item name={'gifting_instructions'} label="Gifting Instructions">
              <Input.TextArea size="large" placeholder="Enter gifting instructions"></Input.TextArea>
            </Form.Item>
          )}

          {currentEditingField === 'misc_charge' && (
            <Form.Item name={'misc_charge'} label="Miscellaneous charges">
              <InputNumber size="large" className="w-full" placeholder="..."></InputNumber>
            </Form.Item>
          )}

          {currentEditingField === 'convenience_charge' && (
            <Form.Item name={'convenience_charge'} label="Convenience charges">
              <InputNumber size="large" className="w-full" placeholder="..."></InputNumber>
            </Form.Item>
          )}

          <Button type="primary" block htmlType="submit">
            <BoldButtonLabel labelText="Save" />
          </Button>
        </Form>
      </Modal>
    </PrimaryLayout>
  );
};

export default OrderCreation;
