import React from 'react';
import { promotionService } from '../../services/promotion.service';
import _ from '../../../../helpers/lodash';

const cache: Record<string, string[]> = {};

export const useSellers = () => {
  const [sellerDropdownFetching, setSellerDropdownFetching] = React.useState(false);
  const [sellerDropdownOptions, setSellerDropdownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchUserDropdownOptions = async (searchQuery: string) => {
    setSellerDropdownFetching(true);

    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setSellerDropdownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await promotionService.getSellers(searchQuery);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setSellerDropdownOptions(() => {
          return [...transformedResponse];
        });
      }
    }

    setSellerDropdownFetching(false);
  };

  const transformResponse = (data: any) => {
    return (data || []).map((value: string) => {
      return { label: value, value };
    });
  };

  const debouncedFetchSellerDropdownOptions = _.debounce(fetchUserDropdownOptions, 1000);

  return {
    debouncedFetchSellerDropdownOptions,
    sellerDropdownFetching,
    sellerDropdownOptions
  };
};
