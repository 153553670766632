import { LOGIN_URI, TENENT_PRODUCT_URI } from '../config/envConfig';
import httpClient from '../http/http.client';

const nodeUrls = {
  getPlansUri: () => `${'x'}/v1/plans`,
  createNodeUri: () => `${'x'}/v1/node`
};

const mockPlanList: any = {
  nebula: [
    {
      name: 'Plan 1',

      description: 'Test Plan',

      interval: 'MONTHLY',

      interval_count: '1',

      currency: 'INR',

      amount: 100000,

      trial_days: 10,

      taxable: true,

      tax_percent: '3',

      overage: '0.01',

      api_limit: '20000',

      domain: 'domain1',

      'business_ grp_id': '',

      tenant_id: '',

      plan_feature: [
        {
          feature_name: 'Earn & Burn'
        },

        {
          feature_name: 'Action Oriented'
        },

        {
          feature_name: 'Tiered'
        }
      ]
    }
  ]
};

const getAllPlansGroupedByDomain = async (domain?: string) => {
  const url = nodeUrls.getPlansUri();

  return {
    data: mockPlanList,
    errors: []
  };
};

const getProduct = async () => {
  const url = `${TENENT_PRODUCT_URI}/v1/products`
  const { data, errors } = await httpClient.get({ url });
  return { data, errors }
}

const getAllPlan = async (params: any) => {
  const url = `${TENENT_PRODUCT_URI}/v1/plans`
  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors }
}

const createPlan = async (bodyData: any) => {
  const url = `${TENENT_PRODUCT_URI}/v1/plans`
  const { data, errors } = await httpClient.post({ url, body: bodyData });
  return { data, errors }
}

const getSubscriptions = async (params: any) => {
  const url = `${TENENT_PRODUCT_URI}/v1/subscriptions`
  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors }
}

const getSubscriptionswithTenent = async (params: any, headers: any) => {
  const url = `${TENENT_PRODUCT_URI}/v1/subscriptions`
  const { data, errors } = await httpClient.get({ url, params, headers });
  return { data, errors }
}

const createSubscription = async (body: any) => {
  const url = `${TENENT_PRODUCT_URI}/v1/subscriptions`
  const { data, errors } = await httpClient.post({ url, body });
  return { data, errors }
}

const getBillControler = async (params: any) => {
  const url = `${TENENT_PRODUCT_URI}/v1/bills`
  const { data, errors } = await httpClient.get({ url, params });
  //getBillControlerDummyResp
  return { data, errors }
}

const getAllTenentData = async () => {
  const url = `${LOGIN_URI}/profiles/v1/tenants`
  const { data, errors } = await httpClient.get({ url });
  return { data, errors }
}


export const cbtProductService = {
  getAllPlansGroupedByDomain,
  getProduct,
  getAllPlan,
  createPlan,
  getSubscriptions,
  createSubscription,
  getSubscriptionswithTenent,
  getBillControler,
  getAllTenentData
};
