import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useAuth } from '../../../../stores/use-auth';
import { DASHBOARD_URI } from '../../../../config/envConfig';

interface ILoyalityDashboard {}

const LoyalityDashboard: React.FunctionComponent<ILoyalityDashboard> = props => {
  const { bussinessGroupName, tenentName } = useAuth(({ bussinessGroupName, tenentName }) => ({
    bussinessGroupName,
    tenentName
  }));

  const labelUrl = `${DASHBOARD_URI}elysian-analytics-dashboard/${bussinessGroupName}/${tenentName}`;
  return (
    <PrimaryLayout>
      <iframe title="Igad" style={{ height: '100%', border: 0 }} src={labelUrl}></iframe>
    </PrimaryLayout>
  );
};

export default LoyalityDashboard;
