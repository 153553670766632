import { Button, Col, Divider, Form, Input, Row, Select, TimePicker } from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../BoldButtonLabel';
import { phoneCountryCodeList } from '../../data/intl-data';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
interface IOperatorContactFormProps {
  operatorContactForm: any;
  operatorDetails: any;
  handleClickNext: (data: any) => void;
  handleClickBack: (data: any) => void;
  currentStep: number;
  isUpdate: boolean;
}

const OperatorContactForm: React.FunctionComponent<IOperatorContactFormProps> = ({
  handleClickBack,
  handleClickNext,
  isUpdate,
  operatorContactForm,
  operatorDetails
}) => {
  React.useEffect(() => {
    operatorContactForm.setFieldsValue(operatorDetails);
  }, [operatorDetails]);

  return (
    <>
      {' '}
      <Form form={operatorContactForm} layout="vertical">
        <div>
          <div className="mb-4">
            <span className="text-lg font-medium">Contacts</span>
          </div>

          <Form.List name="contacts">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Row>
                        <Col xs={12}>
                          <Divider orientation="left" plain>
                            <span className="text-lg">Contact - {index + 1} </span>
                          </Divider>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={12} md={6} xxl={4}>
                          <Form.Item
                            {...restField}
                            rules={[{ required: true, message: 'Please select contact type' }]}
                            name={[name, 'type']}
                            label="Type"
                          >
                            <Select
                              className="w-full"
                              options={[
                                { label: 'Manager', value: 'MANAGER' },
                                { label: 'Supervisor', value: 'SUPERVISOR' }
                              ]}
                              size="large"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={12} md={6} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'organisation']}
                            rules={[{ required: true, message: 'Organisation is required' }]}
                            label="Organization"
                          >
                            <Input className="w-full" size="large" placeholder="Enter organization"></Input>
                          </Form.Item>
                        </Col>

                        <Col xs={12} md={6} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'business_unit']}
                            rules={[{ required: true, message: 'Business unit is required' }]}
                            label="Business Unit"
                          >
                            <Input className="w-full" size="large" placeholder="Enter Business unit"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[{ required: true, message: 'Full name is required' }]}
                            label="Full Name"
                          >
                            <Input className="w-full" size="large" placeholder="Enter full name"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'email_id']}
                            rules={[{ required: true, message: 'Email is required', type: 'email' }]}
                            label="Email ID"
                          >
                            <Input className="w-full" size="large" placeholder="Enter email"></Input>
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xxl={6}>
                          <Form.Item
                            label="Phone Number"
                            name={[name, 'phone_number', 'number']}
                            rules={[{ required: true, message: 'Phone number is required' }]}
                          >
                            <Input
                              addonBefore={
                                <Form.Item
                                  initialValue={phoneCountryCodeList[0]?.value}
                                  noStyle
                                  name={[name, 'phone_number', 'country_code']}
                                  rules={[{ required: true, message: 'Country code is requiered' }]}
                                >
                                  <Select style={{ width: 120 }} options={phoneCountryCodeList} size="large"></Select>
                                </Form.Item>
                              }
                              min={0}
                              placeholder="Enter Phone Number"
                              size="large"
                            ></Input>
                          </Form.Item>
                        </Col>

                        <Col xs={12} md={6} xxl={4}>
                          <Form.Item
                            name={[name, 'phone_availability']}
                            label="Phone Availability"
                            rules={[{ required: true, message: 'Availability is required' }]}
                          >
                            <TimePicker.RangePicker
                              placeholder={['From', 'To']}
                              format={'H:mm A'}
                              size="large"
                            ></TimePicker.RangePicker>
                          </Form.Item>
                        </Col>
                        <div className="ml-2 my-2 flex justify-center items-center">
                          <DeleteOutlined
                            className="text-red-500 text-xl"
                            title="Delete Contact"
                            onClick={() => remove(name)}
                          />
                        </div>
                      </Row>
                    </React.Fragment>
                  );
                })}

                <Form.Item>
                  <Row justify={'start'}>
                    <Col xs={24} md={4}>
                      <Button onClick={() => add()} icon={<PlusOutlined />}>
                        <BoldButtonLabel labelText="Add Contact" />
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <Row gutter={[12, 12]}>
          <Col xs={12} md={4}>
            <Button size="large" onClick={() => handleClickBack(operatorContactForm.getFieldsValue())} block>
              <BoldButtonLabel labelText="Back"></BoldButtonLabel>
            </Button>
          </Col>
          <Col xs={12} md={4}>
            <Button
              type="primary"
              size="large"
              onClick={async () => {
                await operatorContactForm.validateFields();
                handleClickNext(operatorContactForm.getFieldsValue());
              }}
              block
            >
              <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'}></BoldButtonLabel>
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default OperatorContactForm;
