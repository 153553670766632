import { Card, Form } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CatalogForm from './catalog-form';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { priceListService } from '../services/pricelist.service';
import _ from '../../../helpers/lodash';
import React from 'react';
import { useLoader } from '../../../stores/use-loader';

interface ICatalogViewProps {}

const CatalogView: React.FunctionComponent<ICatalogViewProps> = props => {
  const { id } = useParams();
  const [createProductForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    loadInitialCustomeDetails();
  }, []);

  const loadFieldData = async () => {
    const { data, errors } = await priceListService.getSpecificCatalog(id as string);
    if (_.isEmpty(errors)) {
      populateEditFormWithData(data);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const loadInitialCustomeDetails = async () => {
    setLoading(true);
    loadFieldData();
    setLoading(false);
  };

  const handleOnGoBack = () => {
    const backUri = searchParams.get('goBackTo');
    if (backUri) {
      navigate(`${backUri}`);
    } else {
      navigate(`/promotion-engine/promotions/catalog`);
    }
  };

  const handleOnSave = async () => {
    const { name, description } = createProductForm.getFieldsValue();
    const bodyData = {
      name: name,
      description: description
    };
    const { errors } = await priceListService.createMasterData(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Successfully created catalog!' });
      handleOnGoBack();
    } else {
      displayErrorNotifications(errors);
    }
  };

  const populateEditFormWithData = (data: any) => {
    const { name, description } = data;
    createProductForm.setFieldsValue({
      name: name,
      description: description
    });
  };

  return (
    <PrimaryLayout>
      {' '}
      <div className="container mx-auto px-4">
        <Card>
          {' '}
          <CatalogForm
            buttonLabel={'View'}
            isUpdate={false}
            formInstance={createProductForm}
            handleSave={handleOnSave}
            handleGoBack={handleOnGoBack}
            resetData={() => {}}
          />
        </Card>
      </div>{' '}
    </PrimaryLayout>
  );
};

export default CatalogView;
