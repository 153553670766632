import { Button, Card, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';

interface IChannelModalProps {
  formInstance: FormInstance<any>;
  handleOnSave: () => void;
  handleOnModalClose?: () => void;
  modalHead: string;
}
const ChannelModal: React.FunctionComponent<IChannelModalProps> = ({
  formInstance,
  modalHead,
  handleOnSave,
  handleOnModalClose
}) => {
  return (
    <Form form={formInstance} layout="vertical" onFinish={handleOnSave}>
      <Card>
        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              name={'channel_name'}
              label="Enter channel Name"
              rules={[
                { required: true, message: "This field can't be empty" },
                { max: 50, message: 'Max limit is 50 characters' },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message: 'Only Letters, Numbers, Hyphens, and Underscores are allowed without spaces.'
                }
              ]}
            >
              <Input placeholder="Enter channel Name" size="large" allowClear />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              name={'channel_type'}
              label="Select Channel"
              rules={[
                { required: true, message: "This field can't be empty" }
                // { max: 100, message: 'Max limit is 100 characters' }
              ]}
            >
              <Select
                allowClear
                size="large"
                placeholder="Select Channel"
                options={[
                  { label: 'ECom', value: 'ECOM' },
                  { label: 'Store', value: 'STORE' },
                  { label: 'Marketplace', value: 'MARKETPLACE' }
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={24}>
            <Form.Item
              label="Description"
              name="channel_desc"
              rules={[
                // { required: true, message: "This field can't be empty" },
                { max: 100, message: 'Max limit is 100 characters' }
              ]}
            >
              <TextArea rows={4} placeholder="Description" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Button htmlType="submit" block type="primary" size="large" className="mb-4">
              <BoldButtonLabel labelText={modalHead} />
            </Button>
          </Col>
          <Col xs={12} md={8}>
            <Button onClick={handleOnModalClose} block size="large" className="mb-4">
              <BoldButtonLabel labelText="Cancel" />
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ChannelModal;
